import axios from "axios";
import { buildApiUrl, getBaseName } from "../utils/api-url";

export const updatePassword = async (
  old_password: string,
  new_password: string
) => {
  const resp = await axios.put(buildApiUrl("api/nc/change_password"), {
    old_password,
    new_password,
  });

  return resp.status === 200;
};

export const fetchMinPasswordLength = async () => {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "min_password_length",
    },
  });

  const minPasswordLength = Number(resp.data.option_value);

  return minPasswordLength;
};

export const fetchMinPasswordComplexity = async () => {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "min_password_complexity_options",
    },
  });

  return Number(resp.data.option_value);
};

export const sendPasswordResetEmail = async (user_name: string) => {
  const resp = await axios.post(buildApiUrl("api/password_resets"), {
    base_name: getBaseName(), // used by the server
    user_name,
  });

  return resp.status === 200;
};

interface ResetPasswordResponse {
  user_name: string;
}

export const resetPassword = async (password: string, token: string) => {
  const resp = await axios.put<ResetPasswordResponse>(
    buildApiUrl(`api/password_resets/${token}`),
    {
      base_name: getBaseName(), // used by the server
      password,
    }
  );

  // get username from response
  if (resp.status === 200) {
    return resp.data.user_name;
  }

  throw new Error("Invalid token");
};
