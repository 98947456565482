import { getEnv } from "./env";

type AppVersion = {
  version: string;
  env: string;
};

interface MSApp {
  [key: string]: AppVersion;
}

interface MSAppWindow extends Window {
  msapp?: {
    [key: string]: {
      version: string;
    };
  };
}

export function setAppVersion(appName: string, version: string) {
  const appVersion: AppVersion = {
    version: version || "unknown",
    env: getEnv(),
  };

  const msapp: MSApp = {};

  if (!(window as MSAppWindow).msapp) {
    (window as MSAppWindow).msapp = msapp;
  }

  msapp[appName] = appVersion;
}
