import { ROUTES } from "@react-ms-apps/common";
import { Route, Routes } from "react-router-dom";
import BANCompaniesEditor from "../Components/BANCompaniesEditor";
import CatalogManager from "../Components/CatalogManager";
import DeleteZeroBalanceStatements from "../Components/DeleteZeroBalanceStatements";
import MDMEditor from "../Components/MDMEditor";
import NotFoundPage from "../Components/NotFoundPage";
import OrderTypesEditor from "../Components/OrderTypesEditor";
import ReportRolesManager from "../Components/ReportRolesManager";
import StatementEditor from "../Components/StatementEditor";
import UploadProcessor from "../Components/UploadProcessor";
import UtilitiesEditor from "../Components/UtilitiesEditor";
import UtilitiesList from "../Components/UtilitiesList";

export default function UtilitiesApp() {
  return (
    <Routes>
      <Route path={ROUTES.UTILITY.UTILITIES_LIST} element={<UtilitiesList />} />

      <Route
        path={ROUTES.UTILITY.UPLOAD_PROCESSOR}
        element={<UploadProcessor />}
      />

      <Route
        path={ROUTES.UTILITY.UTILITIES_EDITOR}
        element={<UtilitiesEditor />}
      />

      <Route
        path={ROUTES.UTILITY.ORDER_TYPES_EDITOR}
        element={<OrderTypesEditor />}
      />

      <Route
        path={ROUTES.UTILITY.STATEMENT_SUMMARY_EDITOR}
        element={<StatementEditor />}
      />

      <Route
        path={ROUTES.UTILITY.REPORT_ROLES_MANAGER}
        element={<ReportRolesManager />}
      />

      <Route
        path={ROUTES.UTILITY.DELETE_ZERO_BALANCE_STATEMENTS}
        element={<DeleteZeroBalanceStatements />}
      />

      <Route
        path={ROUTES.UTILITY.BAN_COMPANIES_EDITOR}
        element={<BANCompaniesEditor />}
      />

      <Route
        path={`${ROUTES.UTILITY.CATALOG_MANAGER.ROOT}/*`}
        element={<CatalogManager />}
      />

      <Route path={`${ROUTES.UTILITY.MDM_EDITOR}`} element={<MDMEditor />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
