import { Block, CheckCircleOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { Order, OrderActionType, OrderStatusTypes } from "./types";

export default function OrderActions({
  order,
  onSubmit,
  submitting,
}: {
  order: Order | null;
  onSubmit: (actionType: OrderActionType, comments: string) => void;
  submitting: boolean;
}) {
  const [comments, setComments] = useState("");

  const orderActionInProgress = useMemo(() => {
    return submitting;
  }, [submitting]);

  const disableInputs = useMemo(() => {
    if (order && order.order_status_id === OrderStatusTypes.APPROVED_PENDING) {
      return true;
    }

    if (orderActionInProgress) {
      return true;
    }

    return false;
  }, [order, orderActionInProgress]);

  const needsAction = useMemo(() => {
    return (
      order &&
      [
        OrderStatusTypes.NEW,
        OrderStatusTypes.PENDING_APPROVAL_LEVEL_1,
      ].includes(order.order_status_id)
    );
  }, [order]);

  const orderDenied = useMemo(() => {
    return (
      order &&
      [OrderStatusTypes.DENIED_PENDING, OrderStatusTypes.DENIED_FINAL].includes(
        order.order_status_id
      )
    );
  }, [order]);

  const orderApproved = useMemo(() => {
    return order && !needsAction && !orderDenied;
  }, [needsAction, order, orderDenied]);

  if (!order || !order.order_subscribers) {
    return null;
  }

  if (orderDenied) {
    return (
      <div className="flex flex-col w-1/2 text-base text-primary-800 gap-2">
        <div className="flex flex-row items-center gap-4">
          <Block
            className="text-red-700"
            style={{
              height: 50,
              width: 50,
            }}
          />
          <div className="text-3xl font-bold">Order Denied</div>
        </div>
        <div>
          The order has already been denied. No further action is needed.
        </div>
        <div>
          If you believe there is a problem with the order, please contact
          support immediately.
        </div>
      </div>
    );
  }

  if (orderApproved) {
    return (
      <div className="flex flex-col w-1/2 text-base text-primary-800 gap-2">
        <div className="flex flex-row items-center gap-4">
          <CheckCircleOutline
            className="text-green-700"
            style={{
              height: 50,
              width: 50,
            }}
          />
          <div className="text-3xl font-bold">Order Approved</div>
        </div>
        <div className="text-base">
          The order has already been approved. No further action is needed.
        </div>
        <div className="text-base">
          If you believe there is a problem with the order, please contact
          support immediately.
        </div>
      </div>
    );
  }

  if (needsAction) {
    return (
      <div className="flex flex-col w-1/2 gap-2">
        <div className="flex flex-col gap-2">
          <TextField
            multiline
            label="Optional Comments"
            onChange={(e) => setComments(e.target.value)}
            disabled={disableInputs}
          />
          <div className="flex flex-row justify-center gap-2">
            <LoadingButton
              variant="contained"
              onClick={() => onSubmit("approve", comments)}
              disabled={disableInputs}
              loading={submitting}
            >
              Approve
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={() => onSubmit("deny", comments)}
              disabled={disableInputs}
              loading={submitting}
            >
              Disapprove
            </LoadingButton>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
