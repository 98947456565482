import { Button, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { ReportAuthorizedRolesDTO } from "@react-ms-apps/common";
import { useNav } from "@react-ms-apps/common/providers/NavProvider";
import { AuthorizedRoles } from "@react-ms-apps/common/types";
import { useCallback, useMemo, useState } from "react";
import { PlusCircle } from "react-bootstrap-icons";
import AuthorizedRoleToggle from "./AuthorizedRoleToggle";
import DeleteRoleButton from "./DeleteRoleButton";

export default function ReportRolesManagerTable({
  rows,
  loading,
  onSetUpdatedRow,
  onRemoveRow,
  onAddClick,
  isLoadingReportOptions,
}: {
  rows: ReportAuthorizedRolesDTO[];
  loading: boolean;
  onSetUpdatedRow: (row: ReportAuthorizedRolesDTO) => void;
  onRemoveRow: (id: number) => void;
  onAddClick: () => void;
  isLoadingReportOptions: boolean;
}) {
  const { navData } = useNav();

  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const tableHeight = useMemo(() => {
    const appHeader = document.querySelector("#app-header");
    const appHeaderHeight = appHeader?.clientHeight ?? 0;

    const pageHeader = document.querySelector("#report-roles-manager-header");
    const pageHeaderHeight = pageHeader?.clientHeight ?? 0;

    const description = document.querySelector(
      "#report-roles-manager-description"
    );
    const descriptionHeight = description?.clientHeight ?? 0;

    // set a default height
    if (!appHeader || !pageHeader || !description) return 500;

    return (
      window.innerHeight -
      (appHeaderHeight + pageHeaderHeight + descriptionHeight + 200)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navData]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "report",
        type: "string",
        headerName: "Report",
        flex: 1,
        editable: false,
      },
      {
        field: "authorized_roles_sysadmin",
        headerName: "Sys Admins",
        type: "boolean",
        minWidth: 100,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => (
          <AuthorizedRoleToggle
            {...params}
            authorizedRole={AuthorizedRoles.MS_sysadmin}
            onUpdateRow={onSetUpdatedRow}
          />
        ),
      },
      {
        field: "authorized_roles_admin",
        type: "boolean",
        headerName: "Admins",
        minWidth: 70,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => (
          <AuthorizedRoleToggle
            {...params}
            authorizedRole={AuthorizedRoles.MS_admin}
            onUpdateRow={onSetUpdatedRow}
          />
        ),
      },
      {
        field: "authorized_roles_telcoadmin",
        type: "boolean",
        headerName: "Telco Admins",
        minWidth: 100,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => (
          <AuthorizedRoleToggle
            {...params}
            authorizedRole={AuthorizedRoles.MS_telcoadmin}
            onUpdateRow={onSetUpdatedRow}
          />
        ),
      },
      {
        field: "authorized_roles_selfservice",
        type: "boolean",
        headerName: "Self Service",
        minWidth: 100,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => (
          <AuthorizedRoleToggle
            {...params}
            authorizedRole={AuthorizedRoles.MS_selfservice}
            onUpdateRow={onSetUpdatedRow}
          />
        ),
      },
      {
        field: "updated_at",
        type: "dateTime",
        headerName: "Last Updated",
        flex: 1,
        editable: false,
        valueGetter: (params: GridValueGetterParams) =>
          new Date(params.row.updated_at),
      },
      {
        field: "delete_action",
        type: "actions",
        headerName: "Delete",
        sortable: false,
        filterable: false,
        editable: false,
        getActions: (params) => [
          <DeleteRoleButton
            {...params}
            onRemoveRow={(id) => onRemoveRow(id)}
          />,
        ],
      },
    ],
    [onRemoveRow, onSetUpdatedRow]
  );

  // https://github.com/mui/mui-x/issues/2186
  const handleCellClick = useCallback((params: GridCellParams) => {
    if (!params.isEditable) return;

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  return (
    <Paper>
      <Box
        sx={{
          height: tableHeight,
          minWidth: "100%",
        }}
      >
        <DataGrid
          style={{
            fontSize: 12,
          }}
          loading={loading}
          getRowId={(orderType) => orderType.report_authorized_role_id}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            toolbar: () => (
              <div className="flex flex-row justify-between">
                <GridToolbarContainer>
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                  <GridToolbarExport />
                </GridToolbarContainer>
                <GridToolbarContainer>
                  <Button
                    variant="contained"
                    onClick={onAddClick}
                    startIcon={<PlusCircle />}
                    disabled={isLoadingReportOptions || loading}
                  >
                    Add Report
                  </Button>
                </GridToolbarContainer>
              </div>
            ),
          }}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
        />
      </Box>
    </Paper>
  );
}
