import { Delete } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
  GridRowId,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";

interface DeleteToolbarProps {
  onDelete: (selectedRows: GridRowId[]) => void;
}

export default function DeleteToolbar({ onDelete }: DeleteToolbarProps) {
  const apiRef = useGridApiContext();

  const selectedRows = apiRef.current.getSelectedRows();

  const handleDelete = async () => {
    const selectedIds: number[] = [];
    const selectedRows = apiRef.current.getSelectedRows();

    for (const [key] of selectedRows.entries()) {
      selectedIds.push(key as number);
    }

    onDelete(selectedIds);
  };

  return (
    <GridToolbarContainer>
      <Button
        disabled={selectedRows.size === 0}
        color="error"
        startIcon={<Delete />}
        onClick={handleDelete}
      >
        Delete
      </Button>
    </GridToolbarContainer>
  );
}
