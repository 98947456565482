import { UploadProcessTypes } from "@react-ms-apps/common/constants/uploadProcessTypes";
import { Location } from "react-router-dom";

export const getCurrentTotalPath = (location: Location) => {
  return `${location.pathname}${location.search}`;
};

export const getProcessTypeFromPath = (
  location: Location
): UploadProcessTypes => {
  const searchParams = new URLSearchParams(location.search);
  return (searchParams.get("processType") || "") as UploadProcessTypes;
};
