import { Container, Paper } from "@mui/material";
import { classNames } from "@react-ms-apps/common/utils/styles";
import React from "react";

interface LoginWrapperProps {
  children: React.ReactNode;
  loginMessage?: string;
  className?: string;
}

export default function LoginWrapper({
  children,
  loginMessage,
  className,
}: LoginWrapperProps) {
  return (
    <Container
      component="main"
      maxWidth="xs"
      className={classNames(
        "!flex flex-1 justify-center align-center flex-col",
        className
      )}
    >
      <Paper
        className="rounded-xl px-8 py-4"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
      </Paper>

      {!!loginMessage && (
        <div
          data-testid="login-message"
          dangerouslySetInnerHTML={{ __html: loginMessage }}
        />
      )}
    </Container>
  );
}
