import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  Container,
  ROUTES,
  fetchCustomLoginMessage,
  isBadClientError,
  login,
  triggerNotFoundPage,
} from "@react-ms-apps/common";
import Password from "@react-ms-apps/common/components/Password/Password";
import { useAuth } from "@react-ms-apps/common/providers";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginWrapper from "../LoginWrapper";

interface SignInProps {
  allowForgotPassword: boolean;
}

export default function Login({ allowForgotPassword = true }: SignInProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [customLoginMessage, setCustomLoginMessage] = useState<string>("");

  const { isAuthenticated, checkAuth, authLoaded } = useAuth();

  const getIsAuthenticated = useCallback(async () => {
    await checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomLoginMessage = async () => {
    try {
      const message = await fetchCustomLoginMessage();
      if (message) {
        setCustomLoginMessage(message);
      }
    } catch (error) {
      isBadClientError(error) && triggerNotFoundPage({ badClient: true });
      Sentry.captureException(error);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const data = new FormData(event.currentTarget);
    const username = data.get("username") as string;
    const password = data.get("password") as string;

    if (!username || !password) return;

    setIsLoggingIn(true);

    try {
      await login(username, password);
    } catch (error) {
      // show error alert in UI
      if (error instanceof Error) {
        toast.error(
          "There was an error logging in. Try logging in again, resetting your password, or contacting support."
        );
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    // check if user is authenticated
    getIsAuthenticated();

    // fetch custom login message
    getCustomLoginMessage();
  }, [getIsAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.AUTHENTICATED_REDIRECT);
    }
  }, [isAuthenticated, navigate]);

  const showLoading = useMemo(() => {
    if (!authLoaded || isAuthenticated) {
      return true;
    }

    return false;
  }, [authLoaded, isAuthenticated]);

  if (showLoading) {
    return (
      <Container className="!flex flex-1 justify-center items-center">
        <CircularProgress data-testid="login-loading" />
      </Container>
    );
  }

  return (
    <LoginWrapper loginMessage={customLoginMessage} className="!mt-2">
      <Avatar
        sx={{
          bgcolor: "secondary.main",
          height: isSmallScreen ? 30 : 40,
          width: isSmallScreen ? 30 : 40,
        }}
      >
        <LockOutlinedIcon
          sx={{
            height: isSmallScreen ? 18 : 25,
            width: isSmallScreen ? 18 : 25,
          }}
        />
      </Avatar>
      <Typography sx={{ mt: 1 }} variant={isSmallScreen ? "h6" : "h5"}>
        Sign in to your account
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        className="w-full flex flex-col gap-1"
      >
        <TextField
          size={isSmallScreen ? "small" : "medium"}
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          placeholder="name@company.com"
          autoFocus
        />
        <Password
          size={isSmallScreen ? "small" : "medium"}
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          placeholder="*********"
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={isLoggingIn}
        >
          Sign In
        </LoadingButton>
        <Divider
          sx={{ mb: 1 }}
          variant="fullWidth"
          className="!border-dashed"
        />

        {allowForgotPassword && (
          <div className="flex justify-end">
            <Link component={RouterLink} to={ROUTES.LOGIN.FORGOT_PASSWORD}>
              Forgot password?
            </Link>
          </div>
        )}
      </Box>
    </LoginWrapper>
  );
}
