import { OrderTypeDTO } from "@react-ms-apps/common/types/order-type";
import axios from "axios";
import { omit } from "lodash";
import { buildApiUrl } from "../../utils/api-url";

export interface OrderTypeResponse {
  orderType: OrderTypeDTO;
  etag: string;
}

export async function fetchOrderTypes() {
  const response = await axios.get<OrderTypeDTO[]>(
    buildApiUrl(`api/restables/order_types`)
  );

  return response.data;
}

export async function fetchOrderTypeByID(
  id: number
): Promise<OrderTypeResponse> {
  const response = await axios.get<OrderTypeDTO>(
    buildApiUrl(`api/restables/order_types/${id}`)
  );

  return {
    orderType: response.data,
    etag: response.headers["etag"] || "",
  };
}

export async function updateOrderTypeByID(
  id: number,
  orderType: OrderTypeDTO,
  ETAG: string
): Promise<OrderTypeResponse> {
  // custom logic for DB constraints
  if (orderType.custom_description === "") {
    orderType.custom_description = null;
  }
  if (orderType.custom_report_description === "") {
    orderType.custom_report_description = null;
  }

  const omittedFields = [
    "description",
    "order_type_id",
    "updated_at",
    "etag",
    "created_at",
  ];

  // remove omitted fields
  const updatedOrderType = omit(orderType, omittedFields);

  const resp = await axios.put<OrderTypeDTO>(
    buildApiUrl(`api/restables/order_types/${id}`),
    updatedOrderType,
    {
      headers: {
        "If-Match": ETAG,
      },
    }
  );

  return {
    orderType: resp.data,
    etag: resp.headers["etag"] || "",
  };
}
