import React, { createContext, useState } from "react";

interface CompanyFilterContextProps {
  selectedCompany: string;
  setSelectedCompany: React.Dispatch<React.SetStateAction<string>>;
}

export const CompanyFilterContext = createContext<CompanyFilterContextProps>({
  selectedCompany: "",
  setSelectedCompany: () => {},
});

export const useCompanyFilter = () => React.useContext(CompanyFilterContext);

const CompanyFilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedCompany, setSelectedCompany] = useState("");

  return (
    <CompanyFilterContext.Provider
      value={{ selectedCompany, setSelectedCompany }}
    >
      {children}
    </CompanyFilterContext.Provider>
  );
};

export default CompanyFilterProvider;
