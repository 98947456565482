import * as Sentry from "@sentry/react";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { Carrier, fetchCarriers } from "../api/catalog-manager";

// Create a new context for carriers
const CarriersContext = createContext<{
  carriers: Carrier[];
  carriersMap: Record<number, Carrier>;
  addCarrier: (carrier: Carrier) => void;
}>({
  carriers: [],
  addCarrier: () => {},
  carriersMap: {},
});

export const useCarriers = () => React.useContext(CarriersContext);

// Create the CarriersProvider component
export const CarriersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // State to store the list of carriers
  const [carriers, setCarriers] = useState<Carrier[]>([]);

  // Function to add a new carrier to the list
  const addCarrier = (carrier: Carrier) => {
    setCarriers((prevCarriers) => [...prevCarriers, carrier]);
  };

  const getCarriers = async () => {
    try {
      const resp = await fetchCarriers();
      setCarriers(resp);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const carriersMap = useMemo(() => {
    return carriers.reduce((acc, carrier) => {
      acc[carrier.id] = carrier;
      return acc;
    }, {} as Record<number, Carrier>);
  }, [carriers]);

  // load the carriers
  useEffect(() => {
    getCarriers();
  }, []);

  // Provide the carriers and addCarrier function to the children components
  return (
    <CarriersContext.Provider value={{ carriers, addCarrier, carriersMap }}>
      {children}
    </CarriersContext.Provider>
  );
};

export default CarriersProvider;
