import { Divider } from "@mui/material";
import { PageContainer } from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import { isAxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getOrderApproval, updateOrderApproval } from "./api";
import LoadingPage from "./LoadingPage";
import NoOrderPage from "./NoOrderPage";
import OrderActions from "./OrderActions";
import OrderSubscriber from "./OrderSubscriber";
import { Order, OrderActionType, OrderStatusTypes } from "./types";

export default function OrderApproval() {
  const { token } = useParams<{ token: string }>();

  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [etag, setEtag] = useState<string>("");

  const handleGetOrder = async () => {
    if (!token) {
      toast.error("Order ID is required");
      Sentry.captureException(new Error("Order ID is required"));
      return;
    }

    try {
      setLoading(true);

      const { etag, data } = await getOrderApproval(token);

      setEtag(etag);
      setOrder(data);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMessage = ({
    isApproved,
    hasConflict,
    outcomeIsExpected,
  }: {
    isApproved: boolean;
    hasConflict?: boolean;
    outcomeIsExpected?: boolean;
  }) => {
    if (hasConflict) {
      if (outcomeIsExpected) {
        toast.success(
          `Order has already been ${
            isApproved ? "approved" : "rejected"
          } by another user`
        );
      } else {
        toast.error(
          `Order has already been ${
            isApproved ? "rejected" : "approved"
          } by another user. If you need this corrected, please contact support.`
        );
      }
    } else {
      let message = "Order has been ";
      message += isApproved ? "approved" : "denied";
      toast.success(message);
    }
  };

  const getLatestOrderComparison = async (intendedOrderStatusId: number) => {
    let etag = "";
    let orderStatusChanged = false;
    let orderStatusChangedAsExpected = false;

    if (!token) {
      toast.error("Order ID is required");
      Sentry.captureException(new Error("Order ID is required for retry"));
      return {
        etag,
        orderStatusChanged,
        orderStatusChangedAsExpected,
      };
    }

    try {
      const { data, etag: updatedEtag } = await getOrderApproval(token);
      const updatedOrder = data;

      etag = updatedEtag;

      // check if status is changed
      orderStatusChanged =
        order?.order_status_id !== updatedOrder.order_status_id;
      orderStatusChangedAsExpected =
        updatedOrder.order_status_id === intendedOrderStatusId;

      // update the order etag
      setEtag(etag);
      setOrder(updatedOrder);
    } catch (error) {
      Sentry.captureException(error);
    }

    return { orderStatusChanged, etag, orderStatusChangedAsExpected };
  };

  const handleSubmitOrder = async (
    actionType: OrderActionType,
    comments: string,
    etag: string
  ) => {
    if (!token) {
      toast.error("Order ID is required");
      Sentry.captureException(new Error("Order ID is required"));
      return;
    }

    const newOrderStatusId =
      actionType === "approve"
        ? OrderStatusTypes.APPROVED_PENDING
        : OrderStatusTypes.DENIED_PENDING;

    const isApproved = actionType === "approve";

    try {
      setSubmitting(true);

      await updateOrderApproval({
        token: token,
        order_status_id: newOrderStatusId,
        etag,
        approver_comments: comments,
      });

      // refresh order data
      await handleGetOrder();
      handleMessage({ isApproved });
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 412) {
        /**
         * If the order status is unchanged, retry the order.
         * If the order status is changed, show an error message and refresh the order data.
         */
        const { orderStatusChanged, orderStatusChangedAsExpected, etag } =
          await getLatestOrderComparison(newOrderStatusId);

        if (!orderStatusChanged) {
          await handleSubmitOrder(actionType, comments, etag);
          return;
        } else {
          handleMessage({
            isApproved,
            hasConflict: true,
            outcomeIsExpected: orderStatusChangedAsExpected,
          });

          await handleGetOrder();
        }
      } else {
        // otherwise show an error message
        Sentry.captureException(error);
        toast.error("Failed to approve order");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const carrierName = useMemo(() => {
    if (!order) {
      return "";
    }

    return order.carrier.name;
  }, [order]);

  const orderTypeName = useMemo(() => {
    // assume that all subscribers have the same order type
    if (!order || !order.order_subscribers) {
      return "";
    }

    return order.order_type.custom_description || order.order_type.description;
  }, [order]);

  useEffect(() => {
    handleGetOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageLoading = useMemo(() => {
    return loading;
  }, [loading]);

  if (pageLoading) {
    return <LoadingPage />;
  }

  if (!order || !order?.order_subscribers?.length) {
    return <NoOrderPage />;
  }

  return (
    <PageContainer
      header="Order Approval"
      utilityName="Order Approval"
      headerId="order-approval"
    >
      <Divider className="!border-dashed" variant="fullWidth" sx={{ mb: 1 }} />

      <div className="flex flex-1 flex-row justify-between pt-4">
        <div className="flex flex-col gap-8 text-primary-700 text-base w-1/2">
          <h2 className="text-lg font-normal m-0 pb-4">{orderTypeName}</h2>

          {order &&
            order.order_subscribers &&
            order?.order_subscribers.map((subscriber) => (
              <OrderSubscriber
                key={subscriber.order_subscriber_id}
                carrierName={carrierName}
                order={order}
                subscriber={subscriber}
              />
            ))}
        </div>

        <OrderActions
          order={order}
          onSubmit={(actionType, comments) =>
            handleSubmitOrder(actionType, comments, etag)
          }
          submitting={submitting}
        />
      </div>
    </PageContainer>
  );
}
