import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  FormControl,
  Input,
  InputAdornment,
  Paper,
  Typography,
  debounce,
} from "@mui/material";
import {
  PageContainer,
  UtilityCategoryDTO,
  UtilityLinkDTO,
  fetchUtilitiesLinks,
  fetchUtilityCategories,
} from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePageTitle } from "../../Providers/PageTitleProvider";
import UtilitiesEditorTable from "./UtilitiesEditorTable";

export default function UtilitiesEditor() {
  const { setTitle } = usePageTitle();

  const [search, setSearch] = useState<string>("");
  const [loadingUtilities, setLoadingUtilities] = useState(false);
  const [utilityLinks, setUtilityLinks] = useState<UtilityLinkDTO[]>([]);
  const [utilityCategories, setUtilityCategories] = useState<
    UtilityCategoryDTO[]
  >([]);

  const getUtilities = useCallback(async () => {
    setLoadingUtilities(true);
    try {
      const utilitiesData = await fetchUtilitiesLinks();

      let utilityLinks: UtilityLinkDTO[] = [];
      utilitiesData.forEach((utility) => {
        utilityLinks = utilityLinks.concat(utility.links);
      });

      const sortedUtilityLinks = utilityLinks
        // sort alphabetically by utility name
        .sort((a, b) => {
          if (a.utility_name < b.utility_name) {
            return -1;
          }
          if (a.utility_name > b.utility_name) {
            return 1;
          }
          return 0;
        });

      setUtilityLinks(sortedUtilityLinks);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingUtilities(false);
    }
  }, [setUtilityLinks, setLoadingUtilities]);

  const getUtilityCategories = useCallback(async () => {
    try {
      const categories = await fetchUtilityCategories();
      setUtilityCategories(categories);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [setUtilityCategories]);

  useEffect(() => {
    getUtilities();
    getUtilityCategories();
  }, [getUtilities, getUtilityCategories]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredUtilityLinks = useMemo(() => {
    return utilityLinks.filter((utilityLink) => {
      const searchRegex = new RegExp(search, "i");
      return (
        searchRegex.test(utilityLink.utility_name) ||
        searchRegex.test(utilityLink.custom_utility_name || "")
      );
    });
  }, [search, utilityLinks]);

  const visibleUtilityLinksMap = useMemo(() => {
    return Object.fromEntries(
      filteredUtilityLinks.map((utilityLink) => [
        utilityLink.utility_id,
        utilityLink,
      ])
    );
  }, [filteredUtilityLinks]);

  const debounceHandleSearchChange = debounce(handleSearchChange, 400);

  // set page title
  useEffect(() => {
    setTitle("Utilities Management");
  }, [setTitle]);

  return (
    <PageContainer
      className="!w-full flex flex-1 flex-col"
      utilityName="Utilities Management"
      description={
        <Typography>
          Use this page to modify utilities <strong>Custom Name</strong>,{" "}
          <strong>Active</strong>, <strong>Utility Link</strong>,{" "}
          <strong>Category</strong> and <strong>Access restrictions</strong>.
        </Typography>
      }
    >
      {loadingUtilities && (
        <div className="flex justify-center items-center flex-1 my-8">
          {" "}
          <CircularProgress />
        </div>
      )}

      {!loadingUtilities && (
        <Paper className="mb-20">
          <div className="p-3 w-full pt-8">
            <FormControl variant="standard">
              <Input
                fullWidth
                onChange={debounceHandleSearchChange}
                placeholder="Search utility by name"
                id="utilties-manager-search"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <UtilitiesEditorTable
            utilityLinks={utilityLinks}
            utilityCategories={utilityCategories}
            visibleUtilityLinksMap={visibleUtilityLinksMap}
          />
        </Paper>
      )}
    </PageContainer>
  );
}
