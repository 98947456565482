import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FilledInput, { FilledInputProps } from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import React, { ForwardedRef, forwardRef, useState } from "react";

type OmittedFilledInputProps = Omit<FilledInputProps, "onChange">;

interface PasswordInputProps extends OmittedFilledInputProps {
  label?: string;
  onChange?: (value: string) => void;
}

export default forwardRef(function PasswordInput(
  { onChange, label, ...props }: PasswordInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel htmlFor="filled-adornment-password">
        {label || "Password"}
      </InputLabel>
      <FilledInput
        {...props}
        inputRef={ref}
        id="filled-adornment-password"
        type={showPassword ? "text" : "password"}
        onChange={(e) => onChange && onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
});
