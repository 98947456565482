import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Grid, Typography, colors } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import {
  fetchMinPasswordComplexity,
  fetchMinPasswordLength,
} from "@react-ms-apps/common/api/password";
import { NEW_PASSWORD_TEXT } from "@react-ms-apps/common/constants/text";
import {
  complexityLevel,
  getHasLowerCase,
  getHasNumber,
  getHasUpperCase,
  getHasValidSpecialChars,
} from "@react-ms-apps/common/utils/password";
import * as Sentry from "@sentry/react";
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LightTooltip } from "../LightTooltip";
import PasswordRequirement from "./PasswordRequirement";

interface NewPasswordInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValidityChange?: (valid: boolean) => void;
}

export default forwardRef(function NewPasswordInput(
  { onChange, onValidityChange }: NewPasswordInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [showPassword, setShowPassword] = useState(false);
  const [minPasswordLength, setMinPasswordLength] = useState(0);
  const [minPasswordComplexity, setMinPasswordComplexity] = useState(0);
  const [newPassword, setNewPassword] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getMeetsPasswordLength = useCallback(
    (passwordValue: string) => {
      return passwordValue.length >= minPasswordLength;
    },
    [minPasswordLength]
  );

  const getMeetsPasswordComplexity = useCallback(
    (passwordValue: string) => {
      return complexityLevel(passwordValue) >= minPasswordComplexity;
    },
    [minPasswordComplexity]
  );

  const getMinPasswordLength = async () => {
    try {
      const resp = await fetchMinPasswordLength();
      setMinPasswordLength(resp);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const getMinPasswordComplexity = async () => {
    try {
      const resp = await fetchMinPasswordComplexity();
      setMinPasswordComplexity(resp);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setNewPassword(value);

    if (onChange) onChange(e);
  };

  const remainingMinLength = useMemo(() => {
    if (newPassword.length < minPasswordLength) {
      return minPasswordLength - newPassword.length;
    }

    return 0;
  }, [minPasswordLength, newPassword]);

  const newPasswordIndicatorLength = useMemo(() => {
    if (newPassword.length > minPasswordLength) {
      return minPasswordLength;
    }

    return newPassword.length;
  }, [minPasswordLength, newPassword]);

  const hasUpperCase = getHasUpperCase(newPassword);
  const hasLowerCase = getHasLowerCase(newPassword);
  const hasNumber = getHasNumber(newPassword);
  const hasSpecialChar = getHasValidSpecialChars(newPassword);
  const isValid =
    getMeetsPasswordLength(newPassword) &&
    getMeetsPasswordComplexity(newPassword);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [
    getMeetsPasswordComplexity,
    getMeetsPasswordLength,
    newPassword,
    onValidityChange,
    isValid,
  ]);

  useEffect(() => {
    // get min password length
    getMinPasswordLength();

    // get min password complexity
    getMinPasswordComplexity();
  }, []);

  return (
    <>
      <LightTooltip
        placement="right"
        title={
          <Box p={1}>
            <Typography>
              Must have{" "}
              <Typography display="inline" fontWeight="bold">
                at least {minPasswordLength} characters
              </Typography>
            </Typography>
            <Grid container columnGap={2} flexDirection={"row"}>
              {newPasswordIndicatorLength > 0 &&
                Array(newPasswordIndicatorLength)
                  .fill(0)
                  .map((_, i) => (
                    <Grid key={i} item xs={12 / minPasswordLength - 1}>
                      <Box
                        sx={{
                          bgcolor: colors.green[500],
                          height: "2px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                  ))}

              {remainingMinLength > 0 &&
                Array(remainingMinLength)
                  .fill(0)
                  .map((_, i) => (
                    <Grid key={i} item xs={12 / minPasswordLength - 1}>
                      <Box
                        sx={{
                          bgcolor: colors.orange[500],
                          height: "2px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                  ))}
            </Grid>
            <Typography mt={1}>
              Must have{" "}
              <Typography display="inline" fontWeight="bold">
                at least {minPasswordComplexity} of:
              </Typography>
            </Typography>
            <Box>
              <PasswordRequirement
                isValid={isValid}
                isMet={hasUpperCase}
                text="Upper case letters"
              />

              <PasswordRequirement
                isValid={isValid}
                isMet={hasLowerCase}
                text="Lower case letters"
              />

              <PasswordRequirement
                isValid={isValid}
                isMet={hasNumber}
                text="Numbers"
              />

              <PasswordRequirement
                isValid={isValid}
                isMet={hasSpecialChar}
                text="A symbol (#$&)"
              />
            </Box>
          </Box>
        }
      >
        <FormControl sx={{ mt: 1, mb: 1, width: "100%" }} variant="filled">
          <InputLabel htmlFor="new-password">{NEW_PASSWORD_TEXT}</InputLabel>
          <FilledInput
            inputRef={ref}
            name="new-password"
            id="new-password"
            type={showPassword ? "text" : "password"}
            onChange={handlePasswordChange}
            value={newPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </LightTooltip>
    </>
  );
});
