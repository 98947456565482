import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  ReportListItem,
  createReportAuhorizedRoles,
} from "@react-ms-apps/common";
import { AuthorizedRoles } from "@react-ms-apps/common/types";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { toast } from "react-toastify";

const authorizedRolesValuesMap: {
  [key: string]: string;
} = {
  "Sys Admin": AuthorizedRoles.MS_sysadmin,
  "Telco Admin": AuthorizedRoles.MS_telcoadmin,
  // "Back Office": AuthorizedRoles.MS_backoffice, // back office is always added
  Admin: AuthorizedRoles.MS_admin,
  "Self Service": AuthorizedRoles.MS_selfservice,
};
const roleOptions = ["Admin", "Self Service", "Sys Admin", "Telco Admin"];

export default function AddReportModal({
  open,
  onClose,
  onRefresh,
  reportOptions,
  isLoadingReportOptions,
}: {
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
  reportOptions: ReportListItem[];
  isLoadingReportOptions: boolean;
}) {
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleSelectedReportChange = (event: SelectChangeEvent<string>) => {
    setSelectedReport(event.target.value);
  };

  const handleSelectedRolesChange = (event: SelectChangeEvent<string>) => {
    const values = event.target.value as unknown as string[];
    setSelectedRoles(values);
  };

  const handleSubmit = async () => {
    setIsSaving(true);

    // create report authorized roles
    try {
      const roles = selectedRoles.map((role) => authorizedRolesValuesMap[role]);
      roles.push(AuthorizedRoles.MS_backoffice);

      await createReportAuhorizedRoles(selectedReport, roles.join(","));
      onRefresh();

      toast.success(`Role(s) added successfully`);

      setSelectedReport("");
      setSelectedRoles([]);

      onClose();
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error creating report authorized roles");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Report Authorizations</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          {isLoadingReportOptions ? (
            <div className="flex flex-1 justify-center items-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <>
              <DialogContentText>
                Select a report and the roles that should be authorized to view
                it.
              </DialogContentText>
              <FormControl fullWidth className="!mt-4">
                <InputLabel id="select-report-label">Select Report</InputLabel>
                <Select
                  labelId="select-report-label"
                  id="select-report"
                  value={selectedReport}
                  label="Select Report"
                  onChange={handleSelectedReportChange}
                >
                  {reportOptions.map((report) => (
                    <MenuItem
                      key={report.display_name}
                      value={report.display_name}
                    >
                      {report.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth className="!mt-4">
                <InputLabel id="select-roles-label">
                  Select Authorized Roles
                </InputLabel>
                <Select
                  labelId="select-roles-label"
                  id="select-roles"
                  // @ts-ignore
                  value={selectedRoles}
                  label="Select Authorized Roles"
                  onChange={handleSelectedRolesChange}
                  multiple
                >
                  {roleOptions.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSaving}
          endIcon={isSaving && <CircularProgress size={20} />}
        >
          Create Report Roles
        </Button>
      </DialogActions>
    </Dialog>
  );
}
