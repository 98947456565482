import { ROUTES, getClientDB, logout } from "@react-ms-apps/common";
import { goToPage } from "@react-ms-apps/common/utils/url";
import * as Sentry from "@sentry/browser";

export function isLogoutRoute(location: Location): boolean {
  const client = getClientDB();

  return location.pathname === `/${client}${ROUTES.LOGOUT}`;
}

export function Logout() {
  const callLogoutApi = async () => {
    // logout api
    try {
      // set page title
      document.title = "Logging out";

      await logout();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      // redirect to login page
      goToPage(`${window.location.origin}/${getClientDB()}`);
    }
  };

  callLogoutApi();

  return null;
}

export default Logout;
