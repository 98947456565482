import { FormControl, TextField } from "@mui/material";
import { useRowEtagContext } from "@react-ms-apps/app/src/Providers/RowEtagContext";
import { UtilityLinkDTO } from "@react-ms-apps/common";
import { updateUtilityByID } from "@react-ms-apps/common/api/utilities";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { ETAG_ERROR_MESSAGE } from "../constants";

interface CustomNameInputProps extends UtilityLinkDTO {
  fieldName: string;
  label: string;
  defaultValue: string;
  value: string;
  maxLength?: number;
}

export default function CellTextInput({
  utility_id,
  label,
  defaultValue,
  value,
  utility_name,
  fieldName,
  maxLength,
}: CustomNameInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [stateValue, setStateValue] = useState<string>(value || "");

  const { etag, setEtag } = useRowEtagContext();

  const saveValue = async () => {
    const value = inputRef.current?.value;

    if (value === stateValue) return;

    try {
      // update utility name
      const { etag: updatedEtag } = await updateUtilityByID(
        utility_id,
        fieldName,
        value,
        etag
      );
      toast.success(`${label} updated`, {
        autoClose: 1000,
      });
      setEtag(updatedEtag);
      setStateValue(value || "");
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 412) {
        toast.error(ETAG_ERROR_MESSAGE);
        return;
      }

      Sentry.captureException(error);
      toast.error(`Error updating ${label} for ${utility_name}`);
    }
  };

  return (
    <FormControl fullWidth>
      <TextField
        label={label}
        inputRef={inputRef}
        defaultValue={defaultValue || ""}
        onBlur={saveValue}
        inputProps={{
          maxLength,
        }}
      />
    </FormControl>
  );
}
