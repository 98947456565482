import { OrgInfoDTO } from "@react-ms-apps/common/types/org";
import { buildApiUrl } from "@react-ms-apps/common/utils";
import axios from "axios";

export async function fetchCopyrightRemoved(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "remove_copyright",
    },
  });

  return resp.data.option_value;
}

export async function fetchForgotPasswordRemoved(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "disable_forgot_password",
    },
  });

  return resp.data.option_value;
}

export async function fetchSuppressMobilSenseLogo(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "suppress_mobilsense_logo",
    },
  });

  return resp.data.option_value;
}

export async function fetchVendorLogo(): Promise<string | null> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "vendor_logo",
    },
  });

  // if the response is a 302, the value is not set
  // this can occur when the user is quickly switching between clients
  if (resp.status === 302) {
    return "BAD_VALUE";
  }

  return resp.data.option_value || null;
}

export async function fetchOrgInfo(): Promise<OrgInfoDTO> {
  const resp = await axios.get(buildApiUrl("api/restables/organizations"));

  return resp.data[0];
}

export async function fetchCustomLoginMessage(): Promise<string | null> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "login_custom_html",
    },
  });

  return resp.data.option_value;
}

export async function fetchBrandingBodyTitle(): Promise<string | null> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "branding_body_title",
    },
  });

  // if the response is a 302, the value is not set
  // this can occur when the user is quickly switching between clients
  if (resp.status === 302) {
    return "BAD_VALUE";
  }

  return resp.data.option_value;
}

export async function fetchBrandingBodyTitleIsSet(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "branding_body_title",
      is_option_set: true,
    },
  });

  return resp.data.is_option_set;
}

export async function fetchBrandingPageTitlePrefix(): Promise<string | null> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "branding_page_title_prefix",
    },
  });

  return resp.data.option_value;
}
