import { DeviceListItem } from "@react-ms-apps/common/api/catalog-manager";

export type CatalogTabType =
  | "catalog-devices"
  | "catalog-accessories"
  | "devices";

export enum CatalogTabEnum {
  CatalogDevices = 0,
  Devices = 1,
  CatalogAccessories = 2,
}

export interface CatalogDeviceRow {
  catalogDeviceId: number;
  deviceId: number;
  make: string;
  model: string;
  carrier: string;
  isActive: boolean;
  purchasePrice: number | null;
  retailPrice: number | null;
  proPrice: number | null;
  guidelines: string;
}

export interface CatalogAccessoryRow {
  catalogAccessoryId: number;
  accessoryTypeName: string;
  accessoryName: string;
  price: number | null;
  offered: boolean;
  relativeRanking?: number;
  partNumber: string;
  imageUrl: string;
  linked: boolean;
  linkedDevices: DeviceListItem[];
}

export interface DeviceRow extends DeviceListItem {
  pda_style: string;
}

export interface CatalogTab {
  label: string;
  type: CatalogTabType;
  value: CatalogTabEnum;
}
