import { BarChartSlotProps, CardinalDirections } from "@mui/x-charts";

export enum ChartType {
  BAR = "bar",
  LINE = "line",
  CLUSTERED_BAR = "clustered-bar",
}

export enum ByChartData {
  CARRIER = "carrier_name",
  DEVICE = "formatted_device_type",
  COST_CATEGORIES = "cost categories",
  COST_BY_COST_CENTER = "cost by cost center",
}

export const ByChartDataMap: {
  [key: string]: string;
} = {
  [ByChartData.CARRIER]: "Carrier",
  [ByChartData.DEVICE]: "Device",
  [ByChartData.COST_CATEGORIES]: "Cost Category",
  [ByChartData.COST_BY_COST_CENTER]: "Cost Center",
};

export enum ChartDatasets {
  TotalCost = "Total Cost",
  EquipmentCost = "Equipment Cost",
  ServiceCost = "Service Cost",
  InternationalCosts = "International Costs",
  DataOverageCosts = "Data Overage Costs",
  CostByCategory = "Cost By Category",
  CostByCostCenter = "Cost By Cost Center",
}

export const CostCategoryMap: {
  [key: string]: string;
} = {
  total_charges: "Total Cost",
  equipment_total: "Equipment Cost",
  international_total: "International Costs",
  intl_data_roam_charges: "International Data Roaming Costs",
  kb_charges: "Data Overage Costs",
};

export const DataKeysMap: {
  [key: string]: string;
} = {
  ATT: "AT&T",
  total_charges: "Total Charges",
  equipment_total: "Equipment Total",
  international_total: "International Total",
  intl_data_roam_charges: "International Data Roam Charges",
  kb_charges: "KB Charges",
};

export const barChartSlotProps: BarChartSlotProps = {
  legend: {
    position: {
      horizontal: "middle",
      vertical: "top",
    },
  },
};

export const chartMargins: Partial<CardinalDirections<number>> = {
  top: 80,
  right: 20,
  bottom: 20,
  left: 80,
};
