import { Footer as FooterComponent } from "@react-ms-apps/common/components/Footer";
import React from "react";
import ButtonBar from "../components/Footer/ButtonBar";

const FooterContext = React.createContext<{
  Footer: () => JSX.Element;
  setButtonBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}>({
  Footer: () => <></>,
  setButtonBar: () => {},
});

export const useFooter = () => React.useContext(FooterContext);

export const FooterProvider = ({ children }: { children: React.ReactNode }) => {
  const [buttonBar, setButtonBar] = React.useState<React.ReactNode>(
    <ButtonBar />
  );

  const Footer = () => <FooterComponent buttonBar={buttonBar} />;

  return (
    <FooterContext.Provider
      value={{
        Footer,
        setButtonBar,
      }}
    >
      {children}
    </FooterContext.Provider>
  );
};
