import { Delete } from "@mui/icons-material";
import {
  Alert,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { PageContainer, fetchStatementMonthsList } from "@react-ms-apps/common";
import {
  AdminChargeDetailReportResponse,
  AdminChargeDetailReportSchemaResponse,
  deleteBalanceStatements,
  fetchAdminChargeDetailReport,
  fetchAdminChargeDetailReportSchema,
} from "@react-ms-apps/common/api/DeleteZeroBalanceStatements";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DeleteToolbar from "./DeleteToolbar";

interface DeleteZeroBalanceStatementRow {
  statement_id: number;
  invoice_period_end: Date;
  service_number: string;
  carrier_account_number: string;
  foundation_account: string;
}

export default function DeleteZeroBalanceStatements() {
  const [statementMonths, setStatementMonths] = useState<string[]>([]);
  const [loadingStatementMonths, setLoadingStatementMonths] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [rows, setRows] = useState<DeleteZeroBalanceStatementRow[]>([]);
  const [deletingRows, setDeletingRows] = useState(false);

  const [adminChargeDetailReportSchema, setAdminChargeDetailReportSchema] =
    useState<AdminChargeDetailReportSchemaResponse | null>(null);
  const [
    loadingAdminChargeDetailReportSchema,
    setLoadingAdminChargeDetailReportSchema,
  ] = useState(false);

  const [adminChargeDetailReport, setAdminChargeDetailReport] =
    useState<AdminChargeDetailReportResponse | null>(null);
  const [loadingAdminChargeDetailReport, setLoadingAdminChargeDetailReport] =
    useState(false);

  const getStatementMonths = useCallback(async () => {
    setLoadingStatementMonths(true);

    try {
      const statementMonths = await fetchStatementMonthsList();
      setStatementMonths(statementMonths);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingStatementMonths(false);
    }
  }, []);

  const getAdminChargeDetailReportSchema = useCallback(async () => {
    setLoadingAdminChargeDetailReportSchema(true);

    try {
      const adminChargeDetailReportSchema =
        await fetchAdminChargeDetailReportSchema();
      setAdminChargeDetailReportSchema(adminChargeDetailReportSchema);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingAdminChargeDetailReportSchema(false);
    }
  }, []);

  const getAdminChargeDetailReport = useCallback(async () => {
    setLoadingAdminChargeDetailReport(true);

    try {
      const adminChargeDetailReport = await fetchAdminChargeDetailReport(
        selectedMonth
      );
      setAdminChargeDetailReport(adminChargeDetailReport);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingAdminChargeDetailReport(false);
    }
  }, [selectedMonth]);

  const getRows = useCallback((): DeleteZeroBalanceStatementRow[] => {
    if (
      !adminChargeDetailReport ||
      !adminChargeDetailReportSchema ||
      !selectedMonth
    ) {
      return [];
    }

    /*
     * find all statements where:
     * service_number.length !== 10 AND
     * total_charges === 0 AND
     * invoice_period_end === selectedMonth (but compare month and year)
     */
    return adminChargeDetailReport
      .filter(
        (statement) =>
          statement.service_number.length !== 10 &&
          statement.total_charges === 0 &&
          moment(statement.invoice_period_end).isSame(selectedMonth, "month") &&
          moment(statement.invoice_period_end).isSame(selectedMonth, "year")
      )
      .map((statement) => ({
        statement_id: statement.statement_summary_id,
        invoice_period_end: new Date(statement.invoice_period_end),
        service_number: statement.service_number,
        carrier_account_number: statement.carrier_account_number,
        foundation_account: statement.foundation_account,
      }));
  }, [adminChargeDetailReport, adminChargeDetailReportSchema, selectedMonth]);

  const onDelete = useCallback(async (selectedIds: number[]) => {
    setDeletingRows(true);

    try {
      // delete selected rows via API
      const results = await deleteBalanceStatements(selectedIds);

      // gather successful deletes
      const successfulDeletes: number[] = [];
      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        if (result.status === 200) {
          successfulDeletes.push(selectedIds[i]);
        }
      }

      // gather failed deletes
      const failedDeletes = selectedIds.filter(
        (id) => !successfulDeletes.includes(id)
      );

      if (successfulDeletes.length > 0) {
        if (successfulDeletes.length === 1) {
          toast.success("Statement deleted successfully.");
        } else {
          toast.success(
            `${successfulDeletes.length} statements deleted successfully.`
          );
        }
      }

      if (failedDeletes.length > 0) {
        if (failedDeletes.length === 1) {
          toast.error("Statement failed to delete.");
        } else {
          toast.error(`${failedDeletes.length} statements failed to delete.`);
        }
      }

      // remove successful deletes from the grid
      setRows((rows) =>
        rows.filter((row) => !successfulDeletes.includes(row.statement_id))
      );
    } catch (error) {
      Sentry.captureException(error);
      toast.error("An error occurred while deleting statements.");
    } finally {
      setDeletingRows(false);
    }
  }, []);

  const columns: GridColDef[] = useMemo((): GridColDef[] => {
    return [
      {
        field: "invoice_period_end",
        headerName: "Invoice Period End",
        editable: false,
        width: 200,
        type: "date",
      },
      {
        field: "service_number",
        headerName: "Service Number",
        editable: false,
        width: 200,
        type: "string",
        flex: 1,
      },
      {
        field: "carrier_account_number",
        headerName: "Carrier Account Number",
        editable: false,
        width: 200,
        type: "string",
        flex: 1,
      },
      {
        field: "foundation_account",
        headerName: "Foundation Account",
        editable: false,
        width: 200,
        type: "string",
        flex: 1,
      },
      // add delete button
      {
        field: "delete",
        headerName: "Delete",
        editable: false,
        width: 100,
        renderCell: (params) => (
          <IconButton>
            <Delete
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                onDelete([params.row.statement_id]);
              }}
            />
          </IconButton>
        ),
      },
    ];
  }, [onDelete]);

  const loadingData = useMemo((): boolean => {
    return (
      loadingStatementMonths ||
      loadingAdminChargeDetailReportSchema ||
      loadingAdminChargeDetailReport
    );
  }, [
    loadingStatementMonths,
    loadingAdminChargeDetailReportSchema,
    loadingAdminChargeDetailReport,
  ]);

  useEffect(() => {
    getStatementMonths();
    getAdminChargeDetailReportSchema();
    // getAdminChargeDetailReport();
  }, [
    // getAdminChargeDetailReport,
    getAdminChargeDetailReportSchema,
    getStatementMonths,
  ]);

  // auto-select the last known month of the monthly statements
  useEffect(() => {
    if (statementMonths.length > 0) {
      setSelectedMonth(statementMonths[0]);
    }
  }, [statementMonths]);

  // fetch report when the selected month changes
  useEffect(() => {
    if (selectedMonth) {
      getAdminChargeDetailReport();
    }
  }, [getAdminChargeDetailReport, selectedMonth]);

  // update rows whenever the report changes
  useEffect(() => {
    setRows(getRows());
  }, [adminChargeDetailReport, getRows]);

  return (
    <PageContainer
      className="!w-full flex flex-1 flex-col"
      headerId="delete-zero-balance-statements-header"
      utilityName="Delete Zero-Balance Statements"
      description={
        <Typography id="delete-zero-balance-statements-description">
          Use this page to delete summary statements that have a zero balance.
        </Typography>
      }
    >
      <div className="max-w-full flex flex-1 flex-col">
        <FormControl className="w-[150px] !mb-2">
          <InputLabel id="select-month-label">Select Month</InputLabel>
          <Select
            disabled={loadingStatementMonths}
            label="Select Month"
            variant="outlined"
            labelId="select-month-label"
            id="select-month"
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value as string)}
          >
            {statementMonths.map((month) => (
              <MenuItem value={month} key={month}>
                {moment(month).format("MMM YYYY")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {rows.length === 0 && !loadingData && (
          <Alert
            severity="info"
            className="!my-2"
            id="delete-zero-balance-statements-no-data"
          >
            No Zero-Balance Statements found for the selected month.
          </Alert>
        )}

        <DataGridPremium
          getRowId={(row) => row.statement_id}
          loading={loadingData || deletingRows}
          rows={rows}
          columns={columns}
          checkboxSelection
          slots={{
            toolbar: (props) => (
              <DeleteToolbar {...props} onDelete={onDelete} />
            ),
          }}
          slotProps={{
            toolbar: { setRows },
          }}
          pinnedColumns={{ right: ["delete"] }}
        />
      </div>
    </PageContainer>
  );
}
