import { Link, Typography } from "@mui/material";
import { Container } from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

export default function NotFoundPage() {
  // log the path to Sentry for debugging
  useEffect(() => {
    Sentry.captureMessage(`404: ${window.location.pathname}`);
  }, []);

  return (
    <Container className="!flex flex-1">
      <div className="-mt-40 flex flex-1 flex-col justify-center items-center">
        <div className="mb-4">
          <Typography variant="h4">Error</Typography>
        </div>
        <Typography variant="body1">
          We apologize, but the page you are looking for does not exist.
        </Typography>
        <div className="mt-4 mb-4">
          <Typography variant="body1">
            The error has been logged. If the problem persists, please contact
            us and let us know so we can fix it.
          </Typography>
        </div>
        <Link href="/">Return to the application.</Link>
      </div>
    </Container>
  );
}
