export const DataTestIDs = {
  // Utilities List
  UTILITIES_LIST: "utilities-list",
  UTILITIES_LIST_ITEM_LINK: "utilities-list-item-link",
  UTILITIES_LIST_SEARCH: "utilities-list-search",
  UTILITIES_LIST_CATEGORY: "utilities-list-category",
  UTILITIES_LIST_EMPTY: "utilities-list-empty-wrapper",
  // Utility Editor
  UTILITY_EDITOR_SEARCH: "utility-editor-search",
  UTILITY_EDITOR_TABLE: "utility-editor-table",
  UTILITY_EDITOR_TABLE_ROW: "utility-editor-table-row",
  UTILITY_EDITOR_TABLE_SKELETON_ROW: "utility-editor-table-skeleton-row",
  UTILITY_EDITOR_TABLE_EMPTY_ROW: "utility-editor-table-empty-row",
  // Order Type Editor
  ORDER_TYPE_EDITOR_SEARCH: "order-type-editor-search",
  ORDER_TYPE_EDITOR_TABLE: "order-type-editor-table",
  ORDER_TYPE_EDITOR_TABLE_SKELETON_ROW: "order-type-editor-table-skeleton-row",
  ORDER_TYPE_EDITOR_TABLE_ROW: "order-type-editor-table-row",
  ORDER_TYPE_EDITOR_TABLE_ROW_REFRESH_BUTTON:
    "order-type-editor-table-row-refresh-button",
  // Statement Editor
  STATEMENT_EDITOR_SEARCH: "statement-editor-search",
  STATEMENT_EDITOR_TABLE: "statement-editor-table",
  STATEMENT_EDITOR_TABLE_SKELETON_ROW: "statement-editor-table-skeleton-row",
  STATEMENT_EDITOR_TABLE_ROW: "statement-editor-table-row",
  STATEMENT_EDITOR_TABLE_REFRESH_BUTTON:
    "statement-editor-table-refresh-button",
  STATEMENT_EDITOR_SAVE_BUTTON: "statement-editor-save-button",
};
