import { buildApiUrl } from "@react-ms-apps/common";
import axios from "axios";

export interface MDMAction {
  created_at: string;
  display_order: number;
  updated_at: string;
  active: boolean;
  action: string;
  id: number;
}

export async function fetchMDMActions() {
  const resp = await axios.get<MDMAction[]>(
    buildApiUrl("api/restables/mdm_actions")
  );
  return resp.data;
}

export async function updateMDMAction(id: number, action: Partial<MDMAction>) {
  // fetch etag for action
  const etagResp = await axios.get<MDMAction>(
    buildApiUrl(`api/restables/mdm_actions/${id}`)
  );

  const etag = etagResp.headers.etag;

  // update action
  const actionResp = await axios.put<MDMAction>(
    buildApiUrl(`api/restables/mdm_actions/${id}`),
    {
      ...action,
    },
    {
      headers: {
        "If-Match": etag,
      },
    }
  );

  return actionResp.data;
}

export async function fetchMDMActionEtag(id: number): Promise<string> {
  const resp = await axios.get<MDMAction>(
    buildApiUrl(`api/restables/mdm_actions/${id}`)
  );

  return resp.headers.etag as string;
}

interface CreateMDMActionDTO {
  action: string;
  display_order: number;
  active: boolean;
}

export async function createMDMAction(action: CreateMDMActionDTO) {
  const resp = await axios.post<MDMAction>(
    buildApiUrl("api/restables/mdm_actions"),
    action
  );

  return resp.data;
}
