import { Portal } from "@mui/base";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  CarrierList,
  CatalogDeviceList,
  DeviceList,
  fetchCarriers,
  fetchCatalogDevices,
  fetchDeviceItems,
} from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCatalogManager } from "../../CatalogManagerProvider";
import CatalogDevicesTable from "./CatalogDevicesTable";

export default function PullFromClientDialog({
  onClose,
}: {
  onClose: () => void;
}) {
  const { importedDevices, getDeviceName } = useCatalogManager();

  const [clientName, setClientName] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const [devices, setDevices] = useState<DeviceList>([]);
  const [catalogDevices, setCatalogDevices] = useState<CatalogDeviceList>([]);
  const [carriers, setCarriers] = useState<CarrierList>([]);
  const [search, setSearch] = useState("");

  const fetchClientDevices = useCallback(async () => {
    setShowLoading(true);

    // clear existing data
    setDevices([]);
    setCatalogDevices([]);
    setCarriers([]);

    try {
      const devices = await fetchDeviceItems(clientName);
      setDevices(devices);

      const catalogDevices = await fetchCatalogDevices(clientName);
      setCatalogDevices(catalogDevices);

      const carriers = await fetchCarriers(clientName);
      setCarriers(carriers);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to fetch devices from client");
    } finally {
      setShowLoading(false);
    }
  }, [clientName]);

  useEffect(() => {
    // fetch devices from the client when the client name changes after 1 second
    const timeout = setTimeout(() => {
      if (clientName !== "") {
        fetchClientDevices();
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [clientName, fetchClientDevices]);

  const hasData = devices.length > 0 && catalogDevices.length > 0;

  const showTable = clientName !== "" && (hasData || showLoading);

  return (
    <Portal>
      <Dialog
        open={true}
        fullWidth
        maxWidth={hasData ? "lg" : "sm"}
        onClose={onClose}
      >
        <DialogTitle>Get devices from another client</DialogTitle>
        <DialogContent dividers>
          <div className="mb-2 flex flex-row justify-between">
            <TextField
              label="Client Name"
              fullWidth={!showTable}
              variant="outlined"
              size="small"
              onChange={(e) => setClientName(e.target.value)}
              value={clientName}
            />

            {showTable && (
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                onChange={(e) => setSearch(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </div>

          {showTable && (
            <div className="flex flex-row flex-1">
              {importedDevices.length > 0 && (
                <div className="flex flex-col w-1/4">
                  <Typography variant="h6">Imported Devices</Typography>

                  <List>
                    {importedDevices.map((device) => (
                      <ListItem>
                        {getDeviceName(device.manufacturer, device.model)}
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}

              <div className={importedDevices.length > 0 ? "w-3/4" : "w-full"}>
                <CatalogDevicesTable
                  loading={showLoading}
                  catalogDevices={catalogDevices}
                  devices={devices}
                  carriers={carriers}
                  search={search}
                  clientName={clientName}
                />
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
