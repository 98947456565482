export enum EditDeviceTabEnum {
  DEVICE = 0,
  IMAGES = 1,
  CATALOG_DEVICES = 2,
  ACCESSORIES = 3,
}

interface EditDeviceTab {
  label: string;
  value: number;
}

export const tabs: EditDeviceTab[] = [
  {
    label: "Device",
    value: EditDeviceTabEnum.DEVICE,
  },
  {
    label: "Images",
    value: EditDeviceTabEnum.IMAGES,
  },
  {
    label: "Catalog Devices",
    value: EditDeviceTabEnum.CATALOG_DEVICES,
  },
  {
    label: "Accessories",
    value: EditDeviceTabEnum.ACCESSORIES,
  },
];
