import { NavData } from "@react-ms-apps/common/types/nav";
import React from "react";

const NavContext = React.createContext<{
  navData: NavData.NavDataItem[];
  setNavData: React.Dispatch<React.SetStateAction<NavData.NavDataItem[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  navData: [],
  setNavData: () => {},
  isLoading: false,
  setIsLoading: () => {},
});

export const useNav = () => React.useContext(NavContext);

export function NavProvider({
  children,
  initialNavData,
  initialIsLoading,
}: {
  children: React.ReactNode;
  initialNavData?: NavData.NavDataItem[];
  initialIsLoading?: boolean;
}) {
  const [navData, setNavData] = React.useState<NavData.NavDataItem[]>(
    initialNavData || []
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(
    initialIsLoading === false ? false : true
  );

  return (
    <NavContext.Provider
      value={{
        navData,
        setNavData,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}
