import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { CONFIRM_PASSWORD_TEXT } from "../../constants/text";
import { LightTooltip } from "../LightTooltip";
import PasswordRequirement from "./PasswordRequirement";

interface ConfirmPasswordInputProps {
  password: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValidityChange?: (valid: boolean) => void;
}

export default forwardRef(function ConfirmPasswordInput(
  { password, onValidityChange, onChange }: ConfirmPasswordInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setConfirmPassword(value);

    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(confirmPassword === password);
    }
  }, [password, confirmPassword, onValidityChange]);

  const isMatch = confirmPassword.length > 0 && confirmPassword === password;

  return (
    <>
      <LightTooltip
        placement="right"
        title={
          <Box p={1}>
            <PasswordRequirement
              isValid={isMatch}
              isMet={isMatch}
              text="Passwords match"
            />
          </Box>
        }
      >
        <FormControl sx={{ mt: 1, mb: 1, width: "100%" }} variant="filled">
          <InputLabel htmlFor="confirm-password">
            {CONFIRM_PASSWORD_TEXT}
          </InputLabel>
          <FilledInput
            inputRef={ref}
            name="confirm-password"
            id="confirm-password"
            type={showPassword ? "text" : "password"}
            onChange={handlePasswordChange}
            value={confirmPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </LightTooltip>
    </>
  );
});
