import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import {
  fetchMinPasswordComplexity,
  fetchMinPasswordLength,
  updatePassword,
} from "@react-ms-apps/common/api/password";
import {
  ConfirmPasswordInput,
  NewPasswordInput,
  PasswordInput,
} from "@react-ms-apps/common/components/Password";
import { OLD_PASSWORD_TEXT } from "@react-ms-apps/common/constants/text";
import { complexityLevel } from "@react-ms-apps/common/utils/password";
import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";
import { authenticate, fetchUserInfo } from "../../../api";

type FetchingStatus = "fetching" | "success" | "error" | "";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: 24 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[600],
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface ChangePasswordDialogProps {
  onClose: () => void;
  open: boolean;
}

const ChangePasswordDialog = ({ onClose, open }: ChangePasswordDialogProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmValid, setConfirmValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [minPasswordLength, setMinPasswordLength] = useState(0);
  const [fetchingMinPasswordLength, setFetchingMinPasswordLength] =
    useState<FetchingStatus>("");
  const [minPasswordComplexity, setMinPasswordComplexity] = useState(0);
  const [fetchingMinPasswordComplexity, setFetchingMinPasswordComplexity] =
    useState<FetchingStatus>("");

  const getMeetsPasswordLength = (passwordValue: string) => {
    return passwordValue.length >= minPasswordLength;
  };

  const getMeetsPasswordComplexity = (passwordValue: string) => {
    return complexityLevel(passwordValue) >= minPasswordComplexity;
  };

  const oldPasswordInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const getMinPasswordLength = async () => {
    setFetchingMinPasswordLength("fetching");

    try {
      const resp = await fetchMinPasswordLength();
      setMinPasswordLength(resp);
      setFetchingMinPasswordLength("success");
    } catch (error) {
      setFetchingMinPasswordComplexity("error");
      Sentry.captureException(error);
    }
  };

  const getMinPasswordComplexity = async () => {
    setFetchingMinPasswordComplexity("fetching");

    try {
      const resp = await fetchMinPasswordComplexity();
      setMinPasswordComplexity(resp);
      setFetchingMinPasswordComplexity("success");
    } catch (error) {
      setFetchingMinPasswordComplexity("error");
      Sentry.captureException(error);
    }
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const input = e.currentTarget;

    setNewPassword(value);

    // handle password validation
    input.setCustomValidity("");

    // check min length
    if (!getMeetsPasswordLength(value)) {
      input.setCustomValidity(
        `Password must be at least ${minPasswordLength} characters long`
      );
    }

    // check password complexity
    if (!getMeetsPasswordComplexity(value)) {
      input.setCustomValidity(
        `Password must contain at least ${minPasswordComplexity} of the following: uppercase, lowercase, number, special character`
      );
    }
  };

  const handleConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmInput = e.currentTarget;
    const value = confirmInput.value;

    if (!value) {
      confirmInput.setCustomValidity("");
    } else if (value !== newPassword) {
      confirmInput.setCustomValidity("Passwords must match");
    } else {
      confirmInput.setCustomValidity("");
    }

    setConfirmPassword(e.target.value);
  };

  const handleSave = async (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !passwordValid ||
      !confirmValid ||
      !oldPasswordInputRef.current ||
      !passwordInputRef.current ||
      !confirmPasswordInputRef.current
    )
      return;

    // // reset password input validation
    // passwordInputRef.current.setCustomValidity("");
    // confirmPasswordInputRef.current.setCustomValidity("");

    // // validate that the passwords match
    // if (!confirmValid) {
    //   confirmPasswordInputRef.current.setCustomValidity(
    //     "Passwords must match."
    //   );
    //   confirmPasswordInputRef.current.reportValidity();
    //   return;
    // }

    const oldPassword = oldPasswordInputRef.current.value;
    const newPassword = passwordInputRef.current.value;

    // save password
    setIsSaving(true);

    try {
      const { user_name } = await fetchUserInfo();
      const success = await updatePassword(oldPassword, newPassword);

      if (success) {
        // unset form values
        oldPasswordInputRef.current.value = "";
        passwordInputRef.current.value = "";
        confirmPasswordInputRef.current.value = "";

        // close modal
        onClose();

        // re-authenticate using the new password
        await authenticate(user_name, newPassword);

        setSuccessMessage("Password successfully updated");

        // redirect to root login page
        // window.location.href = `/${getClientDB()}/login`;
      } else {
        // handle error
        Sentry.captureMessage("Error saving password");
        setErrorMessage("Error saving password");
      }
    } catch (error) {
      Sentry.captureException(error);

      let errorMessage = "";
      // @ts-ignore
      const errorResponse = error?.response?.data?.d?.errors?.[0] || "";
      if (errorResponse.includes("Invalid password")) {
        errorMessage = "The old password is incorrect. Please try again.";
      } else {
        errorMessage =
          "Sorry, there was an error saving your password. Please try again.";
      }

      setErrorMessage(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    // get min password length
    getMinPasswordLength();

    // get min password complexity
    getMinPasswordComplexity();
  }, []);

  useEffect(() => {
    if (
      fetchingMinPasswordLength === "error" ||
      fetchingMinPasswordComplexity === "error"
    ) {
      setErrorMessage(
        "There was an error fetching password requirements. Please try again later."
      );
    }
  }, [fetchingMinPasswordLength, fetchingMinPasswordComplexity]);

  // clear error message when input is changed
  useEffect(() => {
    setErrorMessage("");
  }, [newPassword, confirmPassword]);

  return (
    <>
      <BootstrapDialog open={open} onClose={onClose}>
        <BootstrapDialogTitle onClose={onClose}>
          Change Password
        </BootstrapDialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent dividers>
            <DialogContentText style={{ marginBottom: 8 }}>
              Enter your old password and new password below.
            </DialogContentText>
            <PasswordInput
              ref={oldPasswordInputRef}
              label={OLD_PASSWORD_TEXT}
            />

            <NewPasswordInput
              ref={passwordInputRef}
              onChange={handleNewPasswordChange}
              onValidityChange={setPasswordValid}
            />

            <ConfirmPasswordInput
              ref={confirmPasswordInputRef}
              password={newPassword}
              onChange={handleConfirmChange}
              onValidityChange={setConfirmValid}
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
            }}
            style={{
              padding: 16,
            }}
          >
            <Button onClick={onClose} style={{ fontSize: 14 }}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              style={{ fontSize: 14 }}
              loading={isSaving}
              variant="contained"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </BootstrapDialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!errorMessage || !!successMessage}
        message={errorMessage || successMessage}
      >
        <Alert
          style={{
            fontSize: 14,
          }}
          severity={errorMessage ? "error" : "success"}
          onClose={() => {
            setErrorMessage("");
            setSuccessMessage("");
          }}
        >
          {errorMessage || successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChangePasswordDialog;
