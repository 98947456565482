import { buildApiUrl } from "@react-ms-apps/common";
import axios from "axios";
import { SummaryTotalItem } from "./types";

interface UsageAndCostResponse {
  usage_and_cost: UsageAndCost[];
}

export interface UsageAndCost {
  equipment_total: number;
  total_charges: number;
  kb_usage: number;
  total_minutes_of_use: number;
  device_count: number;
  invoice_date: string;
  carrier_id: number;
  intl_data_roam_charges: number;
  formatted_device_type: string;
  international_total: number;
  carrier_name: string;
  kb_charges: number;
}

export async function getUsageAndCostData(
  statementMonth: string,
  maxMonths: number
): Promise<UsageAndCostResponse> {
  const response = await axios.get(
    buildApiUrl(
      `/api/aggregated_data?datasets=usage_and_cost&max_months=${maxMonths}&statement_month=${statementMonth}`
    )
  );

  return response.data;
}

export interface StatementMonthsResponse {
  all_months: StatementMonthItem[];
}

export interface StatementMonthItem {
  statement_month: string;
}

export async function getStatementMonths() {
  const response = await axios.get<StatementMonthsResponse>(
    buildApiUrl("/api/statement_months_list")
  );
  return response.data;
}

export async function getSummaryTotalForMonth(statementMonth: string) {
  const response = await axios.get<SummaryTotalItem[]>(
    buildApiUrl(`/api/report/SummaryTotals?statement_month==${statementMonth}`)
  );

  return response.data;
}
