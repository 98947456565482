export function containsAtLeastDigits(input: string, atLeast: number): boolean {
  const digitRegex = /\d/g;
  const digitMatches = input.match(digitRegex);

  if (digitMatches && digitMatches.length >= atLeast) {
    return true;
  } else {
    return false;
  }
}
