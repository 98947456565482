import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import { isArray, uniqBy } from "lodash";
import Papa from "papaparse";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

interface SuccessDialogProps {
  onClose: () => void;
  responseCsv: string[][];
  orginalFileName: string;
}

export default function SuccessDialog({
  onClose,
  responseCsv,
  orginalFileName,
}: SuccessDialogProps) {
  const [selectedStatus, setSelectedStatus] = useState<string>("All");
  const [downloading, setDownloading] = useState<boolean>(false);

  const handleDownload = async () => {
    setDownloading(true);

    try {
      const csvContent = Papa.unparse(responseCsv);
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${orginalFileName}__response.csv`;
      link.click();
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error downloading file");
    } finally {
      setDownloading(false);
    }
  };

  const isValidResponse = useMemo(() => {
    if (!responseCsv || !isArray(responseCsv) || responseCsv.length < 2) {
      return false;
    }

    return true;
  }, [responseCsv]);

  const responseHeaders = useMemo(() => {
    if (!isValidResponse) {
      return [];
    }

    return responseCsv[0];
  }, [isValidResponse, responseCsv]);

  const responseData = useMemo(() => {
    if (!isValidResponse) {
      return [];
    }

    return responseCsv.slice(1);
  }, [isValidResponse, responseCsv]);

  const rows = useMemo(() => {
    if (!isValidResponse) {
      return [];
    }

    // transform responseData from array of arrays to array of objects
    const data = responseData.map((row) => {
      const rowObject: Record<string, string> = {};
      rowObject.id = row[0];
      row.forEach((cell, index) => {
        rowObject[responseHeaders[index]] = cell;
      });
      return rowObject;
    });

    return data;
  }, [isValidResponse, responseData, responseHeaders]);

  const statusOptions: string[] = useMemo(() => {
    const uniqueOptions: string[] = ["All"];

    uniqBy(rows, "Status").forEach((row) => {
      uniqueOptions.push(row.Status);
    });

    return uniqueOptions;
  }, [rows]);

  const filteredRows = useMemo(() => {
    if (selectedStatus === "All") {
      return rows;
    }

    return rows.filter((row) => row.Status === selectedStatus);
  }, [rows, selectedStatus]);

  const cleanCell = useCallback((cell: string) => {
    // take cell from `="value"` to `value`
    if (
      typeof cell === "string" &&
      cell.startsWith('="') &&
      cell.endsWith('"')
    ) {
      return cell.slice(2, -1);
    }

    return cell;
  }, []);

  return (
    <Dialog
      open
      fullWidth
      onClose={onClose}
      maxWidth={isValidResponse ? "xl" : "xs"}
    >
      <DialogTitle>Results</DialogTitle>
      <DialogContent dividers>
        {!isValidResponse && (
          <DialogContentText>
            The file was processed successfully, however you will need to
            manually confirm the results.
          </DialogContentText>
        )}

        {isValidResponse && (
          <DataGridPro
            sx={{
              "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                flex: 1,
              },
            }}
            rows={filteredRows}
            columns={responseHeaders.map(
              (header): GridColDef => ({
                field: header,
                // helps "Message" column to be wider than the rest
                width: header === "Message" ? 350 : 200,
                editable: false,
                // helps "Message" column to be pinned to the right
                [header !== "Message" ? "flex" : ""]: 1,
                // "Status" column has a custom Select header for filtering
                [header === "Status" ? "valueOptions" : ""]: statusOptions,
                [header === "Status" ? "filterable" : ""]: false,
                [header === "Status" ? "sortable" : ""]: false,
                [header === "Status" ? "disableColumnMenu" : ""]: true,
                [header === "Status" ? "renderHeader" : ""]: () => {
                  if (header === "Status") {
                    return (
                      <FormControl
                        id="status-filter"
                        fullWidth
                        sx={{
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                          onBlur={(e) => {
                            e.stopPropagation();
                          }}
                          size="small"
                          labelId="status-label"
                          label="Status"
                          onChange={(e) =>
                            setSelectedStatus(e.target.value as string)
                          }
                          value={selectedStatus}
                        >
                          {statusOptions.map((status) => (
                            <MenuItem value={status}>{status}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }

                  return header;
                },
                renderCell: (params) => {
                  return cleanCell(params.value as string);
                },
                renderHeaderFilter: () => "Hello World",
              })
            )}
            pinnedColumns={{
              right: ["Message"],
            }}
            getRowClassName={(params) => {
              // if the "Status" column is "Success", then the row is green
              if (params.row.Status === "Success") {
                return "!bg-green-50";
              } else if (params.row.Status === "Failure") {
                // if the "Status" column is "Error", then the row is red
                return "!bg-red-50";
              } else if (params.row.Status === "Warning") {
                // if the "Status" column is "Warning", then the row is yellow
                return "!bg-yellow-50";
              }
              return "";
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>

        {isValidResponse && (
          <LoadingButton
            loading={downloading}
            disabled={downloading}
            variant="contained"
            startIcon={<Download />}
            onClick={handleDownload}
          >
            Download
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
