import axios from "axios";
import { ReportDTO, ReportSchemaDTO } from "../types";
import { buildApiUrl, sleep } from "../utils";

export interface ReportAuthorizedRolesDTO {
  report: string;
  report_authorized_role_id: number;
  authorized_roles: string;
  updated_at: string;
  etag?: string;
}

export type ReportsResponse = ReportAuthorizedRolesDTO[];

export async function fetchReportsAuthorizedRoles(): Promise<ReportsResponse> {
  const resp = await axios.get<ReportsResponse>(
    buildApiUrl("api/restables/report_authorized_roles")
  );

  return resp.data;
}

export async function fetchReportAuthorizedRolesByID(id: number) {
  const resp = await axios.get<ReportAuthorizedRolesDTO>(
    buildApiUrl(`api/restables/report_authorized_roles/${id}`)
  );

  return {
    report: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function updateReportAuthorizedRoles(
  id: number,
  report: string,
  authorizedRoles: string,
  ETAG: string
) {
  const resp = await axios.put<ReportAuthorizedRolesDTO>(
    buildApiUrl(`api/restables/report_authorized_roles/${id}`),
    { report, authorized_roles: authorizedRoles },
    {
      headers: {
        "If-Match": ETAG,
      },
    }
  );

  return {
    data: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function createReportAuhorizedRoles(
  report: string,
  authorizedRoles: string
) {
  const resp = await axios.post<ReportAuthorizedRolesDTO>(
    buildApiUrl(`api/restables/report_authorized_roles`),
    { report, authorized_roles: authorizedRoles }
  );

  return {
    report: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function deleteReportAuthorizedRoles(id: number, ETAG: string) {
  await axios.delete<ReportAuthorizedRolesDTO>(
    buildApiUrl(`api/restables/report_authorized_roles/${id}`),
    {
      headers: {
        "If-Match": ETAG,
      },
    }
  );
}

export interface ReportListItem {
  value: string;
  category: string;
  display_name: string;
  url: string;
}

type ReportListResponse = {
  // report value
  [key: string]: {
    // report category
    category: string;
    // report display name
    display_name: string;
  };
};

export async function fetchReportNames(): Promise<ReportListItem[]> {
  const resp = await axios.get<ReportListResponse>(
    buildApiUrl(`api/reportslist`)
  );

  return Object.keys(resp.data)
    .map((key) => ({
      value: key,
      category: resp.data[key].category,
      display_name: resp.data[key].display_name,
      url: buildApiUrl("/reports/?report_name=" + key),
    }))
    .sort((a, b) => {
      if (a.display_name < b.display_name) {
        return -1;
      } else if (a.display_name > b.display_name) {
        return 1;
      }

      return 0;
    });
}

// keeps track of which reports are loading
const REPORTS_LOADING_STATUS: { [key: string]: "loaded" | "loading" | "" } = {};

// caches reports by name and statement month to avoid unnecessary requests
const REPORTS_CACHE: { [key: string]: ReportDTO } = {};

export async function fetchReportByName(
  reportName: string,
  statementMonth: string
): Promise<ReportDTO> {
  const cacheKey = `${reportName}__${statementMonth}`;

  // kill the REPORTS_CACHE when the name changes
  if (
    Object.keys(REPORTS_CACHE).length &&
    !Object.keys(REPORTS_CACHE).some((key) => key.includes(reportName))
  ) {
    Object.keys(REPORTS_CACHE).forEach((key) => {
      delete REPORTS_CACHE[key];
    });
  }

  // if the report is already loading, wait for it to finish
  if (REPORTS_LOADING_STATUS[cacheKey] === "loading") {
    await sleep(500);
    return fetchReportByName(reportName, statementMonth);
  }

  if (REPORTS_CACHE[cacheKey]) {
    // return the cached value
    return REPORTS_CACHE[cacheKey];
  }

  REPORTS_LOADING_STATUS[cacheKey] = "loading";

  const resp = await axios.get<ReportDTO>(
    buildApiUrl(`api/report/${reportName}`),
    {
      params: {
        statement_month: statementMonth,
      },
    }
  );

  REPORTS_CACHE[cacheKey] = resp.data;

  REPORTS_LOADING_STATUS[cacheKey] = "loaded";

  // call the function recursively to return the cached value
  return fetchReportByName(reportName, statementMonth);
}

export async function preFetchReportsByName(
  name: string,
  statementMonths: string[]
): Promise<void> {
  for (let i = 0; i < statementMonths.length; i++) {
    const statementMonth = statementMonths[i];
    // rate limit requests to avoid overloading the server
    await fetchReportByName(name, statementMonth);
  }
}

export async function fetchReportSchemaByName(
  name: string
): Promise<ReportSchemaDTO> {
  const resp = await axios.get(buildApiUrl(`api/report_schema/${name}`));

  return resp.data;
}
