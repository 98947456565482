import { Check, ImportExport } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import {
  CatalogDevice,
  createDevice,
  fetchDevice,
} from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useCatalogManager } from "../../CatalogManagerProvider";

export default function ImportDevice({
  deviceId,
  clientName,
  manufacturer,
  model,
}: {
  deviceId: number;
  clientName: string;
  manufacturer: string;
  model: string;
}) {
  const {
    deviceNameManufacturerMap,
    getDeviceName,
    addDevice,
    addCatalogDevice,
    importedDevicesMap,
    addImportedDevice,
  } = useCatalogManager();

  const [importingDevice, setImportingDevice] = useState(false);

  const importDevice = useCallback(async () => {
    setImportingDevice(true);

    // fetch the device
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const device: any = await fetchDevice(deviceId, clientName);

      // remove "deviceId" from the device
      delete device.device_id;

      // remove "catalogDeviceId" from the device's "catalog_devices"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      device.catalog_devices.forEach((cd: any) => {
        delete cd.catalog_device_id;
        delete cd.device_id;
      });

      // create a new device with the same data
      const newDevice = await createDevice(device);
      toast.success(
        `Device "${newDevice.manufacturer} - ${newDevice.model}" imported successfully`
      );

      // add device to context
      addDevice(newDevice);

      // add catalog devices to context for each "catalog_devices" in the new device
      newDevice.catalog_devices.forEach((cd: CatalogDevice) =>
        addCatalogDevice(cd)
      );

      addImportedDevice(newDevice);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setImportingDevice(false);
    }
  }, [addCatalogDevice, addDevice, addImportedDevice, clientName, deviceId]);

  const deviceExists = useMemo(() => {
    return !!deviceNameManufacturerMap[getDeviceName(manufacturer, model)];
  }, [deviceNameManufacturerMap, getDeviceName, manufacturer, model]);

  const deviceImported = useMemo(() => {
    return !!importedDevicesMap[getDeviceName(manufacturer, model)];
  }, [importedDevicesMap, getDeviceName, manufacturer, model]);

  if (deviceExists) {
    return (
      <Tooltip title="Device already exists" placement="left">
        <LoadingButton
          fullWidth
          size="small"
          onClick={importDevice}
          startIcon={deviceImported ? null : <ImportExport />}
          color="warning"
          variant="outlined"
          loading={importingDevice}
          disabled={importingDevice || deviceImported}
        >
          {deviceImported ? <Check /> : "Import"}
        </LoadingButton>
      </Tooltip>
    );
  }

  // allow import
  return (
    <LoadingButton
      fullWidth
      size="small"
      onClick={importDevice}
      startIcon={deviceImported ? null : <ImportExport />}
      color="primary"
      variant="outlined"
      loading={importingDevice}
      disabled={importingDevice || deviceImported}
    >
      {deviceImported ? <Check /> : "Import"}
    </LoadingButton>
  );
}
