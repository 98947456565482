import { Portal } from "@mui/base";
import { Divider, Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { AvailableImage } from "@react-ms-apps/common/api/catalog-manager";
import { ChangeEvent, useCallback, useState } from "react";
import TabPanel from "../../CustomTabPanel";
import ImagesList from "./ImagesList";

enum TabIndex {
  Images = 0,
  Custom = 1,
}

interface SelectImageTab {
  name: string;
  value: TabIndex;
}

const selectImageTabs: SelectImageTab[] = [
  {
    name: "Images",
    value: TabIndex.Images,
  },
  {
    name: "Custom",
    value: TabIndex.Custom,
  },
];

const ImagesDialog = ({
  onClose,
  onSelect,
  availableImages,
}: {
  onClose: () => void;
  onSelect: (url: string) => void;
  availableImages: AvailableImage[];
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [url, setUrl] = useState("");

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const handleImageSelect = async (image: AvailableImage) => {
    onSelect(image.url);
    onClose();
  };

  const filterDeviceImages = useCallback(
    (deviceImage: AvailableImage) => {
      if (!searchInput) {
        return true;
      }

      return deviceImage.fileName
        .toLowerCase()
        .includes(searchInput.toLowerCase());
    },
    [searchInput]
  );

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSearchInput = event.target.value;
    setSearchInput(newSearchInput);
  };

  return (
    <Portal>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Choose an Image</DialogTitle>
        <DialogContent
          dividers
          style={{
            height: "600px",
            paddingBottom: 0,
          }}
        >
          <Tabs value={selectedTab} className="!pb-4">
            {selectImageTabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.name}
                value={tab.value}
                onClick={() => setSelectedTab(tab.value)}
              />
            ))}
          </Tabs>

          <TabPanel value={0} index={selectedTab}>
            <TextField
              label="Image Search"
              onChange={handleSearchInputChange}
            />

            <Divider className="!my-4" />

            <ImagesList
              availableImages={availableImages.filter(filterDeviceImages)}
              onImageSelect={handleImageSelect}
            />
          </TabPanel>

          <TabPanel value={1} index={selectedTab}>
            <TextField
              autoFocus
              margin="dense"
              label="Custom Image URL"
              type="url"
              fullWidth
              value={url}
              onChange={handleUrlChange}
            />

            {url && (
              <div className="flex justify-center items-center mt-4">
                <img
                  src={url}
                  alt="Custom Image"
                  className="max-w-md max-h-80"
                />
              </div>
            )}
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              onSelect(url);
              onClose();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
};

export default ImagesDialog;
