import { TableCell, TableRow } from "@mui/material";
import { UploadStatement, uploadStatementPDF } from "@react-ms-apps/common";
import { AxiosProgressEvent } from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { trimFilename } from "./utils";

interface FileRowProps {
  file: File;
  onDelete: () => void;
  onUploadComplete: (fileUpload: UploadStatement) => void;
}

export default function UploadFileRow({
  file,
  onDelete,
  onUploadComplete,
}: FileRowProps) {
  const [uploadStatus, setUploadStatus] = useState<
    "pending" | "success" | "error"
  >("pending");
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const uploadInProgress = useRef(false);

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (!progressEvent.loaded || !progressEvent.total) return;

    setUploadProgress(
      Math.round((progressEvent.loaded * 100) / progressEvent.total)
    );
  };

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = async () => {
      // prevent multiple uploads
      if (uploadInProgress.current) return;
      uploadInProgress.current = true;

      try {
        setUploadStatus("pending");
        // const resp = await uploadStatementPdf(file);
        const formData = new FormData();
        formData.append("files", file);

        const resp = await uploadStatementPDF(file, {
          onUploadProgress,
        });

        const statement = resp[file.name];

        const errors = statement.errors;
        if (
          errors &&
          errors.length > 0 &&
          errors[0] === "Statement has already been uploaded."
        ) {
          toast.info("Statement has already been uploaded");

          // remove the file from the list
          onDelete();

          return;
        }

        onUploadComplete(statement);
      } catch (error) {
        toast.error("Error uploading file");
        setUploadStatus("error");
      } finally {
        onDelete();
      }
    };

    reader.readAsDataURL(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableRow>
      <TableCell align="left">
        <div>{trimFilename(file.name)}</div>

        {uploadStatus === "pending" && (
          <div className="max-w-full w-full relative">
            <div className="h-6 rounded-md bg-gray-300">
              <div
                style={{ width: `${uploadProgress}%` }}
                className={
                  "h-6 rounded-md bg-green-600 transition-all duration-300 ease-linear text-center text-white text-xs flex items-center justify-center font-medium"
                }
              >
                {uploadProgress > 10 && <span>{uploadProgress} %</span>}
              </div>
            </div>
          </div>
        )}
      </TableCell>
      <TableCell align="center">Unknown</TableCell>
      <TableCell align="center">Unknown</TableCell>
      <TableCell align="center">Unknown</TableCell>
      <TableCell align="center">Unknown</TableCell>
      <TableCell align="right" />
    </TableRow>
  );
}
