import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataTestIDs } from "@react-ms-apps/app/src/Constants/data-test-ids";
import { RowEtagContextProvider } from "@react-ms-apps/app/src/Providers/RowEtagContext";
import { UtilityCategoryDTO, UtilityLinkDTO } from "@react-ms-apps/common";
import { useAuth } from "@react-ms-apps/common/providers";
import { classNames } from "@react-ms-apps/common/utils/styles";
import React from "react";
import UtilityRow from "../UtilityRow";

interface UtilitiesEditorTableProps {
  utilityLinks: UtilityLinkDTO[];
  utilityCategories: UtilityCategoryDTO[];
  visibleUtilityLinksMap: Record<number, UtilityLinkDTO>;
}

function UtilitiesEditorTable({
  utilityLinks,
  utilityCategories,
  visibleUtilityLinksMap,
}: UtilitiesEditorTableProps) {
  const { isBackOfficeUser } = useAuth();

  return (
    <Table stickyHeader data-testid={DataTestIDs.UTILITY_EDITOR_TABLE}>
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              width: 300,
            }}
            className="whitespace-nowrap"
          >
            Name / Custom Name
          </TableCell>
          <TableCell className="whitespace-nowrap !text-center">
            Active
          </TableCell>

          {isBackOfficeUser && (
            <TableCell
              style={{
                width: 300,
              }}
              className="whitespace-nowrap"
            >
              Utility Link
            </TableCell>
          )}

          <TableCell className="whitespace-nowrap">Category</TableCell>
          <TableCell className="whitespace-nowrap">
            Access Restrictions
          </TableCell>
          <TableCell
            align="center"
            style={{
              width: 100,
            }}
            className="whitespace-nowrap"
          >
            Refresh
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {utilityLinks.map((utilityLink) => (
          <RowEtagContextProvider key={utilityLink.utility_id}>
            <UtilityRow
              utilityID={utilityLink.utility_id}
              utilityCategories={utilityCategories}
              className={classNames(
                !visibleUtilityLinksMap[utilityLink.utility_id] && "!hidden"
              )}
            />
          </RowEtagContextProvider>
        ))}

        {utilityLinks.length === 0 && (
          <TableRow data-testid={DataTestIDs.UTILITY_EDITOR_TABLE_EMPTY_ROW}>
            <TableCell colSpan={7} className="text-center">
              No utilities found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default React.memo(UtilitiesEditorTable);
