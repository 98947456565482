import { Clear, Refresh, Save } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  debounce,
} from "@mui/material";
import {
  ROUTES,
  StatementSummaryField,
  fetchBanSummaries,
  fetchCarriersById,
  fetchStatementSummary,
  updateStatementSummaryFields,
} from "@react-ms-apps/common";
import { BanSummary } from "@react-ms-apps/common/types";
import { containsAtLeastDigits } from "@react-ms-apps/common/utils/digits";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { chain } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataTestIDs } from "../../../Constants/data-test-ids";
import {
  StatementContextProvider,
  StatementFieldsMap,
  useStatementContext,
} from "../StatementContext";
import StatementEditorTable, { editableFields } from "../StatementEditorTable";
import UserInfoTable from "../UserInfoTable";
import { ETAG_ERROR_MESSAGE } from "../constants";

function StatementEditorSearch() {
  // get query string from React Router
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const serviceNumberFromQuery = urlSearchParams.get("serviceNumber") || "";

  const { setStatementFieldsMap, etag, setEtag, statementFieldsMap } =
    useStatementContext();

  const stickyDivRef = useRef<HTMLDivElement>(null);

  /** Local State */
  const serviceNumberInput = useRef<HTMLInputElement>(null);
  const [serviceNumber, setServiceNumber] = useState<string>(
    serviceNumberFromQuery
  );
  const [loadedBanSummaries, setLoadedBanSummaries] = useState(false);
  const [banSummaries, setBanSummaries] = useState<BanSummary.BanSummaries>([]);

  const [carriers, setCarriers] = useState<BanSummary.Carrier[]>([]);

  const [banSummary, setBanSummary] = useState<BanSummary.BanSummary | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccountStatementID, setSelectedAccountStatementID] = useState<
    number | null
  >(null);

  const [showStickyShadow, setShowStickyShadow] = useState(false);

  /** MEMOIZED VALUES */

  // check that service number has at least 1 digit
  const hasValidServiceNumber = useMemo(() => {
    return containsAtLeastDigits(serviceNumber, 1);
  }, [serviceNumber]);

  /** HANDLERS */

  const handleClearData = useCallback(() => {
    if (serviceNumber !== "") {
      setServiceNumber("");
      if (serviceNumberInput.current) {
        serviceNumberInput.current.value = "";
        serviceNumberInput.current.focus();
      }
    }

    setSelectedAccountStatementID(null);
    setBanSummaries([]);
    setBanSummary(null);
  }, [serviceNumber]);

  const handleServiceNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setServiceNumber(event.target.value);
  };

  const debounceHandleSearchChange = debounce(handleServiceNumberChange, 500);

  const handleRefresh = async () => {
    await loadStatementSummary(setIsLoading);
  };

  /** FETCHES */

  const getBanSummaries = useCallback(async () => {
    setLoadedBanSummaries(false);

    try {
      // only keep digits from string
      const number = serviceNumber.replace(/\D/g, "");
      const banSummaries = await fetchBanSummaries(number);
      setBanSummaries(banSummaries);
    } catch (error) {
      toast.error(`Error loading Statements for "${serviceNumber}"`);
      Sentry.captureException(error);
    } finally {
      setLoadedBanSummaries(true);
    }
  }, [serviceNumber]);

  const loadStatementSummary = useCallback(
    async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
      if (!selectedAccountStatementID) return;

      setLoading(true);

      try {
        // fetch utility data
        const { data, etag } = await fetchStatementSummary(
          selectedAccountStatementID
        );
        // get ETAG from utility data
        setBanSummary(data);
        setEtag(etag);

        // set statement context
        const fieldsMap: StatementFieldsMap = {};
        editableFields.forEach((field) => {
          fieldsMap[field.fieldName] = {
            fieldName: field.fieldName,
            friendlyName: field.friendlyName,
            currentValue: data[field.fieldName]?.toString() || "",
            deltaValue: "",
            finalValue: "",
          };
        });
        setStatementFieldsMap(fieldsMap);
      } catch (error) {
        toast.error("Error loading order type data");
      } finally {
        setLoading(false);
      }
    },
    [selectedAccountStatementID, setEtag, setStatementFieldsMap]
  );

  const getCarriers = useCallback(async () => {
    try {
      const carrierIDs = chain(banSummaries)
        .map("statement_summary.carrier_id")
        .uniq()
        .value();

      // fetch carrier names
      const updatedCarriers = await fetchCarriersById(carrierIDs);
      setCarriers(updatedCarriers);
    } catch (error) {
      toast.error(`Error loading carrier names`);
    }
  }, [banSummaries]);

  const saveStatementSummary = async () => {
    if (!banSummary) return;

    try {
      setIsLoading(true);
      const updatedFields: StatementSummaryField[] = [];

      for (const fieldName in statementFieldsMap) {
        const field = statementFieldsMap[fieldName];
        if (!isNaN(Number(field.deltaValue)) && field.deltaValue !== "") {
          updatedFields.push({
            fieldName: field.fieldName,
            fieldValue: field.deltaValue,
          });
        }

        if (!isNaN(Number(field.finalValue)) && field.finalValue !== "") {
          // field value is final value - current value
          const fieldValue = (
            Number(field.finalValue) - Number(field.currentValue)
          ).toFixed(2);

          updatedFields.push({
            fieldName: field.fieldName,
            fieldValue,
          });
        }
      }

      const { etag: updatedEtag } = await updateStatementSummaryFields(
        banSummary.account_statement_id,
        updatedFields,
        etag
      );

      setEtag(updatedEtag);
      toast.success(`Statement updated`, {
        autoClose: 1000,
      });

      handleRefresh();
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 412) {
        toast.error(ETAG_ERROR_MESSAGE);
        return;
      }

      Sentry.captureException(error);
      toast.error(`Error updating statement`);
    } finally {
      setIsLoading(false);
    }
  };

  /* UTILITIES */

  const getQueryParams = useCallback(() => {
    const params = [];
    if (serviceNumber) params.push(`serviceNumber=${serviceNumber}`);
    if (selectedAccountStatementID)
      params.push(`accountStatementID=${selectedAccountStatementID}`);
    return params;
  }, [serviceNumber, selectedAccountStatementID]);

  /* EFFECTS */

  // load ban summaries when service number changes
  useEffect(() => {
    if (!hasValidServiceNumber) return;

    getBanSummaries();
  }, [getBanSummaries, hasValidServiceNumber]);

  // reset selected date and carrier when service number changes
  useEffect(() => {
    if (!hasValidServiceNumber) {
      handleClearData();
    }
  }, [handleClearData, hasValidServiceNumber]);

  // update URL when service number or billing date changes
  useEffect(() => {
    // use service number for query params
    if (
      (!!hasValidServiceNumber && serviceNumberFromQuery !== serviceNumber) ||
      !!selectedAccountStatementID
    ) {
      navigate(
        `${ROUTES.UTILITY.ROOT}${
          ROUTES.UTILITY.STATEMENT_SUMMARY_EDITOR
        }?${getQueryParams().join("&")}`
      );
      return;
    }

    const baseSearchPage = `${ROUTES.UTILITY.ROOT}/${ROUTES.UTILITY.STATEMENT_SUMMARY_EDITOR}`;
    // if no service number, redirect to search page
    if (!hasValidServiceNumber && window.location.pathname !== baseSearchPage) {
      navigate(baseSearchPage);
    }
  }, [
    getQueryParams,
    hasValidServiceNumber,
    navigate,
    selectedAccountStatementID,
    serviceNumber,
    serviceNumberFromQuery,
  ]);

  // load carriers when selected ban summary changes
  useEffect(() => {
    getCarriers();
  }, [getCarriers, setBanSummaries]);

  // load statement summary when selected ban summary changes
  useEffect(() => {
    if (!selectedAccountStatementID) return;

    loadStatementSummary(setIsLoading);
  }, [loadStatementSummary, selectedAccountStatementID]);

  // show sticky shadow when scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (!stickyDivRef.current) return;

      if (stickyDivRef.current.getBoundingClientRect().top === 0) {
        setShowStickyShadow(true);
      } else {
        setShowStickyShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <FormControl
        variant="standard"
        className="!mb-2"
        style={{ display: "initial" }}
      >
        <Input
          className="w-auto"
          inputRef={serviceNumberInput}
          defaultValue={serviceNumber}
          disabled={hasValidServiceNumber && !loadedBanSummaries}
          onChange={debounceHandleSearchChange}
          placeholder="Enter service number"
          id="utilties-manager-search"
          data-testid={DataTestIDs.STATEMENT_EDITOR_SEARCH}
          startAdornment={
            <InputAdornment position="start">
              {hasValidServiceNumber && !loadedBanSummaries ? (
                <CircularProgress size={20} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          }
          // end adornment with clear button
          endAdornment={
            serviceNumber && (
              <InputAdornment
                position="end"
                className="cursor-pointer"
                onClick={handleClearData}
              >
                <Clear />
              </InputAdornment>
            )
          }
        />
      </FormControl>

      {serviceNumberFromQuery && loadedBanSummaries && (
        <Paper>
          <div
            ref={stickyDivRef}
            className={classNames(
              "sticky top-0 bg-white z-10",
              showStickyShadow && "shadow-md"
            )}
          >
            <div className="p-3 w-full pt-8 flex flex-row flex-1 mb-2">
              {banSummaries.length === 0 && (
                <div className="flex flex-1 justify-center items-center">
                  <Typography variant="h6">
                    No account(s) found for: "{serviceNumber}"
                  </Typography>
                </div>
              )}

              {banSummaries.length > 0 && (
                <div className="flex flex-row w-1/2 items-center justify-between">
                  <div className="flex flex-1 w-1/2 mr-2">
                    <FormControl>
                      <InputLabel id="billing-date-select-label">
                        Billing Date
                      </InputLabel>
                      <Select
                        className="min-w-[300px]"
                        defaultValue={selectedAccountStatementID}
                        labelId="billing-date-select-label"
                        id="billing-date-select"
                        value={selectedAccountStatementID}
                        label="Billing Date"
                        onChange={(e) =>
                          setSelectedAccountStatementID(Number(e.target.value))
                        }
                      >
                        {banSummaries.map((banSummary) => (
                          <MenuItem
                            key={banSummary.statement_summary_id}
                            value={banSummary.account_statement_id}
                          >
                            {moment(
                              banSummary.statement_summary.invoice_period_end
                            ).format("YYYY-MM")}
                            {" : "}
                            {carriers.find(
                              (c) =>
                                c.id === banSummary.statement_summary.carrier_id
                            )?.name || ""}{" "}
                            ({banSummary.carrier_account_number})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {!!banSummary && (
                <div className="flex flex-1 ml-2 justify-end">
                  <Button
                    variant="text"
                    className="!px-8 !mr-2"
                    onClick={handleRefresh}
                    disabled={isLoading}
                    data-testid={
                      DataTestIDs.STATEMENT_EDITOR_TABLE_REFRESH_BUTTON
                    }
                  >
                    Refresh
                    <Refresh
                      className={classNames(
                        isLoading && "animate-spin",
                        "ml-2"
                      )}
                    />
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-2 !px-8"
                    onClick={saveStatementSummary}
                    disabled={isLoading}
                    data-testid={DataTestIDs.STATEMENT_EDITOR_SAVE_BUTTON}
                  >
                    Save
                    <Save className="ml-2" />
                  </Button>
                </div>
              )}
            </div>
          </div>

          {banSummaries.length > 0 && selectedAccountStatementID && (
            <>
              <UserInfoTable
                banSummary={banSummary}
                carriers={carriers}
                isLoading={isLoading}
              />

              <StatementEditorTable
                banSummary={banSummary}
                isLoading={isLoading}
                onRefresh={() => loadStatementSummary(setIsLoading)}
                disabled={isLoading}
              />
            </>
          )}
        </Paper>
      )}
    </>
  );
}

export default function StatementEditorSearchWrapper() {
  return (
    <StatementContextProvider>
      <StatementEditorSearch />
    </StatementContextProvider>
  );
}
