// Forgot password
export const FORGOT_PASSWORD_HEADER_TEXT = "Forgot password";
export const BACK_TO_LOGIN_TEXT = "Remember your password?";
export const ENTER_USERNAME_TEXT = "name@company.com";
export const ENTER_USERNAME_TO_RESET_PASSWORD_TEXT =
  "Enter your username to receive an email to reset your password.";
export const SEND_RECOVERY_EMAIL_TEXT = "Send reset email";

// Reset password
export const RESET_PASSWORD_HEADER_TEXT = "Change Password";
export const ENTER_NEW_PASSWORD_TEXT = "Enter new password";
export const CONFIRM_NEW_PASSWORD_TEXT = "Confirm new password";
export const ENTER_NEW_PASSWORD_AND_CONFIRM_TEXT =
  "Please enter your new password and confirm your new password.";
export const RESET_PASSWORD_BUTTON_TEXT = "Reset password";
export const BACK_TO_FORGOT_PASSWORD_TEXT = "Back to forgot password";
export const RESEND_RESET_EMAIL_TEXT = "Resend reset email";

// Login
export const SIGN_IN_TEXT = "Sign in";
export const USERNAME_TEXT = "Enter username";
export const PASSWORD_TEXT = "Enter password";
export const SIGN_IN_ACCOUNT_TEXT = "Sign in to your account";

// Navigation
export const BACK_TO_FORGOT_PASSWORD = "Back to forgot password";
