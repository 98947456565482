import { AuthorizedRoles } from "@react-ms-apps/common/types";

export function getEditableRoles(
  userRoles: AuthorizedRoles[],
  availableRoles: AuthorizedRoles[]
): AuthorizedRoles[] {
  const userRolesSet = new Set(userRoles);

  // tier 1 is MS_backoffice
  // tier 2 is MS_sysadmin
  // tier 3 is MS_telcoadmin
  // tier 4 is MS_admins
  // tier 5 is MS_manager

  // if user has tier 1, they can edit tier 1 and later
  // if user has tier 2, they can edit tier 2 and later
  // etc.

  // tier 1
  if (userRolesSet.has(AuthorizedRoles.MS_backoffice)) {
    return availableRoles;
  }

  // tier 2
  if (userRolesSet.has(AuthorizedRoles.MS_sysadmin)) {
    return availableRoles.filter(
      (role) => role !== AuthorizedRoles.MS_backoffice
    );
  }

  // tier 3
  if (userRolesSet.has(AuthorizedRoles.MS_telcoadmin)) {
    return availableRoles.filter(
      (role) =>
        role !== AuthorizedRoles.MS_backoffice &&
        role !== AuthorizedRoles.MS_sysadmin
    );
  }

  // tier 4
  if (userRolesSet.has(AuthorizedRoles.MS_admin)) {
    return availableRoles.filter(
      (role) =>
        role !== AuthorizedRoles.MS_backoffice &&
        role !== AuthorizedRoles.MS_sysadmin &&
        role !== AuthorizedRoles.MS_telcoadmin
    );
  }

  // tier 5
  if (userRolesSet.has(AuthorizedRoles.MS_manager)) {
    return availableRoles.filter(
      (role) =>
        role !== AuthorizedRoles.MS_backoffice &&
        role !== AuthorizedRoles.MS_sysadmin &&
        role !== AuthorizedRoles.MS_telcoadmin &&
        role !== AuthorizedRoles.MS_admin
    );
  }

  return availableRoles.filter(
    (role) =>
      role !== AuthorizedRoles.MS_backoffice &&
      role !== AuthorizedRoles.MS_sysadmin &&
      role !== AuthorizedRoles.MS_telcoadmin &&
      role !== AuthorizedRoles.MS_admin &&
      role !== AuthorizedRoles.MS_manager
  );
}
