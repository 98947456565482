import { Portal } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { createSupportTicket } from "@react-ms-apps/common/api";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../../providers";
import { ContentWrapper, SupportDialogForm } from "./styled";

interface SupportDialogProps {
  onClose: () => void;
  isBootstrapped?: boolean;
}

export default function SupportDialog({
  onClose,
  isBootstrapped,
}: SupportDialogProps) {
  const { user } = useAuth();
  const { email: userEmail } = user || {};

  const [fromEmail, setFromEmail] = useState<string>(userEmail || "");
  const [subject, setSubject] = useState<string>("");
  const [body, setBody] = useState<string>("");

  const [sendingMessage, setSendingMessage] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setSendingMessage(true);

    try {
      await createSupportTicket({
        email: fromEmail,
        subject,
        body,
      });
      // success message for support request
      const successMessage = "Support request sent successfully.";
      toast.success(successMessage);

      onClose();
    } catch (error) {
      Sentry.captureException(error);
      const errorMessage = "There was an error sending your support request.";
      toast.error(errorMessage);
    } finally {
      setSendingMessage(false);
    }
  };

  return (
    <>
      <Portal>
        <Dialog open={true} maxWidth="sm" fullWidth onClose={onClose}>
          <DialogTitle>Contact Support</DialogTitle>

          <SupportDialogForm isBootstrapped={isBootstrapped}>
            <DialogContent dividers>
              <ContentWrapper>
                <TextField label="To" value={"Support"} disabled />

                <TextField
                  label="From"
                  value={fromEmail}
                  onChange={(event) => setFromEmail(event.target.value)}
                  type="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />

                <TextField
                  label="Subject"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Enter a subject..."
                  required
                />

                <TextField
                  label="Message"
                  multiline
                  onChange={(event) => setBody(event.target.value)}
                  rows={4}
                  placeholder="Enter your message here..."
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </ContentWrapper>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                loading={sendingMessage}
                disabled={sendingMessage}
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Send
              </LoadingButton>
            </DialogActions>
          </SupportDialogForm>
        </Dialog>
      </Portal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
}
