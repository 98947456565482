import axios from "axios";
import { buildApiUrl } from "../utils";

export const uploadCsvToProcessor = async ({
  file,
  allow_blanks,
  service_endpoint,
}: {
  file: File;
  allow_blanks: boolean;
  service_endpoint: string;
}) => {
  var formdata = new FormData();
  formdata.append("files", file, file.name);

  // allows blank values to be updated
  let apiURL = buildApiUrl(service_endpoint);
  if (allow_blanks) {
    apiURL += "?update_blank=1";
  }

  const response = await axios.post<string | { d: null }>(apiURL, formdata);
  return response.data;
};
