import { Delete } from "@mui/icons-material";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";

interface DeleteRoleButtonProps extends GridRowParams {
  onRemoveRow: (id: number) => void;
}

export default function DeleteRoleButton({
  id,
  row,
  onRemoveRow,
}: DeleteRoleButtonProps) {
  return (
    <GridActionsCellItem
      icon={<Delete className="text-red-400" />}
      disabled={!row.etag}
      onClick={() => onRemoveRow(id as number)}
      label="Delete"
    />
  );
}
