import { NavData } from "@react-ms-apps/common/types/nav";
import { useMemo, useState } from "react";
import {
  CascadeHeader,
  NavCascadeItem,
  NavItemButton,
  NavItemDropdown,
  NavItemDropdownCascade,
  NavItemWrapper,
  RotateChevronRight,
} from "./styled";
import { getCascades } from "./utils/data";

interface NavItemProps {
  item: NavData.NavDataItem;
  onClick?: (item: NavData.NavDataItem) => void;
  index: number;
}

const NavItem = ({ item, onClick, index }: NavItemProps) => {
  const [showCascades, setShowCascades] = useState(false);

  const cascades = useMemo(() => getCascades(item), [item]);

  const relativeCascade = useMemo(() => {
    if (cascades.length === 1) return true;

    return (index + 1) * 0.5 >= cascades.length;
  }, [cascades.length, index]);

  return (
    <NavItemWrapper relativeCascade={relativeCascade}>
      <NavItemButton
        onMouseEnter={() => setShowCascades(true)}
        onMouseLeave={() => setShowCascades(false)}
        onClick={() => onClick && onClick(item)}
        href={item.href}
        // @ts-ignore
        target={item.target ? item.target : "_self"}
        variant="text"
        endIcon={
          cascades.length > 0 && <RotateChevronRight open={showCascades} />
        }
      >
        {item.link_name}
      </NavItemButton>

      {cascades.length > 0 && (
        <NavItemDropdown
          onMouseEnter={() => setShowCascades(true)}
          onMouseLeave={() => setShowCascades(false)}
        >
          {cascades.map((cascade) => (
            <NavItemDropdownCascade key={cascade.categoryName}>
              <CascadeHeader>{cascade.categoryName}</CascadeHeader>
              {cascade.items.map((subItem) => (
                <NavCascadeItem
                  key={subItem.name}
                  href={subItem.href}
                  // @ts-ignore
                  target={subItem.target ? subItem.target : "_self"}
                >
                  {subItem.name}
                </NavCascadeItem>
              ))}
            </NavItemDropdownCascade>
          ))}
        </NavItemDropdown>
      )}
    </NavItemWrapper>
  );
};

export default NavItem;
