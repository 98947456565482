import { LineChart, LineChartSlotProps } from "@mui/x-charts";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCost } from "../api";
import {
  barChartSlotProps,
  ByChartData,
  ChartDatasets,
  ChartType,
} from "../constants";
import { ChartEvents } from "../events";
import { currencyFormatter } from "../utils";
import { captureChart } from "./utils";

const CostOverTimeLineChart = ({
  startDate,
  endDate,
  usageAndCostData,
  byChartData,
  selectedDataFilters,
  fieldNames,
  minusFieldNames,
  dataSet,
}: {
  usageAndCostData: UsageAndCost[];
  startDate: string;
  endDate: string;
  byChartData: ByChartData;
  selectedDataFilters: string[];
  fieldNames: string[];
  minusFieldNames: string[];
  dataSet: ChartDatasets;
}) => {
  const lineChartData = useMemo(() => {
    const startDateMoment = moment(startDate, "YYYY-MM-DD");
    const endDateMoment = moment(endDate, "YYYY-MM-DD");
    const currentYearEnd = endDateMoment.clone();
    const currentYearStart = startDateMoment.clone();

    const priorYearEnd = currentYearStart.clone().subtract(1, "days");
    const priorYearStart = priorYearEnd
      .clone()
      .subtract(1, "years")
      .add(1, "days");

    const twoYearsPriorEnd = priorYearStart.clone().subtract(1, "days");
    const twoYearsPriorStart = twoYearsPriorEnd
      .clone()
      .subtract(1, "years")
      .add(1, "days");

    const aggregateDataByYear = (start: Moment, end: Moment) => {
      const monthlyTotal: number[] = [];
      const currentMonth = start.clone().startOf("month");

      while (currentMonth.isSameOrBefore(end, "month")) {
        monthlyTotal.push(0);
        currentMonth.add(1, "month");
      }

      usageAndCostData.forEach((item) => {
        const itemDate = moment(item.invoice_date);
        const isInTimeRange = itemDate.isBetween(start, end, "day", "[]");
        const isInFilteredData = selectedDataFilters.includes(
          byChartData === ByChartData.CARRIER
            ? item.carrier_name
            : item.formatted_device_type
        );

        if (isInTimeRange && isInFilteredData) {
          const monthIndex = itemDate.diff(start, "months");
          const cost = fieldNames.reduce((acc, fieldName) => {
            return acc + Number(item[fieldName as keyof UsageAndCost]);
          }, 0);
          const minusCost = minusFieldNames.reduce((acc, fieldName) => {
            return acc + Number(item[fieldName as keyof UsageAndCost]);
          }, 0);

          monthlyTotal[monthIndex] += cost - minusCost;
        }
      });

      return monthlyTotal;
    };

    const currentYearData = aggregateDataByYear(
      currentYearStart,
      currentYearEnd
    );
    const priorYearData = aggregateDataByYear(priorYearStart, priorYearEnd);
    const twoYearsPriorData = aggregateDataByYear(
      twoYearsPriorStart,
      twoYearsPriorEnd
    );

    const formattedData = currentYearData.map((value, index) => {
      const monthMoment = currentYearStart.clone().add(index, "months");
      return {
        month: monthMoment.format("MMM YY"),
        currentYear: value,
        priorYear: priorYearData[index] || 0,
        twoYearsPrior: twoYearsPriorData[index] || 0,
      };
    });

    return formattedData;
  }, [
    byChartData,
    startDate,
    endDate,
    fieldNames,
    minusFieldNames,
    selectedDataFilters,
    usageAndCostData,
  ]);

  const handleExportData = useCallback(async () => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const data = lineChartData.map((item) => {
      return {
        month: item.month,
        currentYear: formatter.format(item.currentYear),
        priorYear: formatter.format(item.priorYear),
        twoYearsPrior: formatter.format(item.twoYearsPrior),
      };
    });

    const csvData = [
      ["Month", "Current Year", "Prior Year", "2 Yrs Prior"],
      ...data.map((item) => [
        item.month,
        item.currentYear,
        item.priorYear,
        item.twoYearsPrior,
      ]),
    ];

    const csvContent = csvData
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "total-cost-over-time.csv";
    a.click();
    URL.revokeObjectURL(url);
  }, [lineChartData]);

  // listen for export data event
  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);

    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
    };
  }, [handleExportData]);

  // listen for export image event
  useEffect(() => {
    window.addEventListener(ChartEvents.ExportImage, () =>
      captureChart({
        dataSet,
        chartType: ChartType.LINE,
        byChartData,
        startDate,
        endDate,
      })
    );

    return () => {
      window.removeEventListener(ChartEvents.ExportImage, () =>
        captureChart({
          dataSet,
          chartType: ChartType.LINE,
          byChartData,
          startDate,
          endDate,
        })
      );
    };
  }, [byChartData, dataSet, startDate, endDate]);

  return (
    <LineChart
      dataset={lineChartData}
      series={[
        {
          dataKey: "currentYear",
          label: "Current Year",
          valueFormatter: (value) => currencyFormatter(value),
        },
        {
          dataKey: "priorYear",
          label: "Prior Year",
          valueFormatter: (value) => currencyFormatter(value),
        },
        {
          dataKey: "twoYearsPrior",
          label: "2 Yrs Prior",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      xAxis={[{ scaleType: "band", dataKey: "month" }]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      height={window.innerHeight - 300}
      slotProps={barChartSlotProps as LineChartSlotProps}
      margin={{ left: 80, right: 20, top: 20, bottom: 20 }} // Adjust the left margin to provide more space for y-axis labels
    />
  );
};

export default CostOverTimeLineChart;
