import { UserInfoDTO } from "@react-ms-apps/common/types/user";
import * as Sentry from "@sentry/react";
import { isAxiosError } from "axios";
import React, { useCallback, useEffect, useMemo } from "react";
import { establishSession } from "../api";
import { fetchUserInfo } from "../api/user";
import { AuthorizedRoles } from "../types";
import { sleep } from "../utils";

const AuthContext = React.createContext<{
  user: UserInfoDTO | null;
  setUser: React.Dispatch<React.SetStateAction<UserInfoDTO | null>>;
  authLoaded: boolean;
  setAuthLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  checkAuth: () => Promise<void>;
  getUser: () => Promise<null | UserInfoDTO>;
  isBackOfficeUser: boolean;
  isTelcoAdminUser: boolean;
  isSysAdminUser: boolean;
  isAdminUser: boolean;
  roles: AuthorizedRoles[];
  hasCheckedCookieBaseline: boolean;
}>({
  user: null,
  setUser: () => {},
  authLoaded: false,
  setAuthLoaded: () => {},
  isAuthenticated: false,
  setAuthenticated: () => {},
  checkAuth: async () => {},
  getUser: async () => null,
  isBackOfficeUser: false,
  isTelcoAdminUser: false,
  isSysAdminUser: false,
  isAdminUser: false,
  roles: [],
  hasCheckedCookieBaseline: false,
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<null | UserInfoDTO>(null);
  const [authLoaded, setAuthLoaded] = React.useState<boolean>(false);
  const [isAuthenticated, setAuthenticated] = React.useState<boolean>(false);
  const [hasCheckedBaseline, setHasCheckedBaseline] =
    React.useState<boolean>(false);

  const isCheckingAuth = React.useRef(false);

  const checkAuth = useCallback(async (): Promise<void> => {
    if (isCheckingAuth.current) {
      await sleep(300);
      return checkAuth();
    }

    isCheckingAuth.current = true;

    if (!hasCheckedBaseline) {
      // check for the presence of a cookie associated with the user's environment
      await establishSession();
      setHasCheckedBaseline(true);
    }

    setAuthLoaded(false);

    try {
      const user = await fetchUserInfo();
      setUser(user);
      setAuthenticated(true);
    } catch (error) {
      setAuthenticated(false);

      // do not send an error to Sentry if the error is a 401
      // and user is on the login page
      if (
        isAxiosError(error) &&
        error.response?.status === 401 &&
        window.location.pathname.includes("/login")
      ) {
        return;
      }

      Sentry.captureException(error);
    } finally {
      setAuthLoaded(true);
    }
  }, [hasCheckedBaseline]);

  const getUser = async (): Promise<null | UserInfoDTO> => {
    // if user is already set, return it
    if (user) return user;

    await checkAuth();

    return user;
  };

  const isValidRolesData = useMemo(() => {
    if (!user || !user.roles || typeof user.roles !== "string") return false;

    return true;
  }, [user]);

  const roles = useMemo((): AuthorizedRoles[] => {
    if (!isValidRolesData) return [];

    if (
      user &&
      typeof user === "object" &&
      user.roles &&
      typeof user.roles === "string"
    ) {
      return (user.roles.split(",") as unknown as AuthorizedRoles[]) || [];
    }

    return [];
  }, [user, isValidRolesData]);

  const isBackOfficeUser = useMemo(() => {
    return roles.includes(AuthorizedRoles.MS_backoffice) ?? false;
  }, [roles]);

  const isTelcoAdminUser = useMemo(() => {
    return roles.includes(AuthorizedRoles.MS_telcoadmin) ?? false;
  }, [roles]);

  const isSysAdminUser = useMemo(() => {
    return roles.includes(AuthorizedRoles.MS_sysadmin) ?? false;
  }, [roles]);

  const isAdminUser = useMemo(() => {
    return roles.includes(AuthorizedRoles.MS_admin) ?? false;
  }, [roles]);

  useEffect(() => {
    if (authLoaded) return;

    checkAuth();
  }, [authLoaded, checkAuth, user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authLoaded,
        setAuthLoaded,
        isAuthenticated,
        setAuthenticated,
        checkAuth,
        getUser,
        isBackOfficeUser,
        isTelcoAdminUser,
        isSysAdminUser,
        isAdminUser,
        roles,
        hasCheckedCookieBaseline: hasCheckedBaseline,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
