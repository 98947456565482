import {
  UsageAndCost,
  UsageAndCostAlert,
} from "@react-ms-apps/common/types/invoice-summary";
import axios from "axios";
import { buildApiUrl } from "../../utils";
import { StatementMonthsListResponse } from "./types";

interface UsageAndCostDataSetsResponse {
  usage_and_cost: UsageAndCost[];
}

export async function fetchUsageAndCostDataSets(
  statementMonth: string,
  maxMonths: number = 36
) {
  const resp = await axios.get<UsageAndCostDataSetsResponse>(
    buildApiUrl(
      `api/aggregated_data?datasets=usage_and_cost&max_months=${maxMonths}&statement_month=${statementMonth}`
    )
  );

  return resp.data.usage_and_cost;
}

export async function fetchUsageAndCostAlerts(
  statementMonth: string,
  maxMonths: number = 36
) {
  const resp = await axios.get<UsageAndCostAlert[]>(
    buildApiUrl(
      `api/alerts/UsageAndCosts?datasets=usage_and_cost&max_months=${maxMonths}&statement_month=${statementMonth}`
    )
  );

  return resp.data;
}

export async function fetchStatementMonthsList(): Promise<string[]> {
  const resp = await axios.get<StatementMonthsListResponse>(
    buildApiUrl("api/statement_months_list")
  );

  return resp.data.all_months
    .map((item) => item.statement_month)
    .sort((a, b) => {
      const aDate = new Date(a);
      const bDate = new Date(b);

      if (aDate > bDate) {
        return -1;
      }

      if (aDate < bDate) {
        return 1;
      }

      return 0;
    });
}
