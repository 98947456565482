import styled from "@emotion/styled";
import { ChevronRight } from "@mui/icons-material";
import { Button, Paper, Typography } from "@mui/material";

const primaryColor = "#23527c";

export const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0;
  margin-bottom: 0.25rem;
  position: relative;
  z-index: 10;
`;

interface NavItemWrapperProps {
  relativeCascade: boolean;
}

export const NavItemWrapper = styled.div<NavItemWrapperProps>`
  &:hover > a {
    color: white !important;
    background-color: ${primaryColor} !important;
  }

  &:hover > div {
    display: flex;
  }

  ${({ relativeCascade }) =>
    relativeCascade &&
    `
    position: relative;
  `}
`;

export const NavbarOuterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface RotateChevronRightProps {
  open: boolean;
}

export const RotateChevronRight = styled(ChevronRight)<RotateChevronRightProps>`
  transform: ${({ open }) => (open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease-in-out;
  margin-left: -0.5rem !important;
`;

export const NavItemButton = styled(Button)`
  font-size: 13px;
  text-transform: none;
  font-weight: 400;
  position: initial;
  color: ${primaryColor};
  transition: none;
  letter-spacing: 0.05em !important;
`;

export const NavItemDropdown = styled(Paper)`
  flex-direction: row;
  display: none;
  position: absolute;
  left: 0;
  top: 35px;
`;

export const NavItemDropdownCascade = styled.div`
  border-right: 1px solid rgb(209 213 219 / 1);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem;
  padding-bottom: 1rem;

  &:last-of-type {
    border-right: none;
  }
`;

export const CascadeHeader = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 0.1em !important;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  color: rgb(75 85 99 / 1);
  cursor: default;
  white-space: nowrap;
`;

export const NavCascadeItem = styled(Button)`
  text-align: left;
  justify-content: flex-start;
  font-size: 13px;
  text-transform: none;
  width: 100%;
  color: ${primaryColor} !important;
  transition: none;
  white-space: nowrap;

  &:hover {
    color: white !important;
    background-color: ${primaryColor} !important;
  }
`;
