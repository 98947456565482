import { GridColDef } from "@mui/x-data-grid";
import { ReportDTO, ReportItemDTO } from "@react-ms-apps/common/types";
import moment from "moment";

export function createPrintTable(
  columns: GridColDef[],
  report: ReportDTO,
  selectedReportName: string,
  selectedStatementMonth: string
) {
  // create simple table for printing
  const table = document.createElement("table");
  table.className = "report-print-table";

  // create table header
  const thead = document.createElement("thead");
  const tr = document.createElement("tr");
  thead.appendChild(tr);

  // create table body
  const tbody = document.createElement("tbody");

  // create table footer
  const tfoot = document.createElement("tfoot");

  // create table header cells
  columns.forEach((column) => {
    const th = document.createElement("th");
    th.innerText = column.headerName as string;
    tr.appendChild(th);
  });

  // create table body rows
  report.forEach((row) => {
    const tr = document.createElement("tr");

    columns.forEach((column) => {
      const td = document.createElement("td");
      td.innerText = row[column.field as keyof ReportItemDTO] as string;

      // align cells based on column alignment
      td.style.textAlign = column.align as string;

      // format gb usage
      if (column.field === "gb_usage") {
        td.innerText = Number(row[column.field as keyof ReportItemDTO])
          .toFixed(2)
          .toString();
      }

      tr.appendChild(td);
    });

    tbody.appendChild(tr);
  });

  table.appendChild(thead);
  table.appendChild(tbody);
  table.appendChild(tfoot);

  // insert table into a div wrapper
  const wrapper = document.createElement("div");
  wrapper.className = "report-print-wrapper";

  // add a header to the print view
  const header = document.createElement("h1");
  header.className = "report-print-header";
  header.innerText = selectedReportName;

  // add left and right headers for billing cycle and current date
  const leftHeader = document.createElement("h2");
  leftHeader.className = "report-print-header-left";
  leftHeader.innerText = `Billing Cycle: ${moment(
    selectedStatementMonth
  ).format("MMMM YYYY")}`;

  const rightHeader = document.createElement("h2");
  rightHeader.className = "report-print-header-right";
  rightHeader.innerText = `Date: ${moment().format("MMM D, YYYY")}`;

  const subHeadersWrapper = document.createElement("div");
  subHeadersWrapper.className = "report-print-sub-headers-wrapper";
  subHeadersWrapper.appendChild(leftHeader);
  subHeadersWrapper.appendChild(rightHeader);

  wrapper.appendChild(header);
  wrapper.appendChild(subHeadersWrapper);
  wrapper.appendChild(table);

  document.body.appendChild(wrapper);
}

export function destroyPrintTable() {
  const wrapper = document.querySelector(".report-print-wrapper");
  if (wrapper) {
    document.body.removeChild(wrapper);
  }
}
