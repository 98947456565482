import React from "react";

export const RowEtagContext = React.createContext<{
  etag: string;
  setEtag: React.Dispatch<React.SetStateAction<string>>;
}>({
  etag: "",
  setEtag: () => {},
});

export const useRowEtagContext = () => React.useContext(RowEtagContext);

interface RowEtagContextProps {
  children: React.ReactNode;
}

export const RowEtagContextProvider = ({ children }: RowEtagContextProps) => {
  const [etag, setEtag] = React.useState<string>("");

  return (
    <RowEtagContext.Provider value={{ etag, setEtag }}>
      {children}
    </RowEtagContext.Provider>
  );
};
