import { Cancel, ExitToApp, FileCopy, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { PageContainer, ROUTES } from "@react-ms-apps/common";
import { Device } from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import { isEmpty, isEqual, omit } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ImagesProvider } from "../../../Providers/ImagesProvider";
import { useCatalogManager } from "../CatalogManagerProvider";
import AccessoriesTab from "./AccessoriesTab";
import CatalogDevicesTab from "./CatalogDevicesTab";
import ClonedDeviceDialog from "./ClonedDeviceDialog";
// import DeleteDeviceDialog from "./DeleteDeviceDialog";
import DeviceTab from "./DeviceTab";
import ImagesTab from "./ImagesTab";
import MissingDeviceModal from "./MissingDeviceModal";
import { EditDeviceTabEnum, tabs } from "./constants";

export function EditDevice() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    createDevice,
    updateDevice,
    getDevice,
    // deleteDeviceItem,
    cloneDevice,
  } = useCatalogManager();

  // get query params
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj: { [key: string]: string } = {};

    params.forEach((value, key) => {
      paramsObj[key] = value;
    });

    return paramsObj;
  }, []);

  // get catalogDeviceId from query params
  const catalogDeviceId: number | null = useMemo(() => {
    if (!queryParams.catalogDeviceId) {
      return null;
    }

    return Number(queryParams.catalogDeviceId);
  }, [queryParams]);

  const [colors, setColors] = useState<string[]>([]);
  const [device, setDevice] = useState<Device>({} as Device);
  const [updatedDevice, setUpdatedDevice] = useState<Device>({} as Device);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState<EditDeviceTabEnum>(
    catalogDeviceId
      ? EditDeviceTabEnum.CATALOG_DEVICES
      : EditDeviceTabEnum.DEVICE
  );

  const [clonedDevice, setClonedDevice] = useState<Device | null>(null);
  const [cloningDevice, setCloningDevice] = useState<boolean>(false);

  const [loadingDevice, setLoadingDevice] = useState<boolean>(false);
  const [savingDevice, setSavingDevice] = useState<boolean>(false);
  // const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const isSaveAndReturn = useRef<boolean>(false);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const canSave = useMemo(() => {
    // can save if updatedDevice is different from device or
    // if colors are different from device colors
    return (
      !isEqual(device, updatedDevice) ||
      !isEqual(
        device?.device_colors?.map((dc) => dc.color).sort(),
        colors.sort()
      )
    );
  }, [colors, device, updatedDevice]);

  const handleSave = useCallback(
    async (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      // do not continue if device data is not valid
      if (
        !updatedDevice ||
        !updatedDevice.manufacturer ||
        !updatedDevice.model ||
        !updatedDevice.pda_style_id
      ) {
        return;
      }

      setSavingDevice(true);

      const new_device_colors: {
        [key: string]: boolean;
      } = {};

      const deviceColors = device?.device_colors?.map((dc) => dc.color) || [];

      // detemine any new colors that were added based on a diff of device and colors
      // if a color is in colors but not device, it's a new color
      colors
        .filter((color) => !deviceColors.some((dc) => dc === color))
        .forEach((color) => {
          new_device_colors[color] = true;
        });

      // determine any colors that will remain based on a diff of device and colors
      // if a color is in both device and colors, it will remain
      deviceColors
        .filter((dc) => colors.includes(dc))
        .forEach((dc) => {
          new_device_colors[dc] = true;
        });

      // if a color is in device but not colors, it was removed
      deviceColors
        .filter((dc) => !colors.includes(dc))
        .forEach((dc) => {
          new_device_colors[dc] = false;
        });

      const updatedData = omit({ ...updatedDevice, new_device_colors }, [
        "device_colors",
      ]);

      try {
        let data: Device;
        if (!id) {
          // this means we are creating a new device
          data = await createDevice(updatedData);

          // redirect to edit page
          navigate(
            `${ROUTES.UTILITY.ROOT}${ROUTES.UTILITY.CATALOG_MANAGER.ROOT}/${ROUTES.UTILITY.CATALOG_MANAGER.EDIT_DEVICE}/${data.device_id}`,
            {
              replace: true,
            }
          );
        } else {
          // this means we are updating an existing device
          data = await updateDevice(updatedData);
        }

        setDevice(data);
        setUpdatedDevice(data);
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      } finally {
        setSavingDevice(false);
      }
    },
    [
      colors,
      createDevice,
      device?.device_colors,
      id,
      navigate,
      updateDevice,
      updatedDevice,
    ]
  );

  const handleSaveAndReturn = useCallback(
    async (e: React.FormEvent<HTMLElement>) => {
      try {
        await handleSave(e);

        // redirect to back to catalog manager
        navigate(-1);
      } catch (error) {
        toast.error("Error updating device");
      }
    },
    [handleSave, navigate]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      // do not continue if device data is not valid
      if (
        !updatedDevice ||
        !updatedDevice.manufacturer ||
        !updatedDevice.model ||
        !updatedDevice.pda_style_id
      ) {
        if (!updatedDevice.manufacturer) {
          toast.error("Please fill out the Manufacturer field");
        } else if (!updatedDevice.model) {
          toast.error("Please fill out the Model field");
        } else if (!updatedDevice.pda_style_id) {
          toast.error("Please select a PDA style");
        }

        return;
      }

      try {
        if (isSaveAndReturn.current) {
          await handleSaveAndReturn(e);
        } else {
          await handleSave(e);
        }

        toast.success("Device updated successfully");
      } catch (error) {
        toast.error("Error updating device");
      } finally {
        setSavingDevice(false);
      }
    },
    [handleSaveAndReturn, handleSave, updatedDevice]
  );

  const handleCancel = useCallback(() => {
    // redirect to back to catalog manager
    navigate(-1);
  }, [navigate]);

  // const handleDeleteDevice = useCallback(async () => {
  //   try {
  //     await deleteDeviceItem(Number(id));
  //     setShowDeleteDialog(false);

  //     toast.success("Device deleted successfully");

  //     // go back to catalog manager
  //     navigate(`${ROUTES.UTILITY.ROOT}${ROUTES.UTILITY.CATALOG_MANAGER.ROOT}`);
  //   } catch (error) {
  //     Sentry.captureException(error);
  //     toast.error("Error deleting device");
  //   }
  // }, [deleteDeviceItem, id, navigate]);

  const handleGetDevice = useCallback(
    async (deviceId: number) => {
      setLoadingDevice(true);

      try {
        const device = await getDevice(deviceId);
        setDevice(device);
        setUpdatedDevice(device);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoadingDevice(false);
      }
    },
    [getDevice]
  );

  const handleClone = useCallback(async () => {
    try {
      setCloningDevice(true);
      const newDevice = await cloneDevice(Number(id));

      // open modal to go to new device
      setClonedDevice(newDevice);
    } catch (error) {
      Sentry.captureException(error);

      toast.error("Error cloning device");
    } finally {
      setCloningDevice(false);
    }
  }, [cloneDevice, id]);

  useEffect(() => {
    if (!id) {
      // this means we are creating a new device
      return;
    }

    // Fetch device data using the id from the params
    handleGetDevice(Number(id));
  }, [getDevice, handleGetDevice, id]);

  // update colors when device is updated
  useEffect(() => {
    if (isEmpty(device) || !device.device_colors) {
      return;
    }

    setColors(device.device_colors.map((dc) => dc.color));
  }, [device]);

  return (
    <>
      <PageContainer className="pb-10">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-1 flex-col mt-4">
            {loadingDevice ? (
              <div className="flex flex-1 justify-center items-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex flex-col w-full max-w-lg mx-auto pb-8">
                <Tabs value={activeTab}>
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      onClick={() => setActiveTab(tab.value)}
                    />
                  ))}
                </Tabs>

                {!!device?.device_id && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Catalog Device ID</TableCell>
                        <TableCell>Device ID</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          // add white background to the row
                          "&:last-child td, &:last-child th": {
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <TableCell>
                          {(device.catalog_devices || [])
                            .map((cd) => cd.catalog_device_id)
                            .join(", ")}
                        </TableCell>
                        <TableCell>{device.device_id}</TableCell>
                        <TableCell>{device.manufacturer}</TableCell>
                        <TableCell>{device.model}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}

                {!!device && (
                  <>
                    <DeviceTab
                      activeTab={activeTab}
                      device={device}
                      updatedDevice={updatedDevice}
                      onUpdateDevice={setUpdatedDevice}
                      deviceColors={colors}
                      updateDeviceColors={setColors}
                    />

                    <ImagesTab
                      activeTab={activeTab}
                      device={device}
                      updatedDevice={updatedDevice}
                      onUpdateDevice={setUpdatedDevice}
                    />

                    <CatalogDevicesTab
                      activeTab={activeTab}
                      updatedDevice={updatedDevice}
                      onUpdateDevice={setUpdatedDevice}
                    />

                    <AccessoriesTab
                      activeTab={activeTab}
                      updatedDevice={updatedDevice}
                      onUpdateDevice={setUpdatedDevice}
                    />
                  </>
                )}
              </div>
            )}
          </div>

          <div className="fixed bottom-8 left-0 right-0 flex justify-center p-2 bg-gray-100 border-t space-x-2 items-center">
            {/* <Button
              disabled={!id}
              variant="contained"
              color="error"
              size="small"
              endIcon={<Delete />}
              onClick={() => setShowDeleteDialog(true)}
            >
              Delete Device
            </Button> */}
            <LoadingButton
              ref={saveButtonRef}
              disabled={!canSave}
              loading={savingDevice}
              variant="contained"
              color="primary"
              size="small"
              endIcon={<Save />}
              type="submit"
            >
              Save
            </LoadingButton>
            <LoadingButton
              disabled={!canSave}
              loading={savingDevice}
              variant="contained"
              color="primary"
              size="small"
              endIcon={<ExitToApp />}
              onClick={() => {
                isSaveAndReturn.current = true;
                saveButtonRef.current?.click();
              }}
            >
              Save & Return
            </LoadingButton>
            <LoadingButton
              onClick={handleClone}
              disabled={!id || cloningDevice}
              loading={cloningDevice}
              variant="contained"
              color="secondary"
              size="small"
              endIcon={<FileCopy />}
            >
              Clone
            </LoadingButton>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              endIcon={<Cancel />}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </form>
      </PageContainer>

      {!!id && isEmpty(device) && !loadingDevice && isEmpty(updatedDevice) && (
        <MissingDeviceModal />
      )}

      {/* {showDeleteDialog && (
        <DeleteDeviceDialog
          onClose={() => setShowDeleteDialog(false)}
          onDeleteDevice={handleDeleteDevice}
        />
      )} */}

      {!!clonedDevice && (
        <ClonedDeviceDialog
          onClose={() => setClonedDevice(null)}
          device={clonedDevice}
        />
      )}
    </>
  );
}

export default function EditDeviceWrapper() {
  return (
    <ImagesProvider>
      <EditDevice />
    </ImagesProvider>
  );
}
