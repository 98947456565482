import { Refresh } from "@mui/icons-material";
import { Button, Skeleton, TableCell, TableRow } from "@mui/material";
import { useRowEtagContext } from "@react-ms-apps/app/src/Providers/RowEtagContext";
import { UtilityCategoryDTO, UtilityLinkDTO } from "@react-ms-apps/common";
import { fetchUtilityByID } from "@react-ms-apps/common/api/utilities";
import { useAuth } from "@react-ms-apps/common/providers";
import { classNames } from "@react-ms-apps/common/utils/styles";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataTestIDs } from "../../../Constants/data-test-ids";
import AccessRestrictions from "./AccessRestrictions";
import ActiveToggle from "./ActiveToggle";
import CategorySelection from "./CategorySelection";
import CellTextInput from "./CellTextInput";

interface UtilityRowProps {
  utilityID: number;
  utilityCategories: UtilityCategoryDTO[];
  className?: string;
}

function UtilityRow({
  utilityID,
  utilityCategories,
  className,
}: UtilityRowProps) {
  const [utilityLink, setUtilityLink] = useState<UtilityLinkDTO | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { isBackOfficeUser } = useAuth();
  const { setEtag } = useRowEtagContext();

  const loadUtility = useCallback(async () => {
    setIsLoading(true);
    try {
      // fetch utility data
      const { utility, etag } = await fetchUtilityByID(utilityID);
      // get ETAG from utility data
      setUtilityLink(utility);
      setEtag(etag);
    } catch (error) {
      toast.error("Error loading utility data");
    } finally {
      setIsLoading(false);
    }
  }, [setEtag, utilityID]);

  const refreshUtility = async () => {
    await loadUtility();
    toast.success(
      `${
        utilityLink?.custom_utility_name ||
        utilityLink?.utility_name ||
        "Utility"
      } data refreshed`,
      {
        autoClose: 1000,
      }
    );
  };

  useEffect(() => {
    loadUtility();
  }, [loadUtility, utilityID]);

  if (!utilityLink) {
    return (
      // skeleton loader
      <TableRow
        className={className}
        data-testid={DataTestIDs.UTILITY_EDITOR_TABLE_SKELETON_ROW}
      >
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>

        {isBackOfficeUser && (
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
        )}

        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Button variant="text" disabled={true}>
            <Refresh className="animate-spin" />
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow
      key={utilityLink.utility_id}
      data-testid={DataTestIDs.UTILITY_EDITOR_TABLE_ROW}
      className={className}
    >
      <TableCell>
        <CellTextInput
          {...utilityLink}
          label={utilityLink.utility_name}
          value={utilityLink.custom_utility_name}
          defaultValue={utilityLink.custom_utility_name}
          fieldName="custom_utility_name"
          maxLength={40}
        />
      </TableCell>
      <TableCell size="small" align="center">
        <ActiveToggle {...utilityLink} />
      </TableCell>

      {isBackOfficeUser && (
        <TableCell>
          <CellTextInput
            {...utilityLink}
            label="Utility Link"
            value={utilityLink.utility_link}
            defaultValue={utilityLink.utility_link}
            fieldName="utility_link"
            maxLength={128}
          />
        </TableCell>
      )}

      <TableCell size="medium">
        <CategorySelection categories={utilityCategories} {...utilityLink} />
      </TableCell>
      <TableCell>
        <AccessRestrictions {...utilityLink} />
      </TableCell>
      <TableCell>
        <Button variant="text" onClick={refreshUtility} disabled={isLoading}>
          <Refresh className={classNames(isLoading && "animate-spin")} />
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default React.memo(UtilityRow);
