import { Typography } from "@mui/material";
import { Container } from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { usePageTitle } from "../../Providers/PageTitleProvider";

export default function NotFoundClientPage() {
  const { setTitle } = usePageTitle();

  // log the path to Sentry for debugging
  useEffect(() => {
    Sentry.captureMessage(`404: ${window.location.pathname}`);
  }, []);

  useEffect(() => {
    setTitle("Invalid Address");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="!flex flex-1">
      <div className="-mt-40 flex flex-1 flex-col justify-center items-center">
        <div className="mb-4">
          <Typography variant="h4">Error</Typography>
        </div>
        <Typography variant="body1">
          You have reached an invalid address. Please correct it and try again.
        </Typography>
        <Typography variant="body1">
          If you believe you've incorrectly received this message, please
          contact us at{" "}
          <a href="mailto:support@mobilsense.com" target="_blank">
            support@mobilsense.com
          </a>
          .
        </Typography>
      </div>
    </Container>
  );
}
