import { Typography } from "@mui/material";
import { AvailableImage } from "@react-ms-apps/common/api/catalog-manager";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageList = ({
  availableImages,
  onImageSelect,
}: {
  availableImages: AvailableImage[];
  onImageSelect: (image: AvailableImage) => void;
}) => {
  const [images, setImages] = useState<AvailableImage[]>(availableImages);
  const [hasMore, setHasMore] = useState(true);

  const loadMoreImages = () => {
    // Simulating loading more images (local images)
    const additionalImages = Array.from({ length: 10 }, (_, index) => {
      const imageNumber = images.length + index + 1; // Change this formula as needed
      return availableImages[imageNumber];
    });

    // Simulating delay in loading images (setTimeout)
    setTimeout(() => {
      setImages((prevImages) => [...prevImages, ...additionalImages]);
      // For demonstration, let's set a limit of 30 images in total
      if (images.length >= 30) {
        setHasMore(false);
      }
    }, 1000);
  };

  // watch for changes in availableImages
  useEffect(() => {
    setImages(availableImages);
  }, [availableImages]);

  return (
    <div className="image-list">
      <InfiniteScroll
        dataLength={images.length}
        next={loadMoreImages}
        hasMore={hasMore}
        loader={images.length === 0 && <Typography>No results</Typography>}
      >
        <div className="grid grid-cols-3 gap-2">
          {images.map((image, index) => (
            <div
              onClick={() => onImageSelect(image)}
              key={index}
              className="flex flex-col justify-end items-center cursor-pointer hover:bg-primary-50 rounded p-4"
            >
              <LazyLoadImage
                src={image.url}
                alt={`Image ${index + 1}`}
                width="auto"
                height="100px"
              />
              <Typography>{image.fileName}</Typography>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ImageList;
