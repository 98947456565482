import { PageContainer } from "@react-ms-apps/common";
import { useEffect } from "react";
import { usePageTitle } from "../../Providers/PageTitleProvider";
import StatementEditorSearch from "./StatementEditorSearch";

export default function StatementEditor() {
  const { setTitle } = usePageTitle();

  // set page title
  useEffect(() => {
    setTitle("Statement Summary Editor");
  }, [setTitle]);

  return (
    <PageContainer
      utilityName="Statement Summary Editor"
      description="Use this page to modify Billing Statements. Search for a Service
    Number (account phone number) below to begin."
      className="flex-1 flex-col pb-20"
    >
      <StatementEditorSearch />
    </PageContainer>
  );
}
