import Button from "@mui/material/Button";
import { useState } from "react";
import { useAuth } from "../../providers";
import SupportDialog from "../SupportDialog";

interface ButtonBarProps {
  onHelpClick?: () => void;
  onTutorialClick?: () => void;
}

/**
 * ButtonBar
 *
 * This component is loaded into the footer in a special way due to the way the footer is rendered.
 * In order to render this component in the footer, the footer component must be rendered in the App component.
 * However, the button bar is customized on a per-page basis, so the useFooter hook is used to set the button bar.
 */
export default function ButtonBar({
  onHelpClick,
  onTutorialClick,
}: ButtonBarProps) {
  const { isAuthenticated } = useAuth();

  const [showSupportDialog, setShowSupportDialog] = useState(false);

  return (
    <>
      <div className="flex flex-row gap-x-2 pl-4">
        {onHelpClick && (
          <Button
            size="small"
            color="inherit"
            className="!min-w-fit !px-2"
            onClick={onHelpClick}
          >
            Help
          </Button>
        )}

        {onTutorialClick && (
          <Button size="small" color="inherit" onClick={onTutorialClick}>
            Tutorial
          </Button>
        )}

        {isAuthenticated && (
          <Button
            size="small"
            color="inherit"
            onClick={() => setShowSupportDialog(true)}
          >
            Support
          </Button>
        )}
      </div>

      {showSupportDialog && (
        <SupportDialog onClose={() => setShowSupportDialog(false)} />
      )}
    </>
  );
}
