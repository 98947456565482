import { TableCell, TableRow, Typography } from "@mui/material";
import { BanSummary } from "@react-ms-apps/common/types";
import { useStatementContext } from "../StatementContext";
import { currencyHasValue } from "../utils";
import CurrencyInput from "./CurrencyInput";

interface StatementEditorRowProps {
  fieldName: BanSummary.EditableField;
  friendlyName: string;
  disabled?: boolean;
}

export default function StatementEditorRow({
  fieldName,
  friendlyName,
  disabled,
}: StatementEditorRowProps) {
  const { statementFieldsMap, setField } = useStatementContext();
  const statementField = statementFieldsMap[fieldName];

  const handleDeltaChange = (value: string) => {
    const currentValue = statementField?.currentValue || "0.00";
    const deltaValue = value;
    setField(fieldName, { currentValue, deltaValue });
  };

  const handleFinalChange = (value: string) => {
    const currentValue = statementField?.currentValue || "0.00";
    const finalValue = value;
    setField(fieldName, { currentValue, finalValue });
  };

  return (
    <TableRow>
      <TableCell>{statementField.friendlyName}</TableCell>
      <TableCell>
        <Typography align="center">
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(Number(statementField.currentValue || "0.00"))}
        </Typography>
      </TableCell>

      <TableCell className="!text-center">+</TableCell>

      <TableCell align="center">
        <CurrencyInput
          defaultValue={""}
          friendlyFieldName={`${friendlyName} (Delta)`}
          onChange={handleDeltaChange}
          disabled={disabled || currencyHasValue(statementField.finalValue)}
          disabledReason={
            currencyHasValue(statementField.finalValue)
              ? `Clear the "Final" input for "${friendlyName}" to edit this.`
              : undefined
          }
        />
      </TableCell>

      <TableCell className="!text-center">=</TableCell>

      <TableCell align="center">
        <CurrencyInput
          defaultValue={""}
          friendlyFieldName={`${friendlyName} (New)`}
          onChange={handleFinalChange}
          disabled={disabled || currencyHasValue(statementField.deltaValue)}
          disabledReason={
            currencyHasValue(statementField.deltaValue)
              ? `Clear the "Delta" input for "${friendlyName}" to edit this.`
              : undefined
          }
        />
      </TableCell>
    </TableRow>
  );
}
