import { NavData } from "@react-ms-apps/common/types/nav";
import { getNavbarUrl } from "@react-ms-apps/common/utils/api-url";
import axios from "axios";

export const fetchNavbarData = async (): Promise<NavData.NavDataItem[]> => {
  const url = getNavbarUrl();
  const response = await axios.get(url, {
    headers: {
      Accept: "application/json",
    },
  });

  return response.data;
};
