import axios from "axios";
import { buildApiUrl } from "../utils";

interface SupportTicket {
  email: string;
  subject: string;
  body: string;
}

export async function createSupportTicket(ticket: SupportTicket) {
  const response = await axios.post(buildApiUrl("api/nc/support"), {
    from: ticket.email,
    subject: ticket.subject,
    body: ticket.body,
  });
  return response.data;
}
