import { LinearProgress } from "@mui/material";
import {
  PageContainer,
  ReportAuthorizedRolesDTO,
  ReportListItem,
  deleteReportAuthorizedRoles,
  fetchReportAuthorizedRolesByID,
  fetchReportNames,
  fetchReportsAuthorizedRoles,
} from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import AddReportModal from "./AddReportModal";
import ReportRolesManagerTable from "./ReportRolesManagerTable";

export default function ReportRolesManager() {
  const [rows, setRows] = useState<ReportAuthorizedRolesDTO[]>([]);
  const [loadingReports, setLoadingReports] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [loadingEtags, setLoadingEtags] = useState(false);
  const [allReportOptions, setAllReportOptions] = useState<ReportListItem[]>(
    []
  );
  const [isLoadingReportOptions, setIsLoadingReportOptions] = useState(false);
  const [reportsMap, setReportsMap] = useState<{ [key: string]: boolean }>({});

  /**
   * MEMOIZED VALUES
   */
  const reportOptions = useMemo(() => {
    return allReportOptions
      .filter((option) => !reportsMap[option.display_name])
      .sort();
  }, [reportsMap, allReportOptions]);

  const getReportRolesEtags = useCallback(
    async (reportRoles: ReportAuthorizedRolesDTO[]) => {
      setLoadingEtags(true);

      try {
        for (let i = 0; i < reportRoles.length; i++) {
          const item = reportRoles[i];
          const { report, etag } = await fetchReportAuthorizedRolesByID(
            item.report_authorized_role_id
          );

          setRows((prevReportRoles) => {
            return prevReportRoles.map((prevReportRole) => {
              if (
                prevReportRole.report_authorized_role_id ===
                report.report_authorized_role_id
              ) {
                return { ...report, etag };
              }

              return prevReportRole;
            });
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoadingEtags(false);
      }
    },
    []
  );

  const getReportOptions = useCallback(async () => {
    setIsLoadingReportOptions(true);

    try {
      const options = await fetchReportNames();

      const availableOptions = options
        .filter((option) => !reportsMap[option.display_name])
        .sort();

      setAllReportOptions(availableOptions);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error fetching report options");
    } finally {
      setIsLoadingReportOptions(false);
    }
  }, [reportsMap]);

  const getReports = useCallback(async () => {
    setLoadingReports(true);

    let sortedReports: ReportAuthorizedRolesDTO[] = [];

    try {
      const reports = await fetchReportsAuthorizedRoles();

      sortedReports = reports.sort((a, b) => {
        if (a.report > b.report) {
          return 1;
        } else if (a.report < b.report) {
          return -1;
        } else {
          return 0;
        }
      });

      const rows: ReportAuthorizedRolesDTO[] = sortedReports.map((report) => {
        return { ...report, etag: "" };
      });
      setRows(rows);

      // set reports map
      const reportsMap = sortedReports.reduce((acc, cur) => {
        acc[cur.report] = true;
        return acc;
      }, {} as { [key: string]: boolean });

      setReportsMap(reportsMap);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingReports(false);
      await getReportRolesEtags(sortedReports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReportRolesEtags]);

  const handleRemoveRow = useCallback(
    async (reportAuthorizedRoleID: number) => {
      const row = rows.find(
        (report) =>
          report.report_authorized_role_id === Number(reportAuthorizedRoleID)
      );
      if (!row || !row.etag) return;

      try {
        await deleteReportAuthorizedRoles(
          reportAuthorizedRoleID as number,
          row.etag
        );

        setRows((prevReports) => {
          return prevReports.filter(
            (report) =>
              report.report_authorized_role_id !==
              Number(reportAuthorizedRoleID)
          );
        });

        toast.success(`Removed "${row.report}" successfully`);

        // refresh reports
        await getReports();
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [getReports, rows]
  );

  const handleUpdateRow = useCallback(
    (reportAuthorizedRole: ReportAuthorizedRolesDTO) => {
      setRows((prevReports) => {
        return prevReports.map((prevReport) => {
          if (
            prevReport.report_authorized_role_id ===
            reportAuthorizedRole.report_authorized_role_id
          ) {
            return { ...reportAuthorizedRole };
          }

          return prevReport;
        });
      });
    },
    []
  );

  useEffect(() => {
    getReports();
    getReportOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageContainer
        headerId="report-roles-manager-header"
        descriptionId="report-roles-manager-description"
        utilityName="Report Roles Manager"
        description="Use this utility to manage authorized roles for reports."
        className="!w-full flex flex-1 flex-col"
      >
        {loadingEtags && <LinearProgress />}
        <ReportRolesManagerTable
          rows={rows}
          onSetUpdatedRow={handleUpdateRow}
          loading={loadingReports}
          isLoadingReportOptions={isLoadingReportOptions}
          onRemoveRow={handleRemoveRow}
          onAddClick={() => setShowAddModal(true)}
        />
      </PageContainer>

      <AddReportModal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        onRefresh={getReports}
        reportOptions={reportOptions}
        isLoadingReportOptions={isLoadingReportOptions}
      />
    </>
  );
}
