import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  CatalogDeviceImageItem,
  Device,
  addImageToDevice,
} from "@react-ms-apps/common/api/catalog-manager";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useImagesContext } from "../../../Providers/ImagesProvider";
import TabPanel from "../CustomTabPanel";
import DeleteImageModal from "./DeleteImageModal";
import ImagesDialog from "./ImagesDialog";
import { EditDeviceTabEnum } from "./constants";

enum AddImageType {
  None = "none",
  New = "new-image",
  ChangeImage = "change-image",
  ChangeThumbnail = "change-thumbnail",
}

interface ImagesTabProps {
  activeTab: number;
  device: Device;
  updatedDevice: Device;
  onUpdateDevice: (device: Device) => void;
}

export default function ImagesTab({
  activeTab,
  updatedDevice,
  onUpdateDevice,
}: ImagesTabProps) {
  const { deviceImages } = useImagesContext();

  const [selectedDeviceImageId, setSelectedDeviceImageId] = useState<
    number | null
  >(null);
  // const [showImagesDialog, setShowImagesDialog] = useState<boolean>(false);
  const [addImageType, setAddImageType] = useState<AddImageType>(
    AddImageType.None
  );
  const [imageForDeletion, setImageForDeletion] = useState<number | null>(null);

  const selectedDeviceImage = useMemo(() => {
    if (
      !updatedDevice ||
      !updatedDevice.device_images ||
      !updatedDevice.device_images.length ||
      !selectedDeviceImageId
    ) {
      return null;
    }

    return updatedDevice.device_images.find(
      (image) => image.device_image_id === selectedDeviceImageId
    );
  }, [selectedDeviceImageId, updatedDevice]);

  const autoSelectImage = useCallback(() => {
    if (
      !updatedDevice ||
      !updatedDevice.device_images ||
      !updatedDevice.device_images.length
    ) {
      setSelectedDeviceImageId(null);

      return;
    }

    // set selected image to default image
    // if no default image, set to first image
    const defaultImage = updatedDevice.device_images.find(
      (image) => image.is_default
    );
    const firstImage = updatedDevice.device_images[0];
    setSelectedDeviceImageId(
      defaultImage?.device_image_id || firstImage.device_image_id || null
    );
  }, [updatedDevice]);

  const handleRemoveImage = useCallback(
    async (imageId: number) => {
      if (!updatedDevice) {
        return;
      }

      const updatedData = {
        ...updatedDevice,
        device_images: updatedDevice.device_images.filter(
          (image) => image.device_image_id !== imageId
        ),
      };

      onUpdateDevice(updatedData);
      autoSelectImage();
    },
    [autoSelectImage, updatedDevice, onUpdateDevice]
  );

  const handleUpdateImage = useCallback(
    async (url: string) => {
      if (!selectedDeviceImage) return;

      const updatedImage: CatalogDeviceImageItem = {
        ...selectedDeviceImage,
      };
      switch (addImageType) {
        case AddImageType.ChangeImage:
          updatedImage.image_url = url;
          break;
        case AddImageType.ChangeThumbnail:
          updatedImage.thumbnail_url = url;
          break;
        default:
          break;
      }

      const deviceData: Device = {
        ...updatedDevice,
        device_images: updatedDevice.device_images.map((image) =>
          image.device_image_id === updatedImage.device_image_id
            ? updatedImage
            : image
        ),
      };

      onUpdateDevice(deviceData);
    },
    [selectedDeviceImage, addImageType, updatedDevice, onUpdateDevice]
  );

  const handleAddImage = useCallback(
    async (url: string) => {
      const { device_id, device_images = [] } = updatedDevice || {};

      const manufacturer = updatedDevice?.manufacturer || "";
      const model = updatedDevice?.model || "";

      const imageText = `${manufacturer} ${model}`;

      // if no images, set as default
      const isDefault = device_images.length === 0;

      try {
        const image = await addImageToDevice({
          device_id,
          image_alt_text: imageText,
          image_url: url,
          image_caption: imageText,
          is_default: isDefault,
        });

        const updatedData = {
          ...updatedDevice,
          device_images: [...device_images, image],
        };

        onUpdateDevice(updatedData);
        setSelectedDeviceImageId(image.device_image_id);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [updatedDevice, onUpdateDevice]
  );

  useEffect(() => {
    autoSelectImage();
  }, [autoSelectImage]);

  if (!updatedDevice?.device_id) {
    return (
      <TabPanel
        value={activeTab}
        index={EditDeviceTabEnum.IMAGES}
        className="flex flex-1 flex-col gap-4"
      >
        <Alert severity="warning">Device must be saved first.</Alert>
      </TabPanel>
    );
  }

  return (
    <>
      <TabPanel
        value={activeTab}
        index={EditDeviceTabEnum.IMAGES}
        className="flex flex-1 flex-col gap-4"
      >
        <div className="flex flex-row gap-2">
          {updatedDevice &&
            !isEmpty(updatedDevice.device_images) &&
            updatedDevice.device_images.map((image) => (
              <Paper
                key={image.device_image_id}
                component={Button}
                onClick={() => setSelectedDeviceImageId(image.device_image_id)}
                className={classNames(
                  "cursor-pointer !w-[100px] !h-[100px] flex items-center justify-center",
                  selectedDeviceImageId === image.device_image_id &&
                    "!border-2 !border-primary-500"
                )}
                sx={{
                  // show active when selectedDeviceImage is matching the current image
                  border:
                    selectedDeviceImageId === image.device_image_id
                      ? `2px solid rgb(35, 84, 125)`
                      : "2px solid transparent",
                }}
              >
                <img
                  src={image.image_url}
                  alt={image.image_alt_text}
                  className="max-h-[95px] max-w-[95px]"
                />
              </Paper>
            ))}

          <Paper
            component={Button}
            className="cursor-pointer !w-[100px] !h-[100px] flex items-center justify-center"
            onClick={() => setAddImageType(AddImageType.New)}
          >
            Add Image
          </Paper>
        </div>

        {selectedDeviceImage && (
          <Card square={false} className="!rounded-md">
            <CardContent className="flex flex-col gap-4">
              <TextField
                fullWidth
                label="Image Alt. Text"
                value={selectedDeviceImage.image_alt_text || ""}
                onChange={(e) => {
                  if (!selectedDeviceImage) return;

                  const updatedImage: CatalogDeviceImageItem = {
                    ...selectedDeviceImage,
                    image_alt_text: e.target.value,
                  };

                  onUpdateDevice({
                    ...updatedDevice,
                    device_images: updatedDevice.device_images.map((image) =>
                      image.device_image_id === updatedImage.device_image_id
                        ? updatedImage
                        : image
                    ),
                  });
                }}
              />

              <TextField
                fullWidth
                label="Image Caption"
                value={selectedDeviceImage.image_caption || ""}
                onChange={(e) => {
                  if (!selectedDeviceImage) return;

                  const updatedImage: CatalogDeviceImageItem = {
                    ...selectedDeviceImage,
                    image_caption: e.target.value,
                  };

                  onUpdateDevice({
                    ...updatedDevice,
                    device_images: updatedDevice.device_images.map((image) =>
                      image.device_image_id === updatedImage.device_image_id
                        ? updatedImage
                        : image
                    ),
                  });
                }}
              />

              <div className="flex flex-row justify-between">
                <div className="flex flex-col items-start justify-end gap-2">
                  <img
                    src={selectedDeviceImage.image_url || ""}
                    alt=""
                    className="h-28"
                  />
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setAddImageType(AddImageType.ChangeImage)}
                  >
                    Change Image
                  </Button>
                </div>

                <div className="flex flex-col items-end justify-end gap-2">
                  {selectedDeviceImage.thumbnail_url ? (
                    <img
                      src={selectedDeviceImage.thumbnail_url || ""}
                      alt="Thumbnail"
                    />
                  ) : (
                    <div className="w-28 h-28 bg-gray-200 flex items-center justify-center">
                      <Typography className="text-gray-500 text-center">
                        No Thumbnail
                      </Typography>
                    </div>
                  )}
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() =>
                      setAddImageType(AddImageType.ChangeThumbnail)
                    }
                  >
                    Change Thumbnail
                  </Button>
                </div>
              </div>
            </CardContent>

            <CardActions className="flex flex-row justify-between">
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={selectedDeviceImage.is_default} />}
                  label="Default Image"
                  onChange={(e, checked) => {
                    if (!selectedDeviceImage) return;

                    const updatedImage: CatalogDeviceImageItem = {
                      ...selectedDeviceImage,
                      is_default: checked,
                    };

                    const updatedDeviceImages = updatedDevice.device_images.map(
                      // deselect all other images
                      (image) =>
                        image.device_image_id === updatedImage.device_image_id
                          ? updatedImage
                          : { ...image, is_default: false }
                    );

                    onUpdateDevice({
                      ...updatedDevice,
                      device_images: updatedDeviceImages,
                    });
                  }}
                />
              </FormGroup>

              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  setImageForDeletion(selectedDeviceImage.device_image_id)
                }
              >
                Delete This Device Image
              </Button>
            </CardActions>
          </Card>
        )}
      </TabPanel>

      {addImageType !== AddImageType.None && updatedDevice && (
        <ImagesDialog
          onClose={() => setAddImageType(AddImageType.None)}
          onSelect={
            addImageType === AddImageType.New
              ? handleAddImage
              : handleUpdateImage
          }
          availableImages={deviceImages}
        />
      )}

      {!!imageForDeletion && updatedDevice && (
        <DeleteImageModal
          onClose={() => setImageForDeletion(null)}
          onDelete={handleRemoveImage}
          imageId={imageForDeletion}
        />
      )}
    </>
  );
}
