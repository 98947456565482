export enum AuthorizedRoles {
  MS_sysadmin = "MS_sysadmin",
  MS_telcoadmin = "MS_telcoadmin",
  MS_backoffice = "MS_backoffice",
  MS_admin = "MS_admin",
  MS_manager = "MS_manager",
  MS_selfservice = "MS_selfservice",

  // any authenticated user
  Authenticated = "Authenticated",

  // less often used
  MS_profile = "MS_profile",
  MS_editUser = "MS_editUser",
  MS_reporting = "MS_reporting",
  MS_user = "MS_user",
  MS_domainall = "MS_domainall",
}
