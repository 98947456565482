import { buildApiUrl, getClientDB } from "@react-ms-apps/common/utils";
import axios from "axios";
import { inRange } from "lodash";
import {
  AccessoryTypeList,
  AvailableImage,
  CarrierList,
  CatalogAccessory,
  CatalogAccessoryDTO,
  CatalogDevice,
  CatalogDeviceDTO,
  CatalogDeviceImageItem,
  Device,
  DeviceAccessory,
  DeviceCatalogAccessory,
  DeviceDTO,
  DeviceList,
  LinkCatalogAccessory,
  PDAStyles,
} from "./types";

export * from "./types";

export async function fetchPDAStyles() {
  const resp = await axios.get<PDAStyles>(
    buildApiUrl("api/static_data/pda_styles")
  );
  return resp.data;
}

export async function fetchCarriers(clientName: string = getClientDB() || "") {
  const resp = await axios.get<CarrierList>(
    `/${clientName}/api/static_data/carriers`
  );
  return resp.data;
}

export async function fetchDeviceItems(
  clientName: string = getClientDB() || ""
) {
  const resp = await axios.get<DeviceList>(`/${clientName}/api/devices`);
  return resp.data;
}

export async function fetchCatalogDevices(
  clientName: string = getClientDB() || ""
) {
  const resp = await axios.get<CatalogDevice[]>(
    `/${clientName}/api/catalog_devices?includes=can_delete`
  );
  return resp.data;
}

export async function fetchAccessoryTypes() {
  const resp = await axios.get<AccessoryTypeList>(
    buildApiUrl("api/static_data/accessory_types")
  );
  return resp.data;
}

export async function fetchDeviceColors() {
  const resp = await axios.get<string[]>(
    buildApiUrl("api/devices_color_enums")
  );
  return resp.data;
}

export async function updateCatalogDevice(catalogDevice: CatalogDeviceDTO) {
  const resp = await axios.put<CatalogDevice>(
    buildApiUrl("api/catalog_devices"),
    catalogDevice
  );
  return resp.data;
}

export async function deleteCatalogDevice(id: number) {
  await axios.put(buildApiUrl(`api/catalog_device_delete/${id}`), {});
}

export async function fetchCatalogDevice(id: number) {
  const resp = await axios.get<CatalogDevice>(
    buildApiUrl(`api/catalog_devices/${id}`)
  );
  return resp.data;
}

export async function fetchDevice(
  id: number,
  clientName: string = getClientDB() || ""
) {
  const resp = await axios.get<Device>(`/${clientName}/api/devices/${id}`);
  return resp.data;
}

export async function fetchDevicesImages() {
  const resp = await axios.get<AvailableImage[]>(
    "/images/devices/devicesImages.json"
  );

  return resp.data;
}

interface AddImageRequest {
  device_id: number;
  image_alt_text: string;
  image_url: string;
  image_caption: string;
  is_default: boolean;
}

export async function addImageToDevice(data: AddImageRequest) {
  const resp = await axios.put<CatalogDeviceImageItem>(
    buildApiUrl(`api/device_images`),
    data
  );
  return resp.data;
}

export async function deleteDeviceImage(id: number) {
  const resp = await axios.put(buildApiUrl(`api/device_image_delete/${id}`));

  return resp.status === 200;
}

export async function createDevice(data: DeviceDTO) {
  const resp = await axios.post<Device>(buildApiUrl("api/devices"), data);
  return resp.data;
}

export async function updateDevice(deviceId: number, data: DeviceDTO) {
  const resp = await axios.put<Device>(
    buildApiUrl(`api/devices/${deviceId}`),
    data
  );
  return resp.data;
}

export async function disconnectCatalogAccessory(
  linkCatalogAccessory: LinkCatalogAccessory
) {
  const resp = await axios.put(
    buildApiUrl("api/disconnect_catalog_accessory"),
    linkCatalogAccessory
  );
  return resp.data;
}

export async function fetchAccessoriesImages() {
  const resp = await axios.get<AvailableImage[]>(
    "/images/accessories/accessoriesImages.json"
  );

  return resp.data;
}

export async function createCatalogAccessory(
  catalogAccessory: CatalogAccessoryDTO
) {
  const resp = await axios.post<CatalogAccessory>(
    buildApiUrl("api/restables/catalog_accessories"),
    catalogAccessory
  );

  return resp.data;
}

interface UpdateCatalogAccessoryResponse {
  catalogAccessory: CatalogAccessory;
  etag: string;
}

export async function updateCatalogAccessory(
  id: number,
  catalogAccessory: CatalogAccessoryDTO,
  etag: string
): Promise<UpdateCatalogAccessoryResponse> {
  const resp = await axios.put<CatalogAccessory>(
    buildApiUrl(`api/restables/catalog_accessories/${id}`),
    catalogAccessory,
    {
      headers: {
        "If-Match": etag,
      },
    }
  );

  return { catalogAccessory: resp.data, etag: resp.headers.etag };
}

export async function deleteCatalogAccessory(id: number, etag: string) {
  const resp = await axios.delete(
    buildApiUrl(`api/restables/catalog_accessories/${id}`),
    {
      headers: {
        "If-Match": etag,
      },
    }
  );

  return inRange(resp.status, 200, 299);
}

export async function addDeviceAccessory(
  deviceId: number,
  catalogAccessoryId: number
) {
  const resp = await axios.put<DeviceCatalogAccessory[]>(
    buildApiUrl("api/device_accessories"),
    {
      device_id: deviceId,
      catalog_accessory_id: catalogAccessoryId,
    }
  );

  return resp.data[0];
}

export async function fetchCatalogAccessories() {
  const resp = await axios.get<CatalogAccessory[]>(
    buildApiUrl("api/restables/catalog_accessories")
  );

  return resp.data;
}

export async function fetchCatalogAccessoryEtag(id: number): Promise<string> {
  const resp = await axios.get<CatalogAccessory>(
    buildApiUrl(`api/restables/catalog_accessories/${id}`)
  );

  return resp.headers.etag || "";
}

interface CatalogAccessoryResponse {
  catalogAccessory: CatalogAccessory;
  etag: string;
}

export async function fetchCatalogAccessory(
  id: number
): Promise<CatalogAccessoryResponse> {
  const resp = await axios.get<CatalogAccessory>(
    buildApiUrl(`api/restables/catalog_accessories/${id}`)
  );

  return { catalogAccessory: resp.data, etag: resp.headers.etag };
}

export async function connectCatalogAccessory(
  deviceId: number,
  catalogAccessoryId: number
) {
  const resp = await axios.put<DeviceCatalogAccessory>(
    buildApiUrl("api/connect_catalog_accessory"),
    {
      device_id: deviceId,
      catalog_accessory_id: catalogAccessoryId,
    }
  );

  return resp.data;
}

export async function deleteDevice(id: number) {
  const resp = await axios.delete(buildApiUrl(`api/devices/${id}`));

  return resp.status === 200;
}

export async function fetchDeviceAccessories() {
  const resp = await axios.get<DeviceAccessory[]>(
    buildApiUrl("api/device_accessories")
  );

  return resp.data;
}
