import { Portal } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  CatalogAccessory,
  Device,
  DeviceCatalogAccessory,
  connectCatalogAccessory,
  disconnectCatalogAccessory,
} from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useCatalogManager } from "../../CatalogManagerProvider";

interface LinkAccessoryItem extends CatalogAccessory {
  linked: boolean;
}

interface LinkAccessoryDialogProps {
  deviceId: number;
  deviceAccessories: DeviceCatalogAccessory[];
  onClose: () => void;
  availableAccessories: CatalogAccessory[];
  onUpdateDeviceAccesories: (
    updatedDeviceAccessories: DeviceCatalogAccessory[]
  ) => void;
}

export default function LinkAccessoryDialog({
  deviceId,
  deviceAccessories,
  onClose,
  onUpdateDeviceAccesories,
  availableAccessories,
}: LinkAccessoryDialogProps) {
  const { setDevice, deviceItems } = useCatalogManager();

  const initialLinkedAccessories = useMemo(() => {
    return availableAccessories.map((deviceAccessory) => {
      return {
        ...deviceAccessory,
        linked: deviceAccessories.some(
          (accessory) =>
            accessory.catalog_accessory_id ===
            deviceAccessory.catalog_accessory_id
        ),
      };
    });
  }, [availableAccessories, deviceAccessories]);

  const [rows, setRows] = useState<LinkAccessoryItem[]>(
    initialLinkedAccessories
  );
  const [search, setSearch] = useState<string>("");
  const [isLinkingAccessories, setIsLinkingAccessories] =
    useState<boolean>(false);

  // add throttled search handler
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleLinking = useCallback(async () => {
    setIsLinkingAccessories(true);

    const newlyLinkedAccessories = rows
      .filter((row) => row.linked)
      .filter((row) => {
        return !deviceAccessories.some(
          (deviceAccessory) =>
            deviceAccessory.catalog_accessory_id === row.catalog_accessory_id
        );
      });

    const newlyUnlinkedAccessories = deviceAccessories.filter(
      (deviceAccessory) => {
        return rows.some(
          (row) =>
            row.catalog_accessory_id === deviceAccessory.catalog_accessory_id &&
            !row.linked
        );
      }
    );

    let updatedDeviceAccessories: DeviceCatalogAccessory[] = [
      ...deviceAccessories,
    ];

    try {
      for (let i = 0; i < newlyLinkedAccessories.length; i++) {
        const accessory = newlyLinkedAccessories[i];
        const deviceAccessory = await connectCatalogAccessory(
          deviceId,
          accessory.catalog_accessory_id
        );

        updatedDeviceAccessories.push(deviceAccessory);
      }

      for (let i = 0; i < newlyUnlinkedAccessories.length; i++) {
        const accessory = newlyUnlinkedAccessories[i];
        await disconnectCatalogAccessory(accessory);

        updatedDeviceAccessories = updatedDeviceAccessories.filter(
          (deviceAccessory) =>
            deviceAccessory.catalog_accessory_id !==
            accessory.catalog_accessory_id
        );
      }

      const numOfUpdatedAccessories =
        newlyLinkedAccessories.length + newlyUnlinkedAccessories.length;

      const currentDevice = deviceItems.find(
        (device) => device.device_id === deviceId
      );
      if (currentDevice) {
        const updatedDevice = {
          ...currentDevice,
          device_accessories: updatedDeviceAccessories,
        } as Device;
        setDevice(updatedDevice);
      }

      onUpdateDeviceAccesories(updatedDeviceAccessories);

      toast.success(
        `Accessor${
          numOfUpdatedAccessories > 1 ? "ies" : "y"
        } updated successfully`
      );
      onClose();
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error updating accessories");
    } finally {
      setIsLinkingAccessories(false);
    }
  }, [
    deviceAccessories,
    deviceId,
    deviceItems,
    onClose,
    onUpdateDeviceAccesories,
    rows,
    setDevice,
  ]);

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      const lowerCaseSearch = search.toLowerCase();

      return (
        row.accessory_name.toLowerCase().includes(lowerCaseSearch) ||
        row.short_description.toLowerCase().includes(lowerCaseSearch) ||
        row.price?.toString().includes(search) ||
        row.image_url.toLowerCase().includes(lowerCaseSearch)
      );
    });
  }, [rows, search]);

  const columns: GridColDef<LinkAccessoryItem>[] =
    useMemo((): GridColDef<LinkAccessoryItem>[] => {
      return [
        {
          field: "accessory_name",
          headerName: "Name",
          flex: 1,
          editable: false,
          // allow multiple lines
          renderCell: (params) => {
            return (
              <div
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {params.value}
              </div>
            );
          },
        },
        {
          field: "short_description",
          headerName: "Short Desc.",
          flex: 1,
          editable: false,
          // allow multiple lines
          renderCell: (params) => {
            return (
              <div
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {params.value}
              </div>
            );
          },
        },
        {
          field: "price",
          headerName: "Price",
          width: 100,
          type: "number",
          renderCell: (params) => {
            return `${Number(params.value).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`;
          },
          editable: false,
        },
        {
          field: "image_url",
          headerName: "",
          renderCell: (params) => {
            return (
              <img
                style={{
                  maxHeight: "100%",
                }}
                src={params.value}
                alt={params.row.accessory_name}
              />
            );
          },
          align: "right",
          width: 100,
          sortable: false,
          editable: false,
        },
        {
          field: "linked",
          headerName: "Linked",
          width: 100,
          renderCell: (params) => {
            // add a switch to toggle the linked state
            const { row } = params;
            return (
              <Checkbox
                checked={params.value as boolean}
                onChange={(e) => {
                  const updatedRow = { ...row };
                  updatedRow.linked = e.target.checked;
                  setRows((prevRows) => {
                    return prevRows.map((prevRow) => {
                      if (
                        prevRow.catalog_accessory_id ===
                        updatedRow.catalog_accessory_id
                      ) {
                        return updatedRow;
                      }
                      return prevRow;
                    });
                  });
                }}
              />
            );
          },
          editable: false,
        },
      ];
    }, []);

  return (
    <Portal>
      <Dialog open maxWidth="lg" fullWidth onClose={onClose}>
        <DialogTitle>Link Accessory</DialogTitle>
        <DialogContent dividers>
          <div className="mb-2 w-1/3">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearch}
            />
          </div>

          <DataGridPro
            sx={{
              height: "500px",
            }}
            getRowId={(row) => row.catalog_accessory_id}
            rows={filteredRows}
            columns={columns}
            initialState={{
              // sort by name by default
              sorting: {
                sortModel: [
                  {
                    field: "accessory_name",
                    sort: "asc",
                  },
                ],
              },
            }}
            pageSizeOptions={[]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            loading={isLinkingAccessories}
            disabled={isLinkingAccessories}
            onClick={handleLinking}
          >
            Accept
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
