import * as Sentry from "@sentry/react";

import { getEnv, isProd } from "@react-ms-apps/common/utils";

Sentry.init({
  dsn: "https://c6cfa12c5da543ccb8bf56dcb7b1d8de@o4505190049120256.ingest.sentry.io/4505194806509568",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "mobilsense"],
    }),
    // new Sentry.Replay() // disabled for now while we figure out how to use it
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: isProd() ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Debugging
  // debug: isProd() ? false : true,
  environment: getEnv(),
  enabled: isProd(),
  beforeSend(event) {
    // Check if the event has a request property with a URL
    if (event.request?.url) {
      // Check if the URL is from the 'dev' subdomain
      if (/https?:\/\/(dev)\.mobilsense\.com/.test(event.request.url)) {
        // Ignore the event
        return null;
      }
    }
    // Send the event
    return event;
  },
  ignoreErrors: [
    // Ignore AngularJS errors
    /http:\/\/errors\.angularjs\.org\/1\.5\.5\/\$rootScope\/infdig/,
  ],
});
