import { Button } from "@mui/material";
import { useState } from "react";
import { CatalogAccessoryRow } from "../../types";
import LinkedAccessoriesModal from "./LinkedAccessoriesModal";

export default function LinkedDevicesButton({
  catalogAccessoryRow,
}: {
  catalogAccessoryRow: CatalogAccessoryRow;
}) {
  const [open, setOpen] = useState(false);

  const linkedDevices = catalogAccessoryRow.linkedDevices;
  if (linkedDevices.length === 0) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>Linked</Button>

      {open && (
        <LinkedAccessoriesModal
          catalogAccessoryRow={catalogAccessoryRow}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
}
