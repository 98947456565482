import clsx from "clsx";

export default function ChartButton({
  label,
  icon,
  active,
  onClick,
}: {
  label: string;
  icon?: React.ReactNode;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={clsx({
        "cursor-pointer text-white flex flex-row items-center justify-between gap-2 border border-solid border-white rounded-md p-2":
          true,
        "bg-white !text-primary-800": active,
      })}
      onClick={onClick}
    >
      <div>{label}</div>
      {!!icon && icon}
    </div>
  );
}
