import EmailSentIcon from "@mui/icons-material/Send";
import { Avatar, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ROUTES } from "@react-ms-apps/common";
import { Link as RouterLink } from "react-router-dom";

export default function SentPasswordReset() {
  return (
    <Container
      component="main"
      maxWidth="xs"
      className="!flex flex-1 justify-center align-center flex-col -mt-20"
    >
      <Paper
        className="rounded-xl px-8 py-4"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar className="m-1 mb-2" sx={{ bgcolor: "secondary.main" }}>
          <EmailSentIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset email sent
        </Typography>
        <Box className="w-full" sx={{ mt: 1 }}>
          <Typography className="pb-2 pt-2">
            Password reset email sent to your email address. Please check your
            email, including in your spam folder.
          </Typography>
          <Typography className="pb-2 pt-2">
            If you do not receive an email, please contact your administrator.
            If you are an administrator, please contact support.
          </Typography>
          <Divider className="!border-dashed !mb-4 !mt-2" />
          <div className="flex justify-between">
            <Link component={RouterLink} to={ROUTES.LOGIN.HOME}>
              Back to login
            </Link>
            <Link component={RouterLink} to={ROUTES.LOGIN.FORGOT_PASSWORD}>
              Back to forgot password
            </Link>
          </div>
        </Box>
      </Paper>
    </Container>

    // <>
    //   <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-full lg:py-0">
    //     <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
    //       <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
    //         Reset email sent
    //       </h2>
    //       <div className="mt-4 space-y-4 lg:mt-5 md:space-y-5">
    //         <p>
    //           Password reset email sent to your email address. Please check your
    //           email, including in your spam folder.
    //         </p>
    //         <p className="mb-3">
    //           Password reset email sent to your email address. Please check your
    //           email, including in your spam folder.
    //         </p>
    //         <p className="mb-3">
    //           If you do not receive an email, please contact your administrator.
    //           If you are an administrator, please contact support.
    //         </p>
    //         <div className="flex items-center justify-between">
    //           <Link to={ROUTES.LOGIN.HOME} className="text-sm font-medium">
    //             {BACK_TO_LOGIN_TEXT}
    //           </Link>
    //           <Link
    //             to={ROUTES.LOGIN.FORGOT_PASSWORD}
    //             className="text-sm font-medium"
    //           >
    //             Back to forgot password
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}
