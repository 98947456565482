export namespace Loading {
  export enum LoadingStates {
    Initial = "initial",
    Loading = "loading",
    Success = "success",
    Error = "error",
  }

  export type LoadingState = "initial" | "loading" | "success" | "error";
}
