import { buildApiUrl } from "@react-ms-apps/common";
import axios from "axios";
import { Order } from "./types";

interface OrderApprovalEtagResponse {
  data: Order;
  etag: string;
}

export async function getOrderApproval(
  token: string
): Promise<OrderApprovalEtagResponse> {
  const url = buildApiUrl(`/api/order_approval/${token}`);
  const resp = await axios.get<Order>(url);
  const etag = resp.headers["etag"];

  return { data: resp.data, etag };
}

export async function updateOrderApproval({
  token,
  order_status_id,
  etag,
  approver_comments,
}: {
  token: string;
  order_status_id: number;
  etag: string;
  approver_comments: string;
}): Promise<void> {
  const url = buildApiUrl(`/api/order_approval/${token}`);

  const resp = await axios.put(
    url,
    {
      order_status_id,
      approver_comments,
      token,
    },
    {
      headers: {
        "If-Match": etag,
      },
    }
  );

  return resp.data;
}
