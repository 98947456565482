import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BanSummary } from "@react-ms-apps/common/types";

interface UserInfoTableProps {
  banSummary: BanSummary.BanSummary | null;
  carriers: BanSummary.Carrier[];
  isLoading: boolean;
}

export default function UserInfoTable({
  banSummary,
  carriers,
  isLoading,
}: UserInfoTableProps) {
  const renderLoadingRow = () => (
    <TableRow>
      {Array(6)
        .fill(null)
        .map((_, index) => (
          <TableCell key={index}>
            <Skeleton />
          </TableCell>
        ))}
    </TableRow>
  );

  const renderDataRow = () => {
    if (!banSummary) return null;

    const {
      statement_summary: {
        name,
        manager_name,
        carrier_id,
        carrier_account_number,
        carrier_subaccount_number,
        foundation_account,
      },
    } = banSummary;

    const carrierName =
      carriers.find((carrier) => carrier.id === carrier_id)?.name || "";

    return (
      <TableRow>
        <TableCell>{name || "--"}</TableCell>
        <TableCell>{manager_name || "--"}</TableCell>
        <TableCell>{carrierName || "--"}</TableCell>
        <TableCell>{carrier_account_number || "--"}</TableCell>
        <TableCell>{carrier_subaccount_number || "--"}</TableCell>
        <TableCell>{foundation_account || "--"}</TableCell>
      </TableRow>
    );
  };

  return (
    <div className="mx-4 pb-8">
      <div className="w-full">
        <Typography variant="h6">Service Account</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Manager Name</TableCell>
              <TableCell>Carrier</TableCell>
              <TableCell>Carrier Account Number</TableCell>
              <TableCell>Carrier Subaccount Number</TableCell>
              <TableCell>Foundation Account</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? renderLoadingRow() : renderDataRow()}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
