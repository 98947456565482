import "@react-ms-apps/common/config/sentry";
import "@react-ms-apps/common/styles/base.scss";
import { setAppVersion } from "@react-ms-apps/common/utils/app-version";
import Logout, { isLogoutRoute } from "@react-ms-apps/common/utils/logout";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import RootApp from "./App";
import reportWebVitals from "./reportWebVitals";

// get package.json version
import packageJson from "../package.json";
const version = packageJson.version;

(function initApp() {
  if (isLogoutRoute(window.location)) {
    Logout();
    return;
  }

  // add app version to window
  setAppVersion("app", version || "");

  // add favicon to document
  const favicon = document.createElement("link");
  favicon.rel = "icon";
  favicon.href = "/favicon.ico";

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary>
        <RootApp />
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
