import { Checkbox, FormControlLabel } from "@mui/material";
import { ByChartData, ByChartDataMap } from "../constants";
import ChartButton from "./ChartButton";
import { getChartDataKeyLabel } from "./utils";

export default function ChartShell({
  children,
  dataKeyOptions,
  selectedDataKeys,
  selectedByChartData,
  onSelectedDataKeysChange,
  byChartDataOptions,
  onByChartDataChange,
}: {
  children: React.ReactNode;
  dataKeyOptions: string[];
  selectedDataKeys: string[];
  selectedByChartData: ByChartData;
  onSelectedDataKeysChange?: (selectedDataKeys: string[]) => void;
  byChartDataOptions: ByChartData[];
  onByChartDataChange?: (byChartData: ByChartData) => void;
}) {
  const handleByChartDataChange = (byChartData: ByChartData) => {
    onByChartDataChange && onByChartDataChange(byChartData);
  };

  const handleChartDataKeyChange = (key: string) => {
    const updatedSelectedDataKeys = [...selectedDataKeys];
    if (updatedSelectedDataKeys.includes(key)) {
      updatedSelectedDataKeys.splice(updatedSelectedDataKeys.indexOf(key), 1);
    } else {
      updatedSelectedDataKeys.push(key);
    }

    // call the callback with the updated keys
    onSelectedDataKeysChange &&
      onSelectedDataKeysChange(updatedSelectedDataKeys);
  };

  return (
    <div
      className="flex flex-1 flex-row gap-2"
      style={{
        maxHeight: "calc(100vh - 300px)",
      }}
    >
      <div className="flex flex-col bg-primary-800 p-4 gap-8 w-60">
        <div className="flex flex-col gap-2">
          <div className="text-white opacity-80">Chart Data</div>

          {byChartDataOptions.map((byChartDataOption) => (
            <ChartButton
              key={byChartDataOption}
              label={`by ${ByChartDataMap[byChartDataOption]}`}
              active={selectedByChartData === byChartDataOption}
              onClick={() => handleByChartDataChange(byChartDataOption)}
            />
          ))}
        </div>

        {dataKeyOptions.length > 0 && (
          <div className="flex flex-col gap-2 overflow-y-auto">
            <div className="text-white opacity-80">Data Keys</div>
            <div className="flex flex-col overflow-y-auto">
              {dataKeyOptions.sort().map((chartDataKey) => (
                <FormControlLabel
                  key={chartDataKey}
                  control={
                    <Checkbox
                      sx={{
                        // update checkbox color to be white with a blue border and checkmark
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                        "&.MuiCheckbox-root": {
                          "&.Mui-checked": {
                            color: "white",
                          },
                        },
                      }}
                      checked={selectedDataKeys.includes(chartDataKey)}
                    />
                  }
                  label={getChartDataKeyLabel(chartDataKey)}
                  sx={{ color: "white" }}
                  onChange={() => {
                    handleChartDataKeyChange(chartDataKey);
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div id="chart-container" className="flex flex-1">
        {children}
      </div>
    </div>
  );
}
