import { buildApiUrl } from "@react-ms-apps/common";
import axios from "axios";

export interface UploadProcessorConfig {
  created_at: string;
  description: string;
  title: string;
  fields: UploadProcessorConfigField[];
  utility_id: number;
  allow_blanks: boolean;
  updated_at: string;
  service_endpoint: string;
  sentinel: string;
  id: number;
}

export interface UploadProcessorConfigField {
  required: boolean;
  name: string;
  description?: string;
  is_dynamic?: boolean;
}

export const fetchUploadProcessorConfigurations = async () => {
  const resp = await axios.get<UploadProcessorConfig[]>(
    buildApiUrl("/api/restables/upload_processor_configurations")
  );
  return resp.data;
};
