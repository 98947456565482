import { Box, CircularProgress, Typography } from "@mui/material";
import { BarChart, BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getSummaryTotalForMonth } from "../api";
import ChartShell from "../ChartShell";
import {
  ByChartData,
  ChartDatasets,
  chartMargins,
  ChartType,
} from "../constants";
import { captureChart } from "../DataAndUsageCostsChart/utils";
import { ChartEvents } from "../events";
import { SummaryTotalItem } from "../types";
import { currencyFormatter } from "../utils";

interface CostByCostCenterDataItem {
  month: string;
  [costCenter: string]: string | number;
}

const cache: { [month: string]: SummaryTotalItem[] } = {};

export default function CostByCostCenterBarChart({
  startDate,
  endDate,
  chartType,
}: {
  startDate: string;
  endDate: string;
  chartType: ChartType;
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showIndefiniteProgress, setShowIndefiniteProgress] = useState(false);
  const [summaryTotalMonthMap, setSummaryTotalMonthMap] = useState<{
    [month: string]: SummaryTotalItem[];
  }>(cache);
  const [chartDataKeys, setChartDataKeys] = useState<{
    [key: string]: boolean;
  }>({});

  const selectedDataKeys = Object.keys(chartDataKeys).filter(
    (key) => chartDataKeys[key]
  );

  const handleGetCostCenterData = useCallback(async () => {
    if (loadingData) return;

    setLoadingData(true);
    setProgress(0);
    setShowIndefiniteProgress(false);

    const startTime = Date.now();
    const duration = 60000; // 1 minute in milliseconds

    // ignore the prefer-const rule for this line because we need to reassign the variable
    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timeout;

    const updateProgress = () => {
      const elapsed = Date.now() - startTime;
      if (elapsed >= duration) {
        setShowIndefiniteProgress(true);
        clearInterval(intervalId);
      } else {
        const newProgress = Math.min((elapsed / duration) * 100, 100);
        setProgress(newProgress);
      }
    };

    intervalId = setInterval(updateProgress, 1000);

    try {
      const monthsMap: { [month: string]: SummaryTotalItem[] } = {};
      const statementMonths: string[] = [];
      const currentMonth = moment(startDate);
      const endMoment = moment(endDate);

      while (currentMonth.isSameOrBefore(endMoment)) {
        const statementMonth = currentMonth.format("YYYY-MM-01");

        if (cache[statementMonth] && cache[statementMonth].length) {
          monthsMap[statementMonth] = cache[statementMonth];
        } else {
          statementMonths.push(statementMonth);
        }

        currentMonth.add(1, "month");
      }

      const fetchData = async (months: string[]) => {
        const results = await Promise.all(
          months.map((month) =>
            getSummaryTotalForMonth(month).then((statementMonthData) => ({
              month,
              data: statementMonthData,
            }))
          )
        );

        results.forEach(({ month, data }) => {
          monthsMap[month] = data;
          cache[month] = data;
        });
      };

      // We limit to 6 concurrent calls at a time because the server has not been optimized
      // for this data set. This approach allows us to fetch the data in smaller chunks,
      // reducing the load on the server and improving overall performance.
      const chunkSize = 6;
      for (let i = 0; i < statementMonths.length; i += chunkSize) {
        const chunk = statementMonths.slice(i, i + chunkSize);
        await fetchData(chunk);
      }

      // After fetching all the data, we compile the dataset on the client side
      // This is necessary because the server is not optimized to handle the entire dataset at once
      setSummaryTotalMonthMap({ ...monthsMap });

      // Set progress to 100% and keep it there
      setProgress(100);
      clearInterval(intervalId);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      // Ensure the interval is cleared
      clearInterval(intervalId);
      setLoadingData(false);
      setShowIndefiniteProgress(false);
    }
  }, [startDate, endDate, loadingData]);

  const formattedData: CostByCostCenterDataItem[] = useMemo(() => {
    const monthlyCostCenterData: {
      [month: string]: { [costCenter: string]: number };
    } = {};

    for (const month in summaryTotalMonthMap) {
      if (!monthlyCostCenterData[month]) {
        monthlyCostCenterData[month] = {};
      }

      const monthData = summaryTotalMonthMap[month];

      for (const costCenterItem of monthData) {
        const costCenter = costCenterItem.company_cost_ctr;

        if (!costCenter) continue;

        if (!monthlyCostCenterData[month][costCenter]) {
          monthlyCostCenterData[month][costCenter] = 0;
        }

        monthlyCostCenterData[month][costCenter] +=
          costCenterItem.total_charges;
      }
    }

    return Object.entries(monthlyCostCenterData).map(
      ([month, costCenterData]) => ({
        month: moment(month, "YYYY-MM-DD").format("MMM YY"),
        ...costCenterData,
      })
    );
  }, [summaryTotalMonthMap]);

  const filteredData = useMemo(() => {
    return formattedData.map((item) => ({
      month: item.month,
      ...Object.fromEntries(selectedDataKeys.map((key) => [key, item[key]])),
    }));
  }, [formattedData, selectedDataKeys]);

  const handleSelectedDataKeysChange = (selectedDataKeys: string[]) => {
    setChartDataKeys(
      Object.fromEntries(
        Object.keys(chartDataKeys).map((key) => [
          key,
          selectedDataKeys.includes(key),
        ])
      )
    );
  };

  useEffect(() => {
    if (!startDate || !endDate) return;
    handleGetCostCenterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    const dataKeys = new Set<string>();
    for (const item of formattedData) {
      for (const key in item) {
        if (key !== "month") {
          dataKeys.add(key);
        }
      }
    }

    setChartDataKeys(
      Object.fromEntries(Array.from(dataKeys).map((key) => [key, true]))
    );
  }, [formattedData]);

  const chartSeries: MakeOptional<BarSeriesType, "type">[] =
    selectedDataKeys.map((key) => ({
      dataKey: key,
      label: key,
      stack: chartType === ChartType.BAR ? "company" : undefined,
      valueFormatter: (value) => currencyFormatter(value),
    }));

  const handleExportData = useCallback(() => {
    if (filteredData.length === 0) return;

    const keys = Object.keys(filteredData[0]).filter((key) => key !== "month");
    const header = ["month", ...keys].join(",");
    const rows = filteredData.map((item: { [key: string]: string | number }) =>
      [
        item.month,
        ...keys.map((key) => currencyFormatter(item[key] || 0, false)),
      ].join(",")
    );

    const csvContent = [header, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `cost-by-cost-center_${chartType}_${startDate}_${endDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [chartType, startDate, endDate, filteredData]);

  const handleCaptureChart = useCallback(() => {
    captureChart({
      dataSet: ChartDatasets.CostByCostCenter,
      chartType,
      startDate,
      endDate,
    });
  }, [chartType, startDate, endDate]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportImage, handleCaptureChart);
    return () => {
      window.removeEventListener(ChartEvents.ExportImage, handleCaptureChart);
    };
  }, [handleCaptureChart]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);
    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
    };
  }, [handleExportData]);

  return (
    <ChartShell
      byChartDataOptions={[ByChartData.COST_BY_COST_CENTER]}
      selectedByChartData={ByChartData.COST_BY_COST_CENTER}
      dataKeyOptions={Object.keys(chartDataKeys)}
      selectedDataKeys={selectedDataKeys}
      onSelectedDataKeysChange={handleSelectedDataKeysChange}
    >
      {loadingData ? (
        <div className="flex flex-1 items-center justify-center">
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              size={80}
              value={showIndefiniteProgress ? undefined : progress}
              variant={showIndefiniteProgress ? "indeterminate" : "determinate"}
            />
            {!showIndefiniteProgress && (
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: "text.secondary" }}
                >{`${Math.round(progress)}%`}</Typography>
              </Box>
            )}
          </Box>
        </div>
      ) : (
        <BarChart
          dataset={filteredData}
          series={chartSeries}
          xAxis={[{ scaleType: "band", dataKey: "month" }]}
          yAxis={[
            {
              scaleType: "linear",
              valueFormatter: (value) => currencyFormatter(value),
            },
          ]}
          margin={chartMargins}
        />
      )}
    </ChartShell>
  );
}
