import { LoadingButton } from "@mui/lab";
import { Button, TableCell, TableRow } from "@mui/material";
import { ROUTES } from "@react-ms-apps/common";
import { DeviceListItem } from "@react-ms-apps/common/api/catalog-manager";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCatalogManager } from "../../../CatalogManagerProvider";

interface LinkedDeviceRowProps {
  linkedDevice: DeviceListItem;
  catalogAccessoryId: number;
}

export default function LinkedDeviceRow({
  linkedDevice: { device_id, manufacturer, model, short_description },
  catalogAccessoryId,
}: LinkedDeviceRowProps) {
  const { unlinkDeviceAccessory } = useCatalogManager();
  const navigate = useNavigate();

  const [unlinking, setUnlinking] = useState(false);

  const handleEditDevice = useCallback(() => {
    // go to edit device page w/ catalog device id and query param
    navigate(`${ROUTES.UTILITY.CATALOG_MANAGER.EDIT_DEVICE}/${device_id}`);
  }, [navigate, device_id]);

  const handleUnlinkDevice = async () => {
    // unlink device
    try {
      setUnlinking(true);

      await unlinkDeviceAccessory(catalogAccessoryId, device_id);
      toast.success("Device unlinked");
    } catch (error) {
      toast.error("Failed to unlink device");
    } finally {
      setUnlinking(false);
    }
  };

  return (
    <TableRow>
      <TableCell>{device_id}</TableCell>
      <TableCell>{manufacturer}</TableCell>
      <TableCell>{model}</TableCell>
      <TableCell>{short_description}</TableCell>
      <TableCell>
        <LoadingButton
          loading={unlinking}
          disabled={unlinking}
          variant="outlined"
          color="error"
          onClick={handleUnlinkDevice}
        >
          Unlink
        </LoadingButton>
      </TableCell>
      <TableCell>
        <Button
          disabled={unlinking}
          variant="outlined"
          color="primary"
          onClick={handleEditDevice}
        >
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}
