import { Portal } from "@mui/base";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ROUTES } from "@react-ms-apps/common";
import { useNavigate } from "react-router-dom";

export default function MissingDeviceModal() {
  const navigate = useNavigate();

  return (
    <Portal>
      <Dialog open>
        <DialogTitle>Device Missing</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>The device could not be found.</DialogContentText>
          <DialogContentText>
            You can go back to the Catalog Manager or create a new device.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              navigate(
                `${ROUTES.UTILITY.ROOT}${ROUTES.UTILITY.CATALOG_MANAGER.ROOT}`
              )
            }
          >
            Go back
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                `${ROUTES.UTILITY.ROOT}${ROUTES.UTILITY.CATALOG_MANAGER.ROOT}/${ROUTES.UTILITY.CATALOG_MANAGER.NEW_DEVICE}`
              )
            }
          >
            Create new device
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
