import { Divider } from "@mui/material";
import { PageContainer } from "@react-ms-apps/common";

export default function NoOrderPage() {
  return (
    <PageContainer
      header="Order Approval"
      utilityName="Order Approval"
      headerId="order-approval"
    >
      <Divider className="!border-dashed" variant="fullWidth" sx={{ mb: 1 }} />

      <div className="text-base">
        <h2 className="text-xl">Order not found</h2>
        <p>
          If you believe you're seeing this message in error, please contact
          support.
        </p>
      </div>
    </PageContainer>
  );
}
