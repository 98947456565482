import {
  fetchBrandingBodyTitle,
  fetchBrandingBodyTitleIsSet,
  fetchVendorLogo,
} from "@react-ms-apps/common/api";
import Navbar from "@react-ms-apps/common/components/NavBar";
import { useAuth, useOrg } from "@react-ms-apps/common/providers";
import Logout from "@react-ms-apps/common/utils/logout";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import {
  DemoLogo,
  DemoLogoText,
  HeaderContainer,
  HeaderLogo,
  LogoutText,
  SectionTitle,
  UserInfo,
} from "./Header.styled";

interface HeaderProps {
  showNavigation?: boolean;
}

export const Header = ({ showNavigation = true }: HeaderProps) => {
  const { user, getUser, isAuthenticated } = useAuth();

  const [brandingBodyTitleLoaded, setBrandingBodyTitleLoaded] = useState(false);
  const [brandingBodyTitle, setBrandingBodyTitle] = useState<null | string>(
    null
  );
  const [brandingBodyTitleIsSet, setBrandingBodyTitleIsSet] = useState<
    boolean | null
  >(null);
  const [brandingBodyTitleChecked, setBrandingBodyTitleChecked] =
    useState<boolean>(false);
  const [brandingBodyTitleHasBadValue, setBrandingBodyTitleHasBadValue] =
    useState(false);

  const [vendorLogo, setVendorLogo] = useState<null | string>(null);
  const [vendorLogoChecked, setVendorLogoChecked] = useState(false);
  const [vendorHasBadValue, setVendorHasBadValue] = useState(false);

  const { getOrg, org: orgInfo } = useOrg();

  const getBrandingTitle = async () => {
    try {
      const brandingBodyTitle = await fetchBrandingBodyTitle();

      if (brandingBodyTitle === "BAD_VALUE") {
        setBrandingBodyTitleHasBadValue(true);
      }

      setBrandingBodyTitle(brandingBodyTitle);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setBrandingBodyTitleLoaded(true);
    }
  };

  const getBrandingTitleIsSet = async () => {
    try {
      const brandingBodyTitleIsSet = await fetchBrandingBodyTitleIsSet();
      setBrandingBodyTitleIsSet(brandingBodyTitleIsSet);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setBrandingBodyTitleChecked(true);
    }
  };

  const getVendorLogo = async () => {
    try {
      const vendorLogo = await fetchVendorLogo();
      setVendorLogo(vendorLogo);

      if (vendorLogo === "BAD_VALUE") {
        setVendorHasBadValue(true);
      }

      setVendorLogoChecked(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    getOrg();
    getUser();
    getBrandingTitle();
    getBrandingTitleIsSet();
    getVendorLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserName = () => {
    if (!user) return "";

    return `${user.first_name} ${user.last_name}`;
  };

  const renderVendorLogo = () => {
    if (!vendorLogoChecked || vendorHasBadValue) return null;

    if (vendorLogo) {
      return <HeaderLogo data-testid="header-logo" src={vendorLogo} />;
    }

    return (
      <HeaderLogo
        data-testid="header-logo"
        src="/static/app/img/MS15-MobilSense-Logo.gif"
      />
    );
  };

  const renderBrandingBodyTitle = () => {
    if (!brandingBodyTitleLoaded || !brandingBodyTitleChecked) return null;
    if (brandingBodyTitle === null || brandingBodyTitleIsSet === null)
      return null;
    if (brandingBodyTitleHasBadValue) return null;

    if (brandingBodyTitleIsSet && brandingBodyTitle) {
      return (
        <SectionTitle data-testid="header-branding-body-title">
          {brandingBodyTitle}
        </SectionTitle>
      );
    }

    return (
      <SectionTitle data-testid="header-branding-body-title">
        <img
          src="/static/app/img/StandardTitle.gif"
          alt="Mobile Expense Management"
        />
      </SectionTitle>
    );
  };

  const showNavigationElements = showNavigation && isAuthenticated;

  return (
    <>
      <HeaderContainer className="header">
        <UserInfo>
          {isAuthenticated && (
            <>
              <span>{getUserName()}</span>
              <LogoutText className="logout" onClick={Logout}>
                Logout
              </LogoutText>
            </>
          )}
        </UserInfo>

        {orgInfo?.logo_url ? (
          <DemoLogo src={orgInfo.logo_url} alt={orgInfo.name} />
        ) : (
          <DemoLogoText>{orgInfo?.name || ""}</DemoLogoText>
        )}

        {renderVendorLogo()}
        {renderBrandingBodyTitle()}
      </HeaderContainer>

      <div className="flex px-5 mt-1 z-50">
        {showNavigationElements && <Navbar />}
      </div>
    </>
  );
};

export default Header;
