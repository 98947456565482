import { Add, AddLink, Image } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Portal,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  CatalogAccessory,
  Device,
  DeviceCatalogAccessory,
  disconnectCatalogAccessory,
} from "@react-ms-apps/common/api/catalog-manager";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useImagesContext } from "../../../../Providers/ImagesProvider";
import DeviceAccessoryDialog from "../../AccessoryDialog";
import { useCatalogManager } from "../../CatalogManagerProvider";
import TabPanel from "../../CustomTabPanel";
import ImagesDialog from "../ImagesDialog";
import { EditDeviceTabEnum } from "../constants";
import LinkAccessoryDialog from "./LinkAccessoryDialog";

interface AccessoriesTabProps {
  activeTab: number;
  updatedDevice: Device;
  onUpdateDevice: (device: Device) => void;
}

export default function AccessoriesTab({
  activeTab,
  updatedDevice,
  onUpdateDevice,
}: AccessoriesTabProps) {
  const { accessoryImages: availableImages, fetchAccessoryImages } =
    useImagesContext();

  const {
    getAccessoryTypes,
    accessoryTypes,
    getCatalogAccessories,
    catalogAccessories: availableAccessories,
  } = useCatalogManager();

  const [deviceAccessories, setDeviceAccessories] = useState<
    DeviceCatalogAccessory[]
  >(updatedDevice.device_accessories || []);

  const [selectedCatalogAccessoryId, setSelectedCatalogAccessoryId] = useState<
    number | null
  >(null);
  const [
    selectedCatalogAccessoryForDeletion,
    setSelectedCatalogAccessoryForDeletion,
  ] = useState<number | null>(null);
  const [disconnectingCatalogAccessory, setDisconnectingCatalogAccessory] =
    useState<boolean>(false);
  const [showImageDialog, setShowImageDialog] = useState<boolean>(false);
  const [showNewDeviceAccessoryDialog, setShowNewDeviceAccessoryDialog] =
    useState<boolean>(false);
  const [showLinkAccessoryDialog, setShowLinkAccessoryDialog] =
    useState<boolean>(false);

  const [addNewMenuAnchorEl, setAddNewMenuAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const addNewMenuOpen = Boolean(addNewMenuAnchorEl);

  const selectedCatalogAccessory = useMemo(() => {
    if (!selectedCatalogAccessoryId || deviceAccessories.length === 0) {
      return null;
    }

    return deviceAccessories.find(
      (da) => da.catalog_accessory_id === selectedCatalogAccessoryId
    );
  }, [deviceAccessories, selectedCatalogAccessoryId]);

  const autoSelectCatalogAcessory = useCallback(() => {
    if (!deviceAccessories || deviceAccessories.length === 0) {
      return;
    }

    setSelectedCatalogAccessoryId(deviceAccessories[0].catalog_accessory_id);
  }, [deviceAccessories]);

  const handleFieldChange = useCallback(
    (field: keyof CatalogAccessory, value: string | number | boolean) => {
      if (!selectedCatalogAccessory) {
        return;
      }

      const updatedCatalogAccessory: DeviceCatalogAccessory = {
        ...selectedCatalogAccessory,
        catalog_accessory: {
          ...selectedCatalogAccessory.catalog_accessory,
          [field]: value,
        },
      };

      const updatedCatalogDevices = deviceAccessories.map((da) =>
        da.catalog_accessory_id === selectedCatalogAccessoryId
          ? updatedCatalogAccessory
          : da
      );

      onUpdateDevice({
        ...updatedDevice,
        device_accessories: updatedCatalogDevices,
      });
    },
    [
      deviceAccessories,
      onUpdateDevice,
      selectedCatalogAccessory,
      selectedCatalogAccessoryId,
      updatedDevice,
    ]
  );

  const handleRemoveCatalogAccessory = async () => {
    if (!selectedCatalogAccessoryForDeletion || !selectedCatalogAccessory) {
      return;
    }

    setDisconnectingCatalogAccessory(true);

    const updatedDeviceData: Device = { ...updatedDevice };

    try {
      await disconnectCatalogAccessory(selectedCatalogAccessory);

      updatedDeviceData.device_accessories =
        updatedDeviceData.device_accessories.filter(
          (da) => da.catalog_accessory_id !== selectedCatalogAccessoryId
        );

      setSelectedCatalogAccessoryForDeletion(null);
      setDeviceAccessories((prev) =>
        prev.filter(
          (da) => da.catalog_accessory_id !== selectedCatalogAccessoryId
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error disconnecting accessory");
    } finally {
      setDisconnectingCatalogAccessory(false);
    }
  };

  const handleSelectImage = useCallback(
    async (url: string) => {
      handleFieldChange("image_url", url);
      setShowImageDialog(false);
    },
    [handleFieldChange]
  );

  const getAccessoriesImages = useCallback(async () => {
    try {
      await fetchAccessoryImages();
    } catch (error) {
      toast.error("Error fetching images");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateDeviceCatalogAccessory = useCallback(
    (deviceCatalogAccessory: DeviceCatalogAccessory) => {
      setDeviceAccessories([...deviceAccessories, deviceCatalogAccessory]);
    },
    [deviceAccessories]
  );

  const handleUpdateDeviceAccessories = useCallback(
    (deviceAccessories: DeviceCatalogAccessory[]) => {
      setDeviceAccessories(deviceAccessories);
    },
    []
  );

  // auto select catalog accessory on mount
  useEffect(() => {
    autoSelectCatalogAcessory();
  }, [autoSelectCatalogAcessory]);

  // fetch data on mount
  useEffect(() => {
    getAccessoryTypes();
    getAccessoriesImages();
    getCatalogAccessories();
  }, [getAccessoriesImages, getAccessoryTypes, getCatalogAccessories]);

  if (!updatedDevice?.device_id) {
    return (
      <TabPanel
        value={activeTab}
        index={EditDeviceTabEnum.ACCESSORIES}
        className="flex flex-1 flex-col gap-4"
      >
        <Alert severity="warning">Device must be saved first.</Alert>
      </TabPanel>
    );
  }

  return (
    <>
      <TabPanel
        value={activeTab}
        index={EditDeviceTabEnum.ACCESSORIES}
        className="flex flex-1 flex-col gap-4"
      >
        <div className="flex flex-row gap-2">
          {deviceAccessories.map((da) => (
            <Paper
              color="primary"
              key={da.catalog_accessory_id}
              component={Button}
              onClick={() =>
                setSelectedCatalogAccessoryId(da.catalog_accessory_id)
              }
              className={classNames(
                "cursor-pointer !w-[100px] !h-[100px] flex items-center justify-center !border !border-solid",
                selectedCatalogAccessory?.catalog_accessory_id !==
                  da.catalog_accessory_id && "!border-transparent",
                selectedCatalogAccessory?.catalog_accessory_id ===
                  da.catalog_accessory_id &&
                  "!border-primary-500 !text-primary-700"
              )}
            >
              <img
                src={da.catalog_accessory.image_url}
                alt={da.catalog_accessory.accessory_name}
                className="max-h-[95px] max-w-[95px]"
              />
            </Paper>
          ))}

          <Paper
            component={Button}
            className="cursor-pointer flex items-center justify-center"
            onClick={(e) => setAddNewMenuAnchorEl(e.currentTarget)}
            aria-controls={addNewMenuOpen ? "add-accessory-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={addNewMenuOpen ? "true" : undefined}
          >
            <Add className="mr-2" />
            Add Accessory
          </Paper>

          <Menu
            anchorEl={addNewMenuAnchorEl}
            id="add-accessory-menu"
            open={addNewMenuOpen}
            onClose={() => setAddNewMenuAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setShowNewDeviceAccessoryDialog(true);
                setAddNewMenuAnchorEl(null);
              }}
            >
              <Add className="mr-2" />
              Create New Accessory
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowLinkAccessoryDialog(true);
                setAddNewMenuAnchorEl(null);
              }}
            >
              <AddLink className="mr-2" />
              Link Existing Accessory
            </MenuItem>
          </Menu>
        </div>

        {selectedCatalogAccessory && (
          <Card square={false} className="!rounded-md">
            <CardContent className="flex flex-col gap-4">
              <TextField
                fullWidth
                id="accessory-name"
                label="Accessory Name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  selectedCatalogAccessory.catalog_accessory.accessory_name ||
                  ""
                }
                onChange={(e) =>
                  handleFieldChange("accessory_name", e.target.value)
                }
              />

              <FormControl fullWidth>
                <InputLabel id="accessory-type-label">
                  Accessory Type
                </InputLabel>

                <Select
                  label="Accessory Type"
                  labelId="accessory-type-label"
                  id="accessory-type"
                  value={
                    selectedCatalogAccessory.catalog_accessory.accessory_type_id
                  }
                  onChange={(e) => {
                    handleFieldChange("accessory_type_id", e.target.value);
                  }}
                >
                  {accessoryTypes.map((at) => (
                    <MenuItem
                      key={at.accessory_type_id}
                      value={at.accessory_type_id}
                    >
                      {at.accessory_type_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="flex flex-col items-start gap-2">
                {selectedCatalogAccessory.catalog_accessory.image_url ? (
                  <img
                    onClick={() => setShowImageDialog(true)}
                    src={selectedCatalogAccessory.catalog_accessory.image_url}
                    alt={
                      selectedCatalogAccessory.catalog_accessory.accessory_name
                    }
                    className="w-32 h-32 object-contain rounded cursor-pointer"
                  />
                ) : (
                  <div
                    onClick={() => setShowImageDialog(true)}
                    className="w-32 h-32 bg-gray-300 flex items-center justify-center rounded cursor-pointer"
                  >
                    <Image className="!w-28 !h-28" color="action" />
                  </div>
                )}

                <Button
                  onClick={() => setShowImageDialog(true)}
                  variant="outlined"
                >
                  Select Image
                </Button>
              </div>

              <TextField
                fullWidth
                multiline
                id="accessory-short-description"
                label="Short Description"
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  selectedCatalogAccessory.catalog_accessory
                    .short_description || ""
                }
                onChange={(e) =>
                  handleFieldChange("short_description", e.target.value)
                }
              />

              <TextField
                fullWidth
                multiline
                id="accessory-long-description"
                label="Long Description"
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  selectedCatalogAccessory.catalog_accessory.long_description ||
                  ""
                }
                onChange={(e) =>
                  handleFieldChange("long_description", e.target.value)
                }
              />

              <TextField
                fullWidth
                multiline
                id="accessory-guidelines"
                label="Guidelines"
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  selectedCatalogAccessory.catalog_accessory.guideline || ""
                }
                onChange={(e) => handleFieldChange("guideline", e.target.value)}
              />

              <TextField
                fullWidth
                type="number"
                id="accessory-price"
                label="Price"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 0.01,
                }}
                value={selectedCatalogAccessory.catalog_accessory.price || ""}
                onChange={(e) =>
                  handleFieldChange("price", Number(e.target.value))
                }
              />

              <TextField
                fullWidth
                type="number"
                id="accessory-pro-price"
                label="Pro. Price"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 0.01,
                }}
                value={
                  selectedCatalogAccessory.catalog_accessory.pro_price || ""
                }
                onChange={(e) =>
                  handleFieldChange("pro_price", Number(e.target.value))
                }
              />

              <TextField
                fullWidth
                id="accessory-part-number"
                label="Part Number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  selectedCatalogAccessory.catalog_accessory.part_number || ""
                }
                onChange={(e) =>
                  handleFieldChange("part_number", e.target.value)
                }
              />

              <TextField
                fullWidth
                id="accessory-relative-rank"
                label="Relative Rank"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  selectedCatalogAccessory.catalog_accessory.relative_ranking ||
                  ""
                }
                onChange={(e) =>
                  handleFieldChange("relative_ranking", Number(e.target.value))
                }
              />
            </CardContent>

            <CardActions className="flex flex-row justify-between">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        selectedCatalogAccessory.catalog_accessory.offered
                      }
                    />
                  }
                  label="Offered"
                  onChange={(e, checked) =>
                    handleFieldChange("offered", checked)
                  }
                />
              </FormGroup>

              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  setSelectedCatalogAccessoryForDeletion(
                    selectedCatalogAccessoryId
                  )
                }
              >
                Unlink Accessory
              </Button>
            </CardActions>
          </Card>
        )}
      </TabPanel>

      {selectedCatalogAccessoryForDeletion && (
        <Portal>
          <Dialog open>
            <DialogTitle>Disconnect Accessory</DialogTitle>

            <DialogContent>
              Are you sure you want to disconnect this accessory from this
              device?
            </DialogContent>

            <DialogActions>
              <LoadingButton
                loading={disconnectingCatalogAccessory}
                disabled={disconnectingCatalogAccessory}
                variant="outlined"
                onClick={() => setSelectedCatalogAccessoryForDeletion(null)}
              >
                Cancel
              </LoadingButton>

              <Button
                variant="contained"
                color="error"
                onClick={handleRemoveCatalogAccessory}
              >
                Disconnect
              </Button>
            </DialogActions>
          </Dialog>
        </Portal>
      )}

      {showImageDialog && (
        <ImagesDialog
          onClose={() => setShowImageDialog(false)}
          availableImages={availableImages}
          onSelect={handleSelectImage}
        />
      )}

      {showNewDeviceAccessoryDialog && (
        <DeviceAccessoryDialog
          onClose={() => setShowNewDeviceAccessoryDialog(false)}
          deviceId={updatedDevice.device_id}
          onCreateDeviceCatalogAccessory={handleCreateDeviceCatalogAccessory}
        />
      )}

      {showLinkAccessoryDialog && (
        <LinkAccessoryDialog
          deviceId={updatedDevice.device_id}
          onClose={() => setShowLinkAccessoryDialog(false)}
          availableAccessories={availableAccessories}
          onUpdateDeviceAccesories={handleUpdateDeviceAccessories}
          deviceAccessories={deviceAccessories}
        />
      )}
    </>
  );
}
