import { Portal } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCatalogManager } from "../../CatalogManagerProvider";

interface DeleteAccessoryModalProps {
  accessoryId: number;
  onClose: () => void;
}

export default function DeleteAccessoryModal({
  accessoryId,
  onClose,
}: DeleteAccessoryModalProps) {
  const { getCatalogAccessoryById, deleteCatalogAccessory } =
    useCatalogManager();

  const catalogAccessory = getCatalogAccessoryById(accessoryId);

  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    try {
      setDeleting(true);
      await deleteCatalogAccessory(accessoryId);
      toast.success("Accessory deleted");
      onClose();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setDeleting(false);
    }
  };

  if (!catalogAccessory) {
    return null;
  }

  return (
    <Portal>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Delete Accessory</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Are you sure you want to delete{" "}
            <strong>{catalogAccessory.accessory_name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
          <LoadingButton
            variant="outlined"
            color="error"
            loading={deleting}
            onClick={onDelete}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
