import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useRowEtagContext } from "@react-ms-apps/app/src/Providers/RowEtagContext";
import { UtilityLinkDTO } from "@react-ms-apps/common";
import { updateUtilityByID } from "@react-ms-apps/common/api/utilities";
import { AuthorizedRoles } from "@react-ms-apps/common/types";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ETAG_ERROR_MESSAGE } from "../constants";

interface AccessRestrictionsProps extends UtilityLinkDTO {}

const authorizedRolesValuesMap: {
  [key: string]: string;
} = {
  "Sys Admin": AuthorizedRoles.MS_sysadmin,
  "Telco Admin": AuthorizedRoles.MS_telcoadmin,
};

const authorizedRolesFriendlyNamesMap: {
  [key: string]: string;
} = {
  [AuthorizedRoles.MS_sysadmin]: "Sys Admin",
  [AuthorizedRoles.MS_telcoadmin]: "Telco Admin",
};

const authorizedRolesFriendlyNames = Object.values(
  authorizedRolesFriendlyNamesMap
);

export default function AccessRestrictions({
  utility_id,
  authorized_roles,
  utility_name,
}: AccessRestrictionsProps) {
  const parsedAuthorizedRoles = authorized_roles
    .split(",")
    .map((role) => role.trim());

  const [accessRestrictions, setAccessRestrictions] = useState<string[]>(
    parsedAuthorizedRoles
  );

  const [isSaving, setIsSaving] = useState(false);

  const { etag, setEtag } = useRowEtagContext();

  const handleChange = async (role: string, checked: boolean) => {
    setIsSaving(true);

    try {
      let updatedAccessRestrictions: string[] = [];
      if (checked) {
        updatedAccessRestrictions = [...accessRestrictions, role];
      } else {
        updatedAccessRestrictions = accessRestrictions.filter(
          (accessRole) => accessRole !== role
        );
      }
      // add MS_backoffice no matter what
      if (!updatedAccessRestrictions.includes(AuthorizedRoles.MS_backoffice)) {
        updatedAccessRestrictions.push(AuthorizedRoles.MS_backoffice);
      }

      const stringifiedAccessRestrictions = updatedAccessRestrictions.join(",");
      // update utility access restrictions
      const { etag: updatedEtag } = await updateUtilityByID(
        utility_id,
        "authorized_roles",
        stringifiedAccessRestrictions,
        etag
      );
      setEtag(updatedEtag);
      setAccessRestrictions(updatedAccessRestrictions);

      const roleFriendlyName = authorizedRolesFriendlyNamesMap[role];
      if (checked) {
        toast.success(`${roleFriendlyName} added to ${utility_name}`, {
          autoClose: 1000,
        });
      } else {
        toast.info(`${roleFriendlyName} removed from ${utility_name}`, {
          autoClose: 1000,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 412) {
        toast.error(ETAG_ERROR_MESSAGE);
        return;
      }

      Sentry.captureException(error);
      toast.error(`Error updating ${utility_name}`);
    } finally {
      setIsSaving(false);
    }
  };

  if (utility_name === "Utilities Management") {
    return null;
  }

  return (
    <FormGroup className="whitespace-nowrap !flex flex-1 !flex-row">
      {authorizedRolesFriendlyNames.map((role) => (
        <FormControlLabel
          key={role}
          control={
            <Checkbox
              checked={accessRestrictions.includes(
                authorizedRolesValuesMap[role]
              )}
              onChange={(__, checked) =>
                handleChange(authorizedRolesValuesMap[role], checked)
              }
              name={role}
              disabled={isSaving}
            />
          }
          label={role}
        />
      ))}
    </FormGroup>
  );
}
