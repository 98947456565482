import axios from "axios";

import { getClientDB } from "@react-ms-apps/common/utils";

export interface UtilityCategoryDTO {
  priority: number;
  utility_category: string;
  utility_category_id: number;
  icon: string;
}

type UtilityCategoriesResponse = UtilityCategoryDTO[];

export interface UtilityLinkDTO {
  utility_id: number;
  authorized_roles: string;
  utility_name: string;
  utility_link: string;
  utility_category_id: number;
  active: boolean;
  is_zope_link: boolean;
  custom_utility_name?: any;
  updated_at: string;
}

type UtilityLinksResponse = UtilityLinkDTO[];

export interface UtilityCategory extends UtilityCategoryDTO {
  links: UtilityLinkDTO[];
}

export type UtilityCategories = UtilityCategory[];

export async function fetchUtilitiesLinks() {
  const linksResponse = await axios.get<UtilityLinksResponse>(
    `/${getClientDB()}/api/restables/utilities`
  );
  const categories = await fetchUtilityCategories();
  const links = linksResponse.data;

  const utilityCategories: UtilityCategories = categories
    .map((category) => {
      return {
        ...category,
        links: links.filter(
          (link) => link.utility_category_id === category.utility_category_id
        ),
      };
    })
    .sort((a, b) => {
      if (a.priority < b.priority) {
        return -1;
      }

      if (a.priority > b.priority) {
        return 1;
      }

      return 0;
    });

  return utilityCategories;
}

export async function fetchUtilityCategories(): Promise<UtilityCategoryDTO[]> {
  const resp = await axios.get<UtilityCategoriesResponse>(
    `/${getClientDB()}/api/restables/utility_categories`
  );
  return resp.data;
}
