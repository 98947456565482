import { Portal } from "@mui/base";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { CatalogAccessoryRow } from "../../../types";
import LinkedDeviceRow from "./LinkedDeviceRow";

export default function LinkedAccessoriesModal({
  catalogAccessoryRow: { catalogAccessoryId, linkedDevices },
  onClose,
}: {
  catalogAccessoryRow: CatalogAccessoryRow;
  onClose: () => void;
}) {
  return (
    <Portal>
      <Dialog open={true} onClose={onClose} maxWidth="lg">
        <DialogTitle>Linked Devices</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Make</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Short Description</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {linkedDevices.map((device) => (
                <LinkedDeviceRow
                  key={device.device_id}
                  catalogAccessoryId={catalogAccessoryId}
                  linkedDevice={device}
                />
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
