import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
  height: 93px;
  position: relative;
  border: 1px solid #000;
  box-shadow: 0 3px 3px #aaa;
  background: #fff;
  background: linear-gradient(to right, #fff 0, #e7eef2 50%, #d0dde5 100%);
  font-family: verdana, sans-serif;
`;

export const UserInfo = styled.div`
  text-align: right;
  position: relative;
  z-index: 1;
  background-color: #000;
  font-size: 11px;
  font-weight: 700;
  height: 16px;
  line-height: 15px;

  span {
    padding: 0 5px;
    color: #fff;
  }
`;

export const LogoutText = styled.span`
  border-left: 1px solid #fff;
  padding: 0 5px;
  color: #fff;
  cursor: pointer;
`;

export const DemoLogo = styled.img`
  position: absolute;
  top: 5px;
  left: 15px;
`;

export const DemoLogoText = styled.span`
  position: absolute;
  left: 15px;
  color: #23547d;
  font: 700 20px "trebuchet ms";
  top: 41px;
`;

export const HeaderLogo = styled.img`
  position: absolute;
  right: 0;
  bottom: -15px;
  width: 250px;
`;

export const SectionTitle = styled.h1`
  text-align: center;
  color: #23547d;
  font: bold 20px "trebuchet ms";
  margin-top: 25px;

  img {
    margin-top: -12px;
  }
`;
