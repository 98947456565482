import { Switch } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  ReportAuthorizedRolesDTO,
  updateReportAuthorizedRoles,
} from "@react-ms-apps/common";
import { AuthorizedRoles } from "@react-ms-apps/common/types";
import * as Sentry from "@sentry/react";
import { uniq } from "lodash";
import { useCallback } from "react";
import { toast } from "react-toastify";

interface AuthorizedRoleToggleProps
  extends GridRenderCellParams<ReportAuthorizedRolesDTO> {
  authorizedRole: AuthorizedRoles;
  onUpdateRow: (row: ReportAuthorizedRolesDTO) => void;
}

export default function AuthorizedRoleToggle({
  id,
  row,
  authorizedRole,
  onUpdateRow,
}: AuthorizedRoleToggleProps) {
  const { authorized_roles } = row;

  const handleAuthorizedRoleChange = useCallback(
    async (id: number, role: AuthorizedRoles, checked: boolean) => {
      const roles = row.authorized_roles.split(",");
      if (checked) {
        roles.push(role);
      } else {
        roles.splice(roles.indexOf(role), 1);
      }

      // ensure roles are unique
      const uniqueRoles = uniq(roles);
      const { report, etag = "" } = row;

      try {
        const { data: updatedRow, etag: updatedEtag } =
          await updateReportAuthorizedRoles(
            id,
            report,
            uniqueRoles.join(","),
            etag
          );
        toast.success("Updated role");
        onUpdateRow({ ...updatedRow, etag: updatedEtag });
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to update role");
      }
    },
    [onUpdateRow, row]
  );

  const checked = authorized_roles.includes(authorizedRole);

  return (
    <Switch
      disabled={!row.etag}
      onChange={(e, active) =>
        handleAuthorizedRoleChange(id as number, authorizedRole, active)
      }
      checked={checked}
    />
  );
}
