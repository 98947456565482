import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Portal,
} from "@mui/material";
import { deleteDeviceImage } from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import { useState } from "react";

interface DeleteImageModalProps {
  onClose: () => void;
  imageId: number;
  onDelete: (deviceImageId: number) => void;
}

function DeleteImageModal({
  onClose,
  onDelete,
  imageId,
}: DeleteImageModalProps) {
  const [deletingImage, setDeletingImage] = useState(false);

  const handleDelete = async () => {
    setDeletingImage(true);

    try {
      await deleteDeviceImage(imageId);
      onDelete(imageId);
      onClose();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setDeletingImage(false);
    }
  };

  return (
    <Portal>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Delete Device Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the device image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={deletingImage}
            disabled={deletingImage}
            onClick={onClose}
            color="primary"
          >
            Cancel
          </LoadingButton>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

export default DeleteImageModal;
