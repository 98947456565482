import { BarChart, BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { UsageAndCost } from "../api";
import {
  barChartSlotProps,
  ByChartData,
  ChartDatasets,
  chartMargins,
  ChartType,
  CostCategoryMap,
} from "../constants";
import { ChartEvents } from "../events";
import { currencyFormatter } from "../utils";
import { captureChart } from "./utils";

interface CategoryCostDataItem {
  month: string;
  [costCategory: string]: string | number;
}

export default function CostByCategoryBarChart({
  data,
  startDate,
  endDate,
  dataKeys,
  selectedDataKeys,
  chartType,
  fieldNames,
  dataSet,
}: {
  data: UsageAndCost[];
  startDate: string;
  endDate: string;
  dataKeys: string[];
  selectedDataKeys: string[];
  chartType: ChartType;
  fieldNames: string[];
  dataSet: ChartDatasets;
}) {
  const processedData: CategoryCostDataItem[] = useMemo(() => {
    const filteredData = data.filter((item) =>
      moment(item.invoice_date).isBetween(startDate, endDate, null, "[]")
    );

    const monthlyCategoryTotalMap: {
      [month: string]: { [costCategory: string]: number };
    } = {};

    for (const item of filteredData) {
      const month = moment(item.invoice_date).format("MMM YYYY");

      if (!monthlyCategoryTotalMap[month]) {
        monthlyCategoryTotalMap[month] = {};
      }

      for (const fieldName of fieldNames) {
        if (!monthlyCategoryTotalMap[month][fieldName]) {
          monthlyCategoryTotalMap[month][fieldName] = 0;
        }

        monthlyCategoryTotalMap[month][fieldName] += Number(
          item[fieldName as keyof UsageAndCost]
        );
      }
    }

    return Object.keys(monthlyCategoryTotalMap).map((month) => {
      const item = monthlyCategoryTotalMap[month];
      return {
        month,
        ...item,
      };
    });
  }, [data, startDate, endDate, fieldNames]);

  const displayedData = useMemo(() => {
    return processedData.map((item) => {
      const displayedItem: CategoryCostDataItem = {
        month: item.month,
      };

      for (const key of selectedDataKeys) {
        displayedItem[key] = item[key];
      }

      return displayedItem;
    });
  }, [processedData, selectedDataKeys]);

  const formattedData: {
    month: string;
    [key: string]: string | number;
  }[] = useMemo(() => {
    return displayedData.map((item) => {
      return {
        ...item,
        month: moment(item.month, "MMM YYYY").format("MMM YY"),
      };
    });
  }, [displayedData]);

  const barChartSeries: MakeOptional<BarSeriesType, "type">[] = useMemo(() => {
    return dataKeys.map((key) => ({
      dataKey: key,
      label: CostCategoryMap[key],
      stack: chartType === ChartType.BAR ? "category" : undefined,
      valueFormatter: (value) => currencyFormatter(value),
    }));
  }, [dataKeys, chartType]);

  const handleExportData = useCallback(() => {
    if (formattedData.length === 0) return;

    const keys = Object.keys(formattedData[0]).filter((key) => key !== "month");
    const header = ["month", ...keys].join(",");
    const rows = formattedData.map((item) => {
      return [
        item.month,
        ...keys.map((key) => currencyFormatter(item[key] || 0, false)),
      ].join(",");
    });

    const csv = [header, ...rows].join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "cost-by-category.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [formattedData]);

  const handleCaptureChart = useCallback(() => {
    captureChart({
      dataSet,
      chartType,
      byChartData: ByChartData.COST_CATEGORIES,
      startDate,
      endDate,
    });
  }, [dataSet, chartType, startDate, endDate]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportImage, handleCaptureChart);
    return () => {
      window.removeEventListener(ChartEvents.ExportImage, handleCaptureChart);
    };
  }, [handleCaptureChart]);

  useEffect(() => {
    window.addEventListener(ChartEvents.ExportData, handleExportData);
    return () => {
      window.removeEventListener(ChartEvents.ExportData, handleExportData);
    };
  }, [handleExportData]);

  return (
    <BarChart
      dataset={formattedData}
      series={barChartSeries}
      xAxis={[{ scaleType: "band", dataKey: "month" }]}
      yAxis={[
        {
          scaleType: "linear",
          valueFormatter: (value) => currencyFormatter(value),
        },
      ]}
      slotProps={barChartSlotProps}
      margin={chartMargins}
    />
  );
}
