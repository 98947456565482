import axios from "axios";

axios.defaults.baseURL = window.location.origin;

// add a no-cache header to all requests
axios.interceptors.request.use((config) => {
  config.headers["Cache-Control"] = "no-cache";
  return config;
});

axios.interceptors.response.use((response) => {
  if (response.data && response.data.d) {
    response.data = response.data.d;
  }
  return response;
});
