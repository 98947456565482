import { Add } from "@mui/icons-material";
import { Box, Button, Switch, Toolbar } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { usePageTitle } from "@react-ms-apps/app/src/Providers/PageTitleProvider";
import { PageContainer } from "@react-ms-apps/common";
import { useNav } from "@react-ms-apps/common/providers/NavProvider";
import * as Sentry from "@sentry/react";
import { isAxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import CreateActionDialog from "./CreateActionDialog";
import {
  MDMAction,
  fetchMDMActionEtag,
  fetchMDMActions,
  updateMDMAction,
} from "./api";

export default function MDMEditor() {
  const { navData } = useNav();
  const { setTitle } = usePageTitle();

  const [loadingActions, setLoadingActions] = useState(false);
  const [mdmActions, setMdmActions] = useState<MDMAction[]>([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const handleMDMActionCreated = (action: MDMAction) => {
    setMdmActions((prev) => [...prev, action]);
  };

  const handleGetMDMActions = async () => {
    try {
      setLoadingActions(true);

      const mdmActions = await fetchMDMActions();
      setMdmActions(mdmActions);
    } catch (error) {
      toast.error("Failed to fetch MDM Actions");
      Sentry.captureException(error);
    } finally {
      setLoadingActions(false);
    }
  };

  const handleGetMDMActionEtag = async (id: number) => {
    try {
      const etag = await fetchMDMActionEtag(id);
      setMdmActions((prev) =>
        prev.map((action) => {
          if (action.id === id) {
            return {
              ...action,
              etag,
            };
          }
          return action;
        })
      );
    } catch (error) {
      toast.error("Failed to fetch MDM Action Etag");
      Sentry.captureException(error);
      return "";
    }
  };

  const handleOrderUpdate = useCallback(
    async (id: number, updatedPartial: Partial<MDMAction>) => {
      const oldData = mdmActions.find((action) => action.id === id);
      if (!oldData) {
        toast.error("MDM action not found");
        return;
      }

      // update proactively to provide immediate feedback
      setMdmActions((prev) =>
        prev.map((action) => {
          if (action.id === id) {
            return {
              ...action,
              ...updatedPartial,
            };
          }
          return action;
        })
      );

      try {
        // update the action
        const updatedAction = await updateMDMAction(id, updatedPartial);
        // update the action in the list
        setMdmActions((prev) =>
          prev.map((action) => {
            if (action.id === id) {
              return updatedAction;
            }

            return action;
          })
        );

        toast.success("MDM Action updated successfully");
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response?.statusText === "Precondition Failed"
        ) {
          toast.error(
            "The data has been modified by another user. Please refresh the page and try again."
          );
        }

        // revert the change if it fails
        setMdmActions((prev) =>
          prev.map((action) => {
            if (action.id === id) {
              return {
                ...action,
                ...oldData,
              };
            }
            return action;
          })
        );

        Sentry.captureException(error);
        throw error;
      }
    },
    [mdmActions]
  );

  const handleActiveChange = useCallback(
    async (id: number, active: boolean) => {
      // update the active status
      try {
        await handleOrderUpdate(id, { active });
      } catch (error) {
        toast.error("Failed to update MDM Action active status");
      }
    },
    [handleOrderUpdate]
  );

  const handleOrderChange = async (
    id: number,
    newDisplayOrder: number,
    oldDisplayOrder: number
  ) => {
    try {
      // update the display order
      await handleOrderUpdate(id, { display_order: newDisplayOrder });
      return newDisplayOrder;
    } catch (error) {
      toast.error("Failed to update MDM Action display order");
      return oldDisplayOrder;
    }
  };

  const columns: GridColDef[] = useMemo((): GridColDef[] => {
    return [
      {
        headerName: "ID",
        field: "id",
        type: "number",
        editable: false,
        valueFormatter: (params) => {
          // format id as string to avoid comma separator
          return `${params.value}`;
        },
      },
      {
        headerName: "Action",
        field: "action",
        type: "string",
        editable: false,
        flex: 1,
      },
      {
        headerName: "Created At",
        field: "created_at",
        valueGetter: (params) => {
          return new Date(params.value as string);
        },
        type: "dateTime",
        editable: false,
        width: 200,
      },
      {
        headerName: "Updated At",
        field: "updated_at",
        valueGetter: (params) => {
          return new Date(params.value as string);
        },
        type: "dateTime",
        editable: false,
        width: 200,
      },
      {
        headerName: "Display Order",
        field: "display_order",
        type: "number",
        editable: true,
      },
      {
        headerName: "Active",
        field: "active",
        type: "boolean",
        editable: false, // disabled to prevent native editing
        renderCell: (params) => {
          const { value, id } = params;

          return (
            <Switch
              onChange={(e, active) => handleActiveChange(id as number, active)}
              checked={!!value}
            />
          );
        },
      },
    ];
  }, [handleActiveChange]);

  const initialSortModel: GridSortModel = useMemo(() => {
    return [
      {
        field: "id",
        sort: "asc",
      },
    ];
  }, []);

  const tableHeight = useMemo(() => {
    const appHeader = document.querySelector("#app-header");
    const appHeaderHeight = appHeader?.clientHeight ?? 0;

    const pageHeader = document.querySelector("#mdm-action-editor");
    const pageHeaderHeight = pageHeader?.clientHeight ?? 0;

    // set a default height
    if (!appHeader || !pageHeader) return 500;

    return window.innerHeight - (appHeaderHeight + pageHeaderHeight + 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navData]);

  useEffect(() => {
    handleGetMDMActions();
  }, []);

  // set document page title
  useEffect(() => {
    setTitle("MDM Action Editor");
  }, [setTitle]);

  return (
    <>
      <PageContainer
        header="MDM Action Editor"
        description="This page allows you to manage Mobile Device Management actions."
        utilityName="MDM Action Editor"
        headerId="mdm-action-editor"
      >
        <Box
          sx={{
            height: tableHeight,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
            "& .MuiDataGrid-booleanCell[data-value='true']": {
              color: "success.main",
            },
            "& .MuiDataGrid-booleanCell[data-value='false']": {
              color: "error.main",
            },
          }}
        >
          <DataGridPremium
            sortModel={initialSortModel}
            loading={loadingActions}
            getRowId={(row: MDMAction) => row.id}
            rows={mdmActions}
            columns={columns}
            processRowUpdate={async (newRow, oldRow) => {
              if (newRow.display_order !== oldRow.display_order) {
                const finalDisplayOrder = await handleOrderChange(
                  newRow.id,
                  newRow.display_order,
                  oldRow.display_order
                );
                newRow.display_order = finalDisplayOrder;
              }

              return newRow;
            }}
            onCellEditStart={(params) =>
              handleGetMDMActionEtag(params.id as number)
            }
            slots={{
              toolbar: () => (
                <Toolbar
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div />

                  <Button
                    variant="outlined"
                    endIcon={<Add />}
                    onClick={() => setShowCreateDialog(true)}
                  >
                    Add Action
                  </Button>
                </Toolbar>
              ),
            }}
          />
        </Box>
      </PageContainer>

      {showCreateDialog && (
        <CreateActionDialog
          onClose={() => setShowCreateDialog(false)}
          onCreated={handleMDMActionCreated}
        />
      )}
    </>
  );
}
