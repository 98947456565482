import { Print } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  SxProps,
  Theme,
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  ReportListItem,
  fetchReportNames,
  fetchStatementMonthsList,
} from "@react-ms-apps/common";
import { colors } from "@react-ms-apps/common/styles/colors";
import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const selectSx: SxProps<Theme> = {
  color: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  ".MuiSvgIcon-root ": {
    fill: "white !important",
  },
};

interface ReportToolbarProps {
  onLoadingMonthsChange: (loading: boolean) => void;
  onLoadingReportOptionsChange: (loading: boolean) => void;
  onSelectedStatementMonthChange: (month: string) => void;
  onSelectedReportNameChange: (reportName: string) => void;
  onPoolAdjustedChange: (poolAdjusted: boolean) => void;
}

export default function ReportToolbar({
  onLoadingMonthsChange,
  onLoadingReportOptionsChange,
  onSelectedStatementMonthChange,
  onSelectedReportNameChange,
  onPoolAdjustedChange,
}: ReportToolbarProps) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // get the statement month from the query params
  const statementMonthParam = searchParams.get("statement_month") || "";

  // get the report name from the query params
  const reportNameParam = searchParams.get("report_name") || "";

  const [loadingMonths, setLoadingMonths] = useState<boolean>(false);
  const [statementMonths, setStatementMonths] = useState<string[]>([]);
  const [selectedStatementMonth, setSelectedStatementMonth] =
    useState<string>(statementMonthParam);

  const [loadingReportOptions, setLoadingReportOptions] =
    useState<boolean>(false);
  const [reportOptions, setReportOptions] = useState<ReportListItem[]>([]);
  const [selectedReportName, setSelectedReportName] = useState<string>(
    reportNameParam || ""
  );

  const [showPoolAdjusted, setShowPoolAdjusted] = useState<boolean>(false);

  const getAvailableMonths = useCallback(async () => {
    setLoadingMonths(true);

    try {
      const months = await fetchStatementMonthsList();

      // sort months in descending order
      months.sort((a, b) => moment(b).diff(moment(a)));

      setStatementMonths(months);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingMonths(false);
    }
  }, []);

  const getReportOptions = useCallback(async () => {
    setLoadingReportOptions(true);

    try {
      const reportNames = await fetchReportNames();
      setReportOptions(reportNames);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingReportOptions(false);
    }
  }, []);

  const handlePoolAdjustedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowPoolAdjusted(event.target.checked);
  };

  const renderMonth = (date: string) => {
    return moment(date).format("MMM YYYY");
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    getAvailableMonths();
    getReportOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set the selected month to first month available if none selected
  useEffect(() => {
    if (statementMonths.length > 0 && !selectedStatementMonth) {
      setSelectedStatementMonth(statementMonths[0]);
    }
  }, [selectedStatementMonth, statementMonths]);

  // watch loading states
  useEffect(() => {
    onLoadingMonthsChange(loadingMonths);
  }, [loadingMonths, onLoadingMonthsChange]);

  useEffect(() => {
    onLoadingReportOptionsChange(loadingReportOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingReportOptions]);

  // watch selected month
  useEffect(() => {
    onSelectedStatementMonthChange(selectedStatementMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatementMonth]);

  // watch selected report name
  useEffect(() => {
    onSelectedReportNameChange(selectedReportName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportName]);

  // watch pool adjusted
  useEffect(() => {
    onPoolAdjustedChange(showPoolAdjusted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPoolAdjusted]);

  // add the statementh month and report name as a query param
  useEffect(() => {
    const hasNewStatementMonth =
      selectedStatementMonth &&
      searchParams.get("statement_month") !== selectedStatementMonth;
    const hasNewReportName =
      selectedReportName &&
      searchParams.get("report_name") !== selectedReportName;

    if (hasNewStatementMonth || hasNewReportName) {
      setSearchParams(
        {
          report_name: selectedReportName,
          statement_month: selectedStatementMonth,
        },
        {
          replace: true,
        }
      );
    }
  }, [
    location,
    searchParams,
    selectedReportName,
    selectedStatementMonth,
    setSearchParams,
  ]);

  return (
    <GridToolbarContainer className="rounded-tl rounded-tr bg-primary-800 !pb-2 border-0 border-b border-gray-200 border-solid">
      <div className="flex flex-1 flex-row justify-between">
        <div className="flex flex-row gap-x-2 pt-2 pl-2">
          <FormControl size="small" className="w-[130px]">
            <InputLabel id="select-month-label" sx={{ color: "white" }}>
              Select Month
            </InputLabel>

            <Select
              size="small"
              sx={selectSx}
              labelId="select-month-label"
              id="select-month"
              label="Select Month"
              value={statementMonths.length > 0 ? selectedStatementMonth : ""}
              onChange={(event) =>
                setSelectedStatementMonth(event.target.value as string)
              }
            >
              {statementMonths.map((month) => (
                <MenuItem key={month} value={month}>
                  {renderMonth(month)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" className="w-[200px]">
            <InputLabel id="select-report-label" sx={{ color: "white" }}>
              Select Report
            </InputLabel>

            <Select
              size="small"
              sx={selectSx}
              labelId="select-report-label"
              id="select-report"
              label="Select Report"
              value={reportOptions.length > 0 ? selectedReportName : ""}
              onChange={(event) =>
                setSelectedReportName(event.target.value as string)
              }
            >
              {reportOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            className="!ml-0"
            sx={{ color: "white" }}
            control={
              <Switch
                checked={showPoolAdjusted}
                onChange={handlePoolAdjustedChange}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .Mui-checked .MuiSwitch-thumb": {
                    color: "white",
                  },
                  "& .Mui-checked + .MuiSwitch-track": {
                    backgroundColor: colors.primary[600],
                    opacity: 1,
                  },
                }}
              />
            }
            label="Pool Adjusted"
          />
        </div>

        <div className="flex flex-row">
          <GridToolbarContainer
            sx={{
              ".MuiSvgIcon-root": {
                fill: "white !important",
              },
              ".MuiButton-root": {
                color: "white !important",
              },
              ".MuiButton-root:hover": {
                color: "white !important",
                backgroundColor: "rgba(255, 255, 255, 0.08) !important",
              },
              ".MuiButton-root.Mui-disabled": {
                color: "white !important",
              },
              ".MuiButton-root.Mui-disabled:hover": {
                color: "white !important",
              },
            }}
          >
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
              // disables the "Print" button in the export menu
              printOptions={{ disableToolbarButton: true }}
            />

            <Button
              variant="text"
              color="primary"
              startIcon={<Print />}
              disabled={!selectedReportName || !selectedStatementMonth}
              onClick={handlePrint}
            >
              Print
            </Button>
          </GridToolbarContainer>
        </div>
      </div>
    </GridToolbarContainer>
  );
}
