import { CircularProgress } from "@mui/material";
import { PageContainer } from "@react-ms-apps/common";

export default function LoadingPage() {
  return (
    <PageContainer
      header="Order Approval"
      utilityName="Order Approval"
      headerId="order-approval"
    >
      <CircularProgress />
    </PageContainer>
  );
}
