import { buildApiUrl } from "@react-ms-apps/common/utils/api-url";
import axios from "axios";

export type BANCompanies = BANCompany[];

export interface BANCompany {
  ban: string;
  created_at: string;
  ban_company_id: number;
  company_cost_ctr: string;
  updated_at: string;
  etag?: string;
}

export async function fetchBANCompanies(): Promise<BANCompanies> {
  const resp = await axios.get<BANCompanies>(
    buildApiUrl("api/restables/ban_companies")
  );

  return resp.data;
}

export async function fetchBANCompanyByID(id: number) {
  const resp = await axios.get<BANCompany>(
    buildApiUrl(`api/restables/ban_companies/${id}`)
  );

  return {
    data: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function removeBANCompanyByID(id: number) {
  const resp = await axios.delete(
    buildApiUrl(`api/restables/ban_companies/${id}`)
  );

  return resp.data;
}

export async function createBANCompany(company: string, ban: string) {
  const resp = await axios.post<BANCompany>(
    buildApiUrl(`api/restables/ban_companies`),
    {
      company_cost_ctr: company,
      ban,
    }
  );

  return {
    data: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function updateBANCompany(
  id: number,
  company: string,
  ban: string,
  etag: string
) {
  const resp = await axios.put<BANCompany>(
    buildApiUrl(`api/restables/ban_companies/${id}`),
    {
      company_cost_ctr: company,
      ban,
    },
    {
      headers: {
        "If-Match": etag,
      },
    }
  );

  return {
    data: resp.data,
    etag: resp.headers["etag"] || "",
  };
}
