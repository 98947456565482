import { Typography } from "@mui/material";
import { PageContainer } from "@react-ms-apps/common";
import { useEffect } from "react";
import { usePageTitle } from "../../Providers/PageTitleProvider";
import OrderTypesEditorTable from "./OrderTypesEditorTable";

export default function OrderTypesEditor() {
  const { setTitle } = usePageTitle();

  // set page title
  useEffect(() => {
    setTitle("Order Types Management");
  }, [setTitle]);

  return (
    <PageContainer
      headerId="order-types-management-header"
      utilityName="Order Types Management"
      description={
        <Typography id="order-types-management-description">
          Use this page to modify <strong>Order Type</strong> properties. They
          include <strong>Active</strong>, <strong>Auto Approve</strong>,{" "}
          <strong>Custom Description</strong>,{" "}
          <strong>Custom Report Description</strong>,{" "}
          <strong>Hover Description</strong>,{" "}
          <strong>Special Instructions</strong>, and the Access checkboxes (
          <strong>Sys Admins</strong>, <strong>Telco Admins</strong>,{" "}
          <strong>Admins</strong>, <strong>Managers</strong>, and{" "}
          <strong>Authed Users</strong>).
        </Typography>
      }
    >
      <div className="max-w-full mx-auto flex flex-1 flex-col">
        <OrderTypesEditorTable />
      </div>
    </PageContainer>
  );
}
