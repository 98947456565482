import { NavData } from "@react-ms-apps/common/types/nav";

export function getCascades(item: NavData.NavDataItem): NavData.Cascade[] {
  // check for the presence of an array with objects
  const singleCascadeKey = Object.keys(item).find((key) => {
    const value = item[key];
    return (
      Array.isArray(value) && value.length > 0 && typeof value[0] === "object"
    );
  });

  if (!!singleCascadeKey) {
    const cascadeItems = item[singleCascadeKey] as NavData.SingleCategoryItem[];
    return [
      {
        categoryName: item.link_name,
        items: cascadeItems.map((item) => ({
          href: item.href,
          name: item.name,
        })),
      },
    ];
  }

  // check for the presence of an array of strings
  const multipleCascadesDictRefsKey = Object.keys(item).find((key) => {
    const value = item[key];
    return (
      Array.isArray(value) && value.length > 0 && typeof value[0] === "string"
    );
  });

  // check for the presence of an object with key-value pairs
  const multipleCascadesDictKey = Object.keys(item).find((key) => {
    const value = item[key];
    return typeof value === "object" && !Array.isArray(value);
  });

  if (!!multipleCascadesDictRefsKey && !!multipleCascadesDictKey) {
    // iterate through list of
    const cascadeCategories = item[multipleCascadesDictRefsKey] as string[];
    const multiCascadeGroup = item[
      multipleCascadesDictKey
    ] as NavData.MultiCascadeGroup;

    return cascadeCategories.map((categoryName) => {
      const items = multiCascadeGroup[categoryName];
      return {
        categoryName,
        items: items.map((item) => ({
          href: item.href,
          name: item.link_name,
        })),
      };
    });
  }

  return [];
}
