import { fetchChatConfigurationURL } from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { LiveChatWrapper } from "./styled";

export default function LiveChat() {
  const [chatConfigurationUrl, setChatConfigurationUrl] = useState<string>("");

  const getChatConfigurationUrl = async () => {
    try {
      const url = await fetchChatConfigurationURL();
      setChatConfigurationUrl(url);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const initChatConfiguration = () => {
    if (!chatConfigurationUrl) {
      return;
    }

    try {
      const script = document.createElement("script");
      script.src = chatConfigurationUrl;
      script.async = true;

      document.body.appendChild(script);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    getChatConfigurationUrl();
  }, []);

  // load chat configuration when chatConfigurationUrl changes
  useEffect(
    () => {
      initChatConfiguration();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatConfigurationUrl]
  );

  return <LiveChatWrapper id="help-placeholder" />;
}
