import { ImportExport, Link, Refresh, Search } from "@mui/icons-material";
import { Box, Button, IconButton, Tab, Tabs, TextField } from "@mui/material";
import { ROUTES } from "@react-ms-apps/common";
import { DeviceListItem } from "@react-ms-apps/common/api/catalog-manager";
import PageContainer from "@react-ms-apps/common/components/PageContainer";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ImagesProvider } from "../../../Providers/ImagesProvider";
import { useCatalogManager } from "../CatalogManagerProvider";
import { CatalogTab, CatalogTabEnum } from "../types";
import CatalogAccessoriesTab from "./CatalogAccessoriesTab";
import CatalogDevicesTab from "./CatalogDevicesTab";
import DeleteItemModal from "./DeleteItemModal";
import DevicesTab from "./DevicesTab";
import LinkAccessoriesDialog from "./LinkAccessoriesDialog";
import PullFromClientDialog from "./PullFromClientDialog";

const catalogTabs: CatalogTab[] = [
  {
    label: "Catalog Devices",
    type: "catalog-devices",
    value: CatalogTabEnum.CatalogDevices,
  },
  {
    label: "Devices",
    type: "devices",
    value: CatalogTabEnum.Devices,
  },
  {
    label: "Catalog Accessories",
    type: "catalog-accessories",
    value: CatalogTabEnum.CatalogAccessories,
  },
];

function CatalogManager() {
  const navigate = useNavigate();
  const {
    getCatalogDevices,
    getCatalogAccessories,
    getDeviceItems,
    getDeviceAccessories,
    deviceItems,
    catalogAccessories,
    loadingCatalogAccessories,
    loadingCatalogDevices,
    pdaStyles,
    loadingPDAStyles,
    loadingCarriers,
    deleteCatalogDevice,
    loadingDeviceItems,
    deleteDeviceItem,
  } = useCatalogManager();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSearch = queryParams.get("search") || "";

  const [searchFilter, setSearchFilter] = useState(initialSearch);

  // sets the initial tab value based on the query param
  const { search } = useLocation();
  const tabName = new URLSearchParams(search).get("tab");
  const initialTabValue = useMemo(() => {
    const tab = catalogTabs.find((tab) => tab.type === tabName);
    return tab?.value || CatalogTabEnum.CatalogDevices;
  }, [tabName]);

  const [tabValue, setTabValue] = useState<CatalogTabEnum>(
    initialTabValue || CatalogTabEnum.CatalogDevices
  );

  const [itemForDeletion, setItemForDeletion] = useState<number | null>(null);

  const [showLinkAccessoriesDialog, setShowLinkAccessoriesDialog] =
    useState(false);
  const [showPullFromClientDialog, setShowPullFromClientDialog] =
    useState(false);

  const handleDeleteCatalogDevice = async () => {
    if (!itemForDeletion) return;

    try {
      await deleteCatalogDevice(itemForDeletion);
      toast.success("Catalog device deleted successfully");
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setItemForDeletion(null);
    }
  };

  const handleDeleteDevice = useCallback(async () => {
    if (!itemForDeletion) return;

    try {
      await deleteDeviceItem(itemForDeletion);
      toast.success("Device deleted successfully");
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setItemForDeletion(null);
    }
  }, [deleteDeviceItem, itemForDeletion]);

  const handleEditCatalogDevice = useCallback(
    (deviceId: number, catalogDeviceId: number) => {
      // go to edit device page w/ catalog device id and query param
      navigate(
        `${ROUTES.UTILITY.CATALOG_MANAGER.EDIT_DEVICE}/${deviceId}?catalogDeviceId=${catalogDeviceId}`
      );
    },
    [navigate]
  );

  const handleEditDevice = useCallback(
    (deviceId: number) => {
      // go to edit device page
      navigate(`${ROUTES.UTILITY.CATALOG_MANAGER.EDIT_DEVICE}/${deviceId}`);
    },
    [navigate]
  );

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: CatalogTabEnum
  ) => {
    setTabValue(newValue);

    const tabName = catalogTabs.find((tab) => tab.value === newValue)?.type;

    // update query params in url
    navigate(`?tab=${tabName}`);
  };

  const tableHeight = useMemo(() => {
    return window.innerHeight - 300;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataRefresh = async () => {
    // force refresh all data
    await Promise.all([
      getCatalogDevices(true),
      getCatalogAccessories(true),
      getDeviceItems(true),
      getDeviceAccessories(true),
    ]);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.target.value);

    // update the query params
    const params = new URLSearchParams(search);
    params.set("search", e.target.value);
    navigate(`?${params.toString()}`);
  };

  return (
    <>
      <PageContainer headerId="catalog-manager-header">
        <div className="mr-4 mt-4 mb-2">
          <TextField
            size="small"
            onChange={handleSearchChange}
            label="Search"
            InputProps={{
              startAdornment: <Search />,
            }}
            defaultValue={searchFilter}
          />

          <Button
            onClick={() => setShowPullFromClientDialog(true)}
            variant="outlined"
            sx={{ ml: 1 }}
            startIcon={<ImportExport />}
          >
            Pull From Client
          </Button>

          <Button
            onClick={() => setShowLinkAccessoriesDialog(true)}
            variant="outlined"
            sx={{ ml: 1 }}
            startIcon={<Link />}
          >
            Link Accessories
          </Button>
        </div>

        <div className="flex flex-row items-center justify-between">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              {catalogTabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>

          <IconButton onClick={handleDataRefresh}>
            <Refresh color="primary" />
          </IconButton>
        </div>

        <CatalogDevicesTab
          tabValue={tabValue}
          tableHeight={tableHeight}
          loading={
            loadingPDAStyles ||
            loadingCarriers ||
            loadingDeviceItems ||
            loadingCatalogDevices ||
            loadingDeviceItems
          }
          search={searchFilter}
          onEdit={handleEditCatalogDevice}
          onDelete={setItemForDeletion}
        />

        <DevicesTab
          tabValue={tabValue}
          tableHeight={tableHeight}
          loading={loadingDeviceItems || loadingPDAStyles}
          pdaStyles={pdaStyles}
          search={searchFilter}
          onEdit={handleEditDevice}
          onDelete={setItemForDeletion}
        />

        <CatalogAccessoriesTab
          tabValue={tabValue}
          tableHeight={tableHeight}
          loading={
            loadingPDAStyles ||
            loadingCarriers ||
            loadingDeviceItems ||
            loadingCatalogDevices ||
            loadingCatalogAccessories
          }
          search={searchFilter}
        />
      </PageContainer>

      {itemForDeletion && (
        <DeleteItemModal
          onClose={() => setItemForDeletion(null)}
          onDelete={
            tabValue === CatalogTabEnum.CatalogDevices
              ? handleDeleteCatalogDevice
              : handleDeleteDevice
          }
        />
      )}

      {showLinkAccessoriesDialog &&
        !isEmpty(catalogAccessories) &&
        !isEmpty(deviceItems) && (
          <LinkAccessoriesDialog
            onClose={() => setShowLinkAccessoriesDialog(false)}
            devices={deviceItems as DeviceListItem[]}
          />
        )}

      {showPullFromClientDialog && (
        <PullFromClientDialog
          onClose={() => setShowPullFromClientDialog(false)}
        />
      )}
    </>
  );
}

export default function ManagerWrapper() {
  return (
    <ImagesProvider>
      <CatalogManager />
    </ImagesProvider>
  );
}
