import { BanSummary } from "@react-ms-apps/common/types";
import React from "react";

type StatementField = {
  fieldName: BanSummary.EditableField;
  friendlyName: string;
  currentValue: string;
  deltaValue: string;
  finalValue: string;
};

export interface StatementFieldsMap {
  [key: string]: StatementField;
}

export const StatementContext = React.createContext<{
  etag: string;
  setEtag: React.Dispatch<React.SetStateAction<string>>;
  statementFieldsMap: StatementFieldsMap;
  setStatementFieldsMap: React.Dispatch<
    React.SetStateAction<StatementFieldsMap>
  >;
  setField: (
    fieldName: string,
    {
      currentValue,
      deltaValue,
      finalValue,
    }: {
      currentValue?: string;
      deltaValue?: string;
      finalValue?: string;
    }
  ) => void;
}>({
  etag: "",
  setEtag: () => {},
  statementFieldsMap: {},
  setStatementFieldsMap: () => {},
  setField: () => {},
});

export const useStatementContext = () => React.useContext(StatementContext);

interface StatementContextProps {
  children: React.ReactNode;
}

export const StatementContextProvider = ({
  children,
}: StatementContextProps) => {
  const [etag, setEtag] = React.useState<string>("");
  const [fieldsMap, setFieldsMap] = React.useState<StatementFieldsMap>({});

  const setField = (
    fieldName: string,
    {
      currentValue,
      deltaValue,
      finalValue,
    }: {
      currentValue?: string;
      deltaValue?: string;
      finalValue?: string;
    }
  ) => {
    setFieldsMap({
      ...fieldsMap,
      [fieldName]: {
        ...fieldsMap[fieldName],
        currentValue: currentValue ?? fieldsMap[fieldName].currentValue,
        deltaValue: deltaValue ?? fieldsMap[fieldName].deltaValue,
        finalValue: finalValue ?? fieldsMap[fieldName].finalValue,
      },
    });
  };

  return (
    <StatementContext.Provider
      value={{
        etag,
        setEtag,
        statementFieldsMap: fieldsMap,
        setStatementFieldsMap: setFieldsMap,
        setField,
      }}
    >
      {children}
    </StatementContext.Provider>
  );
};
