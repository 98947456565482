import { UploadProcessTypes } from "@react-ms-apps/common/constants/uploadProcessTypes";

export const LegacyUtilities = {
  [UploadProcessTypes.BatchStatementEditor]:
    "/Utility/Refresh/batchStatementEditor",
  [UploadProcessTypes.AssetRefresh]: "/Utility/Refresh/assetRefresh",
  [UploadProcessTypes.UserCreation]: "/Utility/Refresh/userCreation",
  [UploadProcessTypes.CostCenterRefreshByDevice]: "/Utility/Refresh/ccRefresh",
  [UploadProcessTypes.CostCenterRefreshByEmployeeID]:
    "/Utility/Refresh/ccRefresh",
  [UploadProcessTypes.MakeManager]: "/Utility/Refresh/makeManager",
  [UploadProcessTypes.ManagerRefreshByDeviceManager]:
    "/Utility/Refresh/deviceManager",
  [UploadProcessTypes.ManagerRefreshByEmployeeIDManager]:
    "/Utility/Refresh/employeeManager",
  [UploadProcessTypes.PlanOverrideUtility]: "/Utility/PlanOverride",
  UtilityList: "/Utility",
  UtilityManagement: "/Utility/UtilityManagement",
  OrderTypesManagement: "/Utility/OrderTypes/orderTypes",
  StatementSummaryEditor: "/Utility/StatementSummaryEditor",
};

const ROUTES = {
  BASE: "/",
  AUTHENTICATED_REDIRECT: "/authenticated",
  LOGIN: {
    HOME: "/login",
    FORGOT_PASSWORD: "/forgotPassword",
    SENT_PASSWORD_RESET: "/sentPasswordReset",
    PASSWORD_RESET: "/passwordReset",
  },
  LOGOUT: "/logout",
  UTILITY: {
    ROOT: "/utilities",
    UTILITIES_LIST: "/utilities-list",
    UPLOAD_PROCESSOR: "/upload-processor",
    UTILITIES_EDITOR: "/utilities-editor",
    ORDER_TYPES_EDITOR: "/order-types-editor",
    STATEMENT_SUMMARY_EDITOR: "/statement-editor",
    REPORT_ROLES_MANAGER: "/report-roles-manager",
    DELETE_ZERO_BALANCE_STATEMENTS: "/delete-zero-balance-statements",
    BAN_COMPANIES_EDITOR: "/ban-companies-editor",
    CATALOG_MANAGER: {
      ROOT: "/catalog-manager",
      EDIT_DEVICE: "edit-device",
      NEW_DEVICE: "new-device",
    },
    BATCH_STATEMENT_EDITOR:
      LegacyUtilities[UploadProcessTypes.BatchStatementEditor],
    BATCH_ASSET_REFRESH: LegacyUtilities[UploadProcessTypes.AssetRefresh],
    BATCH_USER_CREATION: LegacyUtilities[UploadProcessTypes.UserCreation],
    BATCH_DEVICE_COST_CENTER_REFRESH:
      LegacyUtilities[UploadProcessTypes.CostCenterRefreshByDevice],
    BATCH_EMPLOYEE_COST_CENTER_REFRESH:
      LegacyUtilities[UploadProcessTypes.CostCenterRefreshByEmployeeID],
    BATCH_MAKE_MANAGER: LegacyUtilities[UploadProcessTypes.MakeManager],
    BATCH_MANAGER_DEVICE_REFRESH:
      LegacyUtilities[UploadProcessTypes.ManagerRefreshByDeviceManager],
    BATCH_UPDATE_EMPLOYEE_MANAGER:
      LegacyUtilities[UploadProcessTypes.ManagerRefreshByEmployeeIDManager],
    BATCH_PLAN_OVERRIDE:
      LegacyUtilities[UploadProcessTypes.PlanOverrideUtility],
    MDM_EDITOR: "/mdm-editor",
    LEGACY_UTILITY_MANAGEMENT: LegacyUtilities.UtilityManagement,
    LEGACY_UTILITY_LIST: LegacyUtilities.UtilityList,
    LEGACY_ORDER_TYPES_MANAGEMENT: LegacyUtilities.OrderTypesManagement,
    LEGACY_STATEMENT_SUMMARY_EDITOR: LegacyUtilities.StatementSummaryEditor,
  },
  CHARTS_DASHBOARD: {
    ROOT: "/charts-dashboard",
  },
  ORDER_APPROVAL: "/order-approval",
  REPORTS: {
    ROOT: "/reports",
  },
  DIY: {
    STATEMENT_UPLOAD: "/statement-upload",
  },
  NOT_FOUND: "/not-found",
  NOT_FOUND_CLIENT: "/not-found/client",
};

export const legacyUploadRoutes = [
  ROUTES.UTILITY.BATCH_STATEMENT_EDITOR,
  ROUTES.UTILITY.BATCH_ASSET_REFRESH,
  ROUTES.UTILITY.BATCH_USER_CREATION,
  ROUTES.UTILITY.BATCH_DEVICE_COST_CENTER_REFRESH,
  ROUTES.UTILITY.BATCH_EMPLOYEE_COST_CENTER_REFRESH,
  ROUTES.UTILITY.BATCH_MAKE_MANAGER,
  ROUTES.UTILITY.BATCH_MANAGER_DEVICE_REFRESH,
  ROUTES.UTILITY.BATCH_UPDATE_EMPLOYEE_MANAGER,
  ROUTES.UTILITY.BATCH_PLAN_OVERRIDE,
  ROUTES.UTILITY.LEGACY_UTILITY_LIST,
  ROUTES.UTILITY.LEGACY_ORDER_TYPES_MANAGEMENT,
  ROUTES.UTILITY.LEGACY_ORDER_TYPES_MANAGEMENT,
  ROUTES.UTILITY.LEGACY_STATEMENT_SUMMARY_EDITOR,
  ROUTES.UTILITY.LEGACY_UTILITY_MANAGEMENT,

  // routes that are being moved to new /utilities route
  ROUTES.UTILITY.UTILITIES_LIST,
  ROUTES.UTILITY.ORDER_TYPES_EDITOR,
  ROUTES.UTILITY.STATEMENT_SUMMARY_EDITOR,
  ROUTES.UTILITY.UTILITIES_EDITOR,
];

export default ROUTES;
