import { FormControl, InputAdornment, TextField, Tooltip } from "@mui/material";
import { classNames } from "@react-ms-apps/common/utils/styles";
import { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

interface CurrencyInputProps {
  defaultValue?: string;
  friendlyFieldName: string;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  disabled?: boolean;
  disabledReason?: string;
}

export default function CurrencyInput({
  defaultValue,
  friendlyFieldName,
  onChange,
  disabled,
  disabledReason,
}: CurrencyInputProps) {
  const customNameInputRef = useRef<HTMLInputElement>(null);
  const [currencyValue, setCurrencyValue] = useState<string>(
    defaultValue || ""
  );

  const updateCurrencyValue = async () => {
    if (!customNameInputRef.current) return;

    let value: string = customNameInputRef.current.value || "";

    // do not proceed if value contains no numbers
    if (!/\d/.test(value) && value !== "") {
      return;
    }

    // only allow numbers, decimals, and negative sign
    value = value.replace(/[^0-9.-]/g, "");

    // only allow 2 decimal places
    value = value.replace(/(\.\d{2})\d+/, "$1");

    // set string to 2 decimal places
    value = Number(value).toFixed(2);

    if (onChange) onChange(value);
    setCurrencyValue(value);
  };

  const handleBlur = () => {
    if (!customNameInputRef.current) return;

    const value: string = customNameInputRef.current.value || "";

    // warn user if value is not a number
    if (!/\d/.test(value) && value !== "") {
      toast.warn("Value must be a number");
      return;
    }
  };

  const tooltipTitle = useMemo(() => {
    if (!disabled) return `Click to edit "${friendlyFieldName}"`;

    return disabledReason || "This field is disabled";
  }, [disabled, disabledReason, friendlyFieldName]);

  return (
    <Tooltip title={tooltipTitle}>
      <FormControl>
        <TextField
          inputRef={customNameInputRef}
          defaultValue={currencyValue || ""}
          onChange={updateCurrencyValue}
          onBlur={handleBlur}
          variant={"outlined"}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          disabled={disabled}
          inputProps={{
            className: classNames(disabled ? "cursor-not-allowed" : ""),
          }}
        />
      </FormControl>
    </Tooltip>
  );
}
