import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BanSummary } from "@react-ms-apps/common/types";
import { DataTestIDs } from "../../../Constants/data-test-ids";
import StatementEditorRow from "../StatementEditorRow";

interface EditableField {
  fieldName: BanSummary.EditableField;
  friendlyName: string;
}

export const editableFields: EditableField[] = [
  {
    fieldName: "equipment_charges",
    friendlyName: "Equipment Charges",
  },
  {
    fieldName: "equipment_tax",
    friendlyName: "Equipment Tax Charges",
  },
  {
    fieldName: "total_tax",
    friendlyName: "Total Tax Charges",
  },
  {
    fieldName: "activation_charge",
    friendlyName: "Activation Charge",
  },
  {
    fieldName: "termination_charge",
    friendlyName: "Termination Charge",
  },
  {
    fieldName: "kb_charges",
    friendlyName: "Excess Data Charges",
  },
  {
    fieldName: "intl_data_roam_charges",
    friendlyName: "Excess Data Charges (Roaming)",
  },
  {
    fieldName: "monthly_access",
    friendlyName: "Access Charges",
  },
  {
    fieldName: "total_other",
    friendlyName: "Other Charges",
  },
  {
    fieldName: "download_charges",
    friendlyName: "Download Charges",
  },
  {
    fieldName: "net_feature_charges",
    friendlyName: "Features Charges",
  },
  {
    fieldName: "pda_charges",
    friendlyName: "Data Plan Charges",
  },
];

interface StatementEditorTableProps {
  banSummary: BanSummary.BanSummary | null;
  isLoading: boolean;
  onRefresh: () => void;
  disabled?: boolean;
}

export default function StatementEditorTable({
  banSummary,
  isLoading,
  disabled,
}: StatementEditorTableProps) {
  const showLoading = !banSummary || isLoading;

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell>Item</TableCell>
        <TableCell className="!text-center">Current</TableCell>
        <TableCell className="!text-center w-20">+</TableCell>
        <TableCell className="!text-center">Delta</TableCell>
        <TableCell className="!text-center w-20">=</TableCell>
        <TableCell className="!text-center">Final</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderLoading = () => (
    <Table>
      {renderTableHead()}
      <TableBody>
        {Array(editableFields.length)
          .fill(null)
          .map((_, index) => (
            <TableRow
              data-testid={DataTestIDs.ORDER_TYPE_EDITOR_TABLE_SKELETON_ROW}
              key={index}
              className="h-16"
            >
              {Array(6)
                .fill(null)
                .map((_, index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );

  const renderDataTable = () => {
    if (!banSummary) return null;

    return (
      <Table data-testid={DataTestIDs.ORDER_TYPE_EDITOR_TABLE}>
        {renderTableHead()}

        <TableBody>
          {editableFields.map((field) => (
            <StatementEditorRow
              key={field.fieldName}
              fieldName={field.fieldName}
              friendlyName={field.friendlyName}
              disabled={disabled}
            />
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className="mx-4 pb-4">
      <div className="w-full">
        <div className="mb-2">
          <Typography className="!mb-2">
            You can update the <strong>Delta</strong> or the{" "}
            <strong>Final</strong> for a given item. Once a change has been
            made, the <strong>Current</strong> will be updated to reflect that
            change.
          </Typography>

          {showLoading ? renderLoading() : renderDataTable()}
        </div>
      </div>
    </div>
  );
}
