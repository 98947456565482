import moment from "moment";
import { useMemo } from "react";
import { OrderSubscriber as IOrderSubscriber, Order } from "./types";

export default function OrderSubscriber({
  subscriber,
  carrierName,
  order,
}: {
  subscriber: IOrderSubscriber;
  carrierName: string;
  order: Order;
}) {
  const deviceName = useMemo(() => {
    return subscriber.make + " " + subscriber.model;
  }, [subscriber]);

  const isUpgradeOrder = useMemo(() => {
    return subscriber.type_of_order === "E";
  }, [subscriber]);

  const mayNotBeEligibleForUpgrade = useMemo(() => {
    if (isUpgradeOrder && subscriber.upgrade_eligibility === null) {
      return true;
    }

    return false;
  }, [isUpgradeOrder, subscriber.upgrade_eligibility]);

  const upgradeEligibilityDate = useMemo(() => {
    if (isUpgradeOrder && !!subscriber.upgrade_eligibility) {
      return moment(subscriber.upgrade_eligibility).format("YYYY-MM-DD");
    }

    return null;
  }, [isUpgradeOrder, subscriber.upgrade_eligibility]);

  const subscriberName = useMemo(() => {
    return `${subscriber.first_name} ${subscriber.last_name}`;
  }, [subscriber]);

  return (
    <div key={subscriber.order_subscriber_id} className="flex flex-col">
      <div className="flex flex-row items-center gap-2">
        <span>Subscriber: </span>
        <span>{subscriberName}</span>
      </div>

      <div className="flex flex-row items-center gap-2">
        <span>Carrier: </span>
        <span>{carrierName}</span>
      </div>

      {order.catalog_device_id && (
        <div className="flex flex-row items-center gap-2">
          <span>Device: </span>
          <span>{deviceName}</span>
        </div>
      )}

      {upgradeEligibilityDate && moment().isAfter(upgradeEligibilityDate) && (
        <div className="text-green-700 mt-4">
          Upgrade eligible as of: {upgradeEligibilityDate}
        </div>
      )}

      {upgradeEligibilityDate && moment().isBefore(upgradeEligibilityDate) && (
        <div className="text-red-700 mt-4">
          Warning - Not upgrade eligible until: {upgradeEligibilityDate}
        </div>
      )}

      {mayNotBeEligibleForUpgrade && (
        <div className="text-red-700 mt-4">
          Warning - Device may not be upgrade eligible
        </div>
      )}
    </div>
  );
}
