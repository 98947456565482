import { UsageAndCost } from "@react-ms-apps/common/types";
import moment from "moment";
import { DataConversionOptions } from "./types";

export function currencyFormatter(
  value: number | null | string,
  useGrouping: boolean = true
) {
  if (value === null) {
    return "";
  }

  if (typeof value === "string") {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping, // Control the use of thousand separator
  }).format(value);
}
export const convertKBToGB = (kb: number) => {
  return Math.round((kb / 1048576) * 100) / 100;
};

export const expectedSeries: Series = {
  items: [
    {
      label: "ATT",
      stack: "carrier",
      data: [
        12139.42, 11976.62, 11833.46, 11274.55, 11148.75, 11168.79, 11256.63,
        11577.22, 10873.82, 12305.62, 10595.96, 11039.98,
      ],
    },
    {
      label: "Verizon",
      stack: "carrier",
      data: [
        16634.32, 13291.37, 15177.82, 16339.19, 14949.05, 16611.85, 15277.42,
        15660.86, 16947.74, 14816.78, 16895.49, 16759.07,
      ],
    },
  ],
  xLabels: [
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
  ],
};

export interface Series {
  items: SeriesItem[];
  xLabels: string[];
}

interface SeriesItem {
  data: number[];
  label: string;
  dataKey?: string;
  stack?: string;
  valueFormatter?: (value: number) => string;
}

const trendingYearsNamesMap: {
  [key: string]: string;
} = {
  2: "2 Yrs Prior",
  1: "Prior Year",
  0: "Current Year",
};

// __item__ is a special key that is used to denote a derived value
export enum InvoiceDataSource {
  TotalCost = "total_charges",
  EquipmentCost = "equipment_total",
  ServiceCost = "__service__cost",
  InternationalCosts = "international_total",
  DataOverageCosts = "__data__overage__costs",
  TotalDataUsageGB = "__total__data__usage",
  TotalVoiceUsage = "total_minutes_of_use",
}

export const invoiceDataSourceNamesMap: {
  [key in InvoiceDataSource]: string;
} = {
  [InvoiceDataSource.TotalCost]: "Total Cost",
  [InvoiceDataSource.EquipmentCost]: "Equipment Cost",
  [InvoiceDataSource.InternationalCosts]: "International Costs",
  [InvoiceDataSource.DataOverageCosts]: "Data Overage Costs",
  [InvoiceDataSource.TotalDataUsageGB]: "Total Data Usage (GB)",
  [InvoiceDataSource.TotalVoiceUsage]: "Total Voice Usage",
  [InvoiceDataSource.ServiceCost]: "Service Cost",
};

function getValueByDataSource(
  aggregateBy: InvoiceDataSource,
  item: UsageAndCost
) {
  switch (aggregateBy) {
    case InvoiceDataSource.TotalCost:
      return item.total_charges;

    case InvoiceDataSource.EquipmentCost:
      return item.equipment_total;

    case InvoiceDataSource.ServiceCost:
      return item.total_charges - item.equipment_total;

    case InvoiceDataSource.InternationalCosts:
      return item.international_total;

    case InvoiceDataSource.DataOverageCosts:
      return item.kb_charges - item.intl_data_roam_charges;

    case InvoiceDataSource.TotalDataUsageGB:
      return convertKBToGB(item.kb_usage);

    case InvoiceDataSource.TotalVoiceUsage:
      return item.total_minutes_of_use;

    default:
      return 0;
  }
}

export function convertToSeries(
  usageData: UsageAndCost[],
  dataSource: InvoiceDataSource,
  selectedDate: Date,
  showTrending: boolean = false,
  options: DataConversionOptions = {
    selectedCarriers: [],
    selectedDeviceTypes: [],
    aggregateBy: "carrier_name",
  }
): Series {
  if (showTrending) {
    return convertToTrendingSeries(
      usageData,
      dataSource,
      selectedDate,
      options
    );
  }

  return convertToBarSeries(usageData, dataSource, selectedDate, options);
}

export function convertToTrendingSeries(
  usageData: UsageAndCost[],
  dataSource: InvoiceDataSource,
  selectedDate: Date,
  options: DataConversionOptions
): Series {
  const series: Series = {
    items: [],
    xLabels: [],
  };

  const { selectedCarriers, selectedDeviceTypes } = options;
  const selectedCarriersMap = selectedCarriers.reduce((acc, carrier) => {
    acc[carrier] = true;
    return acc;
  }, {} as { [key: string]: boolean });
  const selectedDeviceTypesMap = selectedDeviceTypes.reduce(
    (acc, deviceType) => {
      acc[deviceType] = true;
      return acc;
    },
    {} as { [key: string]: boolean }
  );

  const monthsArr: string[] = [];

  // add 12 months for xLabels for each month after the selected date
  for (let i = 1; i <= 12; i++) {
    const month = moment(selectedDate).add(i, "months").format("MMM");
    monthsArr.push(month);
  }

  series.xLabels = monthsArr;

  // get aggregate monthly by relative year: "2 Yrs Prior", "1 Yrs Prior", "Current Year"
  const aggregateMonthlyByRelativeYear: {
    [key: string]: {
      [key: string]: number;
    };
  } = {};

  // create zeros for each month for each relative year
  for (const month of monthsArr) {
    for (let i = 0; i <= 2; i++) {
      const relativeYear = trendingYearsNamesMap[i];

      if (!aggregateMonthlyByRelativeYear[relativeYear]) {
        aggregateMonthlyByRelativeYear[relativeYear] = {};
      }
      aggregateMonthlyByRelativeYear[relativeYear][month] = 0;
    }
  }

  for (const item of usageData) {
    const month = moment(item.invoice_date).format("MMM");

    const yearsPrior = Math.abs(
      moment(item.invoice_date).diff(moment(selectedDate), "years")
    );
    const relativeYear = trendingYearsNamesMap[yearsPrior];

    if (!aggregateMonthlyByRelativeYear[relativeYear]) {
      aggregateMonthlyByRelativeYear[relativeYear] = {};
    }

    if (!aggregateMonthlyByRelativeYear[relativeYear][month]) {
      aggregateMonthlyByRelativeYear[relativeYear][month] = 0;
    }

    if (
      selectedCarriersMap[item.carrier_name] ||
      selectedDeviceTypesMap[item.formatted_device_type]
    ) {
      const totalCharges = getValueByDataSource(dataSource, item);
      aggregateMonthlyByRelativeYear[relativeYear][month] += totalCharges;
    }
  }

  series.items = Object.keys(aggregateMonthlyByRelativeYear).map(
    (relativeYear) => {
      const seriesItem: SeriesItem = {
        label: relativeYear,
        data: [],
      };

      for (const month of monthsArr) {
        if (aggregateMonthlyByRelativeYear[relativeYear][month]) {
          const value = Number(
            aggregateMonthlyByRelativeYear[relativeYear][month].toFixed(2)
          );

          seriesItem.data.push(value);
        } else {
          seriesItem.data.push(0);
        }
      }

      return seriesItem;
    }
  );

  return series;
}

export function convertToBarSeries(
  usageData: UsageAndCost[],
  dataSource: InvoiceDataSource,
  selectedDate: Date,
  options: DataConversionOptions
): Series {
  const series: Series = {
    items: [],
    xLabels: [],
  };

  const { selectedCarriers, selectedDeviceTypes, aggregateBy } = options;
  const selectedCarriersMap = selectedCarriers.reduce((acc, carrier) => {
    acc[carrier] = true;
    return acc;
  }, {} as { [key: string]: boolean });
  const selectedDeviceTypesMap = selectedDeviceTypes.reduce(
    (acc, deviceType) => {
      acc[deviceType] = true;
      return acc;
    },
    {} as { [key: string]: boolean }
  );

  const monthsArr: string[] = [];

  // add 12 months for xLabels for each month after the selected date
  for (let i = 1; i <= 12; i++) {
    const month = moment(selectedDate).add(i, "months").format("MMM");
    monthsArr.push(month);
  }

  series.xLabels = monthsArr;

  // get aggregate monthly by relative year: "2 Yrs Prior", "1 Yrs Prior", "Current Year"
  const monthlyDataByAggregate: {
    [key: string]: {
      [key: string]: number;
    };
  } = {};

  // get available aggregate names
  const aggregatesMap = usageData.reduce((acc, item) => {
    acc[item[aggregateBy]] = true;
    return acc;
  }, {} as { [key: string]: boolean });

  const availableAggregates = Object.keys(aggregatesMap);

  // create zeros for each month for each relative year
  for (const month of monthsArr) {
    for (let i = 0; i < availableAggregates.length; i++) {
      const aggregateName = availableAggregates[i];

      if (!monthlyDataByAggregate[aggregateName]) {
        monthlyDataByAggregate[aggregateName] = {};
      }
      monthlyDataByAggregate[aggregateName][month] = 0;
    }
  }

  const selectedDateMoment = moment(selectedDate);
  const yearOfUsageData = usageData.filter((item) => {
    const invoiceDate = moment(item.invoice_date);

    const monthsDiff = Math.abs(invoiceDate.diff(selectedDateMoment, "months"));

    return monthsDiff < 12;
  });

  for (const item of yearOfUsageData) {
    const month = moment(item.invoice_date).format("MMM");

    const aggregateName = item[aggregateBy];

    if (!monthlyDataByAggregate[aggregateName]) {
      monthlyDataByAggregate[aggregateName] = {};
    }

    if (!monthlyDataByAggregate[aggregateName][month]) {
      monthlyDataByAggregate[aggregateName][month] = 0;
    }

    if (
      selectedCarriersMap[item.carrier_name] ||
      selectedDeviceTypesMap[item.formatted_device_type]
    ) {
      const totalCharges = getValueByDataSource(dataSource, item);
      monthlyDataByAggregate[aggregateName][month] += totalCharges;
    }
  }

  series.items = Object.keys(monthlyDataByAggregate).map((aggregateName) => {
    const seriesItem: SeriesItem = {
      label: aggregateName,
      stack: "total",
      data: [],
    };

    for (const month of monthsArr) {
      if (monthlyDataByAggregate[aggregateName][month]) {
        const value = Number(
          monthlyDataByAggregate[aggregateName][month].toFixed(2)
        );

        seriesItem.data.push(value);
      } else {
        seriesItem.data.push(0);
      }
    }

    return seriesItem;
  });

  return series;
}
