import { Add, MenuBook, Phone } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { ROUTES } from "@react-ms-apps/common";
import {
  CarrierList,
  DeviceList,
} from "@react-ms-apps/common/api/catalog-manager";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

interface ToolbarProps {
  onAdd?: () => void;
  showNewDevice?: boolean;
  showNewCatalogEntry?: boolean;
  showNewAccessory?: boolean;
  onNewAccessory?: () => void;
  onNewCatalogEntry?: () => void;
  devices?: DeviceList;
  carriers?: CarrierList;
}

export default function Toolbar({
  showNewDevice,
  showNewCatalogEntry,
  showNewAccessory,
  onNewAccessory,
  onNewCatalogEntry,
  devices,
  carriers,
}: ToolbarProps) {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col p-2">
        <GridToolbarContainer className="flex flex-row justify-between">
          <div className="flex flex-row">
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </div>

          <div className="flex flex-row gap-2">
            {showNewDevice && (
              <Button
                size="small"
                variant="contained"
                startIcon={<Phone />}
                onClick={() =>
                  navigate(
                    `${ROUTES.UTILITY.ROOT}/${ROUTES.UTILITY.CATALOG_MANAGER.ROOT}/${ROUTES.UTILITY.CATALOG_MANAGER.NEW_DEVICE}`
                  )
                }
              >
                New Device
              </Button>
            )}

            {showNewCatalogEntry && (
              <Button
                disabled={isEmpty(devices) || isEmpty(carriers)}
                size="small"
                variant="contained"
                startIcon={<MenuBook />}
                onClick={onNewCatalogEntry}
              >
                New Catalog Entry
              </Button>
            )}

            {showNewAccessory && (
              <Button
                size="small"
                variant="contained"
                startIcon={<Add />}
                onClick={onNewAccessory}
              >
                New Accessory Entry
              </Button>
            )}
          </div>
        </GridToolbarContainer>
      </div>
    </>
  );
}
