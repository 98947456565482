import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, Input, InputAdornment, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { DataTestIDs } from "@react-ms-apps/app/src/Constants/data-test-ids";
import { Container } from "@react-ms-apps/common";
import {
  CascadeHeader,
  NavCascadeItem,
} from "@react-ms-apps/common/components/NavBar/styled";
import { useNavigation } from "@react-ms-apps/common/providers";
import { NavData } from "@react-ms-apps/common/types/nav";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePageTitle } from "../../Providers/PageTitleProvider";

interface UtilityLink {
  href: string;
  link_name: string;
}

interface UtilityCategory {
  category: string;
  links: UtilityLink[];
}

type UtilityCategories = UtilityCategory[];

export default function UtilitiesList() {
  const { navData, isLoading } = useNavigation();
  const { setTitle } = usePageTitle();

  const [search, setSearch] = useState("");

  const utilityCategories = useMemo((): UtilityCategories => {
    const utilitiesGroup = navData.find((item) => item.id === "utilities");

    if (!utilitiesGroup) return [];

    const categories = utilitiesGroup["utility_categories"] as string[];
    const utilitiesGroupMap: NavData.MultiCascadeGroup = utilitiesGroup[
      "navbar_utilities"
    ] as NavData.MultiCascadeGroup;

    return categories.map((category) => {
      return {
        category,
        links: utilitiesGroupMap[category].map(
          (link: NavData.MultiCategoryItem) => {
            return {
              href: link.href,
              link_name: link.link_name,
            };
          }
        ),
      };
    });
  }, [navData]);

  const filterCategoryLink = useCallback(
    (link: UtilityLink) => {
      if (search === "") {
        return link;
      }

      // only show links that match the search term
      return link.link_name.toLowerCase().includes(search.toLowerCase());
    },
    [search]
  );

  const sortCategoryLink = useCallback((a: UtilityLink, b: UtilityLink) => {
    if (a.link_name < b.link_name) {
      return -1;
    }
    if (a.link_name > b.link_name) {
      return 1;
    }
    return 0;
  }, []);

  const filteredUtilityCategories = useMemo(() => {
    if (search === "") {
      return utilityCategories;
    }

    // only show categories that have at least one filtered utility link
    return utilityCategories.filter((category) => {
      return category.links.some((link) =>
        link.link_name.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [search, utilityCategories]);

  // set page title
  useEffect(() => {
    setTitle("Utilities");
  }, [setTitle]);

  return (
    <Container className="!flex flex-1 flex-col">
      {/* loading */}
      {isLoading && (
        <div className="flex flex-col items-center justify-center space-y-4 flex-1">
          <CircularProgress size={60} />
        </div>
      )}

      {/* search filter */}
      {!isLoading && (
        <div className="w-full md:w-1/2 my-6 mb-4">
          <FormControl variant="standard" fullWidth>
            <Input
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              id="utilities-list-search"
              data-testid={DataTestIDs.UTILITIES_LIST_SEARCH}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      )}

      {/* No links found message */}
      {!isLoading && filteredUtilityCategories.length === 0 && (
        <div
          className="flex flex-col items-center justify-center flex-1"
          data-testid={DataTestIDs.UTILITIES_LIST_EMPTY}
        >
          <p className="text-lg text-gray-900 dark:text-white">
            No utilities found.
          </p>

          {search.length > 0 && (
            <p className="text-gray-500 dark:text-gray-400">
              Try a different search term.
            </p>
          )}
        </div>
      )}

      {/* utilities links */}
      {!isLoading && filteredUtilityCategories.length > 0 && (
        <Paper className="mb-20">
          <div className="px-4 py-2">
            <div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
              data-testid={DataTestIDs.UTILITIES_LIST}
            >
              {filteredUtilityCategories.map((category) => (
                <div
                  key={category.category}
                  className="border-0 border-r border-gray-300 border-solid last-of-type:border-r-0 px-2"
                >
                  <CascadeHeader
                    variant="body1"
                    className="!mb-1 !mt-2 uppercase font-light"
                    data-testid={DataTestIDs.UTILITIES_LIST_CATEGORY}
                  >
                    {category.category}
                  </CascadeHeader>

                  <div className="grid grid-cols-1 space-y-2">
                    {category.links
                      .sort(sortCategoryLink)
                      .filter(filterCategoryLink)
                      .map((link) => (
                        <NavCascadeItem
                          key={link.link_name}
                          href={link.href}
                          data-testid={DataTestIDs.UTILITIES_LIST_ITEM_LINK}
                        >
                          {link.link_name}
                        </NavCascadeItem>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Paper>
      )}
    </Container>
  );
}
