import html2canvas from "html2canvas";
import { ByChartData, ChartDatasets, ChartType } from "../constants";

export async function captureChart({
  dataSet,
  chartType,
  byChartData,
  startDate,
  endDate,
}: {
  dataSet: ChartDatasets;
  chartType: ChartType;
  byChartData?: ByChartData;
  startDate: string;
  endDate: string;
}) {
  const chartElement = document.getElementById("chart-container");
  if (chartElement) {
    const canvas = await html2canvas(chartElement);
    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imgData;

    // produce a download file name based on the chart data
    let downloadFileName = "";
    if (dataSet) {
      downloadFileName += `${dataSet}__`;
    }
    if (chartType) {
      downloadFileName += `${chartType}__`;
    }
    if (byChartData) {
      downloadFileName += `${byChartData}__`;
    }
    if (startDate && endDate) {
      downloadFileName += `${startDate}_to_${endDate}`;
    }
    link.download = `${downloadFileName}.png`;

    link.click();
  }
}
