import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Box } from "@mui/material";
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GridActionsCellItem,
  GridAggregationFunction,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { PageContainer } from "@react-ms-apps/common";
import {
  BANCompanies,
  BANCompany,
  fetchBANCompanies,
  fetchBANCompanyByID,
  removeBANCompanyByID,
  updateBANCompany,
} from "@react-ms-apps/common/api/ban-companies";
import { useNav } from "@react-ms-apps/common/providers/NavProvider";
import * as Sentry from "@sentry/react";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { usePageTitle } from "../../Providers/PageTitleProvider";
import AddBANCompanyModal from "./AddBANCompanyModal";
import CompanyFilterProvider from "./CompanyFilterProvider";
import Toolbar from "./Toolbar";

const distinctAggregation: GridAggregationFunction<string, string | null> = {
  apply: (params) => {
    const uniqueValues = new Set(params.values);
    return uniqueValues.size.toString();
  },
  label: "distinct",
  columnTypes: ["singleSelect", "string"],
};

function BANCompaniesEditor() {
  const { navData } = useNav();
  const { setTitle } = usePageTitle();

  const [loadingBanCompanies, setLoadingBanCompanies] = useState(false);
  const [rows, setRows] = useState<BANCompanies>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [showAddModal, setShowAddModal] = useState(false);

  const companiesList = useMemo(() => {
    return (
      rows
        .map((row) => row.company_cost_ctr)
        // unique list
        .filter((value, index, self) => self.indexOf(value) === index)
        // sort alphabetically
        .sort((a, b) => a.localeCompare(b))
    );
  }, [rows]);

  const toggleAddModal = () => {
    setShowAddModal((prev) => !prev);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditStart = async (params: { id: GridRowId }) => {
    // fetch etag for the row
    const { id } = params;

    const { data, etag } = await fetchBANCompanyByID(id as number);

    const updatedRow = { ...data, etag };
    setRows((prevRows) => {
      return prevRows.map((prevRow) => {
        if (prevRow.ban_company_id === updatedRow.ban_company_id) {
          return updatedRow;
        }

        return prevRow;
      });
    });
  };

  const handleEditClick = useCallback(
    (id: GridRowId) => async () => {
      handleEditStart({ id });
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel]
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => async () => {
      try {
        await removeBANCompanyByID(id as number);
        setRows((prevRows) => {
          return prevRows.filter((prevRow) => {
            return prevRow.ban_company_id !== id;
          });
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    []
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const tableHeight = useMemo(() => {
    const appHeader = document.querySelector("#app-header");
    const appHeaderHeight = appHeader?.clientHeight ?? 0;

    const pageHeader = document.querySelector("#ban-companies-editor");
    const pageHeaderHeight = pageHeader?.clientHeight ?? 0;

    // set a default height
    if (!appHeader || !pageHeader) return 500;

    return window.innerHeight - (appHeaderHeight + pageHeaderHeight + 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navData]);

  const getBanCompanies = async () => {
    setLoadingBanCompanies(true);

    try {
      const banCompanies = await fetchBANCompanies();
      setRows(banCompanies);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingBanCompanies(false);
    }
  };

  const addBanCompany = (banCompany: BANCompany) => {
    // append new row to the end of the list
    setRows((prevRows) => {
      return [...prevRows, banCompany];
    });
  };

  const processRowUpdate = async (
    newRow: GridRowModel<BANCompany>,
    oldRow: GridRowModel<BANCompany>
  ) => {
    const updatedRow: GridRowModel<BANCompany> = { ...newRow };

    if (isEqual(updatedRow, oldRow)) return newRow;
    if (!updatedRow.etag) {
      toast.warn("etag is missing");
      return updatedRow;
    }

    try {
      await updateBANCompany(
        updatedRow.ban_company_id,
        updatedRow.company_cost_ctr,
        updatedRow.ban,
        updatedRow.etag
      );
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }

      Sentry.captureException(error);
    }

    return updatedRow;
  };

  const columns: GridColDef[] = useMemo((): GridColDef[] => {
    return [
      {
        field: "company_cost_ctr",
        headerName: "Company",
        width: 250,
        editable: true,
        type: "singleSelect",
        valueOptions: companiesList,
      },
      {
        field: "ban",
        headerName: "BAN #",
        width: 250,
        editable: true,
      },
      {
        field: "updated_at",
        headerName: "Last Updated",
        width: 250,
        editable: false,
        renderCell: ({ value }) => {
          return new Date(value as string).toLocaleString();
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                data-testid={`save-cell--${id}`}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                data-testid={`cancel-cell--${id}`}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              data-testid={`edit-cell--${id}`}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              data-testid={`delete-cell--${id}`}
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  }, [
    companiesList,
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    rowModesModel,
  ]);

  const initialSortModel: GridSortModel = useMemo(() => {
    return [
      {
        field: "company_cost_ctr",
        sort: "asc",
      },
    ];
  }, []);

  useEffect(() => {
    getBanCompanies();
  }, []);

  // set document page title
  useEffect(() => {
    setTitle("BAN/Company Editor");
  }, [setTitle]);

  return (
    <>
      <PageContainer
        header="BAN Companies Editor"
        description="This page allows you to manage BAN associated companies."
        utilityName="BAN Companies Editor"
        headerId="ban-companies-editor"
      >
        <Box
          sx={{
            height: tableHeight,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
            "& .MuiDataGrid-booleanCell[data-value='true']": {
              color: "success.main",
            },
            "& .MuiDataGrid-booleanCell[data-value='false']": {
              color: "error.main",
            },
          }}
        >
          <DataGridPremium
            loading={loadingBanCompanies}
            getRowId={(row) => row.ban_company_id}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            // checkboxSelection
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slots={{
              toolbar: () => (
                <Toolbar onAdd={toggleAddModal} companiesList={companiesList} />
              ),
            }}
            pinnedColumns={{
              right: ["actions"],
            }}
            initialState={{
              sorting: {
                sortModel: initialSortModel,
              },
            }}
            aggregationFunctions={{
              ...GRID_AGGREGATION_FUNCTIONS,
              distinctAggregation,
            }}
            processRowUpdate={processRowUpdate}
            // unstable_headerFilters={rows.length > 0}
          />
        </Box>
      </PageContainer>

      {showAddModal && (
        <AddBANCompanyModal
          onClose={toggleAddModal}
          onAdd={addBanCompany}
          companiesList={companiesList}
          banCompanies={rows}
        />
      )}
    </>
  );
}

export default function BANCompaniesEditorWrapper() {
  return (
    <CompanyFilterProvider>
      <BANCompaniesEditor />
    </CompanyFilterProvider>
  );
}
