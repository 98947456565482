import axios, { AxiosProgressEvent } from "axios";
import { buildApiUrl } from "../../utils";

export interface UploadStatement {
  file_upload_id: number;
  file_name: string;
  carrier_id: number;
  foundation_account: string;
  invoice_date: string;
  invoice_total: number;
  file_upload_status_id: number;
}

export interface StatementUploadResponse {
  expected_pages: number;
  file_upload_status_id: number;
  foundation_account: string;
  upload_timestamp: string;
  file_name: string;
  md5sum: string;
  invoice_date: string;
  carrier_id: number;
  invoice_total: number;
  pdf_pages: number;
  file_upload_id: number;
  carrier_account_number: string;
  upload_user_id: number;
  has_detail: boolean;

  error_type?: string;
  errors?: string[];
}

export interface StatementUploadResponseMap {
  [key: string]: StatementUploadResponse;
}

export async function uploadStatementPDF(
  file: File,
  {
    onUploadProgress,
  }: {
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  } = {
    onUploadProgress: () => {},
  }
) {
  const formData = new FormData();
  formData.append("files", file);

  const resp = await axios.post<StatementUploadResponseMap>(
    buildApiUrl("api/pdf_uploads"),
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }
  );

  return resp.data;
}

export interface PDFUpload {
  file_upload_status_id: number;
  foundation_account: string;
  upload_timestamp: string;
  file_name: string;
  md5sum: string;
  invoice_date: string;
  carrier_id: number;
  invoice_total: number;
  file_upload_id: number;
  carrier_account_number: string;
  upload_user_id: number;
  file_upload_status: FileUploadStatus;
}

export interface FileUploadStatus {
  file_upload_status_id: number;
  file_upload_status: string;
}

export async function fetchPDFUploads() {
  const resp = await axios.get<PDFUpload[]>(buildApiUrl("api/pdf_uploads"));

  return resp.data;
}

export async function fetchPDFUploadStatuses() {
  const resp = await axios.get<FileUploadStatus[]>(
    buildApiUrl("api/static_data/file_upload_statuses")
  );

  return resp.data;
}

export async function deletePDFUpload(fileUploadId: number) {
  await axios.delete(buildApiUrl(`api/pdf_uploads/${fileUploadId}`));
}

export async function fetchUploadStatuses() {
  const resp = await axios.get<FileUploadStatus[]>(
    buildApiUrl("api/static_data/file_upload_statuses")
  );

  return resp.data;
}

export async function submitPDFUploads(
  fileUploadIds: number[],
  uploadStatusId: number
) {
  const data = {
    new_state: {
      file_upload_status_id: uploadStatusId,
    },
    file_upload_ids: fileUploadIds,
  };

  const resp = await axios.put<{
    [key: number]: boolean;
  }>(buildApiUrl("api/pdf_uploads"), data);

  // return true if all uploads were successful
  return Object.values(resp.data).every((success) => success);
}

interface PurgePDFUploadsResponse {
  user_id?: any;
  option_value: string;
  ms_option_id: number;
  carrier_id?: any;
  ms_valid_option_id: number;
  company_cost_ctr?: any;
}

export async function purgePDFUploads() {
  const response = await axios.post<PurgePDFUploadsResponse>(
    buildApiUrl("api/ms_options"),
    {
      option_name: "diy_purgeable",
      option_value: true,
      cost_ctr: null,
      carrier_id: null,
      user_id: null,
    }
    // {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   withCredentials: true,
    // }
  );

  return response.data.option_value === "true";
}

export async function fetchDIYPurgable(): Promise<boolean> {
  const resp = await axios.get(buildApiUrl("api/nc/option_values"), {
    params: {
      option_name: "diy_purgeable",
    },
  });

  return !!resp.data.option_value;
}
