import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Portal,
  TextField,
} from "@mui/material";
import { removeWhitespace } from "@react-ms-apps/common";
import {
  BANCompany,
  createBANCompany,
} from "@react-ms-apps/common/api/ban-companies";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { toast } from "react-toastify";

interface AddBANCompanyModalProps {
  onClose: () => void;
  companiesList: string[];
  onAdd: (banCompany: BANCompany) => void;
  banCompanies: BANCompany[];
}

export default function AddBANCompanyModal({
  onClose,
  companiesList,
  onAdd,
  banCompanies,
}: AddBANCompanyModalProps) {
  const [banNumber, setBanNumber] = useState("");
  const [company, setCompany] = useState("");

  const [addModalSaving, setAddModalSaving] = useState(false);

  const handleAddSave = async () => {
    setAddModalSaving(true);

    try {
      const { data: banCompany } = await createBANCompany(company, banNumber);
      onAdd(banCompany);
      onClose();
    } catch (error) {
      Sentry.captureException(error);

      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setAddModalSaving(false);
    }
  };

  const banCompanyExists = banCompanies.some(
    (banCompany) =>
      banCompany.ban === banNumber.trim() &&
      banCompany.company_cost_ctr === company
  );

  const formIsFilled = Boolean(!!banNumber && !!company);

  return (
    <Portal>
      <Dialog open={true} onClose={onClose}>
        {/* create modal with two inputs: "BAN #" and "Company". Both inputs are text. The modal and inputs should use mui */}
        <DialogTitle>Add BAN Company</DialogTitle>

        <DialogContent dividers className="flex flex-col gap-2">
          <DialogContentText>
            To add a BAN Company association, please enter the BAN # and the
            associated Company name.
          </DialogContentText>

          <Autocomplete
            fullWidth
            freeSolo
            className="mb-2"
            options={companiesList}
            onChange={(e, value) => setCompany(value || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                variant="outlined"
                onChange={(e) => setCompany(e.target.value)}
                disabled={addModalSaving}
                InputLabelProps={{ shrink: true }}
                placeholder="Company Name"
              />
            )}
            disabled={addModalSaving}
          />

          <TextField
            fullWidth
            label="BAN #"
            variant="outlined"
            className="!mb-2"
            onChange={(e) => setBanNumber(e.target.value)}
            onBlur={(e) => setBanNumber(removeWhitespace(e.target.value))}
            value={banNumber}
            disabled={addModalSaving}
            InputLabelProps={{ shrink: true }}
            placeholder="123456789-00001"
          />

          {banCompanyExists && (
            <Alert severity="error">
              This BAN Company association already exists.
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <div className="flex flex-row justify-end w-full gap-2">
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              disabled={addModalSaving || !formIsFilled || banCompanyExists}
              loading={addModalSaving}
              variant="contained"
              color="primary"
              onClick={handleAddSave}
            >
              Save
            </LoadingButton>
          </div>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
