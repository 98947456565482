import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useRowEtagContext } from "@react-ms-apps/app/src/Providers/RowEtagContext";
import { UtilityCategoryDTO, UtilityLinkDTO } from "@react-ms-apps/common";
import { updateUtilityByID } from "@react-ms-apps/common/api/utilities";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ETAG_ERROR_MESSAGE } from "../constants";

interface CategorySelectionProps extends UtilityLinkDTO {
  categories: UtilityCategoryDTO[];
}

export default function CategorySelection({
  utility_id,
  utility_category_id,
  categories,
  utility_name,
  custom_utility_name,
}: CategorySelectionProps) {
  // select input using mui
  const [category, setCategory] = useState<number>(utility_category_id);

  const { etag, setEtag } = useRowEtagContext();

  const handleChange = async (event: SelectChangeEvent<number>) => {
    const updatedCategory = event.target.value as number;
    setCategory(updatedCategory);

    try {
      const { etag: updatedEtag } = await updateUtilityByID(
        utility_id,
        "utility_category_id",
        updatedCategory,
        etag
      );

      // update etag
      setEtag(updatedEtag);

      // update utility category
      toast.success(
        `Category updated for ${custom_utility_name || utility_name}`,
        {
          autoClose: 1000,
        }
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 412) {
        toast.error(ETAG_ERROR_MESSAGE);
        return;
      }

      Sentry.captureException(error);
      toast.error(`Error updating ${utility_name}`);
    }
  };

  const categoryOptions = categories.map(
    (category) => category.utility_category_id
  );

  const categoryIDToNameMap = useMemo(() => {
    const categoryIDToNameMap: {
      [key: number]: string;
    } = {};

    categories.forEach((category) => {
      categoryIDToNameMap[category.utility_category_id] =
        category.utility_category;
    });

    return categoryIDToNameMap;
  }, [categories]);

  return (
    <FormControl fullWidth>
      <InputLabel id={`category-selection::${utility_id}`}>Category</InputLabel>
      <Select
        labelId={`category-selection::${utility_id}`}
        id={`catgory-selection-input::${utility_id}`}
        value={category}
        label="Category"
        onChange={handleChange}
      >
        {categoryOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {categoryIDToNameMap[option]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
