import {
  fetchCopyrightRemoved,
  isBadClientError,
  triggerNotFoundPage,
} from "@react-ms-apps/common";
import SupportDialog from "@react-ms-apps/common/components/SupportDialog";
import "@react-ms-apps/common/config/axios";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import "./index.css";

export default function SupportLinkIconButton({
  isBootstrapped = false,
}: {
  isBootstrapped?: boolean;
}) {
  const [showSupportDialog, setShowSupportDialog] = useState<boolean>(false);
  const [hideCopy, setHideCopy] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const getHasCopyright = async () => {
    try {
      const copyrightRemoved = await fetchCopyrightRemoved();
      setHideCopy(copyrightRemoved);
    } catch (error) {
      isBadClientError(error) && triggerNotFoundPage({ badClient: true });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    getHasCopyright();
  }, []);

  return (
    <span style={{ position: "relative" }}>
      <img
        style={{
          cursor: "pointer",
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        alt="Support"
        src="/static/app/img/iconPerson.png"
        onClick={() => setShowSupportDialog(true)}
      />

      {showTooltip && (
        <div
          className="popover top fade in"
          style={{
            display: "block",
            top: -70,
            left: -25,
          }}
        >
          <div className="arrow" />
          <div
            style={{
              padding: 10,
              color: "#23527c",
            }}
            className="popover-inner"
          >
            {hideCopy ? "Trouble Ticket" : "Support"}
          </div>
        </div>
      )}

      {showSupportDialog && (
        <SupportDialog
          isBootstrapped={isBootstrapped}
          onClose={() => setShowSupportDialog(false)}
        />
      )}
    </span>
  );
}
