import { CircularProgress, Typography } from "@mui/material";
import { fetchLandingPage, getClientDB } from "@react-ms-apps/common";
import { useAuth } from "@react-ms-apps/common/providers";
import { goToPage } from "@react-ms-apps/common/utils/url";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

export default function LoginRedirect() {
  const clientDB = getClientDB();
  const { isAuthenticated } = useAuth();

  const [landingPage, setLandingPage] = useState<string>("");

  const getLandingPage = async () => {
    try {
      const landingPage = await fetchLandingPage();
      setLandingPage(landingPage);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    getLandingPage();
  }, []);

  useEffect(() => {
    if (landingPage) {
      goToPage(`${window.location.origin}${landingPage}`);
    }
  }, [landingPage]);

  useEffect(() => {
    if (!isAuthenticated) {
      goToPage(`${window.location.origin}/${clientDB}/login`);
    }
  }, [clientDB, isAuthenticated]);

  return (
    <main className="flex flex-1 flex-col items-center justify-center gap-4">
      <Typography variant="h4" align="center" className="text-primary-800">
        Authenticated
      </Typography>

      <CircularProgress data-testid="login-loading" size={60} />

      <Typography variant="h6" align="center" className="text-primary-800">
        Redirecting...
      </Typography>
    </main>
  );
}
