import { Check, Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useCallback } from "react";

interface PasswordRequirementProps {
  isValid: boolean;
  isMet: boolean;
  text: string;
}

export default function PasswordRequirement({
  isMet,
  isValid,
  text,
}: PasswordRequirementProps) {
  const Icon = useCallback(() => {
    if (isMet && isValid) {
      return <Check color={"success"} fontSize="medium" />;
    }
    if (isMet) {
      return <Close color={"warning"} fontSize="medium" />;
    }

    return <Close fontSize="medium" />;
  }, [isMet, isValid]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        bgcolor: "background.paper",
        borderRadius: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 0.5,
      }}
    >
      <Icon />
      {text && (
        <Typography fontSize="body" sx={{ paddingLeft: 1 }}>
          {text}
        </Typography>
      )}
    </Box>
  );
}
