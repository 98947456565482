import { ROUTES } from "@react-ms-apps/common";
import React, { useMemo } from "react";
import { Location, useLocation } from "react-router-dom";

interface ILocationContext extends Location {
  isLoginRoute: boolean;
  isUnauthenticatedRoute: boolean;
}

export const LocationContext = React.createContext<ILocationContext>({
  isLoginRoute: false,
  isUnauthenticatedRoute: false,
  pathname: "",
  search: "",
  hash: "",
  state: null,
  key: "",
});

export const useLocationContext = () => React.useContext(LocationContext);

export const LocationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();

  const pathname = location.pathname;

  const isLoginRoute = useMemo(() => {
    return (
      pathname.startsWith(ROUTES.LOGIN.HOME) ||
      pathname.startsWith(ROUTES.LOGIN.FORGOT_PASSWORD) ||
      pathname.startsWith(ROUTES.LOGIN.SENT_PASSWORD_RESET) ||
      pathname.startsWith(ROUTES.LOGIN.PASSWORD_RESET)
    );
  }, [pathname]);

  const isUnauthenticatedRoute = useMemo(() => {
    if (pathname.includes(ROUTES.ORDER_APPROVAL)) {
      return true;
    }

    if (pathname === ROUTES.NOT_FOUND || pathname === ROUTES.NOT_FOUND_CLIENT) {
      return true;
    }

    return isLoginRoute;
  }, [isLoginRoute, pathname]);

  return (
    <LocationContext.Provider
      value={{ ...location, isLoginRoute, isUnauthenticatedRoute }}
    >
      {children}
    </LocationContext.Provider>
  );
};
