import {
  Button,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaChartBar, FaChartLine } from "react-icons/fa";
import { IoBarChart } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { getStatementMonths, getUsageAndCostData, UsageAndCost } from "./api";
import {
  ByChartData,
  ChartDatasets,
  ChartType,
  CostCategoryMap,
} from "./constants";
import CostByCostCenterBarChart from "./CostByCostCenterBarChart";
import DataAndUsageCostsChart from "./DataAndUsageCostsChart";
import { ChartEvents } from "./events";

export default function ChartsDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [selectedDataset, setSelectedDataset] = useState<ChartDatasets>(
    (queryParams.get("dataset") as ChartDatasets) || ChartDatasets.TotalCost
  );
  const [startDate, setStartDate] = useState(
    queryParams.get("startDate") || ""
  );
  const [endDate, setEndDate] = useState(queryParams.get("endDate") || "");
  const [statementMonths, setStatementMonths] = useState<string[]>([]);
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    (queryParams.get("chartType") as ChartType) || ChartType.BAR
  );
  const [usageAndCostData, setUsageAndCostData] = useState<UsageAndCost[]>([]);
  const [usageAndCostDataMonthMap, setUsageAndCostDataMonthMap] = useState<{
    [monthCombo: string]: UsageAndCost[];
  }>({});
  const [loadingData, setLoadingData] = useState(false);

  const updateQueryParams = useCallback(() => {
    const newParams = new URLSearchParams();
    if (startDate) newParams.set("startDate", startDate);
    if (endDate) newParams.set("endDate", endDate);
    newParams.set("dataset", selectedDataset);
    newParams.set("chartType", selectedChartType);
    navigate({ search: newParams.toString() }, { replace: true });
  }, [startDate, endDate, selectedDataset, selectedChartType, navigate]);

  // Determine the last available statement month
  const lastStatementMonth = useMemo(() => {
    if (statementMonths.length === 0) return "";
    return moment
      .max(statementMonths.map((month) => moment(month, "YYYY-MM")))
      .format("YYYY-MM");
  }, [statementMonths]);

  // Determine the first available statement month
  const firstStatementMonth = useMemo(() => {
    if (statementMonths.length === 0) return "";
    return moment
      .min(statementMonths.map((month) => moment(month, "YYYY-MM")))
      .format("YYYY-MM");
  }, [statementMonths]);

  const handleGetUsageAndCostData = useCallback(async () => {
    // Return early if startDate or endDate is missing
    if (!startDate || !endDate) {
      return;
    }

    const formattedEndDate = moment(endDate, "YYYY-MM").format("YYYY-MM-01");
    const maxMonths =
      moment(endDate, "YYYY-MM").diff(moment(startDate, "YYYY-MM"), "months") +
      1;

    const cacheKey = `${startDate}_${endDate}`;
    if (usageAndCostDataMonthMap[cacheKey]) {
      // Using cached data
      setUsageAndCostData(usageAndCostDataMonthMap[cacheKey]);
      return;
    }

    setUsageAndCostData([]);

    try {
      setLoadingData(true);

      const resp = await getUsageAndCostData(formattedEndDate, maxMonths);

      setUsageAndCostData(resp.usage_and_cost);

      setUsageAndCostDataMonthMap((prevMap) => ({
        ...prevMap,
        [cacheKey]: resp.usage_and_cost,
      }));
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingData(false);
    }
  }, [startDate, endDate, usageAndCostDataMonthMap]);

  const handleDatasetChange = (event: SelectChangeEvent<ChartDatasets>) => {
    setSelectedDataset(event.target.value as ChartDatasets);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);

    // Ensure end date is at least one month after start date
    const minEndDate = moment(newStartDate).add(1, "month").format("YYYY-MM");
    if (moment(endDate).isBefore(minEndDate)) {
      setEndDate(minEndDate);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);

    // Ensure start date is at least one month before end date
    const maxStartDate = moment(newEndDate)
      .subtract(1, "month")
      .format("YYYY-MM");
    if (moment(startDate).isAfter(maxStartDate)) {
      setStartDate(maxStartDate);
    }
  };

  const handleChartTypeChange = (event: SelectChangeEvent<ChartType>) => {
    setSelectedChartType(event.target.value as ChartType);
  };

  const handleGetStatementMonths = useCallback(async () => {
    try {
      const resp = await getStatementMonths();

      const statementMonths = resp.all_months
        .map((item) => item.statement_month)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      setStatementMonths(statementMonths);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const handleExportData = () => {
    window.dispatchEvent(new Event(ChartEvents.ExportData));
  };

  const handleExportAsImage = () => {
    window.dispatchEvent(new Event(ChartEvents.ExportImage));
  };

  const yearsList = useMemo(() => {
    if (!firstStatementMonth || !lastStatementMonth) return [];

    const firstDate = moment(firstStatementMonth, "YYYY-MM");
    const lastDate = moment(lastStatementMonth, "YYYY-MM");

    const firstYear = firstDate.year();
    const lastYear = lastDate.year();

    const years = [];
    for (let year = firstYear; year < lastYear; year++) {
      // add the difference between the last year and the current year to the first year
      years.push(year - firstYear + 1);
    }

    // Check if the last year is a complete year
    if (lastDate.month() === 11) {
      years.push(lastYear - firstYear);
    }

    return years;
  }, [firstStatementMonth, lastStatementMonth]);

  const costFieldNames: string[] = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.TotalCost:
        return ["total_charges"];

      case ChartDatasets.EquipmentCost:
        return ["equipment_total"];

      case ChartDatasets.ServiceCost:
        return ["total_charges"];

      case ChartDatasets.InternationalCosts:
        return ["international_total"];

      case ChartDatasets.DataOverageCosts:
        return ["kb_charges"];

      case ChartDatasets.CostByCategory:
        return Object.keys(CostCategoryMap);

      default:
        return [];
    }
  }, [selectedDataset]);

  const costMinusFieldNames: string[] = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.ServiceCost:
        return ["equipment_total"];

      case ChartDatasets.DataOverageCosts:
        return ["intl_data_roam_charges"];

      default:
        return [];
    }
  }, [selectedDataset]);

  const byChartDataOptions: ByChartData[] = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.CostByCategory:
        return [ByChartData.COST_CATEGORIES];

      case ChartDatasets.CostByCostCenter:
        return [ByChartData.COST_BY_COST_CENTER];

      default:
        return [ByChartData.CARRIER, ByChartData.DEVICE];
    }
  }, [selectedDataset]);

  const allowedChartTypes = useMemo(() => {
    switch (selectedDataset) {
      case ChartDatasets.CostByCategory:
      case ChartDatasets.CostByCostCenter:
        return [ChartType.BAR, ChartType.CLUSTERED_BAR];

      default:
        return [ChartType.BAR, ChartType.CLUSTERED_BAR, ChartType.LINE];
    }
  }, [selectedDataset]);

  useEffect(() => {
    handleGetStatementMonths();
  }, [handleGetStatementMonths]);

  useEffect(() => {
    if (statementMonths.length > 0 && !startDate) {
      setStartDate(firstStatementMonth);
    }
  }, [statementMonths, firstStatementMonth, startDate]);

  // Fetch data when startDate or endDate changes
  useEffect(() => {
    if (startDate && endDate) {
      handleGetUsageAndCostData();
    }
  }, [
    startDate,
    endDate,
    selectedDataset,
    selectedChartType,
    handleGetUsageAndCostData,
  ]);

  useEffect(() => {
    if (statementMonths.length && !endDate) {
      setEndDate(lastStatementMonth);
    }
  }, [statementMonths, lastStatementMonth, endDate]);

  useEffect(() => {
    updateQueryParams();
  }, [
    startDate,
    endDate,
    selectedDataset,
    selectedChartType,
    updateQueryParams,
  ]);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex border border-l-0 border-r-0 border-primary-800 border-solid flex-row px-4 items-center gap-2 justify-between">
        <div className="flex flex-row gap-2 items-end pt-2">
          <TextField
            label="Start Billing Date"
            type="month"
            value={startDate}
            onChange={handleStartDateChange}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              htmlInput: {
                min: firstStatementMonth,
                max: moment(endDate).subtract(1, "month").format("YYYY-MM"),
              },
            }}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <TextField
            label="End Billing Date"
            type="month"
            value={endDate}
            onChange={handleEndDateChange}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              htmlInput: {
                min: moment(startDate).add(1, "month").format("YYYY-MM"),
                max: lastStatementMonth,
              },
            }}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <div className="pb-2">
            <InputLabel id="cost-select-label" className="!text-xs">
              Dataset
            </InputLabel>
            <Select
              labelId="cost-select-label"
              value={selectedDataset}
              onChange={handleDatasetChange}
              label="Dataset"
            >
              {Object.values(ChartDatasets).map((dataset) => (
                <MenuItem key={dataset} value={dataset}>
                  {dataset}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="pb-2">
            <InputLabel id="chart-type-label" className="!text-xs">
              Chart Type
            </InputLabel>
            <Select
              labelId="chart-type-label"
              value={selectedChartType}
              onChange={handleChartTypeChange}
              label="Dataset"
              sx={{
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {allowedChartTypes.includes(ChartType.BAR) && (
                <MenuItem value={ChartType.BAR}>
                  <ListItemIcon className="mr-2">
                    <IoBarChart size={20} />
                  </ListItemIcon>
                  <span>Bar</span>
                </MenuItem>
              )}

              {allowedChartTypes.includes(ChartType.CLUSTERED_BAR) && (
                <MenuItem value={ChartType.CLUSTERED_BAR}>
                  <ListItemIcon className="mr-2">
                    <FaChartBar size={20} />
                  </ListItemIcon>
                  <span>Clustered</span>
                </MenuItem>
              )}

              {allowedChartTypes.includes(ChartType.LINE) && (
                <MenuItem value={ChartType.LINE}>
                  <ListItemIcon className="mr-2">
                    <FaChartLine size={20} />
                  </ListItemIcon>
                  <span>Line</span>
                </MenuItem>
              )}
            </Select>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleExportData}
          >
            Export Data
          </Button>

          {/* Add export as image button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportAsImage}
          >
            Export as Image
          </Button>
        </div>
      </div>

      {[
        ChartDatasets.TotalCost,
        ChartDatasets.EquipmentCost,
        ChartDatasets.ServiceCost,
        ChartDatasets.InternationalCosts,
        ChartDatasets.DataOverageCosts,
        ChartDatasets.CostByCategory,
      ].includes(selectedDataset) && (
        <DataAndUsageCostsChart
          startDate={startDate}
          endDate={endDate}
          selectedChartType={selectedChartType}
          usageAndCostData={usageAndCostData}
          isLoading={loadingData}
          fieldNames={costFieldNames}
          minusFieldNames={costMinusFieldNames}
          byChartDataOptions={byChartDataOptions}
          dataSet={selectedDataset}
        />
      )}

      {selectedDataset === ChartDatasets.CostByCostCenter && (
        <CostByCostCenterBarChart
          startDate={startDate}
          endDate={endDate}
          chartType={selectedChartType}
        />
      )}
    </div>
  );
}
