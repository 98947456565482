import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { useMemo, useState } from "react";
import { UploadProcessorConfigField } from "./api";

interface ChooseFileDialogProps {
  open: boolean;
  onClose: () => void;
  instructions: string;
  fields: UploadProcessorConfigField[];
  file: File | null;
  onChange: (file: File | null) => void;
}

export default function ChooseFileDialog({
  open,
  onClose,
  instructions,
  fields,
  file = null,
  onChange,
}: ChooseFileDialogProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(file);

  const showNotesColumn = useMemo(() => {
    return fields.some((field) => field.description);
  }, [fields]);

  const handleCancel = () => {
    setSelectedFile(null);
    onClose();
  };

  const handleConfirm = () => {
    onChange(selectedFile);

    setSelectedFile(null);
    onClose();
  };

  return (
    <Portal>
      <Dialog open={open} onClose={onClose} data-testid="choose-file-dialog">
        <DialogTitle>Choose File</DialogTitle>

        <DialogContent dividers>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Upload Instructions</Typography>
            </AccordionSummary>
            {instructions && (
              <AccordionDetails>
                <Typography marginBottom={2}>{instructions}</Typography>
              </AccordionDetails>
            )}
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Available CSV Fields</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Display the upload format and fields in a table format in bootstrap */}
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell>Required</TableCell>
                      {
                        // display the notes if any
                        showNotesColumn && <TableCell>Notes</TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((field) => (
                      // Indicate required fields with red background color
                      <TableRow key={field.name}>
                        <TableCell>{field.name}</TableCell>
                        <TableCell
                          className={
                            field.required ? "!text-red-600" : "!text-gray-400"
                          }
                        >
                          {field.required ? "Required" : "Optional"}
                        </TableCell>
                        {
                          // display the notes if any
                          showNotesColumn && (
                            <TableCell>{field.description}</TableCell>
                          )
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <div className="mt-8 flex flex-row justify-between">
            <MuiFileInput
              data-testid="file-input"
              id="file-input"
              label="Select File"
              onChange={setSelectedFile}
              required
              inputProps={{
                accept: ".csv, ",
              }}
              value={selectedFile}
            />
            <Button variant="outlined" onClick={() => setSelectedFile(null)}>
              Clear
            </Button>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleConfirm}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
