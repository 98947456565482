import { isAxiosError } from "axios";

export function isBadClientError(err: any): boolean {
  if (!(err instanceof Error)) {
    return false;
  }

  if (isAxiosError(err)) {
    const status: number = err.response?.status || 0;
    const message: string = err.response?.data || "";
    if (
      status === 409 &&
      message.includes(
        "That customer name is invalid in our config at the moment"
      )
    ) {
      return true;
    }
  }

  return false;
}
