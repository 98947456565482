import { CopyAll } from "@mui/icons-material";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  Device,
  DeviceList,
  PDAStyle,
  fetchDeviceColors,
  fetchDeviceItems,
  fetchPDAStyles,
} from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import TabPanel from "../CustomTabPanel";
import { EditDeviceTabEnum } from "./constants";

interface DeviceTabProps {
  activeTab: number;
  device: Device;
  updatedDevice: Device;
  onUpdateDevice: (device: Device) => void;
  deviceColors: string[];
  updateDeviceColors: (colors: string[]) => void;
}

export default function DeviceTab({
  activeTab,
  device,
  updatedDevice,
  onUpdateDevice,
  deviceColors: colors,
  updateDeviceColors: setColors,
}: DeviceTabProps) {
  const [pdaStyles, setPDAStyles] = useState<PDAStyle[]>([]);
  const [availableDeviceColors, setAvailableDeviceColors] = useState<string[]>(
    []
  );
  const [availableDevices, setAvailableDevices] = useState<DeviceList>([]);

  const manufacturerOptions = useMemo(() => {
    return (
      availableDevices
        .map((device) => device.manufacturer)
        // remove duplicates
        .filter((value, index, self) => self.indexOf(value) === index)
        // sort in alphabetical order
        .sort((a, b) => a.localeCompare(b))
        // remove empty items
        .filter((value) => value)
    );
  }, [availableDevices]);

  const getDeviceColors = useCallback(async () => {
    try {
      const data = await fetchDeviceColors();
      setAvailableDeviceColors(data);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const getPDAStyles = useCallback(async () => {
    try {
      const data = (await fetchPDAStyles())
        // sort alphabetically
        .sort((a, b) => a.pda_style_name.localeCompare(b.pda_style_name));

      setPDAStyles(data);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const getAvailableDevices = useCallback(async () => {
    try {
      const data = await fetchDeviceItems();
      setAvailableDevices(data);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  // load device colors and PDA styles
  useEffect(() => {
    getDeviceColors();
    getPDAStyles();
    getAvailableDevices();
  }, [getAvailableDevices, getDeviceColors, getPDAStyles]);

  return (
    <TabPanel
      value={activeTab}
      index={EditDeviceTabEnum.DEVICE}
      className="flex flex-1 flex-col gap-4 pt-4"
    >
      {!!device?.device_id && (
        <TextField
          fullWidth
          label="Device ID"
          value={device?.device_id}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      device?.device_id?.toString() || ""
                    );
                    toast.success("Device ID copied to clipboard");
                  }}
                >
                  <CopyAll />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      <Autocomplete
        options={manufacturerOptions}
        value={updatedDevice.manufacturer}
        onChange={(e, value) => {
          if (!value) {
            return;
          }

          onUpdateDevice({
            ...updatedDevice,
            manufacturer: value,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Manufacturer"
            onChange={(e) => {
              const value = e.target.value;

              if (!value) {
                return;
              }

              onUpdateDevice({
                ...updatedDevice,
                manufacturer: value,
              });
            }}
          />
        )}
        freeSolo
        loading={manufacturerOptions.length === 0}
      />

      <TextField
        label="Model"
        value={updatedDevice.model}
        onChange={(e) =>
          onUpdateDevice({
            ...updatedDevice,
            model: e.target.value,
          })
        }
        required
      />
      <TextField
        label="Short Description"
        multiline
        value={updatedDevice.short_description}
        onChange={(e) =>
          onUpdateDevice({
            ...updatedDevice,
            short_description: e.target.value,
          })
        }
      />
      <TextField
        label="Long Description"
        multiline
        rows={4}
        value={updatedDevice.long_description}
        onChange={(e) =>
          onUpdateDevice({
            ...updatedDevice,
            long_description: e.target.value,
          })
        }
      />
      <FormControl>
        <InputLabel id="pda-style-label" required>
          PDA Style
        </InputLabel>
        <Select
          required
          labelId="pda-style-label"
          id="pda-style"
          label="PDA Style"
          defaultValue={updatedDevice?.pda_style_id}
          onChange={(e) =>
            onUpdateDevice({
              ...updatedDevice,
              pda_style_id: e.target.value as number,
            })
          }
        >
          {pdaStyles.map((pdaStyle) => (
            <MenuItem key={pdaStyle.pda_style_id} value={pdaStyle.pda_style_id}>
              {pdaStyle.pda_style_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {availableDeviceColors && (
        <div>
          <InputLabel id="device-colors-label">Device Colors</InputLabel>
          <div className="grid grid-cols-3">
            {availableDeviceColors.map((availableColor) => (
              <div key={availableColor} className="flex items-center">
                <Switch
                  checked={colors.includes(availableColor)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setColors([...colors, availableColor]);
                    } else {
                      setColors(colors.filter((c) => c !== availableColor));
                    }
                  }}
                />

                <Typography>{availableColor}</Typography>
              </div>
            ))}
          </div>
        </div>
      )}
    </TabPanel>
  );
}
