import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { MDMAction, createMDMAction } from "./api";

export default function CreateActionDialog({
  onClose,
  onCreated,
}: {
  onClose: () => void;
  onCreated: (action: MDMAction) => void;
}) {
  const [name, setName] = useState("");
  const [displayOrder, setDisplayOrder] = useState(1);

  const handleSubmit = async () => {
    try {
      const action = await createMDMAction({
        action: name,
        display_order: displayOrder,
        active: true,
      });
      onCreated(action);
      toast.success("Action created");
      onClose();
    } catch (error) {
      toast.error("Failed to create action");
      Sentry.captureException(error);
    }
  };

  return (
    <Dialog onClose={onClose} open>
      <DialogTitle>Create Action</DialogTitle>
      <DialogContent dividers>
        <div>
          <TextField
            fullWidth
            label="Action Name"
            placeholder="Enter a name for the action"
            margin="normal"
            variant="outlined"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            fullWidth
            label="Display Order"
            placeholder="Enter a display order for the action"
            margin="normal"
            variant="outlined"
            type="number"
            defaultValue={displayOrder}
            onChange={(e) => setDisplayOrder(Number(e.target.value))}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning">
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}
