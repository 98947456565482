import { Portal } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  CatalogAccessory,
  DeviceList,
  DeviceListItem,
} from "@react-ms-apps/common/api/catalog-manager";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useCatalogManager } from "../CatalogManagerProvider";

export default function LinkAccessoriesDialog({
  onClose,
  devices,
}: {
  onClose: () => void;
  devices: DeviceList;
}) {
  const { linkDeviceAccessory, catalogAccessories } = useCatalogManager();

  const [isSaving, setIsSaving] = useState(false);
  const [selectedAccessories, setSelectedAccessories] = useState<
    CatalogAccessory[]
  >([]);
  const [selectedDevices, setSelectedDevices] = useState<DeviceList>([]);

  const [accessoryAutocompleteValue, setAccessoryAutocompleteValue] = useState<{
    label: string;
    value: CatalogAccessory | null;
  }>({
    label: "",
    value: null,
  });

  const [accessorySearch, setAccessorySearch] = useState("");
  const [deviceSearch, setDeviceSearch] = useState("");

  const [deviceAutocompleteValue, setDeviceAutocompleteValue] = useState<{
    label: string;
    value: DeviceListItem | null;
  }>({
    label: "",
    value: null,
  });

  const handleLinking = useCallback(async () => {
    setIsSaving(true);

    try {
      // call link for each device / accessory pair
      for (let i = 0; i < selectedAccessories.length; i++) {
        const accessory = selectedAccessories[i];

        for (let i = 0; i < selectedDevices.length; i++) {
          const device = selectedDevices[i];

          await linkDeviceAccessory(
            accessory.catalog_accessory_id,
            device.device_id
          );
        }
      }

      toast.success("Accessories linked successfully");
      onClose();
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to link accessories");
    } finally {
      setIsSaving(false);
    }
  }, [linkDeviceAccessory, onClose, selectedAccessories, selectedDevices]);

  const getDeviceLabel = useCallback((device: DeviceListItem) => {
    return `${device.manufacturer} - ${device.model}`;
  }, []);

  const catalogAccessoryOptions = useMemo(() => {
    if (isEmpty(catalogAccessories)) {
      return [];
    }

    if (!accessorySearch) {
      return [];
    }

    // return top 5 accessories that match the search
    return (
      catalogAccessories
        .map((accessory) => ({
          label: accessory.accessory_name,
          value: accessory,
        }))
        .filter((accessory) =>
          accessory.label.toLowerCase().includes(accessorySearch.toLowerCase())
        )
        // filter out already selected accessories
        .filter(
          (accessory) =>
            !selectedAccessories.some(
              (sa) =>
                sa.catalog_accessory_id === accessory.value.catalog_accessory_id
            )
        )
        .slice(0, 5)
    );
  }, [accessorySearch, catalogAccessories, selectedAccessories]);

  const deviceOptions = useMemo(() => {
    if (!deviceSearch) {
      return [];
    }

    // return top 5 devices that match the search
    return (
      devices
        .filter((device) =>
          getDeviceLabel(device)
            .toLowerCase()
            .includes(deviceSearch.toLowerCase())
        )
        .slice(0, 5)
        // filter out already selected devices
        .filter(
          (device) =>
            !selectedDevices.some((sd) => sd.device_id === device.device_id)
        )
        .map((device) => ({
          label: getDeviceLabel(device),
          value: device,
        }))
    );
  }, [deviceSearch, devices, getDeviceLabel, selectedDevices]);

  return (
    <Portal>
      <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle>Link Accessories</DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            size="small"
            options={catalogAccessoryOptions}
            id="link-accessory-select"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Accessories"
                onChange={(e) => setAccessorySearch(e.target.value)}
              />
            )}
            renderOption={(props, option) => (
              // render the accessory name and its image
              <li
                {...props}
                className={classNames(props.className, "flex justify-between")}
              >
                {option.value && option.value.image_url && (
                  <img
                    src={option.value.image_url}
                    alt={option.label}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "12px",
                    }}
                  />
                )}

                <span>{option.label}</span>
              </li>
            )}
            onChange={(_, item) => {
              if (item && item.value) {
                setSelectedAccessories([...selectedAccessories, item.value]);
                // clear the input
                setAccessoryAutocompleteValue({
                  label: "",
                  value: null,
                });
                // clear the search
                setAccessorySearch("");
              }
            }}
            value={accessoryAutocompleteValue}
            noOptionsText={
              accessorySearch
                ? "No matching accessories found"
                : "Please enter a search term to find accessories."
            }
          />

          <div className="mb-2 min-h-[40px]">
            {selectedAccessories.map((accessory) => (
              <Chip
                sx={{
                  mr: 1,
                  mt: 1,
                }}
                key={accessory.catalog_accessory_id}
                label={accessory.accessory_name}
                onDelete={() => {
                  setSelectedAccessories(
                    selectedAccessories.filter(
                      (sa) =>
                        sa.catalog_accessory_id !==
                        accessory.catalog_accessory_id
                    )
                  );
                }}
                avatar={
                  <Avatar
                    src={accessory.image_url}
                    alt={accessory.accessory_name}
                  />
                }
              />
            ))}
          </div>

          <Autocomplete
            sx={{
              mt: 2,
            }}
            size="small"
            options={deviceOptions}
            id="link-device-select"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Devices"
                onChange={(e) => setDeviceSearch(e.target.value)}
              />
            )}
            onChange={(_, item) => {
              if (item && item.value) {
                setSelectedDevices([...selectedDevices, item.value]);
                // clear the input
                setDeviceAutocompleteValue({
                  label: "",
                  value: null,
                });
                // clear the search
                setDeviceSearch("");
              }
            }}
            value={deviceAutocompleteValue}
            noOptionsText={
              deviceSearch
                ? "No matching devices found"
                : "Please enter a search term to find devices."
            }
          />

          <div className="mb-2 min-h-[40px]">
            {selectedDevices.map((device) => (
              <Chip
                sx={{
                  mr: 1,
                  mt: 1,
                }}
                key={device.device_id}
                label={getDeviceLabel(device)}
                onDelete={() => {
                  setSelectedDevices(
                    selectedDevices.filter(
                      (sd) => sd.device_id !== device.device_id
                    )
                  );
                }}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            disabled={isSaving}
            loading={isSaving}
            variant="contained"
            onClick={handleLinking}
          >
            Link
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
