import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import { Avatar, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ROUTES } from "@react-ms-apps/common";
import { sendPasswordResetEmail } from "@react-ms-apps/common/api/password";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BACK_TO_LOGIN_TEXT,
  ENTER_USERNAME_TO_RESET_PASSWORD_TEXT,
  FORGOT_PASSWORD_HEADER_TEXT,
  SEND_RECOVERY_EMAIL_TEXT,
} from "../../Constants/text";
import LoginWrapper from "../LoginWrapper";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [sendingResetEmail, setSendingResetEmail] = useState<boolean>(false);

  const handleSendPasswordEmail = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const data = new FormData(e.currentTarget);
    const username = data.get("username") as string;

    try {
      setSendingResetEmail(true);
      const sent = await sendPasswordResetEmail(username);
      if (sent) {
        // go to sent password reset page
        navigate(ROUTES.LOGIN.SENT_PASSWORD_RESET);
      } else {
        toast.error("Error sending password reset email.");
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error sending password reset email.");
    } finally {
      setSendingResetEmail(false);
    }
  };

  return (
    <LoginWrapper>
      <Avatar className="m-1 mb-2" sx={{ bgcolor: "secondary.main" }}>
        <LockIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {FORGOT_PASSWORD_HEADER_TEXT}
      </Typography>
      <Box
        role="form"
        component="form"
        onSubmit={handleSendPasswordEmail}
        className="w-full mt-1"
      >
        <Typography className="pb-2 pt-2">
          {ENTER_USERNAME_TO_RESET_PASSWORD_TEXT}
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Your username"
          placeholder="name@company.com"
          name="username"
          autoComplete="username"
          autoFocus
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          className="!mt-6 !mb-4"
          loading={sendingResetEmail}
        >
          {SEND_RECOVERY_EMAIL_TEXT}
        </LoadingButton>
        <Divider className="!border-dashed !mb-4 !mt-2" />
        <div className="flex justify-start">
          <Link component={RouterLink} to={ROUTES.LOGIN.HOME}>
            {BACK_TO_LOGIN_TEXT}
          </Link>
        </div>
      </Box>
    </LoginWrapper>
  );
}
