import {
  FormControl,
  InputLabel,
  NativeSelect,
  OutlinedInput,
} from "@mui/material";
import { useMemo } from "react";

export default function HeaderDropdown({
  options,
  onSelect,
  value,
  id,
  label,
  width = 150,
}: {
  options: string[];
  onSelect: (value: string) => void;
  value: string | null;
  id: string;
  label: string;
  width?: number;
}) {
  const labelId = `${id}---select`;

  const nonEmptyOptions = useMemo(() => {
    return (
      options
        .filter((option) => option !== "")
        // sort alpanumerically
        .sort((a, b) => a.localeCompare(b))
    );
  }, [options]);

  const nonAllOptions = useMemo(() => {
    return nonEmptyOptions.filter((option) => option !== "All");
  }, [nonEmptyOptions]);

  return (
    <FormControl
      sx={{
        mt: 1,
        mr: 1,
        width,
      }}
      size="small"
    >
      <InputLabel htmlFor={labelId}>{label}</InputLabel>
      <NativeSelect
        disabled={nonAllOptions.length === 0}
        id={labelId}
        variant="outlined"
        fullWidth
        size="small"
        value={value ?? ""}
        input={
          <OutlinedInput
            label={label}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        }
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();

          onSelect(e.target.value as string);
        }}
      >
        {nonEmptyOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
