import { ThemeProvider } from "@mui/material";
import theme from "@react-ms-apps/common/mui/theme";
import { NavData } from "@react-ms-apps/common/types/nav";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import { fetchNavbarData } from "../../api/navbar";
import { useNav } from "../../providers/NavProvider";
import { isLocal } from "../../utils";
import SupportLinkIconButton from "../SupportLinkIconButton";
import ChangePasswordDialog from "./ChangePasswordDialog";
import LiveChat from "./LiveChat";
import NavItem from "./NavItem";
import { NavbarOuterWrapper, NavbarWrapper } from "./styled";

// IMPORTANT: only use for dev environment
const passwordLink: NavData.NavDataItem = {
  href: "#",
  a_class: "",
  link_name: "Password",
  id: "settings",
  onclick: "return employeePopup();",
};

interface NavBarProps {
  isInjected?: boolean;
}

const NavBar = ({ isInjected = false }: NavBarProps) => {
  const { navData, setNavData, setIsLoading } = useNav();

  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);

  const handleListItemClick = (item: NavData.NavDataItem) => {
    if (item.link_name !== "Password") return;

    setShowChangePasswordDialog(true);
  };

  const getNavbarData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await fetchNavbarData();
      const navData = [...response];

      if (isLocal()) {
        navData.push(passwordLink);
      }

      setNavData(navData);
    } catch (error) {
      // send out error to error logging service
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setNavData]);

  useEffect(() => {
    getNavbarData();
  }, [getNavbarData]);

  if (!navData) return null;

  return (
    <ThemeProvider theme={theme}>
      <NavbarOuterWrapper>
        <NavbarWrapper>
          {navData.map((item: NavData.NavDataItem, index) => (
            <NavItem
              index={index}
              key={item.id}
              item={item}
              onClick={handleListItemClick}
            />
          ))}
        </NavbarWrapper>

        {!isInjected && (
          <div className="flex flex-row items-center justify-end">
            <LiveChat />

            <SupportLinkIconButton />
          </div>
        )}
      </NavbarOuterWrapper>

      {showChangePasswordDialog && (
        <ChangePasswordDialog
          onClose={() => setShowChangePasswordDialog(false)}
          open={showChangePasswordDialog}
        />
      )}
    </ThemeProvider>
  );
};

export default NavBar;
