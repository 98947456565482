import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import {
  CarrierList,
  CatalogDevice,
  DeviceList,
} from "@react-ms-apps/common/api/catalog-manager";
import { chain } from "lodash";
import { useMemo, useState } from "react";
import HeaderDropdown from "../../HeaderDropdown";
import { CatalogDeviceRow } from "../../types";
import ImportDevice from "./ImportDevice";

interface CatalogDevicesTableProps {
  loading: boolean;
  catalogDevices: CatalogDevice[];
  devices: DeviceList;
  carriers: CarrierList;
  search: string;
  clientName: string;
}

export default function CatalogDevicesTable({
  loading,
  catalogDevices,
  devices,
  carriers,
  search,
  clientName,
}: CatalogDevicesTableProps) {
  const [selectedMake, setSelectedMake] = useState<string>("All");
  const [selectedModel, setSelectedModel] = useState<string>("All");
  const [selectedCarrier, setSelectedCarrier] = useState<string>("All");
  const [selectedActive, setSelectedActive] = useState<string>("All");

  const catalogDeviceRows: CatalogDeviceRow[] =
    useMemo((): CatalogDeviceRow[] => {
      return catalogDevices
        .map((catalogDevice) => {
          let make = "",
            model = "",
            carrierName = "";

          const device = devices.find(
            (device) => device.device_id === catalogDevice.device_id
          );
          if (device) {
            make = device.manufacturer;
            model = device.model;
          }

          const carrier = carriers.find(
            (carrier) => carrier.carrier_id === catalogDevice.carrier_id
          );
          if (carrier) {
            carrierName = carrier.name;
          }

          return {
            catalogDeviceId: catalogDevice.catalog_device_id,
            deviceId: catalogDevice.device_id,
            make,
            model,
            carrier: carrierName,
            isActive: catalogDevice.active,
            purchasePrice: catalogDevice.purchase_price || null,
            retailPrice: catalogDevice.retail_price || null,
            proPrice: catalogDevice.pro_price || null,
            guidelines: catalogDevice.guidelines || "",
          };
        })
        .filter((row) => {
          if (!search) return true;

          return (
            row.make.toLowerCase().includes(search.toLowerCase()) ||
            row.model.toLowerCase().includes(search.toLowerCase()) ||
            (row.carrier &&
              row.carrier.toLowerCase().includes(search.toLowerCase())) ||
            (row.catalogDeviceId &&
              row.catalogDeviceId.toString().includes(search.toLowerCase())) ||
            (row.deviceId &&
              row.deviceId.toString().includes(search.toLowerCase())) ||
            (row.purchasePrice &&
              row.purchasePrice?.toString().includes(search.toLowerCase())) ||
            (row.retailPrice &&
              row.retailPrice?.toString().includes(search.toLowerCase()))
          );
        });
    }, [carriers, catalogDevices, devices, search]);

  const makeOptions = useMemo(() => {
    const uniqueMakeOptions = chain(catalogDeviceRows)
      .uniqBy("make")
      .map((row) => row.make)
      .sort()
      .value();

    return ["All", ...uniqueMakeOptions];
  }, [catalogDeviceRows]);

  const modelOptions = useMemo(() => {
    const uniqueModelOptions = chain(catalogDeviceRows)
      .uniqBy("model")
      .map((row) => row.model)
      .sort()
      .value();

    return ["All", ...uniqueModelOptions];
  }, [catalogDeviceRows]);

  const carrierOptions = useMemo(() => {
    const uniqueCarrierOptions = chain(catalogDeviceRows)
      .uniqBy("carrier")
      .map((row) => row.carrier)
      .sort()
      .value();

    return ["All", ...uniqueCarrierOptions];
  }, [catalogDeviceRows]);

  const activeOptions = useMemo(() => {
    return ["All", "Active", "Inactive"];
  }, []);

  const filteredCatalogDeviceRows = useMemo(() => {
    return (
      catalogDeviceRows
        // filter make, model, carrier, active
        .filter((row) => {
          if (selectedMake !== "All" && row.make !== selectedMake) {
            return false;
          }

          if (selectedModel !== "All" && row.model !== selectedModel) {
            return false;
          }

          if (selectedCarrier !== "All" && row.carrier !== selectedCarrier) {
            return false;
          }

          if (
            selectedActive !== "All" &&
            row.isActive !== (selectedActive === "Active")
          ) {
            return false;
          }

          return true;
        })
    );
  }, [
    catalogDeviceRows,
    selectedMake,
    selectedModel,
    selectedCarrier,
    selectedActive,
  ]);

  const catalogDevicesColumns: GridColDef<CatalogDeviceRow>[] =
    useMemo((): GridColDef<CatalogDeviceRow>[] => {
      return [
        {
          field: "catalogDeviceId",
          headerName: "CD Id",
          type: "string",
          // width: 130,
          align: "left",
          headerAlign: "left",
          editable: false,
          aggregable: true,
          disableColumnMenu: true,
        },
        {
          field: "deviceId",
          headerName: "Dev Id",
          type: "string",
          // width: 130,
          align: "left",
          headerAlign: "left",
          editable: false,
          aggregable: true,
          disableColumnMenu: true,
        },
        {
          field: "make",
          headerName: "Manufacturer",
          width: 200,
          renderHeader: () => (
            <HeaderDropdown
              width={140}
              value={selectedMake}
              onSelect={setSelectedMake}
              options={makeOptions}
              id="catalog-devices-make-filter"
              label="Make"
            />
          ),
          editable: false,
          aggregable: true,
          disableColumnMenu: true,
        },
        {
          field: "model",
          headerName: "Model",
          type: "string",
          width: 280,
          editable: false,
          renderHeader: () => (
            <HeaderDropdown
              width={220}
              value={selectedModel}
              onSelect={setSelectedModel}
              options={modelOptions}
              id="catalog-devices-model-filter"
              label="Model"
            />
          ),
          aggregable: true,
          disableColumnMenu: true,
        },
        {
          field: "carrier",
          headerName: "Carrier",
          type: "string",
          width: 200,
          editable: false,
          renderHeader: () => (
            <HeaderDropdown
              width={140}
              value={selectedCarrier}
              onSelect={setSelectedCarrier}
              options={carrierOptions}
              id="catalog-devices-carrier-filter"
              label="Carrier"
            />
          ),
          aggregable: true,
          disableColumnMenu: true,
        },
        {
          field: "isActive",
          headerName: "Active",
          type: "boolean",
          width: 160,
          editable: false, // disabled to prevent native editing
          align: "center",
          headerAlign: "center",
          sortable: false,
          renderHeader: () => (
            <HeaderDropdown
              width={100}
              value={selectedActive}
              onSelect={setSelectedActive}
              options={activeOptions}
              id="catalog-devices-active-filter"
              label="Active"
            />
          ),
          cellClassName: () => {
            return "MuiDataGrid-booleanCell !pl-0";
          },
          // renderCell: (params) => {
          //   const { value } = params;

          //   return <Switch disabled defaultChecked={value} />;
          // },
          disableColumnMenu: true,
        },
        {
          field: "purchasePrice",
          headerName: "Purchase Price",
          type: "number",
          align: "right",
          headerAlign: "right",
          width: 150,
          editable: false,
          renderCell: (params) => {
            if (!params.value) return null;

            // render currency but remove the $ sign
            return params.value
              .toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
              .replace("$", "");
          },
          disableColumnMenu: true,
        },
        {
          field: "retailPrice",
          headerName: "Retail Price",
          type: "number",
          align: "right",
          headerAlign: "right",
          width: 150,
          editable: false,
          renderCell: (params) => {
            if (!params.value) return null;

            // render currency but remove the $ sign
            return params.value
              .toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
              .replace("$", "");
          },
          disableColumnMenu: true,
        },
        {
          field: "actions",
          headerName: "",
          type: "actions",
          width: 120,
          editable: false,
          renderCell: (params) => (
            <ImportDevice
              deviceId={params.row.deviceId}
              clientName={clientName}
              manufacturer={params.row.make}
              model={params.row.model}
            />
          ),
        },
      ];
    }, [
      selectedMake,
      makeOptions,
      selectedModel,
      modelOptions,
      selectedCarrier,
      carrierOptions,
      selectedActive,
      activeOptions,
      clientName,
    ]);

  return (
    <>
      <Box
        sx={{
          height: 500,
          // width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          "& .MuiDataGrid-booleanCell[data-value='true']": {
            color: "success.main",
          },
          "& .MuiDataGrid-booleanCell[data-value='false']": {
            color: "error.main",
          },
        }}
      >
        <DataGridPremium
          columnHeaderHeight={75}
          loading={loading}
          getRowId={(row) => row.catalogDeviceId}
          columns={catalogDevicesColumns}
          rows={filteredCatalogDeviceRows}
          pinnedColumns={{
            right: ["actions"],
          }}
          density="compact"
        />
      </Box>
    </>
  );
}
