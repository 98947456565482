import { TableCell, TableRow } from "@mui/material";
import { deletePDFUpload, UploadStatement } from "@react-ms-apps/common";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useMemo, useState } from "react";
import { TrashFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { trimFilename } from "./utils";

interface UploadedPDFRowProps {
  carrier: string;
  onDelete: () => void;
  isSubmitted?: boolean;

  pdfUpload: UploadStatement;
}

export default function UploadedPDFRow({
  carrier,
  onDelete,
  isSubmitted,
  pdfUpload,
}: UploadedPDFRowProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const fileUploadId = useMemo(() => {
    if (pdfUpload && typeof pdfUpload.file_upload_id) {
      return pdfUpload.file_upload_id;
    }

    return "";
  }, [pdfUpload]);

  const handleDelete = async () => {
    if (!fileUploadId) {
      toast.error("Error removing file");
      Sentry.captureException(new Error("No file upload ID"));
      return;
    }

    try {
      setIsDeleting(true);
      await deletePDFUpload(fileUploadId);
      onDelete();
      toast.success("File removed");
    } catch (error) {
      toast.error("Error removing file");
      Sentry.captureException(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const invoiceDate = useMemo(() => {
    if (pdfUpload && pdfUpload.invoice_date) {
      return pdfUpload.invoice_date;
    }

    return "";
  }, [pdfUpload]);

  const invoiceTotal = useMemo(() => {
    if (pdfUpload && typeof pdfUpload.invoice_total === "number") {
      return pdfUpload.invoice_total.toString();
    }

    return "";
  }, [pdfUpload]);

  const accountNumber = useMemo(() => {
    if (pdfUpload && pdfUpload.foundation_account) {
      return pdfUpload.foundation_account;
    }

    return "";
  }, [pdfUpload]);

  const filename = useMemo(() => {
    if (pdfUpload && pdfUpload.file_name) {
      return pdfUpload.file_name;
    }

    return "";
  }, [pdfUpload]);

  return (
    <TableRow
      className={classNames(
        "[&>td]:py-1 [&>td]:text-xs",
        isSubmitted && "bg-gray-300",
        isSubmitted && "[&>td]:text-gray-500"
      )}
    >
      <TableCell align="left">{trimFilename(filename)}</TableCell>
      <TableCell align="center">{carrier}</TableCell>
      <TableCell align="center">{accountNumber}</TableCell>
      <TableCell align="center">
        {moment(invoiceDate).format("MMM YYYY")}
      </TableCell>
      <TableCell align="center">
        {
          // get the currency
          Number(invoiceTotal).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }
      </TableCell>

      <TableCell className="!flex !flex-row !justify-end">
        {!isSubmitted && (
          <button
            disabled={isDeleting}
            onClick={handleDelete}
            className="bg-primary-800 hover:bg-primary-900 border-none text-white rounded-sm flex items-center justify-end py-1 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <TrashFill />
          </button>
        )}
      </TableCell>
    </TableRow>
  );
}
