import { fetchOrgInfo } from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import React from "react";
import { OrgInfoDTO } from "../types";

const OrgContext = React.createContext<{
  org: OrgInfoDTO | null;
  setOrg: React.Dispatch<React.SetStateAction<OrgInfoDTO | null>>;
  getOrg: () => Promise<null | OrgInfoDTO>;
  refreshOrg: () => Promise<void>;
  orgLoaded: boolean;
}>({
  org: null,
  setOrg: () => {},
  getOrg: async () => null,
  refreshOrg: async () => {},
  orgLoaded: false,
});

export const useOrg = () => React.useContext(OrgContext);

export const OrgInfoProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [org, setOrg] = React.useState<null | OrgInfoDTO>(null);
  const [orgLoaded, setOrgLoaded] = React.useState<boolean>(false);

  const refreshOrg = async () => {
    setOrgLoaded(false);

    try {
      const orgInfo = await fetchOrgInfo();
      setOrg(orgInfo);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setOrgLoaded(true);
    }
  };

  const getOrg = async (): Promise<null | OrgInfoDTO> => {
    // if user is already set, return it
    if (org) return org;

    await refreshOrg();

    return org;
  };

  return (
    <OrgContext.Provider
      value={{
        org,
        setOrg,
        refreshOrg,
        getOrg,
        orgLoaded,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};
