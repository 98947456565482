import { Add } from "@mui/icons-material";
import { Autocomplete, Button, TextField } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useCompanyFilter } from "./CompanyFilterProvider";

interface ToolbarProps {
  onAdd: () => void;
  companiesList: string[];
}

export default function Toolbar({ onAdd, companiesList }: ToolbarProps) {
  const api = useGridApiContext();

  const { selectedCompany, setSelectedCompany } = useCompanyFilter();

  const handleCompanyChange = async (company: string) => {
    setSelectedCompany(company);

    // filter on company name if company name is selected
    if (company) {
      api.current.setFilterModel({
        items: [
          {
            field: "company_cost_ctr",
            operator: "is",
            value: company,
          },
        ],
      });

      // sort by BAN # if company name is selected
      api.current.setSortModel([
        {
          field: "ban",
          sort: "asc",
        },
      ]);
    } else {
      // clear filter and sort if company name is empty

      api.current.setFilterModel({
        items: [],
      });

      api.current.setSortModel([]);
    }
  };

  return (
    <GridToolbarContainer className="flex flex-row justify-between mb-2">
      <div className="flex flex-row">
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />

        <Autocomplete
          size="small"
          className="w-[250px] mt-2 ml-4"
          options={companiesList}
          value={selectedCompany}
          onChange={(e, value) => handleCompanyChange(value || "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company"
              variant="outlined"
              className="!mb-2"
              value={selectedCompany}
            />
          )}
        />
      </div>

      <Button
        variant="contained"
        size="large"
        startIcon={<Add />}
        onClick={onAdd}
      >
        Add
      </Button>
    </GridToolbarContainer>
  );
}
