import { Portal } from "@mui/base";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface InfoDialogProps {
  onClose: () => void;
  title: string;
  message: string | JSX.Element;
}

export default function InfoDialog({
  onClose,
  title,
  message,
}: InfoDialogProps) {
  return (
    <Portal>
      <Dialog open onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>{message}</DialogContent>
        <DialogActions>
          <button
            className="border-none bg-primary-700 cursor-pointer hover:bg-primary-900 rounded-md text-white text-sm py-1 px-2"
            onClick={onClose}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
