import { Switch } from "@mui/material";
import { useRowEtagContext } from "@react-ms-apps/app/src/Providers/RowEtagContext";
import { UtilityLinkDTO } from "@react-ms-apps/common";
import { updateUtilityByID } from "@react-ms-apps/common/api/utilities";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ETAG_ERROR_MESSAGE } from "../constants";

interface ActiveToggleProps extends UtilityLinkDTO {}

export default function ActiveToggle({
  utility_id,
  active,
  utility_name,
  custom_utility_name,
}: ActiveToggleProps) {
  const [isActive, setIsActive] = useState<boolean>(active);

  const { etag, setEtag } = useRowEtagContext();

  const handleChange = async (checked: boolean) => {
    setIsActive(checked);

    // update utility active
    try {
      const { etag: updatedEtag } = await updateUtilityByID(
        utility_id,
        "active",
        checked,
        etag
      );
      setEtag(updatedEtag);

      if (checked) {
        toast.success(`${utility_name} activated`, {
          autoClose: 1000,
        });
      } else {
        toast.info(`${utility_name} deactivated`, {
          autoClose: 1000,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 412) {
        toast.error(ETAG_ERROR_MESSAGE);
        return;
      }

      Sentry.captureException(error);
      toast.error(`Error updating ${custom_utility_name || utility_name}`);
      setIsActive(!checked);
    }
  };

  return (
    <Switch
      checked={isActive}
      onChange={(__, checked) => handleChange(checked)}
    />
  );
}
