import { Portal } from "@mui/base";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ROUTES } from "@react-ms-apps/common";
import { Device } from "@react-ms-apps/common/api/catalog-manager";
import { useNavigate } from "react-router-dom";

function ClonedDeviceDialog({
  onClose,
  device,
}: {
  onClose: () => void;
  device: Device;
}) {
  const navigate = useNavigate();

  const handleEditDevice = () => {
    onClose();

    navigate(
      `${ROUTES.UTILITY.ROOT}${ROUTES.UTILITY.CATALOG_MANAGER.ROOT}/${ROUTES.UTILITY.CATALOG_MANAGER.EDIT_DEVICE}/${device.device_id}`,
      {
        replace: true,
      }
    );
  };

  return (
    <Portal>
      <Dialog open>
        <DialogTitle>Clone Successful</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            The device has been cloned successfully.
          </DialogContentText>
          <DialogContentText>
            Would you like to edit the new device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>No</Button>
          <Button variant="contained" onClick={handleEditDevice} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

export default ClonedDeviceDialog;
