export interface Order {
  shipping_state: string;
  order_subscribers: OrderSubscriber[];
  color: null;
  updated_at: string;
  order_timestamp: string;
  order_status_id: number;
  custom_5: null;
  expedite_charge: null;
  device_purchase_price: null;
  approver_email: null;
  custom_1: null;
  custom_2: null;
  custom_3: null;
  duplicate_sentinel: null;
  plan_pro_mrc: null;
  end_date: null;
  order_tracking_number: null;
  order_justification_id: null;
  approver_comments: string;
  comments: string;
  catalog_device: null;
  is_ariba: boolean;
  location_code: null;
  start_date: null;
  order_justification: null;
  order_status: OrderStatus;
  po: null;
  shipping_name: string;
  shipping_tracking_number: null;
  operator_id: number;
  shipping_city: string;
  courier: null;
  custom_order_number: null;
  order_id: number;
  shipping_phone: string;
  operator: Operator;
  carrier_id: number;
  shipping_address2: string;
  shipping_email: null;
  order_type: OrderType;
  plan_definition: null;
  custom_4: null;
  shipping_zip: string;
  catalog_device_id: null;
  plan_definition_id: null;
  approver_id: null;
  expedited: boolean;
  plan_mrc: null;
  order_justification_other: null;
  token: string;
  carrier: Carrier;
  account_number: null;
  operator_name: string;
  passcode: null;
  shipping_company: string;
  operator_email: string;
  device_pro_price: null;
  shipping_country: null;
  shipping_address1: string;
}

interface Carrier {
  contract_discount: number;
  name: string;
  min_pooling: number;
  country_id: number;
  max_pooling: number;
  expedite_charge: number;
  waive_termination: boolean;
  carrier_id: number;
  carrier_logo: string;
  data_discount: number;
  active: boolean;
  id: number;
  monthly_termination_fee: null;
  waive_activation: boolean;
}

interface OrderType {
  custom_description: string;
  description: string;
  not_implemented: boolean;
  display_priority: number;
  authorized_roles: string;
  updated_at: string;
  type_of_order: string;
  active: boolean;
  special_instructions: string;
  custom_report_description: string;
  hover_description: string;
  order_type_id: number;
  auto_approve: boolean;
}

interface Operator {
  domain: number;
  last_name: string;
  update_timestamp: string;
  alternate_extension: null;
  c3_cost_ctr: string;
  update_user_id: null;
  profile_id: number;
  alternate_phone: null;
  company_cost_ctr: string;
  employee_num: string;
  device_type: string;
  division_cost_ctr: string;
  first_name: string;
  user_id: number;
  foundation_account: null;
  subscriber_number: null;
  manager_id: null;
  dynamic_cost_center_date: null;
  reimburse: boolean;
  contracted_rate_plan: null;
  record_metadata: null;
  user_name: string;
  email: string;
  suppress_notifications: boolean;
  project_cost_ctr: string;
  status: number;
  plan_activation: null;
  upgrade_eligibility: null;
  ufmi_number: null;
  department_cost_ctr: string;
  update_user_id_actual: number;
  password_timestamp: string;
  c2_cost_ctr: string;
  organization_id: number;
  c4_cost_ctr: string;
  dynamic_cost_center: string;
  mobile_number: null;
  rate_plan: null;
  password: string;
  recapture: boolean;
  c5_cost_ctr: string;
  alternate_employee_num: null;
  latest_invoice: null;
  c6_cost_ctr: string;
  roles: string;
  show_reports: boolean;
  carrier_subaccount_number: null;
  title_id: null;
  c1_cost_ctr: string;
  create_user_id: number;
  acct_cost_ctr: string;
  carrier_account_number: null;
  task_cost_ctr: string;
  contract_termination: null;
  notes: null;
  create_timestamp: string;
}

interface OrderStatus {
  status: string;
  abbreviated_status: string;
  description: string;
  is_terminal: boolean;
  status_change_msg: null;
  order_status_id: number;
  pending_notify_for: null;
}

export interface OrderSubscriber {
  receipt_signature: null;
  last_name: string;
  split_bill: null;
  billing_name: null;
  asset_tag: null;
  sim_id: string;
  courier: null;
  employee_num: string;
  update_timestamp: string;
  status_timestamp: string;
  acct: string;
  status_date_override: null;
  project: string;
  first_name: string;
  new_service_area_code: string;
  user_id: null;
  pin: null;
  statement_summary_id: null;
  order_tracking_number: null;
  current_passcode: string;
  billing_zip: null;
  authorized_owner: string;
  prior_service_number: null;
  upgrade_eligibility: null;
  department: string;
  esn: null;
  aud_cr_user_id: null;
  email: string;
  division: string;
  shipping_tracking_number: null;
  billing_address1: null;
  billing_address2: null;
  status_id: number;
  order_id: number;
  company: string;
  current_account_number: string;
  current_carrier: string;
  update_user_id: null;
  imei: string;
  ip_address: null;
  tax_id: null;
  order_subscriber_id: number;
  aud_mod_user_id: null;
  task: string;
  asset_description: null;
  billing_city: null;
  service_number: string;
  notes: null;
  type_of_order: string;
  service_zip: null;
  authorized_billing_address: string;
  activation_date: null;
  model: null;
  make: null;
  billing_state: null;
  create_timestamp: string;
}

export enum OrderStatusTypes {
  NEW = 10000,
  PENDING_APPROVAL_LEVEL_1 = 10001,
  APPROVED_PENDING = 10002,
  DENIED_PENDING = 10003,
  APPROVED_FINAL = 10004,
  DENIED_FINAL = 10005,
}

export type OrderActionType = "approve" | "deny";
