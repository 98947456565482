import { Portal } from "@mui/base";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface DeleteItemModalProps {
  onClose: () => void;
  onDelete: () => void;
}

export default function DeleteItemModal({
  onClose,
  onDelete,
}: DeleteItemModalProps) {
  return (
    <Portal>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Delete Item</DialogTitle>

        <DialogContent dividers>
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" color="error" onClick={onDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}
