import {
  AvailableImage,
  fetchAccessoriesImages,
  fetchDevicesImages,
} from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";

export const ImagesContext = React.createContext<{
  deviceImages: AvailableImage[];
  fetchDeviceImages: () => Promise<void>;
  accessoryImages: AvailableImage[];
  fetchAccessoryImages: () => Promise<void>;
}>({
  deviceImages: [],
  fetchDeviceImages: () => Promise.resolve(),
  accessoryImages: [],
  fetchAccessoryImages: () => Promise.resolve(),
});

export const useImagesContext = () => React.useContext(ImagesContext);

interface ImagesContextProps {
  children: React.ReactNode;
}

export const ImagesProvider = ({ children }: ImagesContextProps) => {
  const [deviceImages, setDeviceImages] = React.useState<AvailableImage[]>([]);
  const [accessoryImages, setAccessoryImages] = React.useState<
    AvailableImage[]
  >([]);

  const getDeviceImages = async () => {
    try {
      const devicesImages = await fetchDevicesImages();
      setDeviceImages(devicesImages);
    } catch (error) {
      Sentry.captureException(error);
      throw new Error("Error fetching device images");
    }
  };

  const getAccessoryImages = async () => {
    try {
      const accessoryImages = await fetchAccessoriesImages();
      setAccessoryImages(accessoryImages);
    } catch (error) {
      Sentry.captureException(error);
      throw new Error("Error fetching accessory images");
    }
  };

  useEffect(() => {
    getDeviceImages();
    getAccessoryImages();
  }, []);

  return (
    <ImagesContext.Provider
      value={{
        deviceImages,
        fetchDeviceImages: getDeviceImages,
        accessoryImages,
        fetchAccessoryImages: getAccessoryImages,
      }}
    >
      {children}
    </ImagesContext.Provider>
  );
};
