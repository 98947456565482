export function getHasLowerCase(value: string): boolean {
  return /[a-z]/.test(value);
}

export function getHasUpperCase(value: string): boolean {
  return /[A-Z]/.test(value);
}

export function getHasNumber(value: string): boolean {
  return /[0-9]/.test(value);
}

export function getHasValidSpecialChars(value: string): boolean {
  return /[\\\-!@#$%^&*()_;:<>?,."`~+={}|\][]/.test(value);
}

export function complexityLevel(value: string): number {
  /*
  takes in a value an returns its numbered complexity level
  complexity levels are:
  lowerCase
  upperCase
  number
  symbol \-!@#$%^&*()_;:<>?,."`~+={}|][
   */
  var current_complexity = 0;
  if (getHasLowerCase(value)) {
    current_complexity += 1;
  }
  if (getHasUpperCase(value)) {
    current_complexity += 1;
  }
  if (getHasNumber(value)) {
    current_complexity += 1;
  }
  if (getHasValidSpecialChars(value)) {
    current_complexity += 1;
  }
  return current_complexity;
};