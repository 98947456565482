import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { UsageAndCost } from "../api";
import ChartShell from "../ChartShell";
import { getChartDataKeys } from "../ChartShell/utils";
import { ByChartData, ChartDatasets, ChartType } from "../constants";
import CostByCarrierBarChart from "./CostByCarrierBarChart";
import CostByCategoryBarChart from "./CostByCategoryBarChart";
import CostByDeviceTypeBarChart from "./CostByDeviceBarChart";
import CostOverTimeLineChart from "./CostOverTimeLineChart";

export default function DataAndUsageCostChart({
  startDate,
  endDate,
  selectedChartType,
  usageAndCostData,
  isLoading,
  fieldNames,
  minusFieldNames,
  byChartDataOptions,
  dataSet,
}: {
  startDate: string;
  endDate: string;
  selectedChartType: ChartType;
  usageAndCostData: UsageAndCost[];
  isLoading: boolean;
  fieldNames: string[];
  minusFieldNames: string[];
  byChartDataOptions: ByChartData[];
  dataSet: ChartDatasets;
}) {
  const [selectedByChartData, setSelectedByChartData] = useState<ByChartData>(
    byChartDataOptions[0]
  );
  const [chartDataKeys, setChartDataKeys] = useState<{
    [key: string]: boolean;
  }>({});

  const selectedDataKeys = Object.keys(chartDataKeys).filter(
    (key) => chartDataKeys[key]
  );

  const handleSelectedDataKeysChange = (selectedDataKeys: string[]) => {
    setChartDataKeys(
      Object.fromEntries(
        Object.keys(chartDataKeys).map((key) => [
          key,
          selectedDataKeys.includes(key),
        ])
      )
    );
  };

  // set chart data keys for usageAndCostData
  useEffect(() => {
    const chartDataKeys = getChartDataKeys(
      usageAndCostData,
      selectedByChartData
    );
    setChartDataKeys(
      Object.fromEntries(chartDataKeys.map((key) => [key, true]))
    );
  }, [selectedByChartData, usageAndCostData]);

  // auto-select the byChartData based on the available byChartDataOptions
  useEffect(() => {
    if (byChartDataOptions.length > 0) {
      setSelectedByChartData(byChartDataOptions[0]);
    }
  }, [byChartDataOptions]);

  return (
    <ChartShell
      dataKeyOptions={Object.keys(chartDataKeys)}
      selectedDataKeys={selectedDataKeys}
      onSelectedDataKeysChange={handleSelectedDataKeysChange}
      selectedByChartData={selectedByChartData}
      byChartDataOptions={byChartDataOptions}
      onByChartDataChange={setSelectedByChartData}
    >
      {isLoading ? (
        <div className="flex flex-1 flex-col items-center justify-center">
          <CircularProgress size={60} />
        </div>
      ) : (
        <>
          {[ChartType.BAR, ChartType.CLUSTERED_BAR].includes(
            selectedChartType
          ) &&
            selectedByChartData === ByChartData.CARRIER && (
              <CostByCarrierBarChart
                data={usageAndCostData}
                startDate={startDate}
                endDate={endDate}
                dataKeys={Object.keys(chartDataKeys)}
                selectedDataKeys={selectedDataKeys}
                chartType={selectedChartType}
                fieldNames={fieldNames}
                minusFieldNames={minusFieldNames}
                dataSet={dataSet}
              />
            )}

          {[ChartType.BAR, ChartType.CLUSTERED_BAR].includes(
            selectedChartType
          ) &&
            selectedByChartData === ByChartData.DEVICE && (
              <CostByDeviceTypeBarChart
                data={usageAndCostData}
                startDate={startDate}
                endDate={endDate}
                dataKeys={Object.keys(chartDataKeys)}
                selectedDataKeys={selectedDataKeys}
                chartType={selectedChartType}
                fieldNames={fieldNames}
                minusFieldNames={minusFieldNames}
                dataSet={dataSet}
              />
            )}

          {[ChartType.BAR, ChartType.CLUSTERED_BAR].includes(
            selectedChartType
          ) &&
            selectedByChartData === ByChartData.COST_CATEGORIES && (
              <CostByCategoryBarChart
                data={usageAndCostData}
                startDate={startDate}
                endDate={endDate}
                dataKeys={Object.keys(chartDataKeys)}
                selectedDataKeys={selectedDataKeys}
                chartType={selectedChartType}
                fieldNames={fieldNames}
                dataSet={dataSet}
              />
            )}

          {selectedChartType === "line" && (
            <CostOverTimeLineChart
              usageAndCostData={usageAndCostData}
              startDate={startDate}
              endDate={endDate}
              byChartData={selectedByChartData}
              selectedDataFilters={selectedDataKeys}
              fieldNames={fieldNames}
              minusFieldNames={minusFieldNames}
              dataSet={dataSet}
            />
          )}
        </>
      )}
    </ChartShell>
  );
}
