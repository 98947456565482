import axios from "axios";
import { buildApiUrl } from "../utils";

interface ChatConfigurationURLResponse {
  option_name: "chat_configuration_url";
  option_value: string;
}

export async function fetchChatConfigurationURL() {
  const resp = await axios.get<ChatConfigurationURLResponse>(
    buildApiUrl("api/nc/option_values?option_name=chat_configuration_url")
  );

  return resp.data.option_value;
}
