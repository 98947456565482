import { sleep } from "@react-ms-apps/common";
import {
  AccessoryType,
  AccessoryTypeList,
  Carrier,
  CarrierList,
  CatalogAccessory,
  CatalogAccessoryDTO,
  CatalogDevice,
  CatalogDeviceDTO,
  Device,
  DeviceAccessory,
  DeviceCatalogAccessory,
  DeviceDTO,
  DeviceImage,
  DeviceList,
  DeviceListItem,
  PDAStyles,
  connectCatalogAccessory,
  createCatalogAccessory as createCatalogAccessoryApi,
  createDevice as createDeviceApi,
  deleteCatalogAccessory as deleteCatalogAccessoryApi,
  deleteCatalogDevice as deleteCatalogDeviceApi,
  deleteDevice as deleteDeviceApi,
  disconnectCatalogAccessory,
  fetchAccessoryTypes,
  fetchCarriers,
  fetchCatalogAccessories,
  fetchCatalogAccessory,
  fetchCatalogAccessoryEtag,
  fetchCatalogDevices,
  fetchDevice,
  fetchDeviceAccessories,
  fetchDeviceItems,
  fetchDevicesImages,
  fetchPDAStyles,
  updateCatalogAccessory as updateCatalogAccessoryApi,
  updateCatalogDevice as updateCatalogDeviceApi,
  updateDevice as updateDeviceApi,
} from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import { omit } from "lodash";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";

interface CatalogManagerContextProps {
  loadingDevice: boolean;
  getDevice: (id: number) => Promise<Device>;
  cloneDevice: (id: number) => Promise<Device>;

  // used for local storage of device items when using non-update apis
  setDevice: (device: Device) => void;

  deviceItems: DeviceListItem[];
  getDeviceItems: (forceRefresh?: boolean) => Promise<DeviceListItem[]>;
  loadingDeviceItems: boolean;

  deviceNameManufacturerMap: Record<string, DeviceListItem>;
  addImportedDevice: (device: DeviceListItem) => void;
  importedDevices: DeviceList;
  importedDevicesMap: Record<string, DeviceListItem>;
  getDeviceName: (manufacturer: string, model: string) => string;
  setDevices: (devices: DeviceListItem[]) => void;
  addDevice: (device: Device) => void;
  updateDevice: (device: Partial<DeviceDTO>) => Promise<Device>;
  createDevice: (device: DeviceDTO) => Promise<Device>;
  deleteDeviceItem: (id: number) => Promise<void>;

  catalogDevices: CatalogDevice[];
  loadingCatalogDevices: boolean;
  getCatalogDevices: (forceRefresh?: boolean) => Promise<CatalogDevice[]>;
  addCatalogDevice: (device: CatalogDevice) => void;
  updateCatalogDevice: (
    device: Partial<CatalogDeviceDTO>
  ) => Promise<CatalogDevice>;
  deleteCatalogDevice: (id: number) => Promise<void>;

  catalogAccessories: CatalogAccessory[];
  loadingCatalogAccessories: boolean;
  getCatalogAccessories: (
    forceRefresh?: boolean
  ) => Promise<CatalogAccessory[]>;
  addCatalogAccessory: (accessory: CatalogAccessory) => void;
  getCatalogAccessoryById: (id: number) => CatalogAccessory | undefined;
  getCatalogAccessoryEtag: (id: number) => Promise<string>;
  getCatalogAccessory: (id: number) => Promise<void>;
  createCatalogAccessory: (
    accessory: CatalogAccessoryDTO
  ) => Promise<CatalogAccessory>;
  updateCatalogAccessory: (
    id: number,
    accessory: Partial<CatalogAccessoryDTO>,
    etag?: string
  ) => Promise<CatalogAccessory>;
  deleteCatalogAccessory: (id: number) => Promise<void>;

  carriers: CarrierList;
  loadingCarriers: boolean;
  getCarriers: () => Promise<Carrier[]>;

  pdaStyles: PDAStyles;
  loadingPDAStyles: boolean;
  getPDAStyles: () => Promise<void>;

  deviceAccessories: DeviceAccessory[];
  loadingDeviceAccessories: boolean;
  getDeviceAccessories: (forceRefresh?: boolean) => Promise<DeviceAccessory[]>;
  linkedAccessoriesDevicesMap: Record<number, DeviceList>;
  unlinkDeviceAccessory: (
    catalogAccessoryId: number,
    deviceId: number
  ) => Promise<void>;
  linkDeviceAccessory: (
    catalogAccessoryId: number,
    deviceId: number
  ) => Promise<DeviceCatalogAccessory>;

  accessoryTypes: AccessoryTypeList;
  getAccessoryTypes: () => Promise<void>;
  getAccessoryTypeById: (id: number) => AccessoryType;

  getDevicesImages: () => Promise<DeviceImage[]>;
}

export const CatalogManagerContext = createContext<CatalogManagerContextProps>({
  loadingDevice: false,
  getDevice: async () => ({} as Device),
  setDevice: () => {},
  createDevice: async () => ({} as Device),

  cloneDevice: async () => ({} as Device),

  deviceItems: [],
  getDeviceItems: async () => [],
  loadingDeviceItems: false,

  deviceNameManufacturerMap: {},
  importedDevices: [],
  importedDevicesMap: {},
  addImportedDevice: () => {},
  getDeviceName: () => "",
  setDevices: () => {},
  addDevice: () => {},
  updateDevice: async () => ({} as Device),
  deleteDeviceItem: async () => {},

  catalogDevices: [],
  loadingCatalogDevices: false,
  getCatalogDevices: async () => [],
  addCatalogDevice: () => {},
  updateCatalogDevice: async () => ({} as CatalogDevice),
  deleteCatalogDevice: async () => {},

  catalogAccessories: [],
  loadingCatalogAccessories: false,
  getCatalogAccessories: async () => [],
  addCatalogAccessory: () => {},
  getCatalogAccessoryById: () => ({} as CatalogAccessory),
  getCatalogAccessoryEtag: async () => "",
  getCatalogAccessory: async () => {},
  createCatalogAccessory: async () => ({} as CatalogAccessory),
  updateCatalogAccessory: async () => ({} as CatalogAccessory),
  deleteCatalogAccessory: async () => {},

  carriers: [],
  loadingCarriers: false,
  getCarriers: async () => [],

  pdaStyles: [],
  loadingPDAStyles: false,
  getPDAStyles: async () => {},

  deviceAccessories: [],
  loadingDeviceAccessories: false,
  getDeviceAccessories: async () => [],
  linkedAccessoriesDevicesMap: {},
  unlinkDeviceAccessory: async () => {},
  linkDeviceAccessory: async () => ({} as DeviceCatalogAccessory),

  accessoryTypes: [],
  getAccessoryTypes: async () => {},
  getAccessoryTypeById: () => ({} as AccessoryType),

  getDevicesImages: async () => [],
});

export const useCatalogManager = () => React.useContext(CatalogManagerContext);

const CatalogManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loadingDevice, setLoadingDevice] = useState(false);

  const [deviceItemsMap, setDeviceItemsMap] = useState<
    Record<number, DeviceListItem>
  >({});
  const [loadingDeviceItems, setLoadingDeviceItems] = useState(false);

  const [catalogDevicesMap, setCatalogDevicesMap] = useState<
    Record<number, CatalogDevice>
  >({});
  const [loadingCatalogDevices, setLoadingCatalogDevices] = useState(false);
  const [importedDevices, setImportedDevices] = useState<DeviceList>([]);

  const [catalogAccessoriesMap, setCatalogAccessoriesMap] = useState<
    Record<number, CatalogAccessory>
  >({});

  const [loadingCatalogAccessories, setLoadingCatalogAccessories] =
    useState(false);

  const [carriersMap, setCarriersMap] = useState<Record<number, Carrier>>({});
  const [loadingCarriers, setLoadingCarriers] = useState(false);

  const [pdaStyles, setPDAStyles] = useState<PDAStyles>([]);
  const [loadingPDAStyles, setLoadingPDAStyles] = useState(false);

  const [deviceAccessoriesMap, setDeviceAccessoriesMap] = useState<
    Record<number, DeviceAccessory>
  >({});
  const [loadingDeviceAccessories, setLoadingDeviceAccessories] =
    useState(false);
  const [accessoryTypes, setAccessoryTypes] = useState<AccessoryTypeList>([]);

  const [loadingDeviceImages, setLoadingDeviceImages] = useState(false);
  const [deviceImagesMap, setDeviceImagesMap] = useState<
    Record<number, DeviceImage>
  >({});

  const setDeviceItems = (deviceItems: DeviceListItem[]) => {
    setDeviceItemsMap(
      deviceItems.reduce((acc, device) => {
        acc[device.device_id] = device;
        return acc;
      }, {} as Record<number, DeviceListItem>)
    );
  };

  const getDevice = useCallback(
    async (id: number): Promise<Device> => {
      if (loadingDevice) {
        await sleep(500);
        return getDevice(id);
      }

      let device: Device = {} as Device;

      try {
        device = await fetchDevice(id);

        /**
         * even though device has more data than the device items
         * we'll store it with the others to avoid having to track multiple data sets
         */
        setDeviceItemsMap((prevDeviceItems) => ({
          ...prevDeviceItems,
          [device.device_id]: device,
        }));
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to fetch device");
      } finally {
        setLoadingDevice(false);
      }

      return device;
    },
    [loadingDevice]
  );

  const addDevice = (deviceItem: DeviceListItem) => {
    setDeviceItemsMap((prevDeviceItems) => ({
      ...prevDeviceItems,
      [deviceItem.device_id]: deviceItem,
    }));
  };

  const addCatalogDevice = (device: CatalogDevice) => {
    setCatalogDevicesMap((prevCatalogDevices) => ({
      ...prevCatalogDevices,
      [device.catalog_device_id]: device,
    }));
  };

  const createDevice = useCallback(
    async (device: DeviceDTO): Promise<Device> => {
      try {
        const newDevice = await createDeviceApi(device);

        setDeviceItemsMap((prevDeviceItems) => ({
          ...prevDeviceItems,
          [newDevice.device_id]: newDevice,
        }));

        return newDevice;
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to create device");
        throw error;
      }
    },
    []
  );

  const updateDevice = useCallback(
    async (device: Partial<DeviceDTO>) => {
      let updatedDevice: Device = {} as Device;

      const deviceId = device.device_id as number;
      if (!device.device_id) {
        throw new Error("Device id is required");
      }

      const foundDevice = deviceItemsMap[device.device_id];
      if (!foundDevice) {
        throw new Error("Device not found");
      }

      const deviceData: DeviceDTO = {
        ...foundDevice,
        ...device,
      } as DeviceDTO;

      updatedDevice = await updateDeviceApi(device.device_id, deviceData);

      setDeviceItemsMap((prevDeviceItems) => ({
        ...prevDeviceItems,
        [deviceId]: updatedDevice,
      }));

      // update catalog devices if applicable
      if (updatedDevice.catalog_devices) {
        setCatalogDevicesMap((prevCatalogDevices) => {
          const updatedCatalogDevices = { ...prevCatalogDevices };

          updatedDevice.catalog_devices.forEach((catalogDevice) => {
            updatedCatalogDevices[catalogDevice.catalog_device_id] = {
              ...updatedCatalogDevices[catalogDevice.catalog_device_id],
              ...catalogDevice,
            };
          });

          return updatedCatalogDevices;
        });
      }

      // update device accessories if applicable
      if (updatedDevice.device_accessories) {
        setDeviceAccessoriesMap((prevDeviceAccessories) => {
          const updatedDeviceAccessories = { ...prevDeviceAccessories };

          updatedDevice.device_accessories.forEach((deviceAccessory) => {
            updatedDeviceAccessories[deviceAccessory.device_accessory_id] = {
              ...updatedDeviceAccessories[deviceAccessory.device_accessory_id],
              ...deviceAccessory,
            };
          });

          return updatedDeviceAccessories;
        });
      }

      return updatedDevice;
    },
    [deviceItemsMap]
  );

  const updateCatalogDevice = useCallback(
    async (
      catalogDevice: Partial<CatalogDeviceDTO>
    ): Promise<CatalogDevice> => {
      try {
        const catalogDeviceId = catalogDevice.catalog_device_id as number;
        const existingCatalogDevice =
          catalogDevicesMap[catalogDeviceId] || ({} as CatalogDevice);

        const updatedCatalogDeviceData: CatalogDeviceDTO = {
          ...existingCatalogDevice,
          ...catalogDevice,
        };

        const updatedCatalogDevice = await updateCatalogDeviceApi(
          updatedCatalogDeviceData
        );

        setCatalogDevicesMap((prevCatalogDevices) => ({
          ...prevCatalogDevices,
          [updatedCatalogDevice.catalog_device_id]: updatedCatalogDevice,
        }));

        return updatedCatalogDevice;
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to update catalog device");
        throw error;
      }
    },
    [catalogDevicesMap]
  );

  const deleteCatalogDevice = useCallback(async (id: number) => {
    try {
      await deleteCatalogDeviceApi(id);

      setCatalogDevicesMap((prevCatalogDevices) => {
        const updatedCatalogDevices = { ...prevCatalogDevices };
        delete updatedCatalogDevices[id];
        return updatedCatalogDevices;
      });
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to delete catalog device");
    }
  }, []);

  const addCatalogAccessory = (accessory: CatalogAccessory) => {
    setCatalogAccessoriesMap((prevCatalogAccessories) => ({
      ...prevCatalogAccessories,
      [accessory.catalog_accessory_id]: accessory,
    }));
  };

  const getCatalogDevices = useCallback(
    async (forceRefresh = false): Promise<CatalogDevice[]> => {
      if (!forceRefresh && Object.keys(catalogDevicesMap).length > 0) {
        return Object.values(catalogDevicesMap);
      }

      // do not fetch catalog devices if they are already being fetched
      if (loadingCatalogDevices) {
        await sleep(500);

        return getCatalogDevices(forceRefresh);
      }

      setLoadingCatalogDevices(true);

      let catalogDevices: CatalogDevice[] = [];

      try {
        catalogDevices = await fetchCatalogDevices();
        const catalogDevicesMap = catalogDevices.reduce((acc, device) => {
          acc[device.catalog_device_id] = device;
          return acc;
        }, {} as Record<number, CatalogDevice>);

        setCatalogDevicesMap(catalogDevicesMap);
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to fetch catalog devices");
      } finally {
        setLoadingCatalogDevices(false);
      }

      return catalogDevices;
    },
    [catalogDevicesMap, loadingCatalogDevices]
  );

  const getCarriers = useCallback(async () => {
    if (Object.keys(carriersMap).length > 0) {
      return Object.values(carriersMap);
    }

    // do not fetch carriers if they are already being fetched
    if (loadingCarriers) {
      return [];
    }

    setLoadingCarriers(true);

    let carriersData: CarrierList = [];

    try {
      carriersData = await fetchCarriers();
      setCarriersMap(carriersData);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to fetch carriers");
    } finally {
      setLoadingCarriers(false);
    }

    return carriersData;
  }, [carriersMap, loadingCarriers]);

  const getDeviceItems = useCallback(
    async (forceRefresh = false): Promise<DeviceListItem[]> => {
      if (!forceRefresh && Object.keys(deviceItemsMap).length > 0) {
        return Object.values(deviceItemsMap);
      }

      if (loadingDeviceItems) {
        await sleep(500);

        return getDeviceItems();
      }

      setLoadingDeviceItems(true);

      let deviceItemsData: DeviceListItem[] = [];

      try {
        deviceItemsData = await fetchDeviceItems();
        setDeviceItemsMap(
          deviceItemsData.reduce((acc, device) => {
            acc[device.device_id] = device;
            return acc;
          }, {} as Record<number, DeviceListItem>)
        );
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to fetch devices");
      } finally {
        setLoadingDeviceItems(false);
      }

      return deviceItemsData;
    },
    [deviceItemsMap, loadingDeviceItems]
  );

  const deleteDeviceItem = useCallback(async (id: number) => {
    try {
      await deleteDeviceApi(id);

      setDeviceItemsMap((prevDeviceItems) => {
        const updatedDeviceItems = { ...prevDeviceItems };
        delete updatedDeviceItems[id];
        return updatedDeviceItems;
      });
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  }, []);

  const getDevicesImages = useCallback(async (): Promise<DeviceImage[]> => {
    if (Object.keys(deviceImagesMap).length > 0) {
      return Object.values(deviceImagesMap);
    }

    // do not fetch device images if they are already being fetched
    if (loadingDeviceImages) {
      await sleep(500);

      return getDevicesImages();
    }

    setLoadingDeviceImages(true);

    let deviceImagesData: DeviceImage[] = [];

    try {
      deviceImagesData = await fetchDevicesImages();
      setDeviceImagesMap(deviceImagesData);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to fetch device images");
    } finally {
      setLoadingDeviceImages(false);
    }

    return deviceImagesData;
  }, [deviceImagesMap, loadingDeviceImages]);

  const getCatalogAccessory = useCallback(async (id: number) => {
    const { etag, catalogAccessory } = await fetchCatalogAccessory(id);

    setCatalogAccessoriesMap((prev) => ({
      ...prev,
      [id]: { ...catalogAccessory, etag },
    }));
  }, []);

  const getCatalogAccessoryEtag = useCallback(async (id: number) => {
    try {
      const etag = await fetchCatalogAccessoryEtag(id);

      setCatalogAccessoriesMap((prev) => ({
        ...prev,
        [id]: { ...prev[id], etag },
      }));

      return etag;
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to fetch catalog accessory etag");

      return "";
    }
  }, []);

  const getCatalogAccessories = useCallback(
    async (forceRefresh = false): Promise<CatalogAccessory[]> => {
      if (Object.keys(catalogAccessoriesMap).length > 0 && !forceRefresh) {
        return Object.values(catalogAccessoriesMap);
      }

      if (loadingCatalogAccessories) {
        await sleep(500);

        return getCatalogAccessories(forceRefresh);
      }

      setLoadingCatalogAccessories(true);

      let catalogAccessoriesData: CatalogAccessory[] = [];

      try {
        catalogAccessoriesData = await fetchCatalogAccessories();

        const catalogAccessoriesMap = catalogAccessoriesData.reduce(
          (acc, accessory) => {
            acc[accessory.catalog_accessory_id] = accessory;
            return acc;
          },
          {} as Record<number, CatalogAccessory>
        );
        setCatalogAccessoriesMap(catalogAccessoriesMap);
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to fetch catalog accessories");
      } finally {
        setLoadingCatalogAccessories(false);
      }

      return catalogAccessoriesData;
    },
    [catalogAccessoriesMap, loadingCatalogAccessories]
  );

  const getCatalogAccessoryById = useCallback(
    (id: number): CatalogAccessory | undefined => {
      return catalogAccessoriesMap[id];
    },
    [catalogAccessoriesMap]
  );

  const getPDAStyles = useCallback(async () => {
    // do not fetch PDA styles if they are already being fetched
    if (loadingPDAStyles) {
      return;
    }

    setLoadingPDAStyles(true);

    try {
      const data = await fetchPDAStyles();
      setPDAStyles(data);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to fetch PDA styles");
    } finally {
      setLoadingPDAStyles(false);
    }
  }, [loadingPDAStyles]);

  const getDeviceAccessories = useCallback(
    async (forceRefresh = false): Promise<DeviceAccessory[]> => {
      if (Object.keys(deviceAccessoriesMap).length > 0 && !forceRefresh) {
        return Object.values(deviceAccessoriesMap);
      }

      // do not fetch device accessories if they are already being fetched
      if (loadingDeviceAccessories) {
        await sleep(500);

        return getDeviceAccessories(forceRefresh);
      }

      setLoadingDeviceAccessories(true);

      let deviceAccessoriesData: DeviceAccessory[] = [];

      try {
        deviceAccessoriesData = await fetchDeviceAccessories();
        setDeviceAccessoriesMap(deviceAccessoriesData);
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to fetch device accessories");
      } finally {
        setLoadingDeviceAccessories(false);
      }

      return deviceAccessoriesData;
    },
    [deviceAccessoriesMap, loadingDeviceAccessories]
  );

  const fetchingAccessoryTypes = useRef(false);

  const getAccessoryTypes = useCallback(async () => {
    // do not fetch accessory types if they are already being fetched or have been fetched
    if (fetchingAccessoryTypes.current || accessoryTypes.length > 0) {
      return;
    }

    fetchingAccessoryTypes.current = true;

    try {
      const data = await fetchAccessoryTypes();
      // sort data alphabetically
      data.sort((a, b) =>
        a.accessory_type_name.localeCompare(b.accessory_type_name)
      );
      setAccessoryTypes(data);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to fetch accessory types");
    }
  }, [accessoryTypes]);

  const getAccessoryTypeById = useCallback(
    (id: number) => {
      return (
        accessoryTypes.find((type) => type.accessory_type_id === id) ||
        ({} as AccessoryType)
      );
    },
    [accessoryTypes]
  );

  const updateCatalogAccessory = useCallback(
    async (
      id: number,
      accessory: Partial<CatalogAccessoryDTO>,
      etag: string = ""
    ) => {
      const catalogAccessory = getCatalogAccessoryById(id);
      if (!catalogAccessory) {
        throw new Error("Catalog accessory not found");
      }

      const catalogAccessoryData: CatalogAccessoryDTO = {
        ...catalogAccessory,
        ...accessory,
      };

      // if etag is not provided, try to get it from the catalog accessory
      if (!etag) {
        etag = catalogAccessory.etag || "";
      }

      // if there's still no etag, fetch it, then prompt the user to try again
      if (!etag) {
        await getCatalogAccessoryEtag(id);
        throw new Error("Etag not found. Please try again.");
      }

      const { catalogAccessory: updatedCatalogAccessory, etag: updatedEtag } =
        await updateCatalogAccessoryApi(id, catalogAccessoryData, etag);

      // update item
      setCatalogAccessoriesMap((prev) => ({
        ...prev,
        [id]: { ...updatedCatalogAccessory, etag: updatedEtag },
      }));

      return updatedCatalogAccessory;
    },
    [getCatalogAccessoryById, getCatalogAccessoryEtag]
  );

  const createCatalogAccessory = useCallback(
    async (accessory: CatalogAccessoryDTO) => {
      try {
        const newCatalogAccessory = await createCatalogAccessoryApi(accessory);

        // add new item
        setCatalogAccessoriesMap((prev) => ({
          ...prev,
          [newCatalogAccessory.catalog_accessory_id]: newCatalogAccessory,
        }));

        return newCatalogAccessory;
      } catch (error) {
        toast.error("Failed to create catalog accessory");

        throw error;
      }
    },
    []
  );

  const deleteCatalogAccessory = useCallback(
    async (catalogAccessoryId: number) => {
      try {
        const catalogAccessoryEtag = await getCatalogAccessoryEtag(
          catalogAccessoryId
        );
        await deleteCatalogAccessoryApi(
          catalogAccessoryId,
          catalogAccessoryEtag
        );

        // remove from catalog accessories
        setCatalogAccessoriesMap((prev) => {
          const updatedCatalogAccessories = { ...prev };
          delete updatedCatalogAccessories[catalogAccessoryId];
          return updatedCatalogAccessories;
        });
      } catch (error) {
        toast.error("Failed to delete catalog accessory");

        throw error;
      }
    },
    [getCatalogAccessoryEtag]
  );

  const unlinkDeviceAccessory = useCallback(
    async (catalogAccessoryId: number, deviceId: number) => {
      try {
        await disconnectCatalogAccessory({
          catalog_accessory_id: catalogAccessoryId,
          device_id: deviceId,
        });

        // fetch device accessories again
        await getDeviceAccessories();
      } catch (error) {
        Sentry.captureException(error);

        throw error;
      }
    },
    [getDeviceAccessories]
  );

  const linkDeviceAccessory = useCallback(
    async (
      catalogAccessoryId: number,
      deviceId: number
    ): Promise<DeviceCatalogAccessory> => {
      try {
        const data = await connectCatalogAccessory(
          deviceId,
          catalogAccessoryId
        );

        const { catalog_accessory_id, device_accessory_id, device_id } = data;

        // add new device accessory
        setDeviceAccessoriesMap((prev) => ({
          ...prev,
          [device_accessory_id]: {
            device_accessory_id,
            device_id,
            catalog_accessory_id,
          },
        }));

        return data;
      } catch (error) {
        Sentry.captureException(error);

        throw error;
      }
    },
    []
  );

  const deviceNameManufacturerMap = useMemo(() => {
    return Object.values(deviceItemsMap).reduce((acc, device) => {
      acc[`${device.manufacturer} - ${device.model}`] = device;
      return acc;
    }, {} as Record<string, DeviceListItem>);
  }, [deviceItemsMap]);

  const importedDevicesMap = useMemo(() => {
    return importedDevices.reduce((acc, device) => {
      acc[`${device.manufacturer} - ${device.model}`] = device;
      return acc;
    }, {} as Record<string, DeviceListItem>);
  }, [importedDevices]);

  const getDeviceName = useCallback((manufacturer: string, model: string) => {
    return `${manufacturer} - ${model}`;
  }, []);

  const linkedAccessoriesDevicesMap = useMemo(() => {
    const map: Record<number, DeviceListItem[]> = {};

    // iterate through device accessories, creating a map of accessory ids to devices
    Object.values(deviceAccessoriesMap).forEach((deviceAccessory) => {
      const { catalog_accessory_id, device_id } = deviceAccessory;
      if (!map[catalog_accessory_id]) {
        map[catalog_accessory_id] = [];
      }

      const device = deviceItemsMap[device_id];
      if (device) {
        map[catalog_accessory_id].push(device);
      }
    });

    return map;
  }, [deviceAccessoriesMap, deviceItemsMap]);

  const setDevice = useCallback((device: Device) => {
    setDeviceItemsMap((prevDeviceItems) => ({
      ...prevDeviceItems,
      [device.device_id]: device,
    }));
  }, []);

  const cloneDevice = useCallback(
    async (deviceId: number) => {
      const existingDevice = await getDevice(deviceId);

      const adjustedDeviceData = omit(existingDevice, ["device_id"]) as Device;

      // remove all references to `device_id` from all nested objects in the device
      const removeDeviceId = (device: Partial<Device>) => {
        for (const key in device) {
          if (key === "device_id") {
            delete device[key];
            // @ts-ignore
          } else if (typeof device[key] === "object") {
            // @ts-ignore
            removeDeviceId(device[key]);
          }
        }
      };

      removeDeviceId(adjustedDeviceData);

      const newDevice = await createDevice(adjustedDeviceData);

      // add new device to device items
      addDevice(newDevice);

      // add associated catalog devices to map
      newDevice.catalog_devices.forEach((catalogDevice) => {
        addCatalogDevice(catalogDevice);
      });

      // add associated device accessories to map
      newDevice.device_accessories.forEach((deviceAccessory) => {
        setDeviceAccessoriesMap((prev) => ({
          ...prev,
          [deviceAccessory.device_accessory_id]: deviceAccessory,
        }));
      });

      return newDevice;
    },
    [createDevice, getDevice]
  );

  // load data on mount
  useEffect(() => {
    getCatalogAccessories();
    getDeviceAccessories();
    getCatalogDevices();
    getDeviceItems();
    getCarriers();
    getPDAStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CatalogManagerContext.Provider
      value={{
        loadingDevice: loadingDevice,
        getDevice,
        setDevice,
        cloneDevice,

        loadingDeviceItems,
        deviceItems: Object.values(deviceItemsMap),
        deviceNameManufacturerMap,
        getDeviceName,
        getDeviceItems,
        setDevices: setDeviceItems,
        addDevice,
        createDevice,
        updateDevice,
        deleteDeviceItem,

        catalogDevices: Object.values(catalogDevicesMap),
        loadingCatalogDevices,
        getCatalogDevices,
        addCatalogDevice,
        updateCatalogDevice,
        deleteCatalogDevice,

        catalogAccessories: Object.values(catalogAccessoriesMap),
        loadingCatalogAccessories,
        getCatalogAccessories,
        addCatalogAccessory,
        getCatalogAccessoryById,
        getCatalogAccessoryEtag,
        getCatalogAccessory,
        createCatalogAccessory,
        updateCatalogAccessory,
        deleteCatalogAccessory,

        carriers: Object.values(carriersMap).sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        loadingCarriers,
        getCarriers,

        pdaStyles,
        loadingPDAStyles,
        getPDAStyles,

        importedDevices,
        importedDevicesMap,
        addImportedDevice: (device) => {
          setImportedDevices((prev) => [...prev, device]);
        },

        deviceAccessories: Object.values(deviceAccessoriesMap),
        loadingDeviceAccessories,
        getDeviceAccessories,
        linkedAccessoriesDevicesMap,
        unlinkDeviceAccessory,
        linkDeviceAccessory,

        getAccessoryTypes,
        accessoryTypes,
        getAccessoryTypeById,

        getDevicesImages,
      }}
    >
      {children}
    </CatalogManagerContext.Provider>
  );
};

export default CatalogManagerProvider;
