import styled from "@emotion/styled";

export const SupportDialogForm = styled.form<{
  isBootstrapped?: boolean;
}>`
  ${({ isBootstrapped }) => {
    if (isBootstrapped) {
      return `
      & * {
        font-size: 1.4rem !important;
      }
      `;
    }
  }}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
