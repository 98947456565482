import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Portal,
  Select,
  TextField,
} from "@mui/material";
import { CatalogDevice } from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useCatalogManager } from "../CatalogManagerProvider";

interface NewCatalogEntryDialogProps {
  onClose: () => void;
  selectedDevice?: number;
  onSaveSuccess?: (catalogDevice: CatalogDevice) => void;
}

export default function NewCatalogEntryDialog({
  onClose,
  selectedDevice: initialSelectedDevice,
  onSaveSuccess,
}: NewCatalogEntryDialogProps) {
  const {
    updateCatalogDevice,
    carriers,
    deviceItems: devices,
  } = useCatalogManager();

  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(
    initialSelectedDevice || null
  );
  const [selectedCarrier, setSelectedCarrier] = useState<number | null>(null);
  const [guidelines, setGuidelines] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [outOfStock, setOutOfStock] = useState<boolean>(false);
  const [proPrice, setProPrice] = useState<number | null>(null);
  const [purchasePrice, setPurchasePrice] = useState<number | null>(null);
  const [retailPrice, setRetailPrice] = useState<number | null>(null);
  const [relativeRank, setRelativeRank] = useState<number | null>(null);

  const [savingCatalogEntry, setSavingCatalogEntry] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!selectedDeviceId || !selectedCarrier || !purchasePrice) {
      return;
    }

    setSavingCatalogEntry(true);

    try {
      const updatedCatalogDevice = await updateCatalogDevice({
        device_id: selectedDeviceId,
        carrier_id: selectedCarrier,
        guidelines,
        active,
        out_of_stock: outOfStock,
        pro_price: proPrice,
        purchase_price: purchasePrice,
        retail_price: retailPrice,
        relative_ranking: relativeRank,
      });

      if (onSaveSuccess) {
        onSaveSuccess(updatedCatalogDevice);
      }

      onClose();
      toast.success("Catalog entry saved successfully");
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setSavingCatalogEntry(false);
    }
  };

  const deviceOptions = useMemo(() => {
    if (!devices) return [];

    return (
      devices
        // remove entries without a manufacturer or model
        .filter((device) => device.manufacturer && device.model)
        // map to label/value
        .map((device) => ({
          label: `${device.manufacturer} - ${device.model}`,
          value: device.device_id,
        }))
        // sort alphabetically
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [devices]);

  const selectedDevicePermOption = useMemo(() => {
    // return the deviceOption that matches the selectedDevice
    const selectedDeviceOption = deviceOptions.find(
      (device) => device.value === selectedDeviceId
    );

    return selectedDeviceOption || null;
  }, [deviceOptions, selectedDeviceId]);

  return (
    <Portal>
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle>New Catalog Device</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent dividers>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                {!!selectedDeviceId && (
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label="Device ID"
                      value={selectedDeviceId}
                      disabled
                    />
                  </FormControl>
                )}

                <Autocomplete
                  fullWidth
                  disablePortal
                  disableClearable={!!selectedDevicePermOption}
                  disabled={!!selectedDevicePermOption}
                  defaultValue={selectedDevicePermOption}
                  id="new-catalog-entry-device"
                  options={deviceOptions}
                  onChange={(e, value) =>
                    setSelectedDeviceId(value?.value || null)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Device"
                      disabled={!!selectedDevicePermOption}
                    />
                  )}
                />
              </div>

              <FormControlLabel
                onChange={(e, checked) => setActive(checked)}
                control={<Checkbox />}
                label="Active"
              />

              <FormControl
                fullWidth
                sx={{
                  mb: 1,
                }}
              >
                <InputLabel id="new-catalog-entry-carrier-label" required>
                  Carrier
                </InputLabel>
                <Select
                  labelId="new-catalog-entry-carrier-label"
                  label="Carrier"
                  onChange={(e) => setSelectedCarrier(Number(e.target.value))}
                  required
                >
                  {carriers.map((carrier) => (
                    <MenuItem value={carrier.carrier_id}>
                      {carrier.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Guidelines"
                onChange={(e) => setGuidelines(e.target.value)}
              />

              <FormControlLabel
                onChange={(e, checked) => setOutOfStock(checked)}
                control={<Checkbox />}
                label="Out of Stock"
              />

              <TextField
                fullWidth
                label="Pro Price"
                type="number"
                onChange={(e) => setProPrice(Number(e.target.value))}
                InputProps={{
                  startAdornment: <span className="mr-1">$</span>,
                }}
                // increment by 0.01
                inputProps={{ step: 0.01 }}
                sx={{ mb: 1 }}
              />

              <TextField
                fullWidth
                label="Purchase Price"
                type="number"
                onChange={(e) => setPurchasePrice(Number(e.target.value))}
                InputProps={{
                  startAdornment: <span className="mr-1">$</span>,
                }}
                // increment by 0.01
                inputProps={{ step: 0.01 }}
                sx={{ mb: 1 }}
                required
              />

              <TextField
                fullWidth
                label="Retail Price"
                type="number"
                onChange={(e) => setRetailPrice(Number(e.target.value))}
                InputProps={{
                  startAdornment: <span className="mr-1">$</span>,
                }}
                // increment by 0.01
                inputProps={{ step: 0.01 }}
                sx={{ mb: 1 }}
              />

              <TextField
                fullWidth
                label="Relative Rank"
                type="number"
                onChange={(e) => setRelativeRank(Number(e.target.value))}
                sx={{ mb: 1 }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              variant="contained"
              disabled={savingCatalogEntry}
              loading={savingCatalogEntry}
              type="submit"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Portal>
  );
}
