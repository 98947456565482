export function bytesToSize(bytes: number) {
  const sizes = ["Bytes", "kB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "0 Bytes";
  }

  if (bytes === 1) {
    return "1 Byte";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = (bytes / Math.pow(1024, i)).toFixed(i === 0 ? 0 : 1);
  return parseFloat(value) + " " + sizes[i];
}
