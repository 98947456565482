import { ContainerProps, Container as MUIContainer } from "@mui/material";
import { classNames } from "../../utils/styles";

export default function Container({
  children,
  className,
  ...props
}: ContainerProps) {
  return (
    <MUIContainer
      {...props}
      className={classNames("!flex !w-full !max-w-full px-8", className)}
    >
      {children}
    </MUIContainer>
  );
}
