// collation of all upload process types
// any "Low" priority items are not included
// http://alpine.mobilsense.com:8180/browse/MOB-3159

export enum UploadProcessTypes {
  BatchStatementEditor = "StatementEditor",
  AssetRefresh = "AssetRefresh",
  UserCreation = "UserCreation",
  CostCenterRefreshByDevice = "CcRefreshDevice",
  CostCenterRefreshByEmployeeID = "CcRefreshEmployeeID",
  // BatchUpdateLinkUsers = "BatchUpdateLinkUsers",
  MakeManager = "MakeManager",
  ManagerRefreshByDeviceManager = "ManagerDevice",
  ManagerRefreshByEmployeeIDManager = "UpdateEmployeeManager",
  PlanOverrideUtility = "PlanOverride",
  // OrderSubscriberRecords = "OrderSubscriberRecords",
  // OrderSubsribersUpdate = "OrderSubsribersUpdate",
  Unknown = "Unknown",
}

/**
 * IMPORTANT:
 * These names are used to map to the upload process names
 * so custom names can be leveraged from the /api/restables/utilities endpoint
 */
export const UploadProcessTypeNames: {
  [key in UploadProcessTypes]: string;
} = {
  [UploadProcessTypes.BatchStatementEditor]: "Batch Statement Editor",
  [UploadProcessTypes.AssetRefresh]: "Asset Refresh",
  [UploadProcessTypes.UserCreation]: "Batch User Creation",
  [UploadProcessTypes.CostCenterRefreshByDevice]: "Cost Ctr Refresh (Device)",
  [UploadProcessTypes.CostCenterRefreshByEmployeeID]:
    "Cost Ctr Refresh (EmpID)",
  // BatchUpdateLinkUsers = "Batch Update: Link Users",
  [UploadProcessTypes.MakeManager]: "Make Manager",
  [UploadProcessTypes.ManagerRefreshByDeviceManager]:
    "Manager Refresh (Device/MgrName)",
  [UploadProcessTypes.ManagerRefreshByEmployeeIDManager]:
    "Manager Refresh (EmpID/MgrID)",
  [UploadProcessTypes.PlanOverrideUtility]: "Plan Override Utility",
  // OrderSubscriberRecords = "Batch Update: Create Subscriber Records",
  // OrderSubsribersUpdate = "Batch Update: Update Orders / Subscribers",
  [UploadProcessTypes.Unknown]: "",
};

export interface UploadProcessUIField {
  label: string;
  required: boolean;
  notes?: string;
}

export interface UploadProcessUI {
  processType: UploadProcessTypes;
  title: string;
  description: string[];
  fields: UploadProcessUIField[];
  uploadFormat: string;
  allowBlankValuesFields?: boolean;
  hasDynamicFields?: boolean;
}
