import { ROUTES } from "@react-ms-apps/common";
import { Route, Routes } from "react-router-dom";
import CatalogManagerProvider from "./CatalogManagerProvider";
import EditDevice from "./EditDevice";
import CatalogManager from "./Manager";

export default function CatalogManagerApp() {
  return (
    <CatalogManagerProvider>
      <Routes>
        <Route path="/" element={<CatalogManager />} />

        <Route
          path={`/${ROUTES.UTILITY.CATALOG_MANAGER.EDIT_DEVICE}/:id`}
          element={<EditDevice />}
        />

        <Route
          path={`${ROUTES.UTILITY.CATALOG_MANAGER.NEW_DEVICE}`}
          element={<EditDevice />}
        />
      </Routes>
    </CatalogManagerProvider>
  );
}
