import { Portal } from "@mui/base";
import { Image } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  CatalogAccessoryDTO,
  DeviceCatalogAccessory,
} from "@react-ms-apps/common/api/catalog-manager";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useImagesContext } from "../../Providers/ImagesProvider";
import { useCatalogManager } from "./CatalogManagerProvider";
import ImagesDialog from "./EditDevice/ImagesDialog";

interface NewDeviceAccessoryDialogProps {
  id?: number;
  deviceId?: number;
  onClose: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
  onCreateDeviceCatalogAccessory?: (
    catalogAccessory: DeviceCatalogAccessory
  ) => void;
}

export default function AccessoryDialog({
  id,
  deviceId,
  onClose,
  onSuccess,
  onFail,
  onCreateDeviceCatalogAccessory: onUpdateDeviceCatalogAccessory,
}: NewDeviceAccessoryDialogProps) {
  const { accessoryImages } = useImagesContext();

  const {
    getCatalogAccessories,
    getCatalogAccessoryEtag,
    catalogAccessories,
    accessoryTypes,
    getAccessoryTypes,
    updateCatalogAccessory,
    createCatalogAccessory,
    linkDeviceAccessory,
  } = useCatalogManager();
  const catalogAccessory = catalogAccessories.find(
    (ca) => ca.catalog_accessory_id === id
  );

  const [savingAccessory, setSavingAccessory] = useState(false);
  const [accessoryName, setAccessoryName] = useState(
    catalogAccessory?.accessory_name || ""
  );
  const [accessoryType, setAccessoryType] = useState(
    catalogAccessory?.accessory_type_id || ""
  );
  const [imageUrl, setImageUrl] = useState(catalogAccessory?.image_url || "");
  const [shortDescription, setShortDescription] = useState(
    catalogAccessory?.short_description || ""
  );
  const [longDescription, setLongDescription] = useState(
    catalogAccessory?.long_description || ""
  );
  const [guidelines, setGuidelines] = useState(
    catalogAccessory?.guideline || ""
  );
  const [price, setPrice] = useState<number | null>(
    catalogAccessory?.price || null
  );
  const [proPrice, setProPrice] = useState<number | null>(
    catalogAccessory?.pro_price || null
  );
  const [partNumber, setPartNumber] = useState(
    catalogAccessory?.part_number || ""
  );
  const [isOffered, setIsOffered] = useState(
    catalogAccessory?.offered || false
  );
  const [isGeneric, setIsGeneric] = useState(
    catalogAccessory?.is_generic || false
  );
  const [relativeRank, setRelativeRank] = useState<number | null>(
    catalogAccessory?.relative_ranking || null
  );

  const [showSelectImageDialog, setShowSelectImageDialog] = useState(false);

  const [loadingEtag, setLoadingEtag] = useState(false);

  const isValid = useMemo(() => {
    return price !== null && accessoryType !== "" && accessoryName !== "";
  }, [accessoryName, accessoryType, price]);

  const handleFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!isValid) {
        toast.warn("Please fill out all required fields");
        return;
      }

      setSavingAccessory(true);

      try {
        const catalogAccessoryData: CatalogAccessoryDTO = {
          device_id: deviceId,
          accessory_name: accessoryName,
          accessory_type_id: Number(accessoryType),
          image_url: imageUrl,
          short_description: shortDescription,
          long_description: longDescription,
          guideline: guidelines,
          price: Number(price),
          pro_price: proPrice || null,
          part_number: partNumber,
          offered: isOffered,
          is_generic: isGeneric,
          relative_ranking: relativeRank || null,
        };

        if (id) {
          // update catalog accessory
          await updateCatalogAccessory(id, catalogAccessoryData);
          toast.success("Accessory updated successfully");
        } else {
          // create catalog accessory
          const catalogAccessory = await createCatalogAccessory(
            catalogAccessoryData
          );

          // link catalog accessory to device
          if (deviceId) {
            const deviceCatalogAccessory = await linkDeviceAccessory(
              catalogAccessory.catalog_accessory_id,
              deviceId
            );

            if (onUpdateDeviceCatalogAccessory) {
              onUpdateDeviceCatalogAccessory(deviceCatalogAccessory);
            }
          }

          toast.success("Accessory created successfully");
        }
        onClose();
        onSuccess && onSuccess();
      } catch (error) {
        Sentry.captureException(error);
        if (error instanceof Error) {
          if (
            error.message.includes("Please try again") ||
            error.message.includes("Etag not found")
          ) {
            toast.error("Data temporarily out of sync. Please try again now.");
          } else {
            toast.error("Error saving accessory");
          }
        }
        onFail && onFail();
      } finally {
        setSavingAccessory(false);
      }
    },
    [
      accessoryName,
      accessoryType,
      createCatalogAccessory,
      deviceId,
      guidelines,
      id,
      imageUrl,
      isGeneric,
      isOffered,
      isValid,
      linkDeviceAccessory,
      longDescription,
      onClose,
      onFail,
      onSuccess,
      onUpdateDeviceCatalogAccessory,
      partNumber,
      price,
      proPrice,
      relativeRank,
      shortDescription,
      updateCatalogAccessory,
    ]
  );

  useEffect(() => {
    getCatalogAccessories();
    getAccessoryTypes();
  }, [getAccessoryTypes, getCatalogAccessories]);

  // set data when catalog accessory loads
  useEffect(() => {
    if (catalogAccessory) {
      setAccessoryName(catalogAccessory.accessory_name);
      setAccessoryType(catalogAccessory.accessory_type_id);
      setImageUrl(catalogAccessory.image_url);
      setShortDescription(catalogAccessory.short_description);
      setLongDescription(catalogAccessory.long_description);
      setGuidelines(catalogAccessory.guideline);
      setPrice(catalogAccessory.price);
      setProPrice(catalogAccessory.pro_price);
      setPartNumber(catalogAccessory.part_number);
      setIsOffered(catalogAccessory.offered);
      setIsGeneric(catalogAccessory.is_generic);
      setRelativeRank(catalogAccessory.relative_ranking);
    }
  }, [catalogAccessory, id]);

  const handleEtag = useCallback(async () => {
    if (!id) return;

    setLoadingEtag(true);
    try {
      await getCatalogAccessoryEtag(id);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error fetching etag");
    } finally {
      setLoadingEtag(false);
    }
  }, [getCatalogAccessoryEtag, id]);

  // set etag when catalog accessory loads
  useEffect(() => {
    handleEtag();
  }, [handleEtag]);

  return (
    <>
      <Portal>
        <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            {isEmpty(catalogAccessory) ? "New Accessory" : "Edit Accessory"}
          </DialogTitle>
          <form onSubmit={handleFormSubmit}>
            <DialogContent
              dividers
              sx={{
                maxHeight: "75vh",
              }}
            >
              {loadingEtag && (
                <div className="mb-4">
                  <LinearProgress />
                </div>
              )}
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">
                  {deviceId && (
                    <TextField
                      id="new-accessory--device-id"
                      label="Device ID"
                      value={deviceId}
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                  )}

                  <TextField
                    fullWidth
                    id="new-accessory-name"
                    label="Accessory Name"
                    value={accessoryName}
                    onChange={(e) => setAccessoryName(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    required
                  />

                  <FormControl fullWidth>
                    <InputLabel id="accessory-type-label">
                      Accessory Type
                    </InputLabel>

                    <Select
                      label="Accessory Type"
                      labelId="accessory-type-label"
                      id="accessory-type"
                      value={accessoryType}
                      onChange={(e) => setAccessoryType(e.target.value)}
                      required
                    >
                      {accessoryTypes.map((at) => (
                        <MenuItem
                          key={at.accessory_type_id}
                          value={at.accessory_type_id}
                        >
                          {at.accessory_type_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="flex flex-row justify-between items-end">
                  <div className="flex flex-col gap-4">
                    <TextField
                      fullWidth
                      id="new-accessory-price"
                      label="Price"
                      defaultValue={price?.toFixed(2) || ""}
                      type="number"
                      inputProps={{
                        step: 0.01,
                      }}
                      onBlur={(e) => setPrice(Number(e.target.value))}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                    <TextField
                      fullWidth
                      id="new-accessory-pro-price"
                      label="Pro. Price"
                      defaultValue={proPrice?.toFixed(2) || ""}
                      type="number"
                      inputProps={{
                        step: 0.01,
                      }}
                      onBlur={(e) => setProPrice(Number(e.target.value))}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>

                  <div className="flex flex-col items-start gap-2">
                    {imageUrl ? (
                      <img
                        onClick={() => setShowSelectImageDialog(true)}
                        src={imageUrl}
                        alt={imageUrl}
                        className="w-32 h-32 object-contain rounded cursor-pointer"
                      />
                    ) : (
                      <div
                        onClick={() => setShowSelectImageDialog(true)}
                        className="w-32 h-32 bg-gray-300 flex items-center justify-center rounded cursor-pointer"
                      >
                        <Image className="!w-28 !h-28" color="action" />
                      </div>
                    )}

                    <Button
                      onClick={() => setShowSelectImageDialog(true)}
                      variant="outlined"
                    >
                      Select Image
                    </Button>
                  </div>
                </div>

                <Divider className="!my-2" />

                <TextField
                  id="new-accessory-short-description"
                  label="Short Description"
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  multiline
                />

                <TextField
                  id="new-accessory-long-description"
                  label="Long Description"
                  value={longDescription}
                  onChange={(e) => setLongDescription(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  multiline
                />

                <TextField
                  id="new-accessory-guidelines"
                  label="Guidelines"
                  value={guidelines}
                  onChange={(e) => setGuidelines(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  multiline
                />

                <Divider className="!my-2" />

                <div className="flex flex-row gap-4">
                  <TextField
                    fullWidth
                    id="new-accessory-part-number"
                    label="Part Number"
                    value={partNumber}
                    onChange={(e) => setPartNumber(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    fullWidth
                    id="new-accessory-relative-rank"
                    label="Relative Rank"
                    value={relativeRank}
                    type="number"
                    onChange={(e) => setRelativeRank(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                <div className="flex flex-col">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isOffered}
                        onChange={(e, checked) => setIsOffered(checked)}
                      />
                    }
                    label="Is Offered"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isGeneric}
                        onChange={(e, checked) => setIsGeneric(checked)}
                      />
                    }
                    label="Is Generic"
                  />
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                loading={savingAccessory}
                disabled={savingAccessory || loadingEtag}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      </Portal>

      {showSelectImageDialog && (
        <ImagesDialog
          onClose={() => setShowSelectImageDialog(false)}
          onSelect={setImageUrl}
          availableImages={accessoryImages}
        />
      )}
    </>
  );
}
