import { isProd } from "@react-ms-apps/common/utils/env";
import * as Sentry from "@sentry/react";
import { getCleanPath } from "./location";

export const devNavApi = "/dev01/api/navbar";

export function getNavbarUrl() {
  return buildApiUrl("api/navbar");
}

export function buildApiUrl(path: string) {
  let url = "";

  try {
    const clientDB = getClientDB();

    // build the url with the origin and the first item
    url = getCleanPath(`/${clientDB}/${path}`);
  } catch (error) {
    // send out error to error logging service
    Sentry.captureException(error);
  } finally {
    return url;
  }
}

export function getClientDB() {
  try {
    // split the url into an array
    let urlArray = window.location.pathname
      .split("/")
      .filter((item) => item !== "");

    // retrieve the first item in the array (clientDB)
    let clientDB = urlArray[0];

    // if clientDB is undefined and not in development env, send out error to error logging service
    if (!clientDB) {
      if (!isProd()) {
        clientDB = "dev01";
      } else {
        Sentry.captureMessage("Client DB is undefined");
      }
    }

    return clientDB;
  } catch (error) {
    // send out error to error logging service
    Sentry.captureException(error);
  }
}

/**
 * Used to retrieve the base name for the server. It includes the host name and the clientDB.
 */
export function getBaseName() {
  return `${window.location.origin}/${getClientDB()}`;
}
