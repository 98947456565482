import axios from "axios";
import { buildApiUrl } from "../utils/api-url";

interface UtilityResponse {
  utility: UtilityDTO;
  etag: string;
}

export interface UtilityDTO {
  updated_at: string;
  utility_id: number;
  authorized_roles: string;
  utility_name: string;
  utility_link: string;
  utility_category_id: number;
  active: boolean;
  is_zope_link: boolean;
  custom_utility_name: string;
}

export async function fetchUtilityByID(id: number): Promise<UtilityResponse> {
  const resp = await axios.get(buildApiUrl(`api/restables/utilities/${id}`));

  return {
    utility: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function updateUtilityByID(
  id: number,
  fieldName: string,
  fieldValue: any,
  ETAG: string
): Promise<UtilityResponse> {
  const resp = await axios.put<UtilityDTO>(
    buildApiUrl(`api/restables/utilities/${id}`),
    { [fieldName]: fieldValue },
    {
      headers: {
        "If-Match": ETAG,
      },
    }
  );

  return {
    utility: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function fetchUtilities(): Promise<UtilityDTO[]> {
  const resp = await axios.get(buildApiUrl(`api/restables/utilities`));

  return resp.data;
}
