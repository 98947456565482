import { Paper, Tooltip } from "@mui/material";
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GridAggregationFunction,
  GridAggregationModel,
  GridAlignment,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import {
  PageContainer,
  fetchReportByName,
  fetchReportSchemaByName,
} from "@react-ms-apps/common";
import { useAuth } from "@react-ms-apps/common/providers";
import { useNav } from "@react-ms-apps/common/providers/NavProvider";
import {
  ReportDTO,
  ReportItemDTO,
  ReportSchemaColumnDTO,
  ReportSchemaDTO,
} from "@react-ms-apps/common/types";
import * as Sentry from "@sentry/react";
import { isEmpty, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import ReportToolbar from "./ReportToolbar";
import "./print.scss";
import { createPrintTable, destroyPrintTable } from "./utils";

// @TODO add error handling if a report is not found (error message, clear the page data)

const distinctAggregation: GridAggregationFunction<string, string | null> = {
  apply: (params) => {
    const uniqueValues = new Set(params.values);
    return uniqueValues.size.toString();
  },
  label: "distinct",
  columnTypes: ["singleSelect", "string"],
};

export default function ReportsPage() {
  const { navData } = useNav();
  const { user } = useAuth();

  const [loadingMonths, setLoadingMonths] = useState<boolean>(false);
  const [loadingReportOptions, setLoadingReportOptions] =
    useState<boolean>(false);

  const [selectedStatementMonth, setSelectedStatementMonth] =
    useState<string>("");
  const [selectedReportName, setSelectedReportName] = useState<string>("");

  const [report, setReport] = useState<ReportDTO>([]);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);

  const [reportSchema, setReportSchema] = useState<ReportSchemaDTO | null>(
    null
  );
  const [loadingReportSchema, setLoadingReportSchema] =
    useState<boolean>(false);

  const [gridAggregationModel, setGridAggregationModel] =
    useState<GridAggregationModel>({});

  const [poolAdjusted, setPoolAdjusted] = useState<boolean>(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const Toolbar = useCallback(() => {
    return (
      <ReportToolbar
        onLoadingMonthsChange={setLoadingMonths}
        onLoadingReportOptionsChange={setLoadingReportOptions}
        onSelectedStatementMonthChange={setSelectedStatementMonth}
        onSelectedReportNameChange={setSelectedReportName}
        onPoolAdjustedChange={setPoolAdjusted}
      />
    );
  }, []);

  const rows = useMemo(() => {
    if (!reportSchema) return [];

    if (poolAdjusted) {
      return report.map((row) => {
        const newRow = { ...row };

        reportSchema.columns.forEach((column) => {
          if (column.pool_adjusted_dataset) {
            // @ts-ignore
            newRow[column.dataset] = row[column.pool_adjusted_dataset];
          }
        });

        return newRow;
      });
    }

    return report;
  }, [poolAdjusted, report, reportSchema]);

  // const getReportSiblings = useCallback(async () => {
  //   try {
  //     const currentMonthIndex = statementMonths.findIndex(
  //       (month) => month === selectedStatementMonth
  //     );

  //     const len = statementMonths.length;

  //     const previous = statementMonths[(currentMonthIndex + len - 1) % len];
  //     const next = statementMonths[(currentMonthIndex + 1) % len];

  //     const monthsRange = [previous, next];

  //     await preFetchReportsByName(selectedReportName, monthsRange);
  //   } catch (error) {
  //     Sentry.captureException(error);
  //   }
  // }, [selectedReportName, selectedStatementMonth, statementMonths]);

  const getReport = useCallback(async () => {
    setLoadingReport(true);

    try {
      const report = await fetchReportByName(
        selectedReportName,
        selectedStatementMonth
      );
      setReport(report);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingReport(false);

      // prefetch the siblings of the current report
      // await getReportSiblings();
    }
  }, [selectedReportName, selectedStatementMonth]);

  const getReportSchema = useCallback(async () => {
    // don't fetch the schema if it's already been fetched
    setLoadingReportSchema(true);

    try {
      const reportSchema = await fetchReportSchemaByName(selectedReportName);
      setReportSchema(reportSchema);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingReportSchema(false);
    }
  }, [selectedReportName]);

  const getCellAlignment = (column: ReportSchemaColumnDTO): GridAlignment => {
    if (column.type === "currency") {
      return "right";
    }

    if (column.left_justify) {
      return "left";
    }

    return "center";
  };

  const getCurrency = useCallback((value: number | string) => {
    if (isNaN(Number(value))) return value;

    return Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }, []);

  const getColumnMinWidth = useCallback((column: ReportSchemaColumnDTO) => {
    if (
      ["currency", "int", "float"].includes(column.type) ||
      ["status_formatted", "carrier_name"].includes(column.dataset)
    ) {
      return 80;
    }

    if (["formatted_device_type"].includes(column.dataset)) {
      return 120;
    }

    return 150;
  }, []);

  const reportTableHeight = useMemo(() => {
    const header = document.querySelector("#app-header");
    const headerHeight = header?.clientHeight || 0;

    const reportHeader = document.querySelector("#report-header");
    const reportHeaderHeight = reportHeader?.clientHeight || 0;

    return window.innerHeight - (headerHeight + reportHeaderHeight + 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navData, user]);

  const columns: GridColDef[] = useMemo(() => {
    if (!reportSchema) return [];

    const visibleColumns = reportSchema.columns.filter(
      (column) => column.show_online
    );

    return visibleColumns
      .filter((column) => column.show_online)
      .map(
        (column): GridColDef<ReportItemDTO> => ({
          field: column.dataset || "",
          type: ["currency", "int", "float"].includes(column.type)
            ? "number"
            : "singleSelect",
          headerName: column.title,
          flex: 1,
          minWidth: getColumnMinWidth(column),
          editable: false,
          disableExport: !column.show_export,
          headerAlign: getCellAlignment(column),
          align: getCellAlignment(column),
          valueFormatter: (params) => {
            if (column.dataset === "gb_usage") {
              return Number(params.value).toFixed(2);
            }

            if (column.type === "currency") {
              return getCurrency(params.value);
            }

            return params.value;
          },
          // @ts-ignore
          valueOptions: (): ValueOptions[] =>
            uniqBy(
              report,
              (item) => item[column.dataset as keyof ReportItemDTO]
            )
              // filter out empty values
              .filter(
                (item) => item[column.dataset as keyof ReportItemDTO] !== ""
              )
              .map((item) => ({
                value: item[column.dataset as keyof ReportItemDTO],
                label: item[column.dataset as keyof ReportItemDTO],
              })),
          renderCell: (params) => {
            if (column.dataset === "display_code") {
              return (
                <Tooltip
                  title={params.row.plan_code_description}
                  placement="left"
                  arrow
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {params.formattedValue}
                  </span>
                </Tooltip>
              );
            }

            return params.formattedValue;
          },
        })
      );
  }, [getColumnMinWidth, getCurrency, report, reportSchema]);

  const getRowId = useCallback((row: ReportItemDTO) => {
    // @ts-ignore
    if (row.id) {
      // @ts-ignore
      return row.id;
    }

    if (row.statement_summary_id) {
      return row.statement_summary_id;
    }

    return "";
  }, []);

  // fetch report whenever the report name or statement month changes
  useEffect(() => {
    if (selectedReportName && selectedStatementMonth) {
      getReport();
    }
  }, [getReport, selectedReportName, selectedStatementMonth]);

  // fetch schema whenever the report name changes
  useEffect(() => {
    if (selectedReportName) {
      getReportSchema();
    }
  }, [getReportSchema, selectedReportName]);

  // set aggregation model when the schema is available
  useEffect(() => {
    if (!reportSchema) return;

    if (isEmpty(gridAggregationModel) && !isEmpty(report)) {
      setGridAggregationModel(
        reportSchema.columns.reduce((acc, column) => {
          if (column.total) {
            acc[column.dataset] = column.total;
          }

          return acc;
        }, {} as GridAggregationModel)
      );
    } else if (isEmpty(report)) {
      setGridAggregationModel({});
    }
  }, [gridAggregationModel, report, reportSchema]);

  // print the report when the print button is clicked
  useEffect(() => {
    window.onbeforeprint = () =>
      createPrintTable(
        columns,
        report,
        selectedReportName,
        selectedStatementMonth
      );
    window.onafterprint = destroyPrintTable;
  }, [columns, report, selectedReportName, selectedStatementMonth]);

  useEffect(() => {
    if (!reportSchema) return;

    setSortModel([
      {
        field: reportSchema.default_sort,
        sort: reportSchema.sort_desc ? "desc" : "asc",
      },
    ]);
  }, [reportSchema]);

  return (
    <PageContainer
      headerId="report-header"
      header="Reports"
      className="report-page"
    >
      <Paper>
        <DataGridPremium
          style={{
            fontSize: 12,
            height: reportTableHeight,
            // minHeight: 400,
            // maxHeight: 700,
          }}
          // autoHeight
          loading={
            loadingMonths ||
            loadingReportOptions ||
            loadingReport ||
            loadingReportSchema
          }
          rows={rows}
          getRowId={getRowId}
          getCellClassName={() => {
            return "cursor-pointer";
          }}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          onCellClick={(params) => {
            if (!params.value) {
              toast.warn(`Cannot copy empty value`);
              return;
            }

            const value = params.value.toString();
            navigator.clipboard.writeText(value);

            toast.success(`Copied to clipboard`);
          }}
          columns={columns}
          slots={{
            toolbar: Toolbar,
          }}
          unstable_headerFilters={report.length > 0}
          onAggregationModelChange={(model) => setGridAggregationModel(model)}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            distinct: distinctAggregation,
          }}
          aggregationModel={gridAggregationModel}
        />
      </Paper>
    </PageContainer>
  );
}
