import styled from "@emotion/styled";
import { classNames } from "@react-ms-apps/common/utils/styles";
import { Col, Spinner } from "react-bootstrap";

export const Wrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={classNames(
      "flex flex-wrap items-center mx-auto max-w-screen-xl",
      className
    )}
  >
    {children}
  </div>
);

export const InnerContentWrapper = styled(Col)`
  background: #fff;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 300px;
`;

export const Loading = () => (
  <Page.Wrapper>
    <Spinner animation="border" role="status" />
  </Page.Wrapper>
);

export const Page = {
  Wrapper,
  InnerWrapper: InnerContentWrapper,
  LogoWrapper,
  Logo,
  Loading,
};

export default Page;
