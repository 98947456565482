import { buildApiUrl } from "@react-ms-apps/common/utils";
import axios from "axios";
import {
  AdminChargeDetailReportResponse,
  AdminChargeDetailReportSchemaResponse,
} from "./types";

export * from "./types";

export async function fetchAdminChargeDetailReportSchema() {
  const resp = await axios.get<AdminChargeDetailReportSchemaResponse>(
    buildApiUrl("api/report_schema/AdminChargeDetail")
  );

  return resp.data;
}

export async function fetchAdminChargeDetailReport(
  statementMonth: string = ""
) {
  const params = new URLSearchParams();
  if (statementMonth) {
    params.append("statement_month", statementMonth);
  }

  const resp = await axios.get<AdminChargeDetailReportResponse>(
    buildApiUrl("api/report/AdminChargeDetail"),
    {
      params,
    }
  );

  return resp.data;
}

export async function deleteBalanceStatements(statementSummaryIds: number[]) {
  const promises = statementSummaryIds.map((id) =>
    axios.delete(buildApiUrl(`api/statement_summaries/${id}`))
  );

  return await Promise.all(promises);
}
